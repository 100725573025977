import React from 'react';
import Icon, { IconType } from 'components/Icon';
import { combineClasses } from 'utils/classNames';
import './style.css';

export const SortableHeaderTitle = ({
  currentSortSelection,
  value,
  title,
  setSortSelection,
  position = 'left', // left or right
  isHidden = false,
}) => {
  const onClickHeader = (e, sortCategory) => {
    e.preventDefault();
    const updatedOption = {
      value: sortCategory,
      direction:
        currentSortSelection.value === sortCategory
          ? currentSortSelection.direction === 'asc'
            ? 'desc'
            : 'asc'
          : 'desc',
    };
    setSortSelection(updatedOption);
  };

  const headerStyles = combineClasses(['flex', 'secondary', 'sortable-header', position, isHidden && 'hidden']);
  return (
    <a href="" className={headerStyles} onClick={(e) => onClickHeader(e, value)}>
      {currentSortSelection.value === value && (
        <Icon
          size="14"
          color="var(--wmg-color-grey)"
          type={currentSortSelection.direction === 'asc' ? IconType.ascSort : IconType.descSort}
        />
      )}
      {title}
    </a>
  );
};
