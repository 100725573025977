import Icon, { IconType } from 'components/Icon';
import { IconButton } from 'components/IconButton';
import React from 'react';
import { combineClasses } from 'utils/classNames';
import { Colors } from 'utils/colors';
import './style.css';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  value?: string;
  icon?: IconType;
  secure?: boolean;
  disabled?: boolean;
  optional?: boolean;
  error?: boolean;
  type?: 'text' | 'date' | 'password';
  prefix?: string;
  prepend?: () => React.ReactNode;
  inlineLabel?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onEnter?: () => void;
}

export const TextInput = ({
  placeholder,
  value,
  icon,
  secure,
  disabled,
  optional,
  error,
  type = 'text',
  prepend,
  prefix,
  inlineLabel,
  onFocus,
  onBlur,
  onEnter,
  ...props
}: TextInputProps) => {
  const [focused, setFocused] = React.useState(false);
  const [preview, setPreview] = React.useState(false);

  const containerStyles = combineClasses([
    'text-input-container',
    'grow',
    icon && 'with-icon',
    focused && 'focused',
    disabled && 'disabled',
    error && '.error',
  ]);

  const inputStyles = combineClasses(['text-input', 'grow']);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && onEnter) onEnter();
  };

  return (
    <div className={containerStyles} onClick={props.onClick && props.onClick}>
      <div className={inputStyles}>
        {icon && <Icon type={icon} color={Colors.grey} />}
        {value && type !== 'date' && inlineLabel && <span className="inline-label">{placeholder}</span>}
        {prepend && <span className="prepend">{prepend()}</span>}

        {prefix && <span className="prefix">{prefix}</span>}
        <input
          {...props}
          className={value && 'input--has-value'}
          disabled={disabled}
          onFocus={() => {
            setFocused(true);
            if (onFocus) onFocus();
          }}
          onBlur={() => {
            setFocused(false);
            if (onBlur) onBlur();
          }}
          onKeyDown={handleKeyDown}
          type={!preview && secure ? 'password' : type}
          value={value || ''}
          placeholder={`${placeholder} ${optional ? '(optional)' : ''}`}
        />
        {secure && (
          <IconButton
            onClick={() => setPreview(!preview)}
            icon={preview ? IconType.eye : IconType.eyeCross}
            color={preview ? 'rgba(255, 255, 255, 1)' : 'rgba(130, 130, 130, 1)'}
          />
        )}
      </div>
    </div>
  );
};
