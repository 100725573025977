import { FilterDataType } from 'pages/ArtistList/components/Filters/FilterDrawer';
import React from 'react';
const { selectedFilters: savedFilters } = JSON.parse(localStorage.getItem('artistlist_search_filter_state') || '{}');

const useAppliedFilters = () => {
  const [selectedFilters, setSelectedFilters] = React.useState<FilterDataType[]>(savedFilters || []);

  return { selectedFilters, setSelectedFilters };
};

export default useAppliedFilters;
