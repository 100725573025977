export const clearUser = () => {
  localStorage.removeItem('current_user');
};

export const saveUser = (user) => {
  if (!user.error && !user.detail) {
    localStorage.setItem('current_user', JSON.stringify(user));
  }
};

export const retrieveUser = () => {
  const user = localStorage.getItem('current_user');
  return user && JSON.parse(user);
};
