import React from 'react';
import Icon, { IconType } from 'components/Icon';
import './style.css';

interface IconProps {
  onClick: (id?) => void;
  icon: IconType;
  color?: string;
}
export const IconButton = ({ onClick, icon, color }: IconProps) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };
  return (
    <a href="" className="icon-button" onClick={handleOnClick}>
      <Icon type={icon} color={color} />
    </a>
  );
};
