import React from 'react';
import { useQuery } from 'react-query';
import { getCollections as getCollectionsRequest } from '../api';
import { AddCollection } from '../components/AddCollection';

type CollectionAudienceEntry = {
  id: number;
  name: string;
  image_url: string;
};

export type CollectionAudienceMutation = {
  collectionId: string;
  audienceId: string;
};

export type CollectionEntry = {
  id: string;
  name: string;
  audiences: CollectionAudienceEntry[];
  last_updated: string;
};
interface CollectionContextProps {
  state: {
    items: CollectionEntry[];
    isAddingModalVisible: boolean;
    isLoading: boolean;
    audienceId?: string;
  };
  showAddCollectionModal: (id?: string) => Promise<void>;
  hideAddCollectionModal: () => Promise<void>;
  addCollection: (name: string) => Promise<void>;
  removeCollection: (collectionId: string) => Promise<void>;
  addToCollection: (collectionAudience: CollectionAudienceMutation) => Promise<void>;
  removeFromCollection: (collectionAudience: CollectionAudienceMutation) => Promise<void>;
}

const CollectionContext = React.createContext<CollectionContextProps | undefined>(undefined);
enum ActionTypes {
  GET_COLLECTIONS,
  ADD_COLLECTION,
  REMOVE_COLLECTION,
  ADD_TO_COLLECTION,
  REMOVE_FROM_COLLECTION,
  TOGGLE_ADD_COLLECTION_MODAL,
}

function collectionReducer(state, action) {
  switch (action.type) {
    case ActionTypes.GET_COLLECTIONS: {
      return { ...state, ...action.payload };
    }
    case ActionTypes.ADD_COLLECTION: {
      return { ...state, ...action.payload };
    }
    case ActionTypes.REMOVE_COLLECTION: {
      return { ...state, ...action.payload };
    }
    case ActionTypes.ADD_TO_COLLECTION: {
      return { ...state, ...action.payload };
    }
    case ActionTypes.REMOVE_FROM_COLLECTION: {
      return { ...state, ...action.payload };
    }
    case ActionTypes.TOGGLE_ADD_COLLECTION_MODAL: {
      return { ...state, ...action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
const initialState = {
  items: [],
  isLoading: true,
  isAddingModalVisible: false,
};

export function CollectionProvider({ children }) {
  const { data: items, isLoading } = useQuery('collections', getCollectionsRequest, {
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const [state, dispatch] = React.useReducer(collectionReducer, initialState);

  const showAddCollectionModal = React.useCallback(async (audienceId) => {
    dispatch({
      type: ActionTypes.TOGGLE_ADD_COLLECTION_MODAL,
      payload: {
        isAddingModalVisible: true,
        audienceId: audienceId,
      },
    });
  }, []);

  const hideAddCollectionModal = React.useCallback(async () => {
    dispatch({
      type: ActionTypes.TOGGLE_ADD_COLLECTION_MODAL,
      payload: { isAddingModalVisible: false, audienceId: false },
    });
  }, []);

  const addCollection = React.useCallback(async (name) => {
    dispatch({
      type: ActionTypes.ADD_COLLECTION,
      payload: name,
    });
  }, []);

  const removeCollection = React.useCallback(async (collectionId) => {
    dispatch({
      type: ActionTypes.REMOVE_COLLECTION,
      payload: collectionId,
    });
  }, []);

  const addToCollection = React.useCallback(async (collectionAudience) => {
    dispatch({
      type: ActionTypes.ADD_TO_COLLECTION,
      payload: collectionAudience,
    });
  }, []);

  const removeFromCollection = React.useCallback(async (collectionAudience) => {
    dispatch({
      type: ActionTypes.REMOVE_FROM_COLLECTION,
      payload: collectionAudience,
    });
  }, []);

  return (
    <CollectionContext.Provider
      value={{
        state: { ...state, items, isLoading },
        showAddCollectionModal,
        hideAddCollectionModal,
        addCollection,
        removeCollection,
        addToCollection,
        removeFromCollection,
      }}
    >
      {children}
      <AddCollection isVisible={state.isAddingModalVisible} onClose={hideAddCollectionModal} />
    </CollectionContext.Provider>
  );
}

export function useCollection() {
  const context = React.useContext(CollectionContext);
  if (context === undefined) {
    throw new Error('useCollection must be used within a CollectionProvider');
  }
  return context;
}
