import React from 'react';
import './style.css';
interface Props {
  message: string;
}

const Notification = ({ message }: Props) => {
  return (
    <div className="notification-wrapper">
      <div className="notification">{message}</div>
    </div>
  );
};
export default Notification;
