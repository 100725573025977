import React from 'react';

export const useOutsideClick = ({ isVisible, handleClickOutside }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const detectOutsideClick = (e) => {
      if (isVisible && ref.current && !ref.current.contains(e.target)) {
        handleClickOutside(false);
      }
    };
    document.addEventListener('mousedown', detectOutsideClick);
    return () => {
      document.removeEventListener('mousedown', detectOutsideClick);
    };
  }, [isVisible, handleClickOutside]);

  return { ref };
};
