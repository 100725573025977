import api from 'utils/api';

export const getCountries = async (audienceId, countries) => {
  const data = await api(
    `reporting/segment/${audienceId}/country${
      countries && countries.length ? `?countries=${countries.join('|')}` : ''
    }`,
  );
  if (data.error) {
    return [];
  }

  if (data.length && data[data.length - 1].name === 'Other') {
    data.pop();
  }
  return data;
};
