import React from 'react';
import jwtDecode from 'jwt-decode';
import { refreshAuthToken, authUser, currentUser, signOutUser } from './api';
import {
  clearToken,
  retrieveToken,
  retrieveTokenExpiry,
  saveRefreshToken,
  saveToken,
  saveTokenExpiry,
} from 'utils/authToken';
import { clearUser, retrieveUser, saveUser } from 'utils/user';
import { queryClient } from 'App';
import { DestinationType } from 'pages/CustomAudience/api';

enum ActionTypes {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILED,
  SIGN_OUT_SUCCESS,
  UPDATE_USER,
}

export type UserCredentials = {
  username: string;
  password: string;
};

export type User = {
  email: string;
  exp: number;
  is_staff: boolean;
  jti: string;
  token_type: string;
  user_id: number;
};

const token = retrieveToken();
const tokenExpiration = retrieveTokenExpiry();
const now = new Date();
const isTokenValid = tokenExpiration && new Date(parseInt(tokenExpiration) * 1000) > now;

const cachedUser = retrieveUser();

interface AuthContextProps {
  state: {
    token: string;
    error: string;
    authenticated: boolean;
    user: {
      email: string;
      name: string;
      permissions: { connex_only: boolean };
      destinations: DestinationType[];
    };
  };
  signIn: (credentials: UserCredentials) => void;
  signOut: () => void;
}

const AuthContext = React.createContext<AuthContextProps | undefined>(undefined);

const initialState = { token, user: cachedUser, authenticated: isTokenValid };

function authReducer(state, action) {
  switch (action.type) {
    case ActionTypes.SIGN_IN_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case ActionTypes.SIGN_IN_FAILED: {
      return { ...state, error: action.payload };
    }
    case ActionTypes.SIGN_OUT_SUCCESS: {
      return { token: false, user: false, authenticated: false };
    }
    case ActionTypes.UPDATE_USER: {
      return { ...state, user: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function AuthProvider({ children }) {
  const [state, dispatch] = React.useReducer(authReducer, initialState);

  const getCurrentUser = React.useCallback(async () => {
    const user = await currentUser();
    saveUser(user);
    dispatch({
      type: ActionTypes.UPDATE_USER,
      payload: user,
    });
  }, []);

  const signIn = React.useCallback(async (credentials) => {
    // try {
    const data = await authUser(credentials);

    if (data.detail) {
      dispatch({
        type: ActionTypes.SIGN_IN_FAILED,
        payload: data.detail,
      });
      return;
    }
    const claims = jwtDecode<User>(data.access);

    saveToken(data.access);
    saveTokenExpiry(claims.exp);
    saveRefreshToken(data.refresh);
    const user = await currentUser();
    saveUser(user);

    dispatch({
      type: ActionTypes.SIGN_IN_SUCCESS,
      payload: {
        token: data.access,
        user,
        claims,
        authenticated: true,
      },
    });
    // } catch (error) {
    //   dispatch({
    //     type: ActionTypes.SIGN_IN_FAILED,
    //     payload: error.message,
    //   });
    // }
  }, []);

  const signOut = React.useCallback(async () => {
    queryClient.clear();
    await signOutUser();
    clearToken();
    clearUser();
    localStorage.clear();
    dispatch({ type: ActionTypes.SIGN_OUT_SUCCESS });
  }, []);

  const refreshToken = React.useCallback(async () => {
    try {
      const { data, claims } = await refreshAuthToken();
      dispatch({
        type: ActionTypes.SIGN_IN_SUCCESS,
        payload: { token: data.access, authenticated: true, claims },
      });
    } catch (error) {
      signOut();
    }
  }, [signOut]);

  React.useEffect(() => {
    (async () => {
      if (token && !isTokenValid) {
        await refreshToken();
        await getCurrentUser();
      }
    })();
  }, [refreshToken, getCurrentUser]);

  return <AuthContext.Provider value={{ state, signIn, signOut }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}
