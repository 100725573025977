import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Artwork } from 'components/Artwork';
import Icon, { IconType } from 'components/Icon';
import { Tag } from 'components/Tag';
import { Card } from 'components/Card';
import './style.css';
import { AddToCollectionButton } from '../AddToCollectionButton';
import Notification from 'components/Notification';

export type ArtistType = {
  id?: string;
  image_url?: string;
  name?: string;
};
interface ArtistCardProps extends ArtistType {
  image?: string;
  display_name?: string;
  display_segment_id?: string;
  size?: string;
  query_size?: string;
  isLoading?: boolean;
  isFiltered?: boolean;
}

export const ArtistCard = ({
  image_url,
  image,
  display_segment_id,
  name,
  size,
  query_size,
  isLoading,
  isFiltered,
}: ArtistCardProps) => {
  const history = useHistory();
  const [showNoAudienceMessage, setShowNoAudienceMessage] = useState(false);

  const handleOnClick = () => {
    if (!isLoading) {
      if (!size) {
        setShowNoAudienceMessage(!showNoAudienceMessage);
        return;
      }
      history.push(`/artist/${display_segment_id}`, { name, image_url });
    }
  };

  return (
    <div className="artist-card column gap" onClick={handleOnClick}>
      <Card placeholder={isLoading} noscroll>
        {!isLoading && <Artwork image={image_url || image} />}
        {!isLoading && display_segment_id && <AddToCollectionButton id={display_segment_id} isIcon />}
        {!isLoading && showNoAudienceMessage && (
          <Notification message="Uh oh! This artist has no banked audience yet." />
        )}
      </Card>

      {isLoading && (
        <div className="column gap">
          <h3 className="placeholder">{'Artist name'}</h3>
          <h5 className="placeholder">302m</h5>
        </div>
      )}
      {!isLoading && (
        <div className="column">
          {name && <h3>{name}</h3>}
          <div className="row center">
            {isFiltered && query_size && (
              <Tag small>
                <Icon type={IconType.filter} />
                {query_size.toLocaleString()}
              </Tag>
            )}
            {!!size && (
              <div className="secondary">
                {isFiltered && 'of '}
                {size.toLocaleString()} audience
              </div>
            )}
            {!isLoading && !size && <div className="secondary">no audience</div>}
          </div>
        </div>
      )}
    </div>
  );
};
