import React from 'react';
import { Grid } from 'components/Grid';
import { CollectionCard } from '../CollectionCard';
import { CollectionEntry } from 'pages/CollectionList/context';
import { Card } from 'components/Card';

interface CollectionGridProps {
  isLoading?: boolean;
  data: CollectionEntry[];
}

export const CollectionGrid = ({ isLoading, data }: CollectionGridProps) => (
  <Grid cols={3}>
    {isLoading && [...new Array(9)].map((d, i) => <Card key={i.toString()} placeholder collection />)}
    {!isLoading && data.map((d) => <CollectionCard key={d.id} {...d} />)}
  </Grid>
);
