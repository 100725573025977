import Icon, { IconType } from 'components/Icon';
import React from 'react';
import { combineClasses } from 'utils/classNames';
import './style.css';

interface TagProps {
  children: React.ReactNode;
  onClick?: () => void;
  small?: boolean;
  medium?: boolean;
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
  count?: number;
}

export const Tag = ({ children, onClick, small, medium, active, selected, disabled, count }: TagProps) => {
  const styles = combineClasses([
    'tag',
    'bold',
    'row',
    small && 'small',
    medium && 'medium',
    active && 'active',
    selected && 'selected',
    disabled && 'disabled',
  ]);

  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) onClick();
  };

  if (onClick)
    return (
      <a href="" onClick={handleOnClick} className={styles}>
        {children}
        {selected && <Icon type={IconType.cross} size={16} />}
        {!!count && <div className="count caption bold">{count}</div>}
      </a>
    );
  return <div className={styles}>{children}</div>;
};
