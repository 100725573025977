import api from 'utils/api';
import { UserCredentials } from 'contexts/AuthContext';
import { currentUserHelper, refreshAuthTokenHelper } from './helpers';

export const currentUser = async () => currentUserHelper(await api('users/current_user?partner=14'));

export const refreshAuthToken = async () =>
  refreshAuthTokenHelper(
    await api('token/refresh/', {
      method: 'POST',
    }),
  );

export const authUser = ({ username, password }: UserCredentials) =>
  api('token/', {
    method: 'POST',
    body: JSON.stringify({ username, password }),
  });

export const signOutUser = () => api('token/logout/', { method: 'POST' });
