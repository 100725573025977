import { Tag } from 'components/Tag';
import { isDemographic } from 'pages/ArtistList/api';
import { CreateAudienceModal } from 'pages/CustomAudience/components/CreateAudienceModal';
import Spinner from 'react-svg-spinner';
import React from 'react';
import { updateCustomAudience } from 'pages/CustomAudience/api';
import { useMutation } from 'react-query';
import { FilterDataType } from '../Filters/FilterDrawer';
import PopOver, { PopOverItem } from 'components/PopOver';
import Icon, { IconType } from 'components/Icon';

type ConditionalFilterType = {
  operator: 'and' | 'or';
  items: { operator: 'and' | 'or'; label: string }[];
};

export type GroupOperatorType = {
  demo: 'or' | 'and';
  rules: 'or' | 'and';
};

const conditionalFilters = (filters, groupOperators) => {
  const groupedFilters: ConditionalFilterType[] = [];
  const hasDemo = filters.filter((d) => isDemographic(d.category));
  const hasGender = hasDemo.filter((d) => d.value === 'male' || d.value === 'female');
  const hasAge = hasDemo.filter((d) => !(d.value === 'male' || d.value === 'female' || d.category === 'country'));
  const hasCountry = hasDemo.filter((d) => d.category === 'country');
  const hasNotDemo = filters
    .filter((d) => !isDemographic(d.category))
    .reduce((prev, cur) => {
      const existsAt = prev.findIndex((d) => d.items.some((i) => i.category === cur.category));
      if (existsAt < 0) {
        prev.push({
          operator: groupOperators.rules || 'and',
          items: [cur],
        });
        return prev;
      }

      prev[existsAt].items.push(cur);
      return prev;
    }, []);
  if (hasDemo.length) {
    if (hasGender.length) {
      groupedFilters.push({
        operator: groupOperators.demo || 'and',
        items: hasGender,
      });
    }
    if (hasAge.length) {
      groupedFilters.push({
        operator: groupOperators.demo || 'and',
        items: hasAge.sort((a, b) => a.value.localeCompare(b.value)),
      });
    }
    if (hasCountry.length) {
      groupedFilters.push({
        operator: groupOperators.demo || 'and',
        items: hasCountry,
      });
    }
  }
  if (hasNotDemo.length) {
    groupedFilters.push(...hasNotDemo);
  }
  return groupedFilters;
};

const Operator = ({ value, onChange, group = false }) => {
  const [editing, setEditing] = React.useState(false);

  const onEdit = (e) => {
    e.preventDefault();
    setEditing(true);
  };
  const handleChange = (v) => {
    if (onChange && v !== value) {
      onChange(v);
    }
    setEditing(false);
  };
  const isNotChangeable = React.useMemo(
    () =>
      (group &&
        group[0].category === 'age_gender_membership' &&
        group[0].value !== 'female' &&
        group[0].value !== 'male') ||
      (group && group[0].category === 'country'),
    [group],
  );

  if (isNotChangeable) return null;

  return (
    <span style={{ position: 'relative' }}>
      <a href="#" onClick={onEdit} className={`${group && editing ? '' : 'secondary no-cursor'} caption`}>
        {group ? <Icon type={IconType.vertDots} color="rgba(255,255,255,0.5)" size={16} /> : 'and'}
      </a>
      {group && (
        <PopOver isVisible={editing} onCancel={() => setEditing(false)} left>
          {['or', 'and'].map((v) => (
            <PopOverItem key={v} onClick={handleChange.bind(null, v)}>
              {v}
            </PopOverItem>
          ))}
        </PopOver>
      )}
    </span>
  );
};

const GroupedFilters = ({
  items,
  operator,
  groupIndex,
  groupList,
  onChange,
  onGroupChange,
  onFilterCategoryChange,
  disabled,
}) => {
  const handleGroupOperatorChange = (v, d) => {
    onFilterCategoryChange(groupList[v].items[0].category, d);
  };

  const handleOperatorChange = (v, o) => {
    const isDemo = isDemographic(groupList[v].items[0].category.toLowerCase());
    if (isDemo) {
      onGroupChange({ demo: o });
    } else {
      onGroupChange({ rules: o });
    }
  };

  if (!items.length) return null;
  return (
    <React.Fragment key={groupIndex}>
      <div className="tag-group row gap center">
        {items.map((d, i, a) => (
          <React.Fragment key={i}>
            <Tag selected disabled={disabled} onClick={disabled ? undefined : () => onChange(d)}>
              {d.label.split('/').pop()}
            </Tag>
            {i !== a.length - 1 && <span className={`secondary caption`}>{d.operator}</span>}
          </React.Fragment>
        ))}
        {items.length > 1 && (
          <Operator
            group={items}
            value={items[0].operator}
            onChange={handleGroupOperatorChange.bind(null, groupIndex)}
          />
        )}
      </div>
      {groupIndex !== groupList.length - 1 && groupList.length > 1 && (
        <Operator value={operator} onChange={handleOperatorChange.bind(null, groupIndex)} />
      )}
    </React.Fragment>
  );
};

interface AppliedFiltersProps {
  audienceName?: string;
  audienceId?: string | undefined;
  disabled?: boolean;
  filters: FilterDataType[];
  groupOperators?: GroupOperatorType;
  onChange?: (filter: FilterDataType) => void;
  onGroupChange?: (filter: FilterDataType) => void;
  onFilterCategoryChange?: (category: string, operator: 'or' | 'and') => void;
  onReset?: () => void;
}

export const AppliedFilters = ({
  audienceName,
  audienceId,
  filters,
  groupOperators,
  onChange,
  onGroupChange,
  onFilterCategoryChange,
  onReset,
  disabled,
}: AppliedFiltersProps) => {
  const [isCreating, setIsCreating] = React.useState(false);

  const { mutateAsync, isLoading: isUpdating } = useMutation(updateCustomAudience);

  const onUpdateAudience = async (audience, e) => {
    e.preventDefault();
    await mutateAsync(audience);
    setIsCreating(true);
    if (onReset) onReset();
  };

  const onCreateAudience = async (e) => {
    e.preventDefault();
    setIsCreating(true);
  };

  return (
    <>
      <div className="row wrap">
        <div className="row flex wrap gap center">
          {!!filters.length &&
            conditionalFilters(filters, groupOperators || {}).map(({ items, operator }, groupIndex, groupList) => (
              <GroupedFilters
                key={groupIndex}
                {...{
                  items,
                  operator,
                  groupIndex,
                  groupList,
                }}
                onChange={onChange}
                onGroupChange={onGroupChange}
                onFilterCategoryChange={onFilterCategoryChange}
                disabled={disabled}
              />
            ))}
        </div>
        {isUpdating && <Spinner size="24px" color="#fff" />}
        {!isUpdating && !!filters.length && (
          <div className="column end">
            {!audienceId && (
              <a href="" className="bold primary" onClick={onCreateAudience}>
                Create Custom
              </a>
            )}
            {onChange && audienceId && (
              <a
                href=""
                className="bold primary"
                onClick={onUpdateAudience.bind(null, {
                  id: audienceId,
                  name: audienceName,
                  filters,
                })}
              >
                Save
              </a>
            )}
            {onReset && (
              <a href="" className="secondary" onClick={onReset}>
                Clear all
              </a>
            )}
          </div>
        )}
      </div>
      {onReset && (
        <CreateAudienceModal
          audienceId={audienceId}
          audienceName={audienceName}
          filters={filters}
          visible={isCreating}
          onClose={() => setIsCreating(false)}
          onSuccess={onReset}
        />
      )}
    </>
  );
};
