import api from 'utils/api';
import { getCollectionSizeHelper } from './helpers';

export const getCollection = async (id) => {
  const c = await api(`audiences/collections/${id}`);
  return {
    ...c,
    audiences: c.audiences.map((a) => ({
      ...a,
      id: a.display_segment_id,
    })),
  };
};

export const getCollectionDemographicSummary = (segments) =>
  api(`reporting/partner/age_gender_collection_summary?${segments.map((s) => `segments=${s}`).join('&')}`);
export const getCollectionSize = (audiencesIds) =>
  api('audience-builder/segment/calculate_size', {
    method: 'POST',
    body: JSON.stringify(getCollectionSizeHelper(audiencesIds)),
  });
