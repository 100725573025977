import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getInterests } from './api';
import { Table } from 'components/Table';
import { filterByKey } from 'utils/arrays';
import { SearchBar } from 'pages/ArtistList/components/SearchBar';
import { Pagination } from 'components/Pagination';
import { combineClasses } from 'utils/classNames';
import { TableCard } from 'components/TableCard';
import { IndexTooltip } from 'components/Tooltips/IndexTooltip';
import { SortableHeaderTitle } from 'components/Table/SortHelper/SortableHeaderTitle';
import { useFilterByCountry } from '../FilterByCountry/context';
import { useAuth } from '../../../../contexts/AuthContext';

type InterestDataType = {
  name: string;
  count: string;
  index: string;
};

const pageSize = 20;

const interestTypes = [
  { label: 'In-Market Segments', value: 'inmarket' },
  { label: 'Affinity Segments', value: 'affinity' },
  { label: 'Other Interest Segments', value: 'other' },
];

export const Interests = () => {
  const { id } = useParams();
  const {
    state: { countries },
  } = useFilterByCountry();
  const {
    state: {
      user: {
        permissions: { connex_only: isConnexOnly },
      },
    },
  } = useAuth();
  const [selectedInterest, setSelectedInterest] = React.useState(interestTypes[0].value);
  const [isInitialLoading, setIsInitialLoading] = React.useState(true);
  const { data, isFetching: isLoading } = useQuery(
    [`artist_interests_${id}_${selectedInterest}`, countries],
    () => getInterests(id, selectedInterest, countries),
    { staleTime: 0, refetchOnWindowFocus: false },
  );
  const [sortSelection, setSortSelection] = useState({
    value: isConnexOnly ? 'count' : 'index',
    direction: 'desc',
  });

  React.useEffect(() => {
    if (!isLoading && isInitialLoading) {
      setIsInitialLoading(false);
    }
  }, [isInitialLoading, isLoading]);

  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<InterestDataType[]>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const totalPages = React.useMemo(() => filteredData && Math.ceil(filteredData.length / pageSize), [filteredData]);

  React.useEffect(() => {
    if (!isLoading && data) {
      setFilteredData(filterByKey(data, searchQuery, 'name'));
    }
  }, [isLoading, searchQuery, data]);

  const onQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const onTabChange = (value, e) => {
    e.preventDefault();
    setCurrentPage(1);
    setSelectedInterest(value);
  };

  const sortedData = useMemo(() => {
    if (filteredData && filteredData.length) {
      if (sortSelection.direction === 'asc') {
        return filteredData.sort((a, b) => a[sortSelection.value] - b[sortSelection.value]);
      } else if (sortSelection.direction === 'desc') {
        return filteredData.sort((a, b) => b[sortSelection.value] - a[sortSelection.value]);
      }
    }
    return [];
  }, [filteredData, sortSelection.direction, sortSelection.value]);

  return (
    <TableCard
      header={
        <>
          <div>Interest & Affinity Segmentation</div>
          {!isInitialLoading && (
            <div className="row">
              {interestTypes.map((d) => (
                <div key={d.value} className={combineClasses(['nav-item', selectedInterest === d.value && 'active'])}>
                  <a href="" onClick={onTabChange.bind(null, d.value)} className="caption">
                    {d.label}
                  </a>
                </div>
              ))}
            </div>
          )}
        </>
      }
      isLoading={isLoading}
      noData={!data || !data.length}
    >
      <>
        {data && !!data.length && (
          <div className="column">
            <SearchBar compact query={searchQuery} onChange={onQueryChange} disabled={isLoading} />
          </div>
        )}
        <Table
          header={
            <>
              <th style={{ width: '100%' }} />
              <td style={{ minWidth: 110, textAlign: 'right' }}>
                {!isConnexOnly && (
                  <SortableHeaderTitle
                    position="right"
                    currentSortSelection={sortSelection}
                    setSortSelection={setSortSelection}
                    title="Affinity Score"
                    value="index"
                    isHidden={!!countries.length}
                  />
                )}
              </td>
              <td style={{ minWidth: 100, textAlign: 'right' }}>
                <SortableHeaderTitle
                  position="right"
                  currentSortSelection={sortSelection}
                  setSortSelection={setSortSelection}
                  title="Size"
                  value="count"
                />
              </td>
            </>
          }
          isLoading={isLoading}
        >
          {!isLoading &&
            sortedData.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((d) => (
              <tr key={d.name} className="caption">
                <td>{d.name}</td>
                <td className={'bold'} style={{ textAlign: 'right' }}>
                  {!isConnexOnly && (
                    <>
                      <IndexTooltip index={Number(d.index)} direction="up" isHidden={!!countries.length} />
                      {Number(d.index) / 100}
                    </>
                  )}
                </td>
                <td className={'bold'} style={{ textAlign: 'right' }}>
                  {d.count.toLocaleString()}
                </td>
              </tr>
            ))}
        </Table>
        <div className="column center">
          {!isLoading && <Pagination currentPage={currentPage} totalPages={totalPages} onChange={setCurrentPage} />}
        </div>
      </>
    </TableCard>
  );
};
