import api from 'utils/api';

export const getInterests = async (segmentId, interest_type, countries) => {
  const data = await api(
    `reporting/segment/${segmentId}/interest_${interest_type}${
      countries && countries.length ? `?countries=${countries.join('|')}` : ''
    }`,
  );
  return data;
};
