import React from 'react';
import { SortBy } from './SortBy';

import './style.css';

export const ArtistListHeader = ({ isLoading, artistCount, totalAudience, onSort, sortSelection, selectedFilters }) => {
  return (
    <>
      {isLoading && <h3 className="placeholder">6387 artists</h3>}
      {!isLoading && (
        <div className="row center">
          <div className="flex row center total-counts">
            <h3>{totalAudience.toLocaleString()} total audience</h3>
            <div className={`secondary`}>{artistCount} artists</div>
          </div>

          <SortBy {...{ onSort, sortSelection, filtered: selectedFilters.length }} />
        </div>
      )}
    </>
  );
};
