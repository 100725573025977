import { Table } from 'components/Table';
import { SortableHeaderTitle } from 'components/Table/SortHelper/SortableHeaderTitle';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { IconType } from 'components/Icon';
import { IconButton } from 'components/IconButton';
import { DeleteAudienceModal } from '../DeleteAudienceModal';
import { Pagination } from 'components/Pagination';
import { Checkbox } from 'components/Checkbox';
import { EditDestinationsModal } from '../EditDestinations';
import { AudienceType } from 'pages/CustomAudience/api';
import { useAuth } from 'contexts/AuthContext';

const pageSize = 20;

export const AudiencesTable = ({ data }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(null);
  const [showDestinationsModal, setShowDestinationsModal] = React.useState(false);
  const [selectedAudiences, setSelectedAudiences] = React.useState<AudienceType[]>([]);
  const {
    state: {
      user: {
        permissions: { connex_only: isConnexOnly },
      },
    },
  } = useAuth();

  const history = useHistory();

  const handleDelete = (id) => {
    setShowDeleteConfirmation(id);
  };

  const handleSelection = (audience) => {
    setSelectedAudiences((audiences) =>
      audiences.some((a) => a.id === audience.id) ? audiences.filter((i) => i !== audience) : [audience, ...audiences],
    );
  };

  const handleEditDestinations = (e, audience = undefined) => {
    if (e) e.preventDefault();
    if (audience) setSelectedAudiences([audience]);
    setShowDestinationsModal(true);
  };

  const handleModalClose = () => {
    setSelectedAudiences([]);
    setShowDestinationsModal(false);
  };

  const [sortSelection, setSortSelection] = React.useState('name');
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const totalPages = React.useMemo(() => data && Math.ceil(data.length / pageSize), [data]);

  const onSelection = (d, e) => {
    e.preventDefault();
    e.stopPropagation();

    localStorage.setItem(
      'artistlist_search_filter_state',
      JSON.stringify({
        audienceName: d.name,
        audienceId: d.id,
        selectedFilters: d.filters,
      }),
    );
    history.push(`/`);
  };

  const handleOnClick = ({ id, name, ...audience }, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (audience.collection) {
      // const collection = collections.find(({ audiences }) =>
      //   audiences.every((d) => audience.audience.includes(d.id)),
      // );

      history.push(`/collections/${audience.collection.id}?customAudience=${id}`);
      return;
    }

    history.push(`/artist/${id}?filters=1`, {
      customAudience: true,
      name,
      ...audience,
    });
  };

  if (!data) return null;

  return (
    <div className="column">
      <Table
        header={
          <>
            {!isConnexOnly && <td style={{ minWidth: 40 }}></td>}
            <td width={!isConnexOnly ? '30%' : '60%'}>
              <SortableHeaderTitle
                currentSortSelection={sortSelection}
                setSortSelection={setSortSelection}
                title="Audience Name"
                value="name"
                position="left"
              />
            </td>
            <td width="30%" className="secondary">
              Filters
            </td>
            {!isConnexOnly && (
              <td width="30%" className="secondary">
                <div className="row">
                  Destinations
                  {selectedAudiences.length > 0 && (
                    <a href="/" onClick={handleEditDestinations} className="caption underline primary bold">
                      Edit
                    </a>
                  )}
                </div>
              </td>
            )}
            <td style={{ minWidth: 100, paddingRight: 20 }}>
              <SortableHeaderTitle
                currentSortSelection={sortSelection}
                setSortSelection={setSortSelection}
                title="Approx Size"
                value="size"
                position="right"
              />
            </td>
            <td style={{ width: 50 }} />
          </>
        }
      >
        {data.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((d) => (
          <tr key={d.id} className="gap">
            {!isConnexOnly && (
              <td>
                <Checkbox
                  onClick={handleSelection.bind(null, d)}
                  checked={selectedAudiences.some((s) => s.id === d.id)}
                />
              </td>
            )}
            <td>
              <div className="row center">
                <a href="" onClick={handleOnClick.bind(null, d)} className="bold row underline">
                  {d.name}
                  {d.collection && (
                    <span
                      className="bold caption"
                      style={{
                        backgroundColor: 'var(--wmg-color-white)',
                        color: 'var(--wmg-color-background2)',
                        borderRadius: 'var(--wmg-border-radius-medium)',
                        padding: '2px 5px',
                      }}
                    >
                      Collection
                    </span>
                  )}
                </a>
              </div>
            </td>
            <td>
              <div className="row center gap-s wrap">
                {d.filters.slice(0, 3).map((f) => (
                  <span key={f.label} className="tag-group">
                    {f.label.split('/').pop()}
                  </span>
                ))}
                {d.filters.length > 3 && <span className="secondary">{`+${d.filters.length - 3}`}</span>}
                {!d.audience && (
                  <a
                    key={d.id}
                    href="/"
                    onClick={onSelection.bind(null, d)}
                    className="row center caption gap-l underline primary bold"
                  >
                    Edit
                  </a>
                )}
              </div>
            </td>
            {!isConnexOnly && (
              <td>
                <div className="column gap-s wrap" onClick={(e) => handleEditDestinations(e, d)}>
                  {d.destinations.slice(0, 3).map((f, i) => (
                    <div key={i} className="row center">
                      <span key={f.name}>{f.name}</span>
                      <span className="secondary">{` Acc:(${f.account_id})`}</span>
                    </div>
                  ))}
                  {d.destinations.length > 3 && <span className="secondary">{`+${d.destinations.length - 3}`}</span>}

                  <a
                    key={d.id}
                    href="/"
                    onClick={(e) => handleEditDestinations(e, d)}
                    className="row center caption gap-l underline primary bold"
                  >
                    {!!d.destinations.length ? 'Edit' : 'Add destination'}
                  </a>
                </div>
              </td>
            )}
            <td style={{ textAlign: 'right', paddingRight: 20 }}>
              {d.collection
                ? d.collection.audiences.reduce((p, c) => c.size + p, 0).toLocaleString()
                : d.size.toLocaleString()}
            </td>
            <td className="delete-button">
              <IconButton icon={IconType.trash} onClick={handleDelete.bind(null, d.id)} />
            </td>
          </tr>
        ))}
      </Table>
      <EditDestinationsModal
        onClose={handleModalClose}
        isVisible={showDestinationsModal}
        audiences={selectedAudiences}
      />
      <DeleteAudienceModal onClose={handleModalClose} audienceId={showDeleteConfirmation} />
      <div className="column center">
        <Pagination currentPage={currentPage} totalPages={totalPages} onChange={setCurrentPage} />
      </div>
    </div>
  );
};
