import api from 'utils/api';

export const getCollections = async () => {
  const collections = await api('audiences/collections');
  return collections.map((c) => ({
    ...c,
    audiences: c.audiences.map((a) => ({ ...a, id: a.display_segment_id })),
  }));
};

export const addToCollection = ({ collectionId, audienceId }) =>
  api(`audiences/collections/${collectionId}/audience`, {
    method: 'POST',
    body: JSON.stringify({ audienceId }),
  });

export const removeFromCollection = ({ collectionId, audienceId }) =>
  api(`audiences/collections/${collectionId}/audience`, {
    method: 'DELETE',
    body: JSON.stringify({ audienceId }),
  });
