import { Button } from 'components/Button';
import { ErrorMessage } from 'components/ErrorMessage';
import { IconType } from 'components/Icon';
import { IconButton } from 'components/IconButton';
import { Modal } from 'components/Modal';
import { TextInput } from 'components/TextInput';
import { updatePassword } from 'pages/ResetPassword/api';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

interface ChangePasswordModalProps {
  visible: boolean;
  onClose: () => void;
}

export const ChangePasswordModal = ({ visible, onClose }: ChangePasswordModalProps) => {
  const { control, handleSubmit } = useForm();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const onSubmit = async (data) => {
    setLoading(true);
    if (data.newPassword !== data.newPasswordConfirm) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }
    const status = await updatePassword(data);
    setLoading(false);

    if (status.error) {
      setError('Old password is incorrect');
      setLoading(false);
      return;
    }

    setSuccess(true);
  };

  return (
    <Modal visible={visible}>
      <div className="column gap card center">
        <div className="row center">
          <h4 className="flex">Change Password</h4>
          <IconButton icon={IconType.cross} onClick={onClose} />
        </div>
        {error && <ErrorMessage message={error} />}
        {!success && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="column v-spacing">
              <Controller
                name="oldPassword"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => <TextInput secure placeholder="Old Password" {...field} />}
              />
              <Controller
                name="newPassword"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => <TextInput secure placeholder="Password" {...field} />}
              />
              <Controller
                name="newPasswordConfirm"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <TextInput secure placeholder="Confirm password" {...field} />
                )}
              />
            </div>
            <Button primary submit onClick={handleSubmit(onSubmit)} loading={loading}>
              Update Password
            </Button>
          </form>
        )}
        {success && (
          <div
            className="column center gap"
            style={{
              padding: 30,
              backgroundColor: 'var(--wmg-color-background)',
              borderRadius: 'var(--wmg-border-radius-small)',
            }}
          >
            <span>You password has been successfully changed</span>
          </div>
        )}
      </div>
    </Modal>
  );
};
