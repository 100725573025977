import { SignIn } from 'pages/SignIn';
import ArtistList from 'pages/ArtistList';
import ArtistDetail from 'pages/ArtistDetail';
import CollectionList from 'pages/CollectionList';
import { CollectionDetail } from 'pages/CollectionDetail';
import CustomAudience from 'pages/CustomAudience';
import { ResetPassword } from 'pages/ResetPassword';
import { ForgotPassword } from 'pages/ForgotPassword';

export default [
  {
    label: 'Sign In',
    path: '/login',
    slug: 'sign_in',
    component: SignIn,
    public: true,
    exact: true,
  },
  {
    label: 'Forgot Password',
    path: '/forgot-password',
    slug: 'forgot_password',
    component: ForgotPassword,
    public: true,
    exact: false,
  },
  {
    label: 'Reset Password',
    path: '/reset-password/:uid/:token',
    slug: 'reset_password',
    component: ResetPassword,
    public: true,
    exact: false,
  },
  {
    label: 'Artist Catalogue',
    path: '/',
    slug: 'artist_catalogue',
    component: ArtistList,
    navbar: true,
    exact: true,
  },
  {
    label: 'Custom Audiences',
    path: '/audiences',
    slug: 'custom_audience',
    component: CustomAudience,
    navbar: true,
    exact: true,
  },
  {
    label: 'My Collections',
    path: '/collections',
    slug: 'collections',
    component: CollectionList,
    navbar: true,
    exact: true,
  },
  {
    label: 'My Collections',
    path: '/collections/:id',
    slug: 'collection_detail',
    component: CollectionDetail,
    exact: true,
  },
  {
    label: 'Artist Detail',
    path: '/artist/:id',
    slug: 'artist_detail',
    component: ArtistDetail,
    exact: true,
  },
];
