import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { AddToCollectionButton } from 'pages/ArtistList/components/AddToCollectionButton';
import { getAudienceSegment, getMetadata, getMetrics } from 'pages/ArtistDetail/api';
import { useQueriesTyped } from 'hooks/useQueriesTyped';
import { combineClasses } from 'utils/classNames';
import './style.css';
import DownloadButton from '../../../ArtistList/components/DownloadButton';
import { FilterByCountry } from '../FilterByCountry';
import { useFilterByCountry } from '../FilterByCountry/context';
import { AppliedFilters } from 'pages/ArtistList/components/AppliedFilters';

export const ArtistDetailHeader = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const {
    state: { countries },
  } = useFilterByCountry();

  const [headerScrolled, setHeaderScrolled] = React.useState(false);

  const queryResults = useQueriesTyped([
    {
      queryKey: [`artist_meta_${id}`, countries],
      queryFn: () => getMetadata(id, countries),
      options: { staleTime: 0, refetchOnWindowFocus: false },
    },
    {
      queryKey: [`artist_metrics_${id}`, countries],
      queryFn: () => getMetrics(id, countries),
      options: { staleTime: 0, refetchOnWindowFocus: false },
    },
    {
      queryKey: `audience_segment_${id}`,
      queryFn: () => getAudienceSegment(id),
      options: { staleTime: 0, refetchOnWindowFocus: false },
    },
  ]);

  const isLoading = queryResults.some((query) => query.isLoading);
  const metaData = queryResults[0].data;
  const metricsData = queryResults[1].data;

  const totalAudience = metricsData ? metricsData.banked_audience + metricsData.youtube_audience : 0;

  const listenScrollEvent = () => {
    if (window.scrollY < 43) {
      return setHeaderScrolled(false);
    } else if (window.scrollY > 40) {
      return setHeaderScrolled(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', () => window.requestAnimationFrame(listenScrollEvent));

    return () => window.removeEventListener('scroll', () => window.requestAnimationFrame(listenScrollEvent));
  }, []);

  const displayName = (state && state.name.toUpperCase()) || (metaData && metaData.name.toUpperCase()) || '';

  const headerStyles = combineClasses([
    'artist-detail-header',
    'column',
    headerScrolled && 'scrolled',
    displayName.length > 20 && 'smaller',
  ]);

  return (
    <>
      <div
        className="artist-detail-background column"
        data-html2canvas-ignore="true"
        style={{
          backgroundImage:
            !isLoading || (state && state.image_url) || (metaData && metaData.image_url)
              ? `linear-gradient(rgba(37, 38, 39, 0.40), var(--wmg-color-background)),url(${
                  (state && state.image_url) || metaData.image_url
                })`
              : 'none',
        }}
      />
      <div className={headerStyles}>
        <div className="row center">
          <div className="column flex gap">
            {isLoading && !state && (
              <>
                <h1 className="placeholder">{'DAVID GUETTA'}</h1>
              </>
            )}
            {(!isLoading || state) && (
              <>
                <h1>{displayName}</h1>
              </>
            )}
          </div>
        </div>
        <div className="row center">
          {isLoading && <h4 className="placeholder">{`total banked audience`}</h4>}
          {!isLoading && (
            <h4 className="flex">
              {`${metricsData.banked_audience && totalAudience.toLocaleString()} total banked audience`}
            </h4>
          )}

          <div className="flex gap row end">
            {state && !state.customAudience && <FilterByCountry disabled={isLoading} />}
            {state && !state.customAudience && <AddToCollectionButton id={id} disabled={isLoading} dark />}
            <DownloadButton artistName={!isLoading && metaData.name} disabled={isLoading} />
          </div>
        </div>
        {metaData && metaData.filters && (
          <div className="flex row">
            <AppliedFilters audienceName={displayName} audienceId={id} filters={metaData.filters} disabled />
          </div>
        )}
      </div>
    </>
  );
};
