import React from 'react';
import Icon, { IconType } from 'components/Icon';
import { combineClasses } from 'utils/classNames';
import './style.css';

interface CheckboxProps {
  onClick: (id?) => void;
  checked?: boolean;
  disabled?: boolean;
}
export const Checkbox = ({ onClick, checked }: CheckboxProps) => {
  const styles = combineClasses(['checkbox', checked && 'checked']);

  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };
  return (
    <a href="" className={styles} onClick={handleOnClick}>
      {checked && <Icon type={IconType.check} color="white" />}
    </a>
  );
};
