import React from 'react';
import Icon, { IconType } from 'components/Icon';
import PopOver, { PopOverItem } from 'components/PopOver';
import { useMutation, useQueryClient } from 'react-query';
import Spinner from 'react-svg-spinner';
import { addToCollection, removeFromCollection } from 'pages/CollectionList/api';
import { useCollection } from 'pages/CollectionList/context';
import { Button } from 'components/Button';
import './style.css';
import { Colors } from 'utils/colors';
import { combineClasses } from 'utils/classNames';

const CollectionListItem = ({ collectionId, audienceId, name, isAdded }) => {
  const queryClient = useQueryClient();

  const { mutate: addToCollectionMutate, isLoading: isAdding } = useMutation(addToCollection, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('collections');
    },
  });

  const { mutate: removeFromCollectionMutate, isLoading: isRemoving } = useMutation(removeFromCollection, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('collections');
    },
  });

  const onAddToCollection = () => {
    if (!isAdded || !isAdded.includes(collectionId)) {
      addToCollectionMutate({ collectionId, audienceId });
    }
    if (isAdded && isAdded.includes(collectionId)) {
      removeFromCollectionMutate({ collectionId, audienceId });
    }
  };
  return (
    <PopOverItem key={collectionId} onClick={onAddToCollection} disabled={isAdding}>
      <div className="column center" style={{ width: 24, minWidth: 24 }}>
        {(isAdding || isRemoving) && <Spinner color={Colors.white} />}
        {!(isAdding || isRemoving) && isAdded && isAdded.includes(collectionId) && <Icon type={IconType.check} />}
      </div>
      <span>{name}</span>
    </PopOverItem>
  );
};

interface AddToCollectionButtonProps {
  id: string;
  isIcon?: boolean;
  dark?: boolean;
  disabled?: boolean;
}

export const AddToCollectionButton = ({ id: audienceId, isIcon, dark, disabled }: AddToCollectionButtonProps) => {
  const {
    state: { items: collections },
    showAddCollectionModal,
  } = useCollection();

  const [isPopOverVisible, setIsPopOverVisible] = React.useState(false);

  const isAdded = React.useMemo(() => {
    if (collections) {
      const isInCollections = collections
        .filter(({ audiences }) => audiences.some((d) => d.id === parseInt(audienceId)))
        .map((d) => d.id);
      return !!isInCollections.length && isInCollections;
    }
  }, [audienceId, collections]);

  const onToggleCollections = (e) => {
    e.preventDefault();
    setIsPopOverVisible(true);
  };

  const containerStyles = React.useMemo(
    () => combineClasses(['save-collection-container', isAdded && 'isAdded']),
    [isAdded],
  );

  return (
    <div data-html2canvas-ignore="true" className={containerStyles} onClick={(e) => e.stopPropagation()}>
      {isIcon && (
        <a href="#" className="save-collection-button" onClick={onToggleCollections}>
          <Icon type={isAdded ? IconType.heart : IconType.heartStroke} />
        </a>
      )}
      {!isIcon && (
        <Button
          onClick={onToggleCollections}
          icon={isAdded ? IconType.heart : IconType.heartStroke}
          dark={dark}
          disabled={disabled}
        >
          Add To Collection
        </Button>
      )}
      <PopOver isVisible={isPopOverVisible} onCancel={() => setIsPopOverVisible(false)} bottom={!isIcon}>
        <PopOverItem onClick={() => showAddCollectionModal(audienceId)}>
          <Icon type={IconType.plus} /> <span>{'Create Collection'}</span>
        </PopOverItem>

        {collections &&
          collections.map(({ id: collectionId, name }) => (
            <CollectionListItem
              key={collectionId}
              collectionId={collectionId}
              audienceId={audienceId}
              name={name}
              isAdded={isAdded}
            />
          ))}
      </PopOver>
    </div>
  );
};
