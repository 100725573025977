import React from 'react';
import './style.css';
import { SimpleTooltip } from './SimpleTooltip';

interface TooltipProps {
  index: number;
  children?: number;
  direction: 'right' | 'left' | 'up' | 'down';
  isHidden: boolean;
}

export const IndexTooltip = ({ index, children, direction, isHidden }: TooltipProps) =>
  isHidden ? (
    <></>
  ) : (
    <SimpleTooltip
      description={
        <div>
          {index === 100 && 'Indexes at the same level across all DMP data'}
          {index > 100 && 'Indexes higher than all DMP data for this data point'}
          {index < 100 && 'Indexes lower than all DMP data for this data point'}
        </div>
      }
      direction={direction}
    >
      {children && children}
    </SimpleTooltip>
  );
