export const ContextDescription = {
  Concertgoers: 'From Short Urls that have been tagged with the content type Tickets.',
  Downloads: 'From Short Urls that have been tagged with the content type Downloads.',
  Livestream: 'From Short Urls that have been tagged with the content type Livestream.',
  Lyrics:
    'From websites that have lyric in the domain name, or from Short Urls that have been tagged with the content type Lyrics.',
  Merchandise:
    'Banked IDs from websites that have ‘store’ or ‘shop’ in the domain name, or from Short Urls that have been tagged with the content type Merchandise',
  Music: 'From Short Urls that have been tagged with the content type Music.',
  'News & Articles': 'From Short Urls that have been tagged with the content type Music.',
  Other: 'From Short Urls used which fall into the following categories',

  'Photos & Pictures': 'From Short Urls that have been tagged with the content type Photos & Pictures.',

  ['YT Subscribers']: 'Number of subscribers to the configured WMX Video channel(s) for this artist or label',
  Spotify: 'Number of Spotify followers',
  Social: 'Total number of followers across the following social platforms',
  Video: 'From Short Urls that have been tagged with the content type Video.',
  Website: 'Banked IDs from non-eComm websites that have the Audigent’s code on page',
  'WMX Video': 'Number of video views on the configured WMX Video channel(s) for this artist or label',
};
