import React from 'react';

interface CustomAxisTickProps {
  x: number;
  y: number;
  value?: string;
  children?: React.ReactNode;
}
export const CustomAxisTick = ({ x, y, value, children }: CustomAxisTickProps) => {
  return (
    <g x={0} y={0} transform={`translate(${x},${y})`} className="caption">
      {children && children}
      {!children && value && <text fill="var(--wmg-color-grey)">{value}</text>}
    </g>
  );
};
