import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';

export const PublicRoute = ({ component: Component, ...rest }) => {
  const { state } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => (
        <div className="no-auth-app-container flex">
          {state.token && state.user ? <Redirect to={{ pathname: '/' }} /> : <Component {...props} />}
        </div>
      )}
    />
  );
};
