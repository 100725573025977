import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { IconType } from 'components/Icon';
import { IconButton } from 'components/IconButton';
import { Modal } from 'components/Modal';
import AdditionalRecipients from '../AdditionalRecipients';

const GamStaholderModal = ({ isVisible, onClose, query, onChange, onEnter, options, onRemove }) => {
  return (
    <Modal visible={!!isVisible}>
      <Card>
        <div className="column gap">
          <div className="row">
            <div className="column flex">
              <h3 className="flex">Google tag manager</h3>
            </div>
            <IconButton icon={IconType.cross} onClick={onClose} />
          </div>
          <div className="column gap scrollbar" style={{ maxHeight: 200 }}>
            <AdditionalRecipients
              title="Add stakeholders"
              placeholder={'eg. joe@wmx.com'}
              value={query}
              onChange={onChange}
              onEnter={onEnter}
              onRemove={onRemove}
              options={options}
            />
          </div>
          <div className="row">
            <Button sml onClick={onClose}>
              Done
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  );
};

export default GamStaholderModal;
