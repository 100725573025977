import React from 'react';

import { TooltipProps } from 'recharts';

import { toTitleCase } from 'utils/strings';

const PieCustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip">
        <div>
          <h2>{toTitleCase(data.name)}</h2>
          <p>Count: {data.count ? data.count.toLocaleString() : 'n/a'}</p>
        </div>
      </div>
    );
  }
  return null;
};

export default PieCustomTooltip;
