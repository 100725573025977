import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { combineClasses } from 'utils/classNames';
import './style.css';

export const NavItem = ({ label, path }) => {
  const match = useRouteMatch(path);

  const active = match && match.isExact;
  const styles = combineClasses(['nav-item', active && 'active']);

  return (
    <div className={styles}>
      <Link to={path}>
        <h4>{label}</h4>
      </Link>
    </div>
  );
};
