import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { IconType } from 'components/Icon';
import { IconButton } from 'components/IconButton';
import { Modal } from 'components/Modal';
import { TextInput } from 'components/TextInput';
import { FilterDataType } from 'pages/ArtistList/components/Filters/FilterDrawer';
import { createCustomAudience } from 'pages/CustomAudience/api';
import React from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

export const DEFAULT_CUSTOM_AUDIENCE_NAME = 'WMX > CUSTOM > ';
interface CreateAudienceModalProps {
  audienceId?: string;
  audienceName?: string;
  readonly?: boolean;
  visible: boolean;
  onClose: () => void;
  filters: FilterDataType[];
  onSuccess: () => void;
}

export const CreateAudienceModal = ({
  audienceId,
  audienceName,
  readonly,
  visible,
  onClose: _onClose,
  filters,
  onSuccess,
}: CreateAudienceModalProps) => {
  const [name, setName] = React.useState(audienceName || DEFAULT_CUSTOM_AUDIENCE_NAME);
  const [id, setId] = React.useState(audienceId || '');
  const [isSaved, setIsSaved] = React.useState(audienceId);
  const history = useHistory();

  const { mutateAsync: createCustomAudienceMutate, isLoading } = useMutation(createCustomAudience, {
    onSuccess: async (data) => {
      setId(data.display_segment_id);
      onSuccess();
    },
  });

  const onSubmit = async (event) => {
    if (event) event.preventDefault();

    await createCustomAudienceMutate({ name, filters });
  };

  const onChange = (e) => {
    setName(e.target.value);
  };

  const onClose = () => {
    setName(DEFAULT_CUSTOM_AUDIENCE_NAME);
    setId('');
    _onClose();
    setIsSaved(undefined);
  };
  return (
    <Modal visible={visible}>
      {!id && (
        <Card>
          <div className="row">
            <div className="column">
              <h3 className="flex">Create custom audience</h3>
              <div className="secondary column">
                {`Give this audience a descriptive name, and you’ll be 
              \nable to access it in the Custom Audiences page`}
              </div>
            </div>
            <IconButton icon={IconType.cross} onClick={onClose} />
          </div>
          <div className="column v-spacing">
            <form onSubmit={onSubmit}>
              <TextInput placeholder="Audience name" value={name} disabled={readonly || isLoading} {...{ onChange }} />
            </form>
          </div>
          <Button primary submit onClick={(e) => onSubmit(e)} loading={isLoading}>
            Create
          </Button>
        </Card>
      )}
      {id && (
        <Card>
          <div className="column ">
            <div className="row center">
              <h3 className="flex">{`Audience ${isSaved ? 'Saved' : 'Created'}`}</h3>
              <IconButton icon={IconType.cross} onClick={onClose} />
            </div>
            <p className="secondary column">{`${name} was successfully ${isSaved ? 'saved' : 'created'}`}</p>
          </div>
          <div className="column gap">
            <a
              href=""
              className="bold primary"
              onClick={() => {
                history.push(`/artist/${id}`, { customAudience: true, name });
                setIsSaved(undefined);
              }}
            >
              {`View ${isSaved ? 'saved' : 'created'} custom audience`}
            </a>
            <a
              href=""
              className="bold primary"
              onClick={() => {
                setIsSaved(undefined);
                history.push('/audiences');
              }}
            >
              View all custom audiences
            </a>
          </div>
        </Card>
      )}
    </Modal>
  );
};
