import React from 'react';
import { IconType } from 'components/Icon';
import { TextInput } from 'components/TextInput';
import { combineClasses } from 'utils/classNames';
import './style.css';

interface SearchBarProps {
  query?: string;
  onChange: (string) => void;
  disabled?: boolean;
  fixedToTop?: boolean;
  compact?: boolean;
  flex?: boolean;
}

export const SearchBar = ({ query = '', onChange, disabled, fixedToTop, compact, flex }: SearchBarProps) => {
  const styles = combineClasses(['search-bar', flex && 'flex', fixedToTop && 'fixed', compact && 'compact']);
  return (
    <div data-html2canvas-ignore="true" className={styles}>
      <TextInput placeholder="Search" icon={IconType.search} value={query} disabled={disabled} {...{ onChange }} />
    </div>
  );
};
