import React from 'react';
import Spinner from 'react-svg-spinner';
import { Card } from 'components/Card';
import './styles.css';
import { combineClasses } from '../../utils/classNames';

interface TableCardProps {
  header: string | React.ReactNode;
  children: React.ReactNode;
  isLoading?: boolean;
  noData?: boolean;
  staticHeight?: boolean;
}
export const TableCard = ({ header, children, isLoading, noData, staticHeight }: TableCardProps) => {
  const containerStyles = combineClasses(['table-card', 'column', 'flex', staticHeight && 'static-height']);
  return (
    <div className={containerStyles}>
      <Card flex>
        <div className="table-card-header column">{header}</div>
        {isLoading && (
          <div className="column center flex">
            <div className="row center flex">
              <Spinner size="32px" color="rgba(255,255,255,0.15)" />
            </div>
          </div>
        )}
        {!isLoading && !noData && children}
        {!isLoading && noData && (
          <div className="column center flex">
            <div className="row center flex">
              <h3>No data found</h3>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};
