import Icon, { IconType } from 'components/Icon';
import React from 'react';
import './style.css';

export const Artwork = ({ image }) => {
  return (
    <div className="artwork" style={image && { backgroundImage: `url(${image})` }}>
      {!image && <Icon type={IconType.noArtwork} size={200} />}
    </div>
  );
};
