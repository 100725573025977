export default [
  {
    value: 'Abilene-Sweetwater TX',
    label: 'Abilene-Sweetwater TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: '1000422',
    label: 'Adelaide, AU',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'Afghanistan',
    label: 'Afghanistan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'AFRICA',
    label: 'Africa',
    category: 'World_Region',
    remove: 0,
  },
  {
    value: 'Alabama, United States',
    label: 'Alabama, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Åland Islands',
    label: 'Åland Islands',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Alaska, United States',
    label: 'Alaska, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Albania',
    label: 'Albania',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Albany GA',
    label: 'Albany GA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Albany-Schenectady-Troy NY',
    label: 'Albany-Schenectady-Troy NY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Albuquerque-Santa Fe NM',
    label: 'Albuquerque-Santa Fe NM',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Alexandria LA',
    label: 'Alexandria LA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Algeria',
    label: 'Algeria',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Alpena MI',
    label: 'Alpena MI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Amarillo TX',
    label: 'Amarillo TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'American Samoa',
    label: 'American Samoa',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Anchorage AK',
    label: 'Anchorage AK',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Andorra',
    label: 'Andorra',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Angola',
    label: 'Angola',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Anguilla',
    label: 'Anguilla',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Antarctica',
    label: 'Antarctica',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Antigua and Barbuda',
    label: 'Antigua and Barbuda',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Argentina',
    label: 'Argentina',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Arizona, United States',
    label: 'Arizona, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Arkansas, United States',
    label: 'Arkansas, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Armenia',
    label: 'Armenia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Aruba',
    label: 'Aruba',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'ASIA',
    label: 'Asia',
    category: 'World_Region',
    remove: 0,
  },
  {
    value: 'Atlanta GA',
    label: 'Atlanta GA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Auckland, New Zealand',
    label: 'Auckland, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Augusta GA',
    label: 'Augusta GA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Austin TX',
    label: 'Austin TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Australia',
    label: 'Australia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'AUSTRALIA & OCEANIA',
    label: 'Australia & Oceania',
    category: 'World_Region',
    remove: 0,
  },
  {
    value: 'Australian Capital Territory, Australia',
    label: 'Australian Capital Territory, Australia',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Austria',
    label: 'Austria',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Azerbaijan',
    label: 'Azerbaijan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Baden-Wurttemberg, Germany',
    label: 'Baden-Wurttemberg, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Bahamas',
    label: 'Bahamas',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Bahrain',
    label: 'Bahrain',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Bakersfield CA',
    label: 'Bakersfield CA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Baltimore MD',
    label: 'Baltimore MD',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Bangladesh',
    label: 'Bangladesh',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Bangor ME',
    label: 'Bangor ME',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Barbados',
    label: 'Barbados',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Baton Rouge LA',
    label: 'Baton Rouge LA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Bavaria, Germany',
    label: 'Bavaria, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Bay of Plenty, New Zealand',
    label: 'Bay of Plenty, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Beaumont-Port Arthur TX',
    label: 'Beaumont-Port Arthur TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Belarus',
    label: 'Belarus',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Belgium',
    label: 'Belgium',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Belize',
    label: 'Belize',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Bend OR',
    label: 'Bend OR',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Benin',
    label: 'Benin',
    category: 'Country',
    remove: 0,
  },
  {
    value: '1003854',
    label: 'Berlin, DE',
    category: 'Metro_id',
    remove: 1,
  },
  {
    value: 'Berlin, Germany',
    label: 'Berlin, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Bermuda',
    label: 'Bermuda',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Bhutan',
    label: 'Bhutan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Billings, MT',
    label: 'Billings, MT',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Biloxi-Gulfport MS',
    label: 'Biloxi-Gulfport MS',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Binghamton NY',
    label: 'Binghamton NY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Birmingham (Ann and Tusc) AL',
    label: 'Birmingham (Ann and Tusc) AL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: '1006524',
    label: 'Birmingham, UK',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'Bluefield-Beckley-Oak Hill WV',
    label: 'Bluefield-Beckley-Oak Hill WV',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Boise ID',
    label: 'Boise ID',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Bolivia',
    label: 'Bolivia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Bonaire, Sint Eustatius and Saba',
    label: 'Bonaire, Sint Eustatius and Saba',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Border',
    label: 'Border',
    category: 'other',
    remove: 1,
  },
  {
    value: 'Bosnia and Herzegovina',
    label: 'Bosnia and Herzegovina',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Boston MA-Manchester NH',
    label: 'Boston MA-Manchester NH',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Botswana',
    label: 'Botswana',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Bouvet Island',
    label: 'Bouvet Island',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Bowling Green KY',
    label: 'Bowling Green KY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Brandenburg, Germany',
    label: 'Brandenburg, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Brazil',
    label: 'Brazil',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Bremen, Germany',
    label: 'Bremen, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: '1000339',
    label: 'Brisbane, AU',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'British Indian Ocean Territory',
    label: 'British Indian Ocean Territory',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Brunei Darussalam',
    label: 'Brunei Darussalam',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Buffalo NY',
    label: 'Buffalo NY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Bulgaria',
    label: 'Bulgaria',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Burkina Faso',
    label: 'Burkina Faso',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Burlington VT-Plattsburgh NY',
    label: 'Burlington VT-Plattsburgh NY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Burundi',
    label: 'Burundi',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Butte-Bozeman MT',
    label: 'Butte-Bozeman MT',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'California, United States',
    label: 'California, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Cambodia',
    label: 'Cambodia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Cameroon',
    label: 'Cameroon',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Canada',
    label: 'Canada',
    category: 'Country',
    remove: 0,
  },
  {
    value: '1000142',
    label: 'Canberra, AU',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'Canterbury, New Zealand',
    label: 'Canterbury, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Cape Verde',
    label: 'Cape Verde',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Casper-Riverton WY',
    label: 'Casper-Riverton WY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Cayman Islands',
    label: 'Cayman Islands',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Cedar Rapids-Waterloo-Iowa City & Dubuque IA',
    label: 'Cedar Rapids-Waterloo-Iowa City & Dubuque IA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Central African Republic',
    label: 'Central African Republic',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'CENTRAL AMERICA & CARIBBEAN',
    label: 'Central America & Caribbean',
    category: 'World_Region',
    remove: 0,
  },
  {
    value: 'Central Scotland',
    label: 'Central Scotland, United Kingdom',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Chad',
    label: 'Chad',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Champaign & Springfield-Decatur IL',
    label: 'Champaign & Springfield-Decatur IL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Charleston SC',
    label: 'Charleston SC',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Charleston-Huntington WV',
    label: 'Charleston-Huntington WV',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Charlotte NC',
    label: 'Charlotte NC',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Charlottesville VA',
    label: 'Charlottesville VA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Chattanooga TN',
    label: 'Chattanooga TN',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Cheyenne WY-Scottsbluff NE',
    label: 'Cheyenne WY-Scottsbluff NE',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Chicago IL',
    label: 'Chicago IL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Chico-Redding CA',
    label: 'Chico-Redding CA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Chile',
    label: 'Chile',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'China',
    label: 'China',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Christmas Island',
    label: 'Christmas Island',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'JP_CHUKYO',
    label: 'Chukyo, Japan',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Cincinnati OH',
    label: 'Cincinnati OH',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Clarksburg-Weston WV',
    label: 'Clarksburg-Weston WV',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Cleveland-Akron (Canton) OH',
    label: 'Cleveland-Akron (Canton) OH',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Cocos (Keeling) Islands',
    label: 'Cocos (Keeling) Islands',
    category: 'Country',
    remove: 0,
  },
  {
    value: '1004607',
    label: 'Cologne, DE',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'Colombia',
    label: 'Colombia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Colorado Springs-Pueblo CO',
    label: 'Colorado Springs-Pueblo CO',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Colorado, United States',
    label: 'Colorado, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Columbia SC',
    label: 'Columbia SC',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Columbia-Jefferson City MO',
    label: 'Columbia-Jefferson City MO',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Columbus GA',
    label: 'Columbus GA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Columbus OH',
    label: 'Columbus OH',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Columbus-Tupelo-West Point MS',
    label: 'Columbus-Tupelo-West Point MS',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Comoros',
    label: 'Comoros',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Congo',
    label: 'Congo',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Congo, the Democratic Republic of the',
    label: 'Congo, the Democratic Republic of the',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Connecticut, United States',
    label: 'Connecticut, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Cook Islands',
    label: 'Cook Islands',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Corpus Christi TX',
    label: 'Corpus Christi TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Costa Rica',
    label: 'Costa Rica',
    category: 'Country',
    remove: 0,
  },
  {
    value: "Côte d'Ivoire",
    label: "Côte d'Ivoire",
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Croatia',
    label: 'Croatia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Cuba',
    label: 'Cuba',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Curaçao',
    label: 'Curaçao',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Cyprus',
    label: 'Cyprus',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Czech Republic',
    label: 'Czech Republic',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Dallas-Ft. Worth TX',
    label: 'Dallas-Ft. Worth TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Davenport IA-Rock Island-Moline IL',
    label: 'Davenport IA-Rock Island-Moline IL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Dayton OH',
    label: 'Dayton OH',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Delaware, United States',
    label: 'Delaware, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Denmark',
    label: 'Denmark',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Denver CO',
    label: 'Denver CO',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Des Moines-Ames IA',
    label: 'Des Moines-Ames IA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Detroit MI',
    label: 'Detroit MI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'District of Columbia, United States',
    label: 'District of Columbia, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Djibouti',
    label: 'Djibouti',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Dominica',
    label: 'Dominica',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Dominican Republic',
    label: 'Dominican Republic',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Dothan AL',
    label: 'Dothan AL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Duluth MN-Superior WI',
    label: 'Duluth MN-Superior WI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'East Of England',
    label: 'East Of England',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Ecuador',
    label: 'Ecuador',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Egypt',
    label: 'Egypt',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'El Paso TX',
    label: 'El Paso TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'El Salvador',
    label: 'El Salvador',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Elmira (Corning) NY',
    label: 'Elmira (Corning) NY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'England, United Kingdom',
    label: 'England, United Kingdom',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Equatorial Guinea',
    label: 'Equatorial Guinea',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Erie PA',
    label: 'Erie PA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Eritrea',
    label: 'Eritrea',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Estonia',
    label: 'Estonia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Ethiopia',
    label: 'Ethiopia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Eugene OR',
    label: 'Eugene OR',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Eureka CA',
    label: 'Eureka CA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'EUROPE',
    label: 'Europe',
    category: 'World_Region',
    remove: 0,
  },
  {
    value: 'Evansville IN',
    label: 'Evansville IN',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Fairbanks AK',
    label: 'Fairbanks AK',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Falkland Islands (Islas Malvinas)',
    label: 'Falkland Islands (Islas Malvinas)',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Fargo-Valley City ND',
    label: 'Fargo-Valley City ND',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Faroe Islands',
    label: 'Faroe Islands',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Fiji',
    label: 'Fiji',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Finland',
    label: 'Finland',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Flint-Saginaw-Bay City MI',
    label: 'Flint-Saginaw-Bay City MI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Florence-Myrtle Beach SC',
    label: 'Florence-Myrtle Beach SC',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Florida, United States',
    label: 'Florida, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'France',
    label: 'France',
    category: 'Country',
    remove: 0,
  },
  {
    value: '1004363',
    label: 'Frankfurt am Main, DE',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'French Guiana',
    label: 'French Guiana',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'French Polynesia',
    label: 'French Polynesia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'French Southern Territories',
    label: 'French Southern Territories',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Fresno-Visalia CA',
    label: 'Fresno-Visalia CA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Ft. Myers-Naples FL',
    label: 'Ft. Myers-Naples FL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Ft. Smith-Fayetteville-Springdale-Rogers AR',
    label: 'Ft. Smith-Fayetteville-Springdale-Rogers AR',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Ft. Wayne IN',
    label: 'Ft. Wayne IN',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Gabon',
    label: 'Gabon',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Gainesville FL',
    label: 'Gainesville FL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Gambia',
    label: 'Gambia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Georgia',
    label: 'Georgia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Georgia, United States',
    label: 'Georgia, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Germany',
    label: 'Germany',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Ghana',
    label: 'Ghana',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Gibraltar',
    label: 'Gibraltar',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Gisborne, New Zealand',
    label: 'Gisborne, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: '1007336',
    label: 'Glasgow, UK',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'Glendive MT',
    label: 'Glendive MT',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Global',
    label: 'Global',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Grand Junction-Montrose CO',
    label: 'Grand Junction-Montrose CO',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Grand Rapids-Kalamazoo-Battle Creek MI',
    label: 'Grand Rapids-Kalamazoo-Battle Creek MI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Great Falls MT',
    label: 'Great Falls MT',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Greece',
    label: 'Greece',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Green Bay-Appleton WI',
    label: 'Green Bay-Appleton WI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Greenland',
    label: 'Greenland',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Greensboro-High Point-Winston Salem NC',
    label: 'Greensboro-High Point-Winston Salem NC',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Greenville-New Bern-Washington NC',
    label: 'Greenville-New Bern-Washington NC',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Greenville-Spartanburg-Asheville-Anderson',
    label: 'Greenville-Spartanburg-Asheville-Anderson',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Greenwood-Greenville MS',
    label: 'Greenwood-Greenville MS',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Grenada',
    label: 'Grenada',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Guadeloupe',
    label: 'Guadeloupe',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Guam',
    label: 'Guam',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Guatemala',
    label: 'Guatemala',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Guernsey',
    label: 'Guernsey',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Guinea',
    label: 'Guinea',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Guinea-Bissau',
    label: 'Guinea-Bissau',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Guyana',
    label: 'Guyana',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Haiti',
    label: 'Haiti',
    category: 'Country',
    remove: 0,
  },
  {
    value: '1004437',
    label: 'Hamburg, DE',
    category: 'Metro_id',
    remove: 1,
  },
  {
    value: 'Hamburg, Germany',
    label: 'Hamburg, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Harlingen-Weslaco-Brownsville-McAllen TX',
    label: 'Harlingen-Weslaco-Brownsville-McAllen TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Harrisburg-Lancaster-Lebanon-York PA',
    label: 'Harrisburg-Lancaster-Lebanon-York PA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Harrisonburg VA',
    label: 'Harrisonburg VA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Hartford & New Haven CT',
    label: 'Hartford & New Haven CT',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Hattiesburg-Laurel MS',
    label: 'Hattiesburg-Laurel MS',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Hawaii, United States',
    label: 'Hawaii, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: "Hawke's Bay, New Zealand",
    label: "Hawke's Bay, New Zealand",
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Heard Island and McDonald Islands',
    label: 'Heard Island and McDonald Islands',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Helena MT',
    label: 'Helena MT',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Hesse, Germany',
    label: 'Hesse, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Hessen, Germany',
    label: 'Hessen, Germany',
    category: 'Metro',
    remove: 1,
  },
  {
    value: 'Holy See (Vatican City State)',
    label: 'Holy See (Vatican City State)',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Honduras',
    label: 'Honduras',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Hong Kong',
    label: 'Hong Kong',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Honolulu HI',
    label: 'Honolulu HI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Houston TX',
    label: 'Houston TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'HTV Wales',
    label: 'HTV Wales',
    category: 'other',
    remove: 1,
  },
  {
    value: 'HTV West',
    label: 'HTV West',
    category: 'other',
    remove: 1,
  },
  {
    value: 'Hungary',
    label: 'Hungary',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Huntsville-Decatur (Florence) AL',
    label: 'Huntsville-Decatur (Florence) AL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Iceland',
    label: 'Iceland',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Idaho Falls-Pocatello ID',
    label: 'Idaho Falls-Pocatello ID',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Idaho, United States',
    label: 'Idaho, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Illinois, United States',
    label: 'Illinois, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'India',
    label: 'India',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Indiana, United States',
    label: 'Indiana, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Indianapolis IN',
    label: 'Indianapolis IN',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Indonesia',
    label: 'Indonesia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Iowa, United States',
    label: 'Iowa, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Iran, Islamic Republic of',
    label: 'Iran, Islamic Republic of',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Iraq',
    label: 'Iraq',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Ireland',
    label: 'Ireland',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Isle of Man',
    label: 'Isle of Man',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Isle of Man, United Kingdom',
    label: 'Isle of Man, United Kingdom',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Israel',
    label: 'Israel',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Italy',
    label: 'Italy',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Jackson MS',
    label: 'Jackson MS',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Jackson TN',
    label: 'Jackson TN',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Jacksonville FL',
    label: 'Jacksonville FL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Jamaica',
    label: 'Jamaica',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Japan',
    label: 'Japan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Jersey',
    label: 'Jersey',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Jervis Bay Territory, Australia',
    label: 'Jervis Bay Territory, Australia',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Johnstown-Altoona-State College PA',
    label: 'Johnstown-Altoona-State College PA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Jonesboro AR',
    label: 'Jonesboro AR',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Joplin MO-Pittsburg KS',
    label: 'Joplin MO-Pittsburg KS',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Jordan',
    label: 'Jordan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'JP_OTHER',
    label: 'JP_OTHER',
    category: 'Metro',
    remove: 1,
  },
  {
    value: 'Juneau AK',
    label: 'Juneau AK',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Kansas City MO',
    label: 'Kansas City MO',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Kansas, United States',
    label: 'Kansas, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'JP_KANTO',
    label: 'Kanto, Japan',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Kazakhstan',
    label: 'Kazakhstan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Kentucky, United States',
    label: 'Kentucky, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Kenya',
    label: 'Kenya',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'JP_KINKI',
    label: 'Kinki, Japan',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Kiribati',
    label: 'Kiribati',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Knoxville TN',
    label: 'Knoxville TN',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: "Korea, Democratic People's Republic of",
    label: "Korea, Democratic People's Republic of",
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Korea, Republic of',
    label: 'Korea, Republic of',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Kosovo',
    label: 'Kosovo',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Kuwait',
    label: 'Kuwait',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Kyrgyzstan',
    label: 'Kyrgyzstan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'La Crosse-Eau Claire WI',
    label: 'La Crosse-Eau Claire WI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Lafayette IN',
    label: 'Lafayette IN',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Lafayette LA',
    label: 'Lafayette LA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Lake Charles LA',
    label: 'Lake Charles LA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Lansing MI',
    label: 'Lansing MI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: "Lao People's Democratic Republic",
    label: "Lao People's Democratic Republic",
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Laredo TX',
    label: 'Laredo TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Las Vegas NV',
    label: 'Las Vegas NV',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Latvia',
    label: 'Latvia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Lebanon',
    label: 'Lebanon',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Lesotho',
    label: 'Lesotho',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Lexington KY',
    label: 'Lexington KY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Liberia',
    label: 'Liberia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Libya',
    label: 'Libya',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Liechtenstein',
    label: 'Liechtenstein',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Lima OH',
    label: 'Lima OH',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Lincoln & Hastings-Kearney NE',
    label: 'Lincoln & Hastings-Kearney NE',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Lithuania',
    label: 'Lithuania',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Little Rock-Pine Bluff AR',
    label: 'Little Rock-Pine Bluff AR',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: '1006886',
    label: 'London, UK',
    category: 'Metro_id',
    remove: 1,
  },
  {
    value: 'London',
    label: 'London, United Kingdom',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Los Angeles CA',
    label: 'Los Angeles CA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Louisiana, United States',
    label: 'Louisiana, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Louisville KY',
    label: 'Louisville KY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Lower Saxony, Germany',
    label: 'Lower Saxony, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Lubbock TX',
    label: 'Lubbock TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Luxembourg',
    label: 'Luxembourg',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Macao',
    label: 'Macao',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Macedonia, the Former Yugoslav Republic of',
    label: 'Macedonia, the Former Yugoslav Republic of',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Macon GA',
    label: 'Macon GA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Madagascar',
    label: 'Madagascar',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Madison WI',
    label: 'Madison WI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Maine, United States',
    label: 'Maine, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Malawi',
    label: 'Malawi',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Malaysia',
    label: 'Malaysia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Maldives',
    label: 'Maldives',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Mali',
    label: 'Mali',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Malta',
    label: 'Malta',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Manawatu-Wanganui, New Zealand',
    label: 'Manawatu-Wanganui, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: '1006912',
    label: 'Manchester, UK',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'Mankato MN',
    label: 'Mankato MN',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Marlborough, New Zealand',
    label: 'Marlborough, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Marquette MI',
    label: 'Marquette MI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Marshall Islands',
    label: 'Marshall Islands',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Martinique',
    label: 'Martinique',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Maryland, United States',
    label: 'Maryland, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Massachusetts, United States',
    label: 'Massachusetts, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Mauritania',
    label: 'Mauritania',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Mauritius',
    label: 'Mauritius',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Mayotte',
    label: 'Mayotte',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Mecklenburg-Vorpommern, Germany',
    label: 'Mecklenburg-Vorpommern, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Medford-Klamath Falls OR',
    label: 'Medford-Klamath Falls OR',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: '1000567',
    label: 'Melbourne, AU',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'Memphis TN',
    label: 'Memphis TN',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Meridian (exc. Channel Islands)',
    label: 'Meridian (exc. Channel Islands), United Kingdom',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Meridian MS',
    label: 'Meridian MS',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Mexico',
    label: 'Mexico',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Miami-Ft. Lauderdale FL',
    label: 'Miami-Ft. Lauderdale FL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Michigan, United States',
    label: 'Michigan, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Micronesia',
    label: 'Micronesia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'MID-ATLANTIC',
    label: 'MID-ATLANTIC',
    category: 'US_Region',
    remove: 1,
  },
  {
    value: 'Midlands',
    label: 'Midlands, United Kingdom',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'MIDWEST',
    label: 'MIDWEST',
    category: 'US_Region',
    remove: 1,
  },
  {
    value: 'Milwaukee WI',
    label: 'Milwaukee WI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Minneapolis-St. Paul MN',
    label: 'Minneapolis-St. Paul MN',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Minnesota, United States',
    label: 'Minnesota, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Minot-Bismarck-Dickinson(Williston) ND',
    label: 'Minot-Bismarck-Dickinson(Williston) ND',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Mississippi, United States',
    label: 'Mississippi, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Missoula MT',
    label: 'Missoula MT',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Missouri, United States',
    label: 'Missouri, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Mobile AL-Pensacola (Ft. Walton Beach) FL',
    label: 'Mobile AL-Pensacola (Ft. Walton Beach) FL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Moldova, Republic of',
    label: 'Moldova, Republic of',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Monaco',
    label: 'Monaco',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Mongolia',
    label: 'Mongolia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Monroe LA-El Dorado AR',
    label: 'Monroe LA-El Dorado AR',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Montana, United States',
    label: 'Montana, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Montenegro',
    label: 'Montenegro',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Monterey-Salinas CA',
    label: 'Monterey-Salinas CA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Montgomery-Selma, AL',
    label: 'Montgomery-Selma, AL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Montserrat',
    label: 'Montserrat',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Morocco',
    label: 'Morocco',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Mozambique',
    label: 'Mozambique',
    category: 'Country',
    remove: 0,
  },
  {
    value: '1004234',
    label: 'Munich, DE',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'Myanmar',
    label: 'Myanmar',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Namibia',
    label: 'Namibia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Nashville TN',
    label: 'Nashville TN',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Nauru',
    label: 'Nauru',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Nebraska, United States',
    label: 'Nebraska, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Nelson, New Zealand',
    label: 'Nelson, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Nepal',
    label: 'Nepal',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Netherlands',
    label: 'Netherlands',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Nevada, United States',
    label: 'Nevada, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'New Caledonia',
    label: 'New Caledonia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'NEW ENGLAND',
    label: 'NEW ENGLAND',
    category: 'US_Region',
    remove: 1,
  },
  {
    value: 'New Hampshire, United States',
    label: 'New Hampshire, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'New Jersey, United States',
    label: 'New Jersey, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'New Mexico, United States',
    label: 'New Mexico, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'New Orleans LA',
    label: 'New Orleans LA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'New South Wales, Australia',
    label: 'New South Wales, Australia',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'New York, NY',
    label: 'New York, NY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'New York, United States',
    label: 'New York, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'New Zealand',
    label: 'New Zealand',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Nicaragua',
    label: 'Nicaragua',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Niger',
    label: 'Niger',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Nigeria',
    label: 'Nigeria',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Niue',
    label: 'Niue',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Norfolk Island',
    label: 'Norfolk Island',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Norfolk-Portsmouth-Newport News VA',
    label: 'Norfolk-Portsmouth-Newport News VA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'North Carolina, United States',
    label: 'North Carolina, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'North Dakota, United States',
    label: 'North Dakota, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'North East',
    label: 'North East',
    category: 'US_Region',
    remove: 1,
  },
  {
    value: 'North Platte NE',
    label: 'North Platte NE',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'North Rhine-Westphalia, Germany',
    label: 'North Rhine-Westphalia, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'North Scotland',
    label: 'North Scotland',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'North West',
    label: 'North West',
    category: 'US_Region',
    remove: 1,
  },
  {
    value: 'Northern Ireland, United Kingdom',
    label: 'Northern Ireland, United Kingdom',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Northern Territory, Australia',
    label: 'Northern Territory, Australia',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Northland, New Zealand',
    label: 'Northland, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'NORTHWEST',
    label: 'NORTHWEST',
    category: 'US_Region',
    remove: 1,
  },
  {
    value: 'Norway',
    label: 'Norway',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Odessa-Midland TX',
    label: 'Odessa-Midland TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Ohio, United States',
    label: 'Ohio, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Oklahoma City OK',
    label: 'Oklahoma City OK',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Oklahoma, United States',
    label: 'Oklahoma, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Omaha NE',
    label: 'Omaha NE',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Oman',
    label: 'Oman',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Oregon, United States',
    label: 'Oregon, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Orlando-Daytona Beach-Melbourne FL',
    label: 'Orlando-Daytona Beach-Melbourne FL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Otago, New Zealand',
    label: 'Otago, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Ottumwa IA-Kirksville MO',
    label: 'Ottumwa IA-Kirksville MO',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Paducah KY-Cape Girardeau MO-Harrisburg-Mount Vernon IL',
    label: 'Paducah KY-Cape Girardeau MO-Harrisburg-Mount Vernon IL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Pakistan',
    label: 'Pakistan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Palau',
    label: 'Palau',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Palestine, State of',
    label: 'Palestine, State of',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Palm Springs CA',
    label: 'Palm Springs CA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Panama',
    label: 'Panama',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Panama City FL',
    label: 'Panama City FL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Papua New Guinea',
    label: 'Papua New Guinea',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Paraguay',
    label: 'Paraguay',
    category: 'Country',
    remove: 0,
  },
  {
    value: '1006094',
    label: 'Paris, FR',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'Parkersburg WV',
    label: 'Parkersburg WV',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Pennsylvania, United States',
    label: 'Pennsylvania, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Peoria-Bloomington IL',
    label: 'Peoria-Bloomington IL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: '1000676',
    label: 'Perth, AU',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'Peru',
    label: 'Peru',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Philadelphia PA',
    label: 'Philadelphia PA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Philippines',
    label: 'Philippines',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Phoenix AZ',
    label: 'Phoenix AZ',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Pitcairn',
    label: 'Pitcairn',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Pittsburgh PA',
    label: 'Pittsburgh PA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Poland',
    label: 'Poland',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Portland OR',
    label: 'Portland OR',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Portland-Auburn ME',
    label: 'Portland-Auburn ME',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Portugal',
    label: 'Portugal',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Presque Isle ME',
    label: 'Presque Isle ME',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Providence-New Bedford,MA',
    label: 'Providence-New Bedford,MA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Puerto Rico',
    label: 'Puerto Rico',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Qatar',
    label: 'Qatar',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Queensland, Australia',
    label: 'Queensland, Australia',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Quincy IL-Hannibal MO-Keokuk IA',
    label: 'Quincy IL-Hannibal MO-Keokuk IA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Raleigh-Durham (Fayetteville) NC',
    label: 'Raleigh-Durham (Fayetteville) NC',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Rapid City SD',
    label: 'Rapid City SD',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Reno NV',
    label: 'Reno NV',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Réunion',
    label: 'Réunion',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Rhineland-Palatinate, Germany',
    label: 'Rhineland-Palatinate, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Rhode Island, United States',
    label: 'Rhode Island, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Richmond-Petersburg VA',
    label: 'Richmond-Petersburg VA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Roanoke-Lynchburg VA',
    label: 'Roanoke-Lynchburg VA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Rochester NY',
    label: 'Rochester NY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Rochester-Mason City-Austin,IA',
    label: 'Rochester-Mason City-Austin,IA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Rockford IL',
    label: 'Rockford IL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Romania',
    label: 'Romania',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Russia',
    label: 'Russia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Rwanda',
    label: 'Rwanda',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Saarland, Germany',
    label: 'Saarland, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Sacramento-Stockton-Modesto CA',
    label: 'Sacramento-Stockton-Modesto CA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Saint Helena, Ascension and Tristan da Cunha',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Saint Kitts and Nevis',
    label: 'Saint Kitts and Nevis',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Saint Lucia',
    label: 'Saint Lucia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Saint Martin (French part)',
    label: 'Saint Martin (French part)',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Saint Pierre and Miquelon',
    label: 'Saint Pierre and Miquelon',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Saint Vincent and the Grenadines',
    label: 'Saint Vincent and the Grenadines',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Salisbury MD',
    label: 'Salisbury MD',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Salt Lake City UT',
    label: 'Salt Lake City UT',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Samoa',
    label: 'Samoa',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'San Angelo TX',
    label: 'San Angelo TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'San Antonio TX',
    label: 'San Antonio TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'San Diego CA',
    label: 'San Diego CA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'San Francisco-Oakland-San Jose CA',
    label: 'San Francisco-Oakland-San Jose CA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'San Marino',
    label: 'San Marino',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Santa Barbara-Santa Maria-San Luis Obispo CA',
    label: 'Santa Barbara-Santa Maria-San Luis Obispo CA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: '1001773',
    label: 'Sao Paulo, BR',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'São Tomé & Príncipe',
    label: 'São Tomé & Príncipe',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Saudi Arabia',
    label: 'Saudi Arabia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Savannah GA',
    label: 'Savannah GA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Saxony-Anhalt, Germany',
    label: 'Saxony-Anhalt, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Saxony, Germany',
    label: 'Saxony, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Schleswig-Holstein, Germany',
    label: 'Schleswig-Holstein, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Scotland, United Kingdom',
    label: 'Scotland, United Kingdom',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Seattle-Tacoma WA',
    label: 'Seattle-Tacoma WA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Senegal',
    label: 'Senegal',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Serbia',
    label: 'Serbia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Seychelles',
    label: 'Seychelles',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Sherman-Ada, OK',
    label: 'Sherman-Ada, OK',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Shreveport LA',
    label: 'Shreveport LA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Sierra Leone',
    label: 'Sierra Leone',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Singapore',
    label: 'Singapore',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Sint Maarten (Dutch part)',
    label: 'Sint Maarten (Dutch part)',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Sioux City IA',
    label: 'Sioux City IA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Sioux Falls(Mitchell) SD',
    label: 'Sioux Falls(Mitchell) SD',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Slovakia',
    label: 'Slovakia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Slovenia',
    label: 'Slovenia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Solomon Islands',
    label: 'Solomon Islands',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Somalia',
    label: 'Somalia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'South Africa',
    label: 'South Africa',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'SOUTH AMERICA',
    label: 'South America',
    category: 'World_Region',
    remove: 0,
  },
  {
    value: 'South Australia, Australia',
    label: 'South Australia, Australia',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'South Bend-Elkhart IN',
    label: 'South Bend-Elkhart IN',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'South Carolina, United States',
    label: 'South Carolina, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'South Dakota, United States',
    label: 'South Dakota, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'South Georgia and the South Sandwich Islands',
    label: 'South Georgia and the South Sandwich Islands',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'South Sudan',
    label: 'South Sudan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'South West',
    label: 'South West',
    category: 'US_Region',
    remove: 1,
  },
  {
    value: 'SOUTHEAST',
    label: 'SOUTHEAST',
    category: 'US_Region',
    remove: 1,
  },
  {
    value: 'Southland, New Zealand',
    label: 'Southland, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'SOUTHWEST',
    label: 'SOUTHWEST',
    category: 'US_Region',
    remove: 1,
  },
  {
    value: 'Spain',
    label: 'Spain',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Spokane WA',
    label: 'Spokane WA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Springfield MO',
    label: 'Springfield MO',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Springfield-Holyoke MA',
    label: 'Springfield-Holyoke MA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Sri Lanka',
    label: 'Sri Lanka',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'St. Barthélemy',
    label: 'St. Barthélemy',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'St. Joseph MO',
    label: 'St. Joseph MO',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'St. Louis MO',
    label: 'St. Louis MO',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: '1004054',
    label: 'Stuttgart, DE',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'Sudan',
    label: 'Sudan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Suriname',
    label: 'Suriname',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Svalbard and Jan Mayen',
    label: 'Svalbard and Jan Mayen',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Swaziland',
    label: 'Swaziland',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Sweden',
    label: 'Sweden',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Switzerland',
    label: 'Switzerland',
    category: 'Country',
    remove: 0,
  },
  {
    value: '1000286',
    label: 'Sydney, AU',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'Syracuse NY',
    label: 'Syracuse NY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Syrian Arab Republic',
    label: 'Syrian Arab Republic',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Taiwan',
    label: 'Taiwan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Tajikistan',
    label: 'Tajikistan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Tallahassee FL-Thomasville GA',
    label: 'Tallahassee FL-Thomasville GA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Tampa-St. Petersburg (Sarasota) FL',
    label: 'Tampa-St. Petersburg (Sarasota) FL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Tanzania, United Republic of',
    label: 'Tanzania, United Republic of',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Taranaki, New Zealand',
    label: 'Taranaki, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Tasman, New Zealand',
    label: 'Tasman, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Tasmania, Australia',
    label: 'Tasmania, Australia',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Tennessee, United States',
    label: 'Tennessee, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Terre Haute IN',
    label: 'Terre Haute IN',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Texas, United States',
    label: 'Texas, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Thailand',
    label: 'Thailand',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Thuringia, Germany',
    label: 'Thuringia, Germany',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Timor-Leste',
    label: 'Timor-Leste',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Togo',
    label: 'Togo',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Tokelau',
    label: 'Tokelau',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Toledo OH',
    label: 'Toledo OH',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Tonga',
    label: 'Tonga',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Topeka KS',
    label: 'Topeka KS',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: '1002451',
    label: 'Toronto, CA',
    category: 'Metro_id',
    remove: 0,
  },
  {
    value: 'Traverse City-Cadillac MI',
    label: 'Traverse City-Cadillac MI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Tri-Cities TN-VA',
    label: 'Tri-Cities TN-VA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Trinidad and Tobago',
    label: 'Trinidad and Tobago',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Tucson (Sierra Vista) AZ',
    label: 'Tucson (Sierra Vista) AZ',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Tulsa OK',
    label: 'Tulsa OK',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Tunisia',
    label: 'Tunisia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Turkey',
    label: 'Turkey',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Turkmenistan',
    label: 'Turkmenistan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Turks and Caicos Islands',
    label: 'Turks and Caicos Islands',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Tuvalu',
    label: 'Tuvalu',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Twin Falls ID',
    label: 'Twin Falls ID',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Tyler-Longview(Lufkin & Nacogdoches) TX',
    label: 'Tyler-Longview(Lufkin & Nacogdoches) TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Uganda',
    label: 'Uganda',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Ukraine',
    label: 'Ukraine',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Ulster',
    label: 'Ulster, United Kingdom',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'United Arab Emirates',
    label: 'United Arab Emirates',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'United Kingdom',
    label: 'United Kingdom',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'United States',
    label: 'United States',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'US & CANADA',
    label: 'United States & Canada',
    category: 'World_Region',
    remove: 1,
  },
  {
    value: 'United States Minor Outlying Islands',
    label: 'United States Minor Outlying Islands',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Uruguay',
    label: 'Uruguay',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Utah, United States',
    label: 'Utah, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Utica NY',
    label: 'Utica NY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Uzbekistan',
    label: 'Uzbekistan',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Vanuatu',
    label: 'Vanuatu',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Venezuela, Bolivarian Republic of',
    label: 'Venezuela, Bolivarian Republic of',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Vermont, United States',
    label: 'Vermont, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Victoria TX',
    label: 'Victoria TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Victoria, Australia',
    label: 'Victoria, Australia',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Viet Nam',
    label: 'Viet Nam',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Virgin Islands, British',
    label: 'Virgin Islands, British',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Virgin Islands, U.S.',
    label: 'Virgin Islands, U.S.',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Virginia, United States',
    label: 'Virginia, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Waco-Temple-Bryan TX',
    label: 'Waco-Temple-Bryan TX',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Waikato, New Zealand',
    label: 'Waikato, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Wales, United Kingdom',
    label: 'Wales, United Kingdom',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Wallis and Futuna',
    label: 'Wallis and Futuna',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Washington DC (Hagerstown MD)',
    label: 'Washington DC (Hagerstown MD)',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Washington, United States',
    label: 'Washington, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Watertown NY',
    label: 'Watertown NY',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Wausau-Rhinelander WI',
    label: 'Wausau-Rhinelander WI',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Wellington, New Zealand',
    label: 'Wellington, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'WEST',
    label: 'WEST',
    category: 'US_Region',
    remove: 1,
  },
  {
    value: 'West Coast, New Zealand',
    label: 'West Coast, New Zealand',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'West Palm Beach-Ft. Pierce FL',
    label: 'West Palm Beach-Ft. Pierce FL',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'West Virginia, United States',
    label: 'West Virginia, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Western Australia, Australia',
    label: 'Western Australia, Australia',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Western Sahara',
    label: 'Western Sahara',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Wheeling WV-Steubenville OH',
    label: 'Wheeling WV-Steubenville OH',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Wichita Falls TX & Lawton OK',
    label: 'Wichita Falls TX & Lawton OK',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Wichita-Hutchinson KS',
    label: 'Wichita-Hutchinson KS',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Wilkes Barre-Scranton PA',
    label: 'Wilkes Barre-Scranton PA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Wilmington NC',
    label: 'Wilmington NC',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Wisconsin, United States',
    label: 'Wisconsin, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Wyoming, United States',
    label: 'Wyoming, United States',
    category: 'US_State',
    remove: 0,
  },
  {
    value: 'Yakima-Pasco-Richland-Kennewick WA',
    label: 'Yakima-Pasco-Richland-Kennewick WA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Yemen',
    label: 'Yemen',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Yorkshire',
    label: 'Yorkshire, United Kingdom',
    category: 'Metro',
    remove: 0,
  },
  {
    value: 'Youngstown OH',
    label: 'Youngstown OH',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Yuma AZ-El Centro CA',
    label: 'Yuma AZ-El Centro CA',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Zambia',
    label: 'Zambia',
    category: 'Country',
    remove: 0,
  },
  {
    value: 'Zanesville OH',
    label: 'Zanesville OH',
    category: 'US_Metro',
    remove: 0,
  },
  {
    value: 'Zimbabwe',
    label: 'Zimbabwe',
    category: 'Country',
    remove: 0,
  },
];
