import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { TextInput } from 'components/TextInput';
import { Button } from 'components/Button';
import { Logo } from 'components/Logo';
import { passwordResetRequest } from 'pages/ResetPassword/api';

export const ForgotPassword = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const history = useHistory();

  const onSubmit = async (data) => {
    setLoading(true);
    await passwordResetRequest(data);
    setLoading(false);
    setSubmitted(true);
  };

  const handleLoginLink = (e) => {
    e.preventDefault();
    history.push(`/login`);
  };

  return (
    <div className="column card center">
      <div className="v-spacing row center">
        <Logo />
      </div>

      {!submitted && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="column v-spacing">
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextInput placeholder="E-mail" error={errors.clientName} {...field} />
              )}
            />
          </div>
          <Button primary submit onClick={handleSubmit(onSubmit)} loading={loading}>
            Forgot Password
          </Button>
        </form>
      )}
      {submitted && (
        <div
          className="column center gap"
          style={{
            padding: 30,
            backgroundColor: 'var(--wmg-color-background)',
            borderRadius: 'var(--wmg-border-radius-small)',
          }}
        >
          <span>Please check your email for the reset link</span>
          <a href="/" className="bold primary" onClick={handleLoginLink}>
            Login
          </a>
        </div>
      )}
    </div>
  );
};
