import React from 'react';
import { combineClasses } from 'utils/classNames';
import './style.css';

interface AvatarProps {
  name: string;
  onClick?: () => void;
}

export const Avatar = ({ name, onClick }: AvatarProps) => {
  const styles = combineClasses(['avatar', onClick && 'clickable']);
  return (
    <div className={styles} onClick={onClick} data-html2canvas-ignore="true">
      {name.substr(0, 1).toUpperCase()}
    </div>
  );
};
