export const filterByKey = (data, query, key) => {
  return data
    .filter((d) =>
      query.length
        ? d.children
          ? filterByKey(d.children, query, key)
          : d[key].toLowerCase().startsWith(query.toLowerCase())
        : d,
    )
    .map((d) => (query.length && d.children ? { ...d, children: filterByKey(d.children, query, key) } : d));
};
