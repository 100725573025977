import Icon, { IconType } from 'components/Icon';
import './style.css';

const ChildTableRow = ({ data, onSelection, isSelected, level = -1 }) => {
  return (
    <>
      {level > -1 && (
        <tr key={data.value + data.category} className={`center caption child ${isSelected(data) ? 'selected' : ''}`}>
          <td width="100%" style={{ padding: 0, paddingLeft: level * 12 }}>
            {level > 0 && <span className="indentation"></span>}
            <a
              href="#"
              className="caption underline"
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                display: 'flex',
                flexDirection: 'row',
                flex: 0,
              }}
              onClick={(e) => {
                e.preventDefault();
                onSelection(data);
              }}
            >
              <span>
                {data.label} {isSelected(data) && <Icon type={IconType.check} color="white" />}
              </span>
            </a>
          </td>
        </tr>
      )}
      {data.children &&
        data.children.map((child) => (
          <ChildTableRow data={child} onSelection={onSelection} isSelected={isSelected} level={level + 1} />
        ))}
    </>
  );
};

export default ChildTableRow;
