import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { TextInput } from 'components/TextInput';
import { Button } from 'components/Button';
import { ErrorMessage } from 'components/ErrorMessage';
import { Logo } from 'components/Logo';
import { resetPasswordConfirmation } from './api';

export const ResetPassword = () => {
  const { uid, token } = useParams();
  const history = useHistory();

  const { control, handleSubmit } = useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const onSubmit = async (data) => {
    setLoading(true);
    if (data.newPassword !== data.newPasswordConfirm) {
      setError('Passwords do not match');
      return;
    }
    await resetPasswordConfirmation({ uid, token, ...data });
    history.push(`/login`);

    setLoading(false);
  };

  React.useEffect(() => {
    if (error) setLoading(false);
  }, [error]);

  return (
    <div className="column card center">
      <div className="v-spacing row center">
        <Logo />
      </div>
      {error && <ErrorMessage message={error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="column v-spacing">
          <Controller
            name="newPassword"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => <TextInput secure placeholder="Password" {...field} />}
          />
          <Controller
            name="newPasswordConfirm"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => <TextInput secure placeholder="Confirm password" {...field} />}
          />
        </div>
        <Button primary submit onClick={handleSubmit(onSubmit)} loading={loading}>
          Reset Password
        </Button>
      </form>
    </div>
  );
};
