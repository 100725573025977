import React from 'react';
import './style.css';
import 'App.css';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartCard } from 'components/ChartCard';
import { abbreviateWholeNumber } from 'utils/numbers';
import { CustomAxisTick } from '../CustomAxisTick';
import BarCustomTooltip from './BarCustomToolTip';
const YAxisLabel = ({ name, count }) => {
  return (
    <>
      <text fill="var(--wmg-color-white)" fontWeight="bold">
        {name}
      </text>
      <text dy={15} fill="var(--wmg-color-grey)">
        {count.toLocaleString()}
      </text>
    </>
  );
};

export const EngagementContextChart = ({ data, isLoading }) => {
  return (
    <ChartCard
      header={
        <div className="row center">
          Source of Audience Engagement
          <div className="chart-scale">logarithmic table</div>
        </div>
      }
      isLoading={isLoading}
      noData={!isLoading && !data?.length}
    >
      {!isLoading && !!data?.length && (
        <ResponsiveContainer width={'100%'} height={data.length * (data.length > 1 ? 50 : 65)}>
          <BarChart
            className="engagement-context-chart"
            barCategoryGap="16"
            layout="vertical"
            width={575}
            data={data}
            margin={{ left: 60 }}
          >
            <CartesianGrid horizontal={false} strokeDasharray="5 5" opacity={0.1} />
            <YAxis
              tickLine={false}
              type="category"
              dataKey="name"
              axisLine={false}
              tick={({ payload, x, y, index }) => {
                return (
                  <CustomAxisTick x={x - 110} y={y}>
                    <YAxisLabel name={payload.value} count={data[index].count} />
                  </CustomAxisTick>
                );
              }}
            />
            <XAxis
              axisLine={false}
              tickLine={false}
              type="number"
              tick={{
                fill: 'var(--wmg-color-grey)',
                fontSize: 'var(--wmg-text-caption-size)',
              }}
              orientation="top"
              scale="log"
              domain={['auto', 'auto']}
              ticks={[100, 1000, 10000, 100000, 1000000, 10000000, 500000000]}
              tickFormatter={abbreviateWholeNumber}
            />
            <Tooltip cursor={{ fill: 'transparent' }} content={<BarCustomTooltip />} />
            <Bar
              // stackId={'a'}
              dataKey="count"
              fill="var(--wmg-color-primary)"
              radius={[0, 20, 20, 0]}
              legendType="none"
              barSize={12}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </ChartCard>
  );
};
