import Icon, { IconType } from 'components/Icon';
import React from 'react';
import Spinner from 'react-svg-spinner';
import { combineClasses } from 'utils/classNames';
import './style.css';

interface ButtonProps {
  children: React.ReactNode;
  onClick: (event?) => void;
  primary?: boolean;
  secondary?: boolean;
  dark?: boolean;
  disabled?: boolean;
  loading?: boolean;
  icon?: IconType;
  submit?: boolean;
  sml?: boolean;
}

export const Button = ({
  children,
  onClick,
  primary,
  secondary,
  dark,
  disabled,
  loading,
  icon,
  submit,
  sml,
}: ButtonProps) => {
  const containerStyles = combineClasses([
    'button',
    'row',
    primary && 'primary',
    secondary && 'secondary',
    dark && 'dark',
    sml && 'sml',
    (disabled || loading) && 'disabled',
  ]);

  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) onClick(e);
  };

  return (
    <a href="" className={containerStyles} onClick={handleOnClick}>
      {loading && <Spinner size="24px" color="#fff" />}
      {!loading && icon && <Icon type={icon} />}
      {!loading && children}
      {submit && <input type="submit" />}
    </a>
  );
};
