import React, { useEffect } from 'react';
import { ResponsiveContainer, AreaChart, XAxis, YAxis, Area, Tooltip, CartesianGrid } from 'recharts';
// import { abbreviateNumber } from 'utils/numbers';
import { CustomTooltip } from './CustomTooltip';
import { CustomAxisTick } from '../CustomAxisTick';
import { BankedAudienceType } from './api';
import { ChartCard } from 'components/ChartCard';
import { format, differenceInDays, subDays } from 'date-fns';
import { abbreviateNumber } from 'js-abbreviation-number';
import { DateRangeFilter } from './DateRangeFilter';

const filterOptions = [
  { label: '7 days', value: 7 },
  { label: '14 days', value: 14 },
  { label: '30 days', value: 30 },
];

export const BankedAudienceChart = ({ data, isLoading }) => {
  const [filteredData, setFilteredData] = React.useState<BankedAudienceType[]>([]);
  const [activeDateRange, setActiveDateRange] = React.useState(filterOptions[0]);

  useEffect(() => {
    if (!isLoading && data.reduce((acc, curr) => acc + curr.value, 0)) {
      const filtered = data.filter(
        ({ date }) => differenceInDays(subDays(new Date(), 1), date) <= activeDateRange.value,
      );

      setFilteredData(filtered);
    }
  }, [activeDateRange.value, data, isLoading]);

  const handleFilterDateRange = (d) => {
    setActiveDateRange(d);
  };

  return (
    <ChartCard header={'Banked audience members'} isLoading={isLoading} noData={!isLoading && !filteredData.length}>
      {!isLoading && !!filteredData.length && (
        <>
          <DateRangeFilter onChange={handleFilterDateRange} options={filterOptions} activeFilter={activeDateRange} />

          <ResponsiveContainer width={'100%'} height={'100%'}>
            <AreaChart data={filteredData} margin={{ left: -20, bottom: 0 }}>
              <defs>
                <linearGradient id="areaChartGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="var(--wmg-color-white)" stopOpacity={0.14} />
                  <stop offset="75%" stopColor="var(--wmg-color-white)" stopOpacity={0.05} />
                </linearGradient>
              </defs>

              <Area activeDot={false} dataKey="value" stroke="var(--wmg-color-grey)" fill="url(#areaChartGradient)" />
              <Area
                yAxisId="1"
                activeDot={false}
                dataKey="total"
                stroke="var(--wmg-color-primary)"
                fill="transparent"
              />

              <XAxis
                dataKey="date"
                padding={{ left: 15, right: 15 }}
                axisLine={false}
                tickLine={false}
                interval={0}
                allowDataOverflow={true}
                ticks={[filteredData[0].date, filteredData[filteredData.length - 1].date]}
                tick={({ payload, x, y }) => {
                  return <CustomAxisTick value={format(payload.value, 'MM/dd')} x={x - 23} y={y + 15} />;
                }}
              />

              <YAxis
                dataKey="value"
                padding={{ top: 50 }}
                domain={[(dataMin) => dataMin / 1.25, (dataMax) => dataMax]}
                axisLine={false}
                tickLine={false}
                // tickCount={10}
                tick={({ payload, x, y }) => (
                  <CustomAxisTick value={abbreviateNumber(payload.value)} x={x - 30} y={y} />
                )}
              />
              <YAxis
                dataKey="total"
                orientation="right"
                yAxisId="1"
                padding={{ top: 50 }}
                axisLine={false}
                tickLine={false}
                tick={({ payload, x, y }) => <CustomAxisTick value={abbreviateNumber(payload.value, 0)} x={x} y={y} />}
              />
              <Tooltip
                isAnimationActive={false}
                allowEscapeViewBox={{ x: true }}
                cursor={{ stroke: 'var(--wmg-color-primary)', strokeWidth: 2 }}
                position={{ y: 0 }}
                content={({ active, payload, label, viewBox }) => {
                  return (
                    active &&
                    payload && (
                      <CustomTooltip
                        value={`${payload[0].value?.toLocaleString()}`}
                        total={`${payload[1].value?.toLocaleString()}`}
                        label={format(label, 'MM/dd')}
                        chartHeight={viewBox?.height}
                      />
                    )
                  );
                }}
              />
              <CartesianGrid strokeDasharray="6 6" opacity={0.1} vertical={false} />
            </AreaChart>
          </ResponsiveContainer>
        </>
      )}
    </ChartCard>
  );
};
