import { Grid, GridColumns } from 'components/Grid';
import { Page } from 'components/Page';
import { useQueryString } from 'hooks/useQuery';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { AgeGenderChart } from '../AgeGenderChart';
import { getAgeGender } from '../AgeGenderChart/api';
import { BankedAudienceChart } from '../BankedAudienceChart';
import { Countries } from '../Countries';
import { EngagementContextChart } from '../EngagementContextChart';
import { SocialDataChart } from '../SocialDataCharts';
import { EngagementTypeChart } from '../EngagementTypeChart';
import { useFilterByCountry } from '../FilterByCountry/context';
import { Interests } from '../Interests';
import { useParams, useLocation } from 'react-router-dom';
import { getBankedAudience } from '../BankedAudienceChart/api';
import { getEngagementContext } from '../EngagementContextChart/api';
import { getEngagementType } from '../EngagementTypeChart/api';
import { getSocialData } from '../SocialDataCharts/api';
import { getInterests } from '../Interests/api';
import { getCountries } from '../Countries/api';
import { getMetadata } from 'pages/ArtistDetail/api';
//no data found - http://localhost:3000/artist/2527
// http://localhost:3000/artist/591271
// http://localhost:3000/artist/117364

const ArtistDetailContent = () => {
  const { id } = useParams();

  const { state } = useLocation();
  const query = useQueryString();
  const {
    state: { countries },
  } = useFilterByCountry();

  const withFilters = query.get('filters');

  const { data: metaData, isFetching: isLoadingMetadata } = useQuery(
    [`artist_meta_${id}`, countries],
    () => getMetadata(id, countries),
    { staleTime: 0, refetchOnWindowFocus: false },
  );

  const { data: ageGenderData, isFetching: isLoadingAgeGender } = useQuery(
    [`artist_age_gender_data_${id}`, countries],
    () => getAgeGender(id, countries),
    { staleTime: 0, refetchOnWindowFocus: false },
  );

  const { data: bankedAudienceData, isFetching: isLoadingBankedAudience } = useQuery(
    [`banked_audience_${id}`, countries],
    () => getBankedAudience(id, countries),
    { staleTime: 0, refetchOnWindowFocus: false },
  );

  const { data: engagementData, isFetching: isLoadingEngagement } = useQuery(
    [`artist_engagement_context_${id}`, countries],
    () => getEngagementContext(id, state?.name || metaData.name.toUpperCase(), countries),
    { staleTime: 0, refetchOnWindowFocus: false },
  );

  // const { data: socialData, isFetching: isLoadingSocialData } = useQuery(
  //   [`socialData_${state?.name || metaData?.name.toUpperCase()}`],
  //   () => getSocialData(state?.name || metaData.name.toUpperCase()),
  //   { staleTime: 0, refetchOnWindowFocus: false },
  // );

  const { data: engagementTypeData, isFetching: isLoadingEngagementType } = useQuery(
    [`artist_engagement_type_${id}`, countries],
    () => getEngagementType(id, countries),
    { staleTime: 0, refetchOnWindowFocus: false },
  );

  const { data: interestData, isFetching: isLoadingInterestData } = useQuery(
    [`artist_interests_${id}_inmarket`, countries],
    () => getInterests(id, 'inmarket', countries),
    { staleTime: 0, refetchOnWindowFocus: false },
  );

  const { data: countriesData, isFetching: isLoadingCountries } = useQuery(
    [`artist_country_${id}`, countries],
    () => getCountries(id, countries),
    { staleTime: 0, refetchOnWindowFocus: false },
  );

  const bankedAudienceNoShow = useMemo(
    () =>
      bankedAudienceData ? !bankedAudienceData.reduce((acc, curr) => acc + curr.value, 0) : !isLoadingBankedAudience,
    [bankedAudienceData, isLoadingBankedAudience],
  );
  const ageGenderNoShow = useMemo(
    () => (ageGenderData ? !ageGenderData.length : !isLoadingAgeGender),
    [ageGenderData, isLoadingAgeGender],
  );
  const engagementNoShow = useMemo(
    () => (engagementData ? !engagementData.reduce((acc, curr) => acc + curr.count, 0) : !isLoadingEngagement),
    [engagementData, isLoadingEngagement],
  );

  // const socialNoShow = useMemo(
  //   () => (socialData ? !Object.keys(socialData).some((d) => socialData[d]?.length) : !isLoadingSocialData),
  //   [socialData, isLoadingSocialData],
  // );

  const engagementTypeNoShow = useMemo(
    () =>
      engagementTypeData ? !engagementTypeData.reduce((acc, curr) => acc + curr.count, 0) : !isLoadingEngagementType,
    [engagementTypeData, isLoadingEngagementType],
  );
  const interestNoShow = useMemo(
    () => (interestData ? !interestData.length : !isLoadingInterestData),
    [interestData, isLoadingInterestData],
  );

  const countriesNoShow = useMemo(
    () => (countriesData ? !countriesData.length : !isLoadingCountries),
    [countriesData, isLoadingCountries],
  );

  const data = useMemo(
    () => [
      {
        data: bankedAudienceData,
        isLoading: isLoadingBankedAudience,
        title: 'Banked audience members',
        component: (props) => <BankedAudienceChart {...props} />,
        noShow: bankedAudienceNoShow,
      },
      {
        data: ageGenderData,
        isLoading: isLoadingAgeGender && isLoadingMetadata,
        title: 'Gender & Age distribution',
        noShow: ageGenderNoShow,
      },
      {
        data: engagementData,
        isLoading: isLoadingEngagement,
        title: 'Source of Audience Engagement',
        noShow: engagementNoShow,
      },
      // {
      //   data: socialData,
      //   isLoading: isLoadingSocialData,
      //   title: 'Social Media Data',
      //   noShow: socialNoShow,
      // },
      {
        data: engagementTypeData,
        isLoading: isLoadingEngagementType,
        title: 'Audience Segments',
        noShow: engagementTypeNoShow,
      },
      {
        data: countriesData,
        isLoading: isLoadingCountries,
        title: 'Location',
        noShow: countriesNoShow,
      },
      {
        data: interestData,
        isLoading: isLoadingInterestData,
        title: 'Interest & Affinity Segmentation',
        noShow: interestNoShow,
      },
    ],
    [
      ageGenderData,
      ageGenderNoShow,
      bankedAudienceData,
      bankedAudienceNoShow,
      countriesData,
      countriesNoShow,
      engagementData,
      engagementNoShow,
      engagementTypeData,
      engagementTypeNoShow,
      interestData,
      interestNoShow,
      isLoadingAgeGender,
      isLoadingBankedAudience,
      isLoadingCountries,
      isLoadingEngagement,
      isLoadingEngagementType,
      isLoadingInterestData,
      isLoadingMetadata,
    ],
  );

  return (
    <Page>
      <div className="column gap-m" style={{ paddingTop: withFilters ? 200 : 150 }}>
        <Grid cols={GridColumns.two}>
          {!bankedAudienceNoShow && (
            <BankedAudienceChart data={bankedAudienceData} isLoading={isLoadingBankedAudience} />
          )}
          {!engagementNoShow && <EngagementContextChart data={engagementData} isLoading={isLoadingEngagement} />}
          {!ageGenderNoShow && (
            <AgeGenderChart data={ageGenderData} metadata={metaData} isLoading={isLoadingAgeGender} />
          )}
          {!engagementTypeNoShow && (
            <EngagementTypeChart data={engagementTypeData} isLoading={isLoadingEngagementType} />
          )}
        </Grid>
        {!countriesNoShow && <Countries data={countriesData} isLoading={isLoadingCountries} />}
        <Grid cols={GridColumns.two}>{!interestNoShow && <Interests />}</Grid>

        {/* {!socialNoShow && <SocialDataChart data={socialData} isLoading={isLoadingSocialData} />}
        {data.some((d) => d.noShow) && (
          <div className="column  ">
            <div className="caption small ">This artist doesn't have metrics for following sections:</div>
            <div className="column caption small secondary">
              {data
                .filter((d) => d.noShow)
                .map((d, index) => (
                  <div key={index}>{d.title}</div>
                ))}
            </div>
          </div>
        )} */}
      </div>
    </Page>
  );
};

export default ArtistDetailContent;
