import React from 'react';
import { combineClasses } from 'utils/classNames';
import './styles.css';

export enum GridColumns {
  'two' = 2,
  'three' = 3,
}

interface GridProps {
  cols: GridColumns;
  children: React.ReactNode;
}

export const Grid = ({ cols, children }: GridProps) => {
  const styles = combineClasses(['grid', `cols-${cols}`]);
  return <div className={styles}>{children}</div>;
};
