import React from 'react';
import { abbreviateNumber } from 'utils/numbers';
import { SimpleTooltip } from '../../../../components/Tooltips/SimpleTooltip';
const colors = ['var(--wmg-color-primary)', 'var(--wmg-color-primary2)', 'var(--wmg-color-secondary)'];

const EngagementTypeLegendItem = ({ data, index }) => {
  return (
    <>
      <div className="row center" style={{ alignItems: 'baseline' }}>
        <SimpleTooltip description={data.description}>
          <h2 style={{ color: colors[index], minWidth: '100px' }}>{(data.percent * 100).toFixed(1) + '%'}</h2>
        </SimpleTooltip>
        <div className="column" style={{ minWidth: '60px' }}>
          <span className="body" style={{ color: 'var(--wmg-color-grey)' }}>
            {abbreviateNumber(data.count, 1)}
          </span>
        </div>
      </div>
      <span
        className="caption"
        style={{
          marginTop: -10,
          marginBottom: 10,
          color: 'var(--wmg-color-grey)',
        }}
      >
        {data.name}
      </span>
    </>
  );
};

export default EngagementTypeLegendItem;
