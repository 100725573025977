import React from 'react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { combineClasses } from 'utils/classNames';
import './style.css';

interface PopOverProps {
  children: React.ReactNode;
  isVisible: boolean;
  light?: boolean;
  bottom?: boolean;
  left?: boolean;
  fullWidth?: boolean;
  onCancel: () => void;
}

const PopOver = ({ children, isVisible, light, bottom, left, fullWidth, onCancel }: PopOverProps) => {
  const { ref } = useOutsideClick({
    isVisible,
    handleClickOutside: onCancel,
  });

  const styles = combineClasses([
    'pop-over',
    'column',
    'with-scrollbar',
    light && 'light',
    bottom && 'bottom',
    left && 'left',
    fullWidth && 'fullWidth',
  ]);

  if (!isVisible) return null;

  return (
    isVisible && (
      <div ref={ref} className={styles} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    )
  );
};

interface PopOverItemProps {
  children: React.ReactNode;
  onClick?: (d?) => void;
  disabled?: boolean;
  selected?: boolean;
}

const PopOverItem = ({ children, onClick, disabled, selected }: PopOverItemProps) => {
  const handleClick = (e) => {
    e.preventDefault();
    if (!disabled && onClick) onClick();
  };
  const styles = combineClasses([
    'pop-over-item',
    'row',
    disabled && 'disabled',
    disabled && 'secondary',
    selected && 'selected',
  ]);
  return onClick ? (
    <a href="" onClick={handleClick} className={styles}>
      {children}
    </a>
  ) : (
    <div className={styles}>{children}</div>
  );
};

export { PopOverItem };

export default PopOver;
