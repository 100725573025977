import React from 'react';

import { TooltipProps } from 'recharts';

import { ContextDescription } from './ContextDescription';

const BarCustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip engagement-context-chart">
        <h2>{data.name}</h2>
        <div>
          <p>{ContextDescription[data.name]}</p>

          {data.values ? (
            <div style={{ marginTop: 5 }}>
              {data.values
                .sort((a, b) => a.order - b.order)
                .map((v) => (
                  <p key={v.name} className="flex row">
                    <span className="flex">{v.name}</span> {v.count.toLocaleString()}
                  </p>
                ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  return null;
};

export default BarCustomTooltip;
