import { getArtistBodyHelper } from 'pages/ArtistList/api';
import api from 'utils/api';
import { audienceHelper } from './helpers';

const partnerId = 14;
const audigentAudBankId = 31;

type FilterType = {
  category: string;
  label: string;
  operator: string;
  value: string;
};

export type DestinationType = {
  account_id: string;
  display_segment_id: number;
  id: number;
  name: string;
};

export type AudienceType = {
  created: string;
  destinations: DestinationType[];
  display_segment__second_party_size: number;
  filters: FilterType[];
  id: number;
  name: string;
  segment__partner_site_id: number;
  segment__user__account__email: string;
  segment_id: number;
  size: number;
};

export const updateCustomAudience = async ({ id, name, filters, additionalParticipants }) =>
  api(`audience-builder/segment-display/${id}/update-audience-bank-segment`, {
    method: 'PUT',
    body: JSON.stringify({
      name,
      partner_id: partnerId,
      ...getArtistBodyHelper(filters),
      additional_participants: additionalParticipants || [],
    }),
  });

export const createCustomAudience = async ({ name, filters }) =>
  api('audience-builder/segment-display/audience-bank-segment', {
    method: 'POST',
    body: JSON.stringify({
      name,
      partner_id: partnerId,
      ...getArtistBodyHelper(filters),
      additional_participants: [],
    }),
  });

export const deleteCustomAudience = async (segment_id) =>
  api(`audience-builder/segment-display/${segment_id}/delete-audience-bank-segment`, { method: 'DELETE' });

export const getSegmentDestinations = async (segment_id) => {
  const destinations = await api(`audience-builder/segment/${segment_id}/destinations`);
  return destinations.filter((d) => d.id !== audigentAudBankId);
};

export const getDestinations = async () => {
  const destinations = await api(`partner_site/${partnerId}/destinations`);
  return destinations.filter((d) => d.id !== audigentAudBankId);
};

export const updateDestination = async ({ segmentId, destinationId, additionalParticipants }) =>
  api(`audience-builder/segment-display`, {
    method: 'POST',
    body: JSON.stringify({
      complex_filter_segment_id: segmentId,
      partner_site_id: partnerId,
      destination_id: destinationId,
      additional_participants: additionalParticipants || [],
    }),
  });

export const deleteDestination = async (segment_id) =>
  api(`audience-builder/segment-display/${segment_id}`, { method: 'DELETE' });

export const getCustomAudiences = async () => {
  let audiences = await api('audience-builder/segment-display?audiencebank=true');

  audiences = await Promise.all(
    audiences.map(async (a) => {
      const destinations = await getSegmentDestinations(a.segment_id);
      return {
        destinations,
        ...a,
      };
    }),
  );

  return audienceHelper(audiences);
};
