import React, { useMemo, useState } from 'react';
import { TableCard } from 'components/TableCard';
import { Table } from 'components/Table';
import { ReactComponent as WorldMap } from '../../../../assets/worldMap.svg';
import { SearchBar } from '../../../ArtistList/components/SearchBar';
import { filterByKey } from '../../../../utils/arrays';
import './style.css';
import { CircleFlag } from 'react-circle-flags';
import { CountryCodes } from './constants/CountryCodes';
import { SortableHeaderTitle } from 'components/Table/SortHelper/SortableHeaderTitle';

const countryToCode = (countryName) => {
  return CountryCodes[countryName] ? CountryCodes[countryName].toLowerCase() : 'na';
};

const countryMapElement = (countryName) => {
  const tempCountryG = document.querySelector(`g[id='${countryToCode(countryName)}']`);
  const tempCountryP = document.querySelector(`path[id='${countryToCode(countryName)}']`);
  return tempCountryG || tempCountryP;
};

type CountryObject = {
  name: string;
  count: number;
  percent: number;
  index: number;
};
export const Countries = ({ data, isLoading }) => {
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<CountryObject[]>([]);
  const [sortSelection, setSortSelection] = useState({
    value: 'count',
    direction: 'desc',
  });

  React.useEffect(() => {
    if (!isLoading && data) {
      setFilteredData(filterByKey(data, searchQuery, 'name'));
    }
  }, [isLoading, searchQuery, data]);

  const onQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useMemo(() => {
    if (filteredData && filteredData.length) {
      if (sortSelection.direction === 'asc') {
        return filteredData.sort((a, b) => a[sortSelection.value] - b[sortSelection.value]);
      } else if (sortSelection.direction === 'desc') {
        return filteredData.sort((a, b) => b[sortSelection.value] - a[sortSelection.value]);
      }
    }
    return [];
  }, [filteredData, sortSelection.direction, sortSelection.value]);

  return (
    <TableCard staticHeight header="Location" isLoading={isLoading} noData={!data || !data.length}>
      <div className="row country-table">
        <div className="column flex">
          <>
            <SearchBar query={searchQuery} onChange={onQueryChange} disabled={isLoading} />
            <Table
              header={
                <>
                  <td width="100%" />
                  <td style={{ minWidth: 100, textAlign: 'right' }}>
                    <SortableHeaderTitle
                      position="right"
                      setSortSelection={setSortSelection}
                      currentSortSelection={sortSelection}
                      title="Size"
                      value="count"
                    />
                  </td>
                </>
              }
              isLoading={isLoading}
            >
              {!isLoading &&
                data.length > 0 &&
                filteredData.map((d) => (
                  <tr
                    key={d.name}
                    className="caption country-row"
                    onMouseOver={() => {
                      const tempCountry = countryMapElement(d.name);
                      if (tempCountry) {
                        tempCountry.classList.add('hover-country');
                      }
                    }}
                    onMouseLeave={() => {
                      const tempCountry = countryMapElement(d.name);
                      if (tempCountry) {
                        tempCountry.classList.remove('hover-country');
                      }
                    }}
                  >
                    <td>
                      <CircleFlag
                        style={{ marginRight: 10 }}
                        countryCode={countryToCode(d.name)}
                        height="20"
                        data-html2canvas-ignore="true"
                      />
                      {d.name}
                    </td>
                    <td className={' bold'} style={{ textAlign: 'right' }}>
                      {d.count}
                    </td>
                  </tr>
                ))}
            </Table>
          </>
        </div>
        <div className="map-svg column flex">
          <WorldMap />
        </div>
      </div>
    </TableCard>
  );
};
