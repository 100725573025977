import React from 'react';
import { Page } from 'components/Page';
import { useQuery } from 'react-query';
import { getCustomAudiences } from './api';
import { AudiencesTable } from './components/AudiencesTable';
import { SearchBar } from 'pages/ArtistList/components/SearchBar';
import Spinner from 'react-svg-spinner';
import { filterByKey } from 'utils/arrays';
import { useCollection } from 'pages/CollectionList/context';
import { useAuth } from 'contexts/AuthContext';

export const CustomAudience = () => {
  const [filteredData, setFilteredData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const {
    state: { items: collections },
  } = useCollection();
  const {
    state: { user },
  } = useAuth();
  const { data, isFetching } = useQuery('customAudiences', () => getCustomAudiences(), {
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    if (!isFetching && data) {
      const username = user.email.split('@')[0];
      setFilteredData(
        filterByKey(data, searchQuery, 'name')
          .filter((c) => (c.audience ? (c.name.includes(username) ? true : false) : true))
          .map((d) => {
            const collection = collections.find((c) => d.name.includes(username) && d.name.endsWith(c.name));

            return collection ? { ...d, collection: collection } : d;
          }),
      );
    }
  }, [isFetching, searchQuery, data, user, collections]);

  const onQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <Page>
        <div className="row">
          <SearchBar flex query={searchQuery} onChange={onQueryChange} />
        </div>
        {isFetching && (
          <div className="column center flex">
            <div className="row center flex">
              <Spinner size="32px" color="rgba(255,255,255,0.15)" />
            </div>
          </div>
        )}
        {!isFetching && !filteredData.length && (
          <div className="column center flex">
            <div className="row center flex secondary">No custom audiences found</div>
          </div>
        )}

        {!isFetching && !!filteredData.length && <AudiencesTable data={filteredData} />}
      </Page>
    </>
  );
};

export default CustomAudience;
