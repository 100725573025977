import React from 'react';
import { useParams } from 'react-router-dom';
import Icon, { IconType } from 'components/Icon';
import PopOver, { PopOverItem } from 'components/PopOver';

import './style.css';
import { useQuery } from 'react-query';
import { getCountries } from '../Countries/api';
import { useFilterByCountry } from './context';
import { Button } from 'components/Button';
export type SortType = {
  value: string;
  direction: 'desc' | 'asc';
  label?: string;
  onlyFiltered?: boolean;
};

export const ArtistSortOptions = [
  { value: 'query_size', label: 'Audience Size', direction: 'asc' },
  { value: 'name', label: 'Artist Name A-Z', direction: 'desc' },
  { value: 'name', label: 'Artist Name Z-A', direction: 'asc' },
  {
    value: 'zscore',
    label: 'Relevance',
    direction: 'desc',
    onlyFiltered: true,
  },
];

export const FilterByCountry = ({ disabled }) => {
  const { id } = useParams();
  const {
    state: { countries },
    setCountry,
  } = useFilterByCountry();

  const { data, isLoading } = useQuery(`artist_country_${id}`, () => getCountries(id, []));
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOnClick = (countryName) => {
    setIsOpen(false);
    setCountry(countryName);
  };
  if (data && !data.length) return null;
  return (
    <div className="sort-by-container row center">
      <Button onClick={() => setIsOpen(!isOpen)} dark disabled={disabled}>
        <div className="flex" />
        {!!countries.length && (
          <div className="row">
            {countries[0]}
            {countries.length > 1 && <span className="secondary">{`+${countries.length - 1}`}</span>}
          </div>
        )}
        {!countries.length && 'All Countries'}
        <div className="flex" />
        <Icon type={IconType.arrowDown} />
      </Button>
      <PopOver isVisible={isOpen} onCancel={() => setIsOpen(false)} bottom>
        {!isLoading && (
          <>
            <PopOverItem key={'All Countries'} onClick={() => handleOnClick(undefined)}>
              <div className="column center" style={{ width: 24, minWidth: 24 }} />
              {'All Countries'}
            </PopOverItem>
            {data.map(({ name }) => (
              <PopOverItem key={name} onClick={() => handleOnClick(name)}>
                <div className="column center" style={{ width: 24, minWidth: 24 }}>
                  {countries.includes(name) && <Icon type={IconType.check} />}
                </div>
                <span>{name}</span>
              </PopOverItem>
            ))}
          </>
        )}
      </PopOver>
    </div>
  );
};
