import React from 'react';
import { useHistory } from 'react-router-dom';
import { Artwork } from 'components/Artwork';
import { Card } from 'components/Card';
import { CollectionEntry } from 'pages/CollectionList/context';
import { IconButton } from 'components/IconButton';
import { IconType } from 'components/Icon';
import './style.css';
import { DeleteCollectionModal } from '../DeleteCollectionModal';
interface CollectionCardProps extends CollectionEntry {
  key: string;
  customAudience?: boolean;
  isLoading?: boolean;
}

export const CollectionCard = ({ id, isLoading, audiences, name, customAudience }: CollectionCardProps) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const history = useHistory();

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const handleOnClick = (e) => {
    e.stopPropagation();
    history.push(`/collections/${id}`);
  };

  return (
    <div className="artist-card column v-spacing" onClick={handleOnClick}>
      <Card placeholder={isLoading} collection>
        {!isLoading && audiences?.slice(0, 4).map((a, i) => <Artwork key={i} image={a.image_url} />)}
      </Card>
      <div className="column">
        {isLoading && <h3 className="placeholder">{'Collection name'}</h3>}
        {!isLoading && (
          <div className="row center">
            <h3 className="flex">{name}</h3>
            {!customAudience && (
              <span className="delete-button">
                <IconButton icon={IconType.trash} onClick={handleDelete} />
                <DeleteCollectionModal
                  isVisible={showDeleteConfirmation}
                  onClose={() => setShowDeleteConfirmation(false)}
                  collectionId={id}
                />
              </span>
            )}
          </div>
        )}
        {!isLoading && (
          <div className="row center">
            <span className="secondary">{`${audiences?.length} artists`}</span>
          </div>
        )}
      </div>
    </div>
  );
};
