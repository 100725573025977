import React from 'react';
import Icon, { IconType } from 'components/Icon';
import './style.css';

interface ErrorMessageProps {
  message: string;
}

export const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <div className="error-message row center">
    <Icon type={IconType.info} />
    <span>{message}</span>
  </div>
);
