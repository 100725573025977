import { Tag } from 'components/Tag';
import { useOutsideClick } from 'hooks/useOutsideClick';
import React from 'react';
import { combineClasses } from 'utils/classNames';
import { FilterDataType, FilterDrawer } from './FilterDrawer';
import './style.css';

const filterGroups = [
  {
    label: 'Demographics',
    value: 'demographic',
    categories: ['age_gender_membership'],
  },
  {
    label: 'Locations',
    value: 'location',
    categories: ['world_region', 'metro', 'city_id', 'country', 'us_region'],
    suggestions: [
      {
        value: 'ASIA',
        label: 'ASIA',
        category: 'world_region',
      },
      {
        value: 'United Kingdom',
        label: 'United Kingdom',
        category: 'country',
      },
      {
        value: 'United States',
        label: 'United States',
        category: 'country',
      },
    ],
  },
  {
    label: 'Engagement & Device',
    value: 'engagement',
    categories: [
      'device_brand',
      'social_network',
      'device_operating_system',
      'content_type',
      'device_category',
      'event',
    ],
    suggestions: [
      {
        category: 'social_network',
        value: 'youtube',
        label: 'YOUTUBERS',
      },
      {
        category: 'device_brand',
        value: 'apple',
        label: 'iPhone',
      },
      {
        category: 'device_brand',
        value: 'google',
        label: 'GOOGLE',
      },
    ],
  },
  {
    label: 'Interests & Affinities',
    value: 'interests',
    categories: ['interest_inmarket', 'interest_other', 'interest_affinity'],
    suggestions: [
      {
        category: 'interest_affinity',
        value: 'Media & Entertainment/Movie Lovers',
        label: 'MOVIE LOVERS',
      },
      {
        category: 'interest_affinity',
        value: 'Sports & Fitness/Health & Fitness Buffs',
        label: 'HEALTH & FITNESS BUFFS',
      },
    ],
  },
];

export const Filters = ({ onChange, selected }) => {
  const [active, setActive] = React.useState<FilterDataType | undefined>();
  const containerStyles = combineClasses(['filters-container', 'column', active && 'active']);

  const onCancel = () => {
    setActive(undefined);
  };

  const toggleFilter = (d) => {
    setActive(active?.value !== d.value ? d : false);
  };

  const { ref } = useOutsideClick({
    isVisible: active,
    handleClickOutside: onCancel,
  });

  return (
    <div ref={ref} className={containerStyles}>
      <div className="row center">
        {filterGroups.map((d) => (
          <Tag
            active={active?.value === d.value}
            key={d.value}
            onClick={() => toggleFilter(d)}
            count={selected.filter((s) => d.categories?.some((c) => c === s.category)).length}
          >
            {d.label}
          </Tag>
        ))}
      </div>
      {active && <FilterDrawer filterGroup={active} onSelection={onChange} selectedFilters={selected} />}
    </div>
  );
};
