import Cookies from 'js-cookie';
const { REACT_APP_AUTH_COOKIE: AUTH_COOKIE, REACT_APP_AUTH_ENV: AUTH_ENV } = process.env;

const COOKIE_DOMAIN = AUTH_ENV === 'prod' ? '.warnermusicexperience.com' : `.${AUTH_ENV}.audigent.com`;
const AUTH_COOKIE_EXPIRY = `${AUTH_COOKIE}_e`;

let isDefunctTokenCleared = false;

export const retrieveToken = () => Cookies.get(AUTH_COOKIE);

export const saveToken = (token) => {
  if (AUTH_ENV !== 'local') {
    Cookies.set(AUTH_COOKIE, token, {
      path: '/',
      domain: COOKIE_DOMAIN,
    });
  } else {
    Cookies.set(AUTH_COOKIE, token);
  }
};

export const saveRefreshToken = (token) => {
  if (AUTH_ENV === 'prod' && AUTH_COOKIE) {
    Cookies.set(`${AUTH_COOKIE}_rt`, token, {
      path: '/',
      domain: COOKIE_DOMAIN,
    });
  }
};

export const retrieveTokenExpiry = () => Cookies.get(AUTH_COOKIE_EXPIRY);

export const saveTokenExpiry = (expiry) => Cookies.set(AUTH_COOKIE_EXPIRY, expiry);

export const clearToken = () => {
  Cookies.remove(AUTH_COOKIE, {
    path: '/',
    domain: COOKIE_DOMAIN,
  });
  Cookies.remove(`${AUTH_COOKIE}_rt`, {
    path: '/',
    domain: COOKIE_DOMAIN,
  });

  Object.keys(Cookies.get()).forEach((d) => Cookies.remove(d));
};

export const clearDefunctToken = () => {
  if (AUTH_ENV === 'prod' && !isDefunctTokenCleared) {
    const OLD_COOKIE_DOMAIN = 'connects.warnermusicexperience.com';
    Cookies.remove(AUTH_COOKIE, {
      path: '/',
      domain: OLD_COOKIE_DOMAIN,
    });
    Cookies.remove(`${AUTH_COOKIE}_rt`, {
      path: '/',
      domain: OLD_COOKIE_DOMAIN,
    });
    isDefunctTokenCleared = true;
  }

  Object.keys(Cookies.get()).forEach((d) => Cookies.remove(d));
};
