import api from 'utils/api';

export type BankedAudienceType = {
  date: string;
  value: number;
  total: number;
};

export const getBankedAudience = async (id, countries) => {
  const data = await api(
    `reporting/segment/${id}/growth${countries && countries.length ? `?countries=${countries.join('|')}` : ''}`,
  );
  return data
    .map((d: BankedAudienceType) => ({
      date: new Date(d[0]),
      value: d[2],
      total: d[1],
    }))
    .slice(0, -1);
};
