import api from 'utils/api';

export const createAudienceExtension = async ({
  brand,
  flightDates,
  budget,
  adType,
  inventoryType,
  geo,
  dsp,
  dspSeatId,
  additionalParticipants,
}) =>
  api('jira-ticket/submit', {
    method: 'POST',
    body: JSON.stringify({
      requester: 'sheryl@audigent.com',
      title: `WMX - Audience Extension - ${brand} - ${flightDates.to} > ${flightDates.from}`,
      description: `
        Brand: ${brand}
        Flight Dates: ${flightDates.to} > ${flightDates.from}
        Budget: ${budget}
        Ad type: ${adType.toString()}
        Inventory type: ${inventoryType.toString()}
        Country: ${geo}
        DSP: ${dsp}
        DSP Seat ID: ${dspSeatId}
        additionalParticipants: ${additionalParticipants.toString()}`,
      service_desk: 'SUPPORT',
      priority: 'Medium',
      additional_participants: additionalParticipants,
    }),
  });
