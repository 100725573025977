import api from 'utils/api';
import { FilterDataType } from '../components/Filters/FilterDrawer';

type RuleType = { operator: string; field: string; values: [number] };

type FilteredSegmentsRequestType = {
  definition: {
    base: {
      operator: string;
      rules: RuleType[];
    };
    demo?: {
      operator: string;
      rules: RuleType[];
    };
    rules?:
      | [
          {
            operator: string;
            rules: RuleType[];
          },
        ]
      | [];
  };
};

const demographicOptions = ['countries', 'country', 'age_gender_membership'];
const baseOptions = ['Audience'];

export const isDemographic = (field) => demographicOptions.some((c) => field.toLowerCase() === c);

export const isBase = (field) => baseOptions.some((c) => field === c);

export const groupRulesByCategoryHelper = (filters): RuleType[] =>
  filters.reduce((prev, cur) => {
    const existsAt = prev.findIndex((d) => d.field === cur.category.toLowerCase());
    if (existsAt < 0) {
      prev.push({
        operator: cur.operator,
        field: cur.category !== 'Audience' ? cur.category.toLowerCase() : cur.category,
        values: [cur.value],
      });
      return prev;
    }

    prev[existsAt].values.push(cur.value);
    return prev;
  }, []);

const ageGenderMembershipValueHelper = (values) =>
  values.reduce((prev, cur, i, list) => {
    const genders = ['female', 'male'];
    const gender = list.find((l) => genders.some((g) => g === l)) || '';
    const currentIsGender = genders.some((g) => g === cur);
    const age = !currentIsGender ? cur : '';
    if (currentIsGender && list.length > 1) return prev;
    prev.push(`${gender}_${age}_more-scale`);
    if (gender) {
    } else {
      genders.forEach((g) => prev.push(`${g}_${age}_more-scale`));
    }
    return prev;
  }, []);

const ageFilterHelper = (filters) => {
  const isNonAgeFilter = (f) => f.category !== 'age_gender_membership' || f.value === 'female' || f.value === 'male';
  const nonAgeFilters = filters.filter((f) => isNonAgeFilter(f));
  return filters.length - nonAgeFilters.length === 6 ? nonAgeFilters : filters;
};

export const getArtistBodyHelper = (filters) => {
  const newFilters = ageFilterHelper(filters);
  const demoRules = groupRulesByCategoryHelper(newFilters.filter((f) => isDemographic(f.category))).map((d) => {
    if (d.field === 'age_gender_membership') {
      d.values = ageGenderMembershipValueHelper(d.values);
    }

    return d;
  });

  const baseRules = groupRulesByCategoryHelper(filters.filter((f) => isBase(f.category)));

  const rules = groupRulesByCategoryHelper(filters.filter((f) => !isDemographic(f.category) && !isBase(f.category)));

  const filteredSegmentsRequest: FilteredSegmentsRequestType = {
    definition: {
      base: {
        operator: 'or',
        rules: [
          {
            operator: 'or',
            field: 'PartnerSite',
            values: [14],
          },
        ],
      },
    },
  };

  if (baseRules.length) {
    filteredSegmentsRequest.definition.base.rules = filteredSegmentsRequest.definition.base.rules.concat(baseRules);

    filteredSegmentsRequest.definition.demo = {
      operator: 'and',
      rules: [],
    };
    filteredSegmentsRequest.definition.rules = [];
  }

  if (demoRules.length) {
    filteredSegmentsRequest.definition.demo = {
      operator: 'and',
      rules: demoRules,
    };
    filteredSegmentsRequest.definition.rules = [];
  }

  if (rules.length) {
    filteredSegmentsRequest.definition.rules = [
      {
        operator: 'and',
        rules: rules,
      },
    ];
  }

  return filteredSegmentsRequest;
};

// const flattenChildren = (filter: FilterDataType[]) => {
//   const flattened: FilterDataType[] = [];

//   filter.forEach((d) => {
//     if (d.children) {
//       flattened.push(...flattenChildren(d.children));
//     }
//     flattened.push(d);
//   });
//   return flattened;
// };

export const getArtists = (artistFilters: FilterDataType[]) =>
  api('segments/filtered-segments', {
    method: 'POST',
    body: JSON.stringify(getArtistBodyHelper(artistFilters)),
  });

export const getSize = (artistFilters) =>
  api('audience-builder/segment/calculate_size', {
    method: 'POST',
    body: JSON.stringify(getArtistBodyHelper(artistFilters)),
  });

export const getMetrics = () => api('reporting/partner/metrics');
