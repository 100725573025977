import api from 'utils/api';
import { metadataHelper } from './helpers';

export const getMetadata = (audienceId, countries) =>
  metadataHelper(
    api(
      `reporting/segment/${audienceId}/meta${countries && countries.length ? `?countries=${countries.join('|')}` : ''}`,
    ),
  );

export const getMetrics = (audienceId, countries) =>
  api(
    `reporting/segment/${audienceId}/metrics${
      countries && countries.length ? `?countries=${countries.join('|')}` : ''
    }`,
  );

export const getAudienceSegment = (id) => api(`audience-builder/segment/${id}`);
