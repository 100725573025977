import React from 'react';
import { Page } from 'components/Page';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ArtistGrid } from 'pages/ArtistList/components/ArtistGrid';
import { getCollection, getCollectionSize } from './api';
import DemographicSummary from './components/DemographicSummary';
import { Button } from 'components/Button';
import { CreateAudienceModal } from 'pages/CustomAudience/components/CreateAudienceModal';
import { FilterDataType } from 'pages/ArtistList/components/Filters/FilterDrawer';
import { useQueryString } from 'hooks/useQuery';
import { useAuth } from 'contexts/AuthContext';

export const CollectionDetail = () => {
  const { id } = useParams();
  const { state } = useAuth();

  const query = useQueryString();

  const [audience, setAudience] = React.useState<FilterDataType[] | undefined>();

  const customAudience = query.get('customAudience');

  const { data: collectionData, isFetching: isLoadingCollectionData } = useQuery(
    [`collections`, id],
    () => getCollection(id),
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const audienceIds = collectionData ? collectionData.audiences.map((t) => t.id) : [];

  const { data: sizeData, isFetching: isLoadingSizes } = useQuery(
    [`collectionSizeIds`, collectionData],
    () => getCollectionSize(audienceIds),
    { staleTime: 0, refetchOnWindowFocus: false },
  );

  const handleActivateCollection = () => {
    setAudience(
      collectionData.audiences.map((d) => ({
        category: 'Audience',
        label: d.name,
        value: d.id,
        operator: 'or',
      })) as unknown as FilterDataType[],
    );
  };

  return (
    <Page>
      <div className="column gap-m">
        {isLoadingCollectionData && <h1 className="placeholder">{'Artist name'}</h1>}
        {isLoadingCollectionData && <h3 className="placeholder">6387 artists</h3>}
        {!isLoadingCollectionData && (
          <div className="column">
            <div className="flex row center">
              <h1 className="flex">{collectionData.name}</h1>
              {audienceIds.length && !customAudience && (
                <Button onClick={handleActivateCollection}>Save as Custom Audience</Button>
              )}
            </div>
            <div className="flex row center">
              <div className="flex column">
                <h3 className="flex">{collectionData.audiences.length.toLocaleString()} artists</h3>
                {/* {!isLoadingSizes && sizeData && sizeData.sizes && (
                  <div className="secondary">
                    {(sizeData.sizes.youtube_180 + sizeData.sizes['180']).toLocaleString()} total audience
                  </div>
                )} */}
              </div>
              {audienceIds.length && <DemographicSummary segments={audienceIds} />}
            </div>
          </div>
        )}
        {!isLoadingCollectionData && (
          <ArtistGrid data={collectionData.audiences} isLoading={isLoadingCollectionData} isFiltered={false} />
        )}
      </div>
      {audience && (
        <CreateAudienceModal
          audienceName={`WMX > ${state.user.email.split('@')[0]} > ${collectionData.name}`}
          filters={audience}
          visible={!!audience}
          onClose={() => setAudience(undefined)}
          onSuccess={() => null}
          readonly
        />
      )}
    </Page>
  );
};
