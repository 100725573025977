import { Page } from 'components/Page';
import { Pagination } from 'components/Pagination';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { filterByKey } from 'utils/arrays';
import { getArtists } from './api';
import { AppliedFilters, GroupOperatorType } from './components/AppliedFilters';
import { ArtistGrid } from './components/ArtistGrid';
import { ArtistListHeader } from './components/ArtistListHeader';
import { ArtistSortOptions } from './components/ArtistListHeader/SortBy';
import { Filters } from './components/Filters';
import { SearchBar } from './components/SearchBar';

import useAppliedFilters from 'hooks/useAppliedFilters';
import './style.css';

const rows = 8;
const columns = 3;
const pageSize = rows * columns;

type ArtistType = {
  display_segment_id: number;
  id: number;
  image_url: string;
  name: string;
  query_size: number;
  size: number;
};

const ArtistList = () => {
  const {
    audienceName,
    audienceId,
    sortSelection: savedSort,
    currentPage: savedPage,
    searchQuery: savedQuery,
  } = JSON.parse(localStorage.getItem('artistlist_search_filter_state') || '{}');

  const [searchQuery, setSearchQuery] = React.useState<string>(savedQuery || '');
  const { selectedFilters, setSelectedFilters } = useAppliedFilters();

  const [groupOperators, setGroupOperators] = React.useState<GroupOperatorType>({
    demo: 'or',
    rules: 'or',
  });

  const [sortSelection, setSortSelection] = React.useState(savedSort || ArtistSortOptions[0]);

  const [currentPage, setCurrentPage] = React.useState(savedPage || 1);

  const [filteredData, setFilteredData] = React.useState<ArtistType[]>([]);
  const [totalAudience, setTotalAudience] = React.useState<number>(0);
  React.useEffect(() => {
    localStorage.setItem(
      'artistlist_search_filter_state',
      JSON.stringify({
        audienceName,
        audienceId,
        selectedFilters,
        sortSelection,
        currentPage,
        searchQuery,
      }),
    );
  }, [sortSelection, currentPage, searchQuery, audienceName, audienceId, selectedFilters]);

  const {
    data,
    isRefetching,
    isLoading: isLoadingArtists,
  } = useQuery(['artists', selectedFilters, groupOperators], () => getArtists(selectedFilters), {
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const isLoading = React.useMemo(() => isRefetching || isLoadingArtists, [isLoadingArtists, isRefetching]);

  const totalPages = React.useMemo(() => filteredData && Math.ceil(filteredData.length / pageSize), [filteredData]);

  useEffect(() => {
    if (!isLoading && data) {
      const { sizes, results: artists } = data;
      let sortedArtist = artists;

      if (sortSelection.value === 'relevance') {
        //(filtered IDs count)^2 / (total banked IDs for the artist)
        sortedArtist = sortedArtist.map((a) => ({
          ...a,
          relevance: (Math.pow(a.query_size, 2) / a.size).toFixed(10),
        }));
      }

      sortedArtist = sortedArtist.sort((a, b) =>
        sortSelection.value !== 'relevance'
          ? a[sortSelection.value].toString().localeCompare(b[sortSelection.value].toString(), 'en', {
              numeric: typeof b[sortSelection.value] === 'number',
            })
          : parseFloat(a[sortSelection.value]) * 10000 - parseFloat(b[sortSelection.value]) * 10000,
      );

      if (sortSelection.value === 'name') {
        sortedArtist = [
          ...sortedArtist.filter((d) => d.name.match(/^[A-Z]/g)),
          ...sortedArtist.filter((d) => !d.name.match(/^[A-Z]/g)),
        ];
      }

      if (sortSelection.direction === 'asc') {
        sortedArtist.reverse();
      }
      if (selectedFilters.length > 0) {
        sortedArtist = sortedArtist.filter((d) => d.query_size && d.query_size > 0);
      }

      setFilteredData(filterByKey(sortedArtist, searchQuery, 'name'));
      setTotalAudience(sizes['180'] + sizes.youtube_180);
    }
  }, [isLoading, data, searchQuery, sortSelection, savedPage, selectedFilters.length]);

  const onQueryChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const onClearFilter = () => {
    localStorage.removeItem('artistlist_search_filter_state');
    setCurrentPage(1);
    setSelectedFilters([]);
    setSortSelection(ArtistSortOptions[0]);
  };

  const onFilterChange = (filter) => {
    setCurrentPage(1);

    const existsAtIndex = selectedFilters.findIndex((d) => d.category === filter.category && d.label === filter.label);

    // when all filters get deleted, rest to the default status
    if (existsAtIndex !== -1 && selectedFilters.length === 1) {
      onClearFilter();
      return;
    }

    //if filter is the parent filter, don't add it into selectedFilter
    const lastSlashIndex = filter.label.lastIndexOf('/');

    setSelectedFilters((filters) => {
      const temp = [...filters];

      // if the filter is a child filter, remove the parent filter from selectedFilters
      let newFilters = temp;
      const parent = filter.label.slice(0, lastSlashIndex);
      newFilters = temp.filter((d) => d.label !== parent);

      if (existsAtIndex > -1) {
        newFilters.splice(existsAtIndex, 1);
      } else {
        newFilters = newFilters.map((d) => (d.category === filter.category ? { ...d, operator: filter.operator } : d));
        newFilters.unshift(filter);
      }

      return newFilters;
    });
  };

  const onFilterCategoryChange = (category, operator) => {
    setSelectedFilters((filters) => filters.map((d) => (d.category === category ? { ...d, operator } : d)));
  };

  const onFilterGroupChange = (operator) => {
    setGroupOperators({ ...groupOperators, ...operator });
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const isFiltered = selectedFilters.length > 0;

  return (
    <Page>
      <div className="fixed column">
        <ArtistListHeader
          isLoading={isLoading}
          artistCount={filteredData.length.toLocaleString()}
          totalAudience={totalAudience}
          sortSelection={sortSelection}
          onSort={setSortSelection}
          selectedFilters={selectedFilters}
        />
        <div className="row center search-and-filters">
          <SearchBar flex query={searchQuery} onChange={onQueryChange} disabled={isLoading} />
          <Filters onChange={onFilterChange} selected={selectedFilters} />
        </div>
        <AppliedFilters
          audienceName={audienceName}
          audienceId={audienceId}
          filters={selectedFilters}
          groupOperators={groupOperators}
          onChange={onFilterChange}
          onGroupChange={onFilterGroupChange}
          onFilterCategoryChange={onFilterCategoryChange}
          onReset={onClearFilter}
        />
      </div>
      {!isLoading && !filteredData.length && (
        <div className="flex row center secondary">
          <div className="column center flex">
            {'It looks like you made a request too precise. \n Try to simplify your search parameters.'}
          </div>
        </div>
      )}
      <ArtistGrid
        data={filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
        isLoading={isLoading}
        isFiltered={isFiltered}
      />
      <div className="column center v-spacing">
        {!isLoading && !!filteredData.length && (
          <Pagination currentPage={currentPage} totalPages={totalPages} onChange={onPageChange} />
        )}
      </div>
    </Page>
  );
};

export default ArtistList;
