import Icon, { IconType } from 'components/Icon';
import './style.css';

const formatLabel = (label) =>
  label
    .replace('city_id', 'city')
    .split('_')
    .map((c) => c.replace('interest', ''))
    .join(' ');

const ParentTableRow = ({ data, selected, onSelection, isSelectedFilter }) => {
  return (
    <tr
      key={data.value + data.category}
      className={`center caption ${selected ? 'selected' : ''} ${isSelectedFilter ? 'selectedFilter' : ''}`}
    >
      <td width="100%" className="gap" style={{ padding: 5 }}>
        <div>
          <a
            href="#"
            className="flex row caption  underline"
            style={{ paddingLeft: 10, width: '100%' }}
            onClick={(e) => {
              e.preventDefault();
              onSelection(selected ? undefined : data);
            }}
          >
            <div className="flex column">
              <div className="flex flex-1">{data.label}</div>
              <div className="secondary" style={{ minWidth: 200, textAlign: 'right' }}>
                {data.categoryLabel ? formatLabel(data.categoryLabel) : formatLabel(data.category)}
              </div>
            </div>
            {isSelectedFilter ? <Icon type={IconType.check} color="white" /> : ''}
            {/* {data.children && isSearching && (
              <span className="flex row center row-count">
                {data.children.reduce((p, d) => (d.children ? d.children.length + p : data.children.length + p), 1)}
              </span>
            )} */}
          </a>
        </div>
      </td>
    </tr>
  );
};

export default ParentTableRow;
