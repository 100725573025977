import React from 'react';
import { TextInput } from 'components/TextInput';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { addCollection } from './api';
import { useMutation, useQueryClient } from 'react-query';
import { Modal } from 'components/Modal';
import { IconButton } from 'components/IconButton';
import { IconType } from 'components/Icon';
import { useCollection } from 'pages/CollectionList/context';
import { addToCollection } from 'pages/CollectionList/api';

export const AddCollection = ({ isVisible, onClose }) => {
  const {
    state: { audienceId },
  } = useCollection();
  const [collectionName, setCollectionName] = React.useState('');
  const queryClient = useQueryClient();

  const { mutateAsync: addToCollectionMutate } = useMutation(addToCollection);

  const { mutateAsync, isLoading } = useMutation(addCollection, {
    onSuccess: async ({ id: collectionId }) => {
      if (audienceId) {
        await addToCollectionMutate({ collectionId, audienceId });
      }
      await queryClient.invalidateQueries('collections');
    },
  });

  const onSubmit = async (event) => {
    if (event) event.preventDefault();
    await mutateAsync({ name: collectionName });
    setCollectionName('');
    onClose();
  };

  const onChange = (e) => {
    setCollectionName(e.target.value);
  };

  return (
    <Modal visible={isVisible}>
      <Card>
        <div className="row">
          <h3 className="flex">Add Collection</h3>
          <IconButton icon={IconType.cross} onClick={onClose} />
        </div>
        <div className="column v-spacing">
          <form onSubmit={onSubmit}>
            <TextInput placeholder="Name of collection" value={collectionName} disabled={isLoading} {...{ onChange }} />
          </form>
        </div>
        <Button primary submit onClick={(e) => onSubmit(e)} loading={isLoading}>
          Create Collection
        </Button>
      </Card>
    </Modal>
  );
};
