import { Tag } from 'components/Tag';
import { TextInput } from 'components/TextInput';

const AdditionalRecipients = ({ title, placeholder, value, onChange, onEnter, onRemove, options }) => {
  return (
    <div className="column gap scrollbar" style={{ maxHeight: '50vh', maxWidth: 400, minWidth: 300 }}>
      <div className="column gap ">
        <div className="caption bold sticky-header" style={{ background: 'var(--wmg-color-background2)' }}>
          {title}
        </div>
        {options.map((option) => (
          <Tag small selected onClick={() => onRemove(option)}>
            {option}
          </Tag>
        ))}
        <div className="column sticky-footer" style={{ background: 'var(--wmg-color-background2)' }}>
          <TextInput value={value} placeholder={placeholder} onChange={onChange} onEnter={onEnter} />
        </div>
      </div>
    </div>
  );
};

export default AdditionalRecipients;
