import React from 'react';
import { ArtistCard } from '../ArtistCard';
import { Grid } from 'components/Grid';

export const ArtistGrid = ({ isLoading, data, isFiltered }) => (
  <Grid cols={3}>
    {isLoading && [...new Array(9)].map((d, i) => <ArtistCard isLoading={isLoading} key={i.toString()} />)}
    {!isLoading && data.map((d) => <ArtistCard key={d.id} {...d} isFiltered={isFiltered} />)}
  </Grid>
);
