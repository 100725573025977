import React from 'react';
import Icon, { IconType } from 'components/Icon';
import PopOver, { PopOverItem } from 'components/PopOver';
import './style.css';

export type SortType = {
  value: string;
  direction: 'desc' | 'asc';
  label?: string;
  onlyFiltered?: boolean;
};

export const ArtistSortOptions = [
  { value: 'query_size', label: 'Audience Size', direction: 'asc' },
  { value: 'name', label: 'Artist Name A-Z', direction: 'desc' },
  { value: 'name', label: 'Artist Name Z-A', direction: 'asc' },
  {
    value: 'relevance',
    label: 'Relevance',
    direction: 'asc',
    onlyFiltered: true,
  },
];

export const SortBy = ({ onSort, sortSelection, filtered }) => {
  const [isSortOptionsVisible, setIsSortOptionsVisible] = React.useState(false);

  const onSortSelection = (d) => {
    setIsSortOptionsVisible(false);
    onSort(d);
  };

  const onOpenClick = (e) => {
    e.preventDefault();
    setIsSortOptionsVisible(true);
  };

  return (
    <div className="sort-by-container row">
      <a href="" className="row center" onClick={onOpenClick}>
        <div className="secondary">Sort by:</div>
        <div>{sortSelection.label}</div>
        <Icon type={IconType.arrowDown} />
      </a>
      <PopOver isVisible={isSortOptionsVisible} onCancel={() => setIsSortOptionsVisible(false)}>
        {ArtistSortOptions.map((d) => (
          <PopOverItem key={d.label} disabled={d.onlyFiltered && !filtered} onClick={() => onSortSelection(d)}>
            {d.label}
          </PopOverItem>
        ))}
      </PopOver>
    </div>
  );
};
