import React from 'react';
import { combineClasses } from 'utils/classNames';
import './style.css';

interface TableProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  isLoading?: boolean;
  cellspacing?: number;
}

export const Table = ({ children, header, isLoading, cellspacing = 0 }: TableProps) => {
  const headerStyles = React.useMemo<string>(() => combineClasses(['table-header', 'center', 'caption']), []);

  return (
    <div className="table-content">
      <table cellSpacing={cellspacing}>
        {!isLoading && (
          <>
            {header && (
              <thead>
                <tr className={headerStyles}>{header}</tr>
              </thead>
            )}
            <tbody>{children}</tbody>
          </>
        )}
      </table>
    </div>
  );
};
