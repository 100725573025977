export default [
  {
    value: 'Apparel & Accessories',
    label: 'Apparel & Accessories',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Apparel & Accessories/Activewear',
        label: 'Activewear',
        category: 'interest_inmarket',
      },
      {
        value: 'Apparel & Accessories/Costumes',
        label: 'Costumes',
        category: 'interest_inmarket',
      },
      {
        value: 'Apparel & Accessories/Eyewear',
        label: 'Eyewear',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Apparel & Accessories/Eyewear/Sunglasses',
            label: 'Sunglasses',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Apparel & Accessories/Formal Wear',
        label: 'Formal Wear',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Apparel & Accessories/Formal Wear/Bridal Wear',
            label: 'Bridal Wear',
            category: 'interest_inmarket',
          },
          {
            value: 'Apparel & Accessories/Formal Wear/Suits & Business Attire',
            label: 'Suits & Business Attire',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Apparel & Accessories/Handbags',
        label: 'Handbags',
        category: 'interest_inmarket',
      },
      {
        value: 'Apparel & Accessories/Jewelry & Watches',
        label: 'Jewelry & Watches',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Apparel & Accessories/Jewelry & Watches/Fine Jewelry',
            label: 'Fine Jewelry',
            category: 'interest_inmarket',
          },
          {
            value: 'Apparel & Accessories/Jewelry & Watches/Watches',
            label: 'Watches',
            category: 'interest_inmarket',
          },
          {
            value: 'Apparel & Accessories/Jewelry & Watches/Wedding & Engagement Rings',
            label: 'Wedding & Engagement Rings',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Apparel & Accessories/Lingerie',
        label: 'Lingerie',
        category: 'interest_inmarket',
      },
      {
        value: 'Apparel & Accessories/Luggage',
        label: 'Luggage',
        category: 'interest_inmarket',
      },
      {
        value: "Apparel & Accessories/Men's Apparel",
        label: "Men's Apparel",
        category: 'interest_inmarket',
      },
      {
        value: 'Apparel & Accessories/Outerwear',
        label: 'Outerwear',
        category: 'interest_inmarket',
      },
      {
        value: 'Apparel & Accessories/Shoes',
        label: 'Shoes',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Apparel & Accessories/Shoes/Athletic Shoes',
            label: 'Athletic Shoes',
            category: 'interest_inmarket',
          },
          {
            value: 'Apparel & Accessories/Shoes/Boots',
            label: 'Boots',
            category: 'interest_inmarket',
          },
          {
            value: 'Apparel & Accessories/Shoes/Dress Shoes',
            label: 'Dress Shoes',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Apparel & Accessories/Swimwear',
        label: 'Swimwear',
        category: 'interest_inmarket',
      },
      {
        value: 'Apparel & Accessories/Wallets, Briefcases & Leather Goods',
        label: 'Wallets, Briefcases & Leather Goods',
        category: 'interest_inmarket',
      },
      {
        value: "Apparel & Accessories/Women's Apparel",
        label: "Women's Apparel",
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Arts & Entertainment',
    label: 'Arts & Entertainment',
    category: 'interest_other',
    children: [
      {
        value: 'Arts & Entertainment/Celebrities & Entertainment News',
        label: 'Celebrities & Entertainment News',
        category: 'interest_other',
      },
      {
        value: 'Arts & Entertainment/Comics & Animation',
        label: 'Comics & Animation',
        category: 'interest_other',
        children: [
          {
            value: 'Arts & Entertainment/Comics & Animation/Anime & Manga',
            label: 'Anime & Manga',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Comics & Animation/Cartoons',
            label: 'Cartoons',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Comics & Animation/Comics',
            label: 'Comics',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Arts & Entertainment/Entertainment Industry',
        label: 'Entertainment Industry',
        category: 'interest_other',
        children: [
          {
            value: 'Arts & Entertainment/Entertainment Industry/Film & TV Industry',
            label: 'Film & TV Industry',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Entertainment Industry/Film & TV Industry/Film & TV Awards',
                label: 'Film & TV Awards',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Entertainment Industry/Film & TV Industry/Film & TV Production',
                label: 'Film & TV Production',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Arts & Entertainment/Entertainment Industry/Recording Industry',
            label: 'Recording Industry',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Entertainment Industry/Recording Industry/Music Awards',
                label: 'Music Awards',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Entertainment Industry/Recording Industry/Record Labels',
                label: 'Record Labels',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Arts & Entertainment/Events & Listings',
        label: 'Events & Listings',
        category: 'interest_other',
        children: [
          {
            value: 'Arts & Entertainment/Events & Listings/Bars, Clubs & Nightlife',
            label: 'Bars, Clubs & Nightlife',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Events & Listings/Concerts & Music Festivals',
            label: 'Concerts & Music Festivals',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Events & Listings/Event Ticket Sales',
            label: 'Event Ticket Sales',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Events & Listings/Expos & Conventions',
            label: 'Expos & Conventions',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Events & Listings/Film Festivals',
            label: 'Film Festivals',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Events & Listings/Food & Beverage Events',
            label: 'Food & Beverage Events',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Events & Listings/Live Sporting Events',
            label: 'Live Sporting Events',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Events & Listings/Movie Listings & Theater Showtimes',
            label: 'Movie Listings & Theater Showtimes',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Arts & Entertainment/Fun & Trivia',
        label: 'Fun & Trivia',
        category: 'interest_other',
        children: [
          {
            value: 'Arts & Entertainment/Fun & Trivia/Flash-Based Entertainment',
            label: 'Flash-Based Entertainment',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Fun & Trivia/Fun Tests & Silly Surveys',
            label: 'Fun Tests & Silly Surveys',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Arts & Entertainment/Humor',
        label: 'Humor',
        category: 'interest_other',
        children: [
          {
            value: 'Arts & Entertainment/Humor/Funny Pictures & Videos',
            label: 'Funny Pictures & Videos',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Humor/Live Comedy',
            label: 'Live Comedy',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Humor/Political Humor',
            label: 'Political Humor',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Humor/Spoofs & Satire',
            label: 'Spoofs & Satire',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Arts & Entertainment/Movies',
        label: 'Movies',
        category: 'interest_other',
        children: [
          {
            value: 'Arts & Entertainment/Movies/Action & Adventure Films',
            label: 'Action & Adventure Films',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Movies/Action & Adventure Films/Martial Arts Films',
                label: 'Martial Arts Films',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Movies/Action & Adventure Films/Superhero Films',
                label: 'Superhero Films',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Movies/Action & Adventure Films/Western Films',
                label: 'Western Films',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Arts & Entertainment/Movies/Animated Films',
            label: 'Animated Films',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Movies/Bollywood & South Asian Film',
            label: 'Bollywood & South Asian Film',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Movies/Classic Films',
            label: 'Classic Films',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Movies/Classic Films/Silent Films',
                label: 'Silent Films',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Arts & Entertainment/Movies/Comedy Films',
            label: 'Comedy Films',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Movies/Cult & Indie Films',
            label: 'Cult & Indie Films',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Movies/DVD & Video Shopping',
            label: 'DVD & Video Shopping',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Movies/DVD & Video Shopping/DVD & Video Rentals',
                label: 'DVD & Video Rentals',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Arts & Entertainment/Movies/Documentary Films',
            label: 'Documentary Films',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Movies/Drama Films',
            label: 'Drama Films',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Movies/Family Films',
            label: 'Family Films',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Movies/Horror Films',
            label: 'Horror Films',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Movies/Movie Memorabilia',
            label: 'Movie Memorabilia',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Movies/Movie Reference',
            label: 'Movie Reference',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Movies/Movie Reference/Movie Reviews & Previews',
                label: 'Movie Reviews & Previews',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Arts & Entertainment/Movies/Musical Films',
            label: 'Musical Films',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Movies/Romance Films',
            label: 'Romance Films',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Movies/Science Fiction & Fantasy Films',
            label: 'Science Fiction & Fantasy Films',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Movies/Thriller, Crime & Mystery Films',
            label: 'Thriller, Crime & Mystery Films',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Arts & Entertainment/Music & Audio',
        label: 'Music & Audio',
        category: 'interest_other',
        children: [
          {
            value: 'Arts & Entertainment/Music & Audio/CD & Audio Shopping',
            label: 'CD & Audio Shopping',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Classical Music',
            label: 'Classical Music',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Country Music',
            label: 'Country Music',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Dance & Electronic Music',
            label: 'Dance & Electronic Music',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Experimental & Industrial Music',
            label: 'Experimental & Industrial Music',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Folk & Traditional Music',
            label: 'Folk & Traditional Music',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Jazz & Blues',
            label: 'Jazz & Blues',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Music & Audio/Jazz & Blues/Blues',
                label: 'Blues',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/Jazz & Blues/Jazz',
                label: 'Jazz',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Music Art & Memorabilia',
            label: 'Music Art & Memorabilia',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Music Education & Instruction',
            label: 'Music Education & Instruction',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Music Equipment & Technology',
            label: 'Music Equipment & Technology',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Music & Audio/Music Equipment & Technology/DJ Resources & Equipment',
                label: 'DJ Resources & Equipment',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/Music Equipment & Technology/Music Recording Technology',
                label: 'Music Recording Technology',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/Music Equipment & Technology/Musical Instruments',
                label: 'Musical Instruments',
                category: 'interest_other',
                children: [
                  {
                    value:
                      'Arts & Entertainment/Music & Audio/Music Equipment & Technology/Musical Instruments/Drums & Percussion',
                    label: 'Drums & Percussion',
                    category: 'interest_other',
                  },
                  {
                    value:
                      'Arts & Entertainment/Music & Audio/Music Equipment & Technology/Musical Instruments/Guitars',
                    label: 'Guitars',
                    category: 'interest_other',
                  },
                  {
                    value:
                      'Arts & Entertainment/Music & Audio/Music Equipment & Technology/Musical Instruments/Pianos & Keyboards',
                    label: 'Pianos & Keyboards',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Arts & Entertainment/Music & Audio/Music Equipment & Technology/Samples & Sound Libraries',
                label: 'Samples & Sound Libraries',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Music Reference',
            label: 'Music Reference',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Music & Audio/Music Reference/Music Composition & Theory',
                label: 'Music Composition & Theory',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/Music Reference/Sheet Music',
                label: 'Sheet Music',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/Music Reference/Song Lyrics & Tabs',
                label: 'Song Lyrics & Tabs',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Music Streams & Downloads',
            label: 'Music Streams & Downloads',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Music Videos',
            label: 'Music Videos',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Podcasts',
            label: 'Podcasts',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Pop Music',
            label: 'Pop Music',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Radio',
            label: 'Radio',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Music & Audio/Radio/Talk Radio',
                label: 'Talk Radio',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Rock Music',
            label: 'Rock Music',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Music & Audio/Rock Music/Classic Rock & Oldies',
                label: 'Classic Rock & Oldies',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/Rock Music/Hard Rock & Progressive',
                label: 'Hard Rock & Progressive',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/Rock Music/Indie & Alternative Music',
                label: 'Indie & Alternative Music',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/Rock Music/Metal (Music)',
                label: 'Metal (Music)',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/Rock Music/Punk (Music)',
                label: 'Punk (Music)',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Soundtracks',
            label: 'Soundtracks',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Urban & Hip-Hop',
            label: 'Urban & Hip-Hop',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Music & Audio/Urban & Hip-Hop/Rap & Hip-Hop',
                label: 'Rap & Hip-Hop',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/Urban & Hip-Hop/Reggaeton',
                label: 'Reggaeton',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/Urban & Hip-Hop/Soul & R&B',
                label: 'Soul & R&B',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Arts & Entertainment/Music & Audio/Vocals & Show Tunes',
            label: 'Vocals & Show Tunes',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Music & Audio/World Music',
            label: 'World Music',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Music & Audio/World Music/African Music',
                label: 'African Music',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/World Music/Arab & Middle Eastern Music',
                label: 'Arab & Middle Eastern Music',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/World Music/East Asian Music',
                label: 'East Asian Music',
                category: 'interest_other',
                children: [
                  {
                    value: 'Arts & Entertainment/Music & Audio/World Music/East Asian Music/Korean Pop',
                    label: 'Korean Pop',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Arts & Entertainment/Music & Audio/World Music/Latin American Music',
                label: 'Latin American Music',
                category: 'interest_other',
                children: [
                  {
                    value: 'Arts & Entertainment/Music & Audio/World Music/Latin American Music/Brazilian Music',
                    label: 'Brazilian Music',
                    category: 'interest_other',
                  },
                  {
                    value: 'Arts & Entertainment/Music & Audio/World Music/Latin American Music/Latin Pop',
                    label: 'Latin Pop',
                    category: 'interest_other',
                  },
                  {
                    value: 'Arts & Entertainment/Music & Audio/World Music/Latin American Music/Salsa & Tropical Music',
                    label: 'Salsa & Tropical Music',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Arts & Entertainment/Music & Audio/World Music/Middle Eastern & North African Music',
                label: 'Middle Eastern & North African Music',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/World Music/Reggae & Caribbean Music',
                label: 'Reggae & Caribbean Music',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/World Music/South Asian Music',
                label: 'South Asian Music',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Music & Audio/World Music/Southeast Asian Music',
                label: 'Southeast Asian Music',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Arts & Entertainment/Offbeat',
        label: 'Offbeat',
        category: 'interest_other',
      },
      {
        value: 'Arts & Entertainment/Online Media',
        label: 'Online Media',
        category: 'interest_other',
        children: [
          {
            value: 'Arts & Entertainment/Online Media/Online Image Galleries',
            label: 'Online Image Galleries',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Online Media/Virtual Tours',
            label: 'Virtual Tours',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Arts & Entertainment/Performing Arts',
        label: 'Performing Arts',
        category: 'interest_other',
        children: [
          {
            value: 'Arts & Entertainment/Performing Arts/Acting & Theater',
            label: 'Acting & Theater',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Performing Arts/Broadway & Musical Theater',
            label: 'Broadway & Musical Theater',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Performing Arts/Circus',
            label: 'Circus',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Performing Arts/Dance',
            label: 'Dance',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Performing Arts/Magic',
            label: 'Magic',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Performing Arts/Opera',
            label: 'Opera',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Arts & Entertainment/TV & Video',
        label: 'TV & Video',
        category: 'interest_other',
        children: [
          {
            value: 'Arts & Entertainment/TV & Video/Online Video',
            label: 'Online Video',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/TV & Video/Online Video/Live Video Streaming',
                label: 'Live Video Streaming',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/TV & Video/Online Video/Movie & TV Streaming',
                label: 'Movie & TV Streaming',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/TV & Video/Online Video/Video Blogs',
                label: 'Video Blogs',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/TV & Video/Online Video/Web Series',
                label: 'Web Series',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Arts & Entertainment/TV & Video/TV Commercials',
            label: 'TV Commercials',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/TV & Video/TV Guides & Reference',
            label: 'TV Guides & Reference',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/TV & Video/TV Networks & Stations',
            label: 'TV Networks & Stations',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/TV & Video/TV Shows & Programs',
            label: 'TV Shows & Programs',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/TV & Video/TV Shows & Programs/TV Comedies',
                label: 'TV Comedies',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/TV & Video/TV Shows & Programs/TV Documentary & Nonfiction',
                label: 'TV Documentary & Nonfiction',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/TV & Video/TV Shows & Programs/TV Dramas',
                label: 'TV Dramas',
                category: 'interest_other',
                children: [
                  {
                    value: 'Arts & Entertainment/TV & Video/TV Shows & Programs/TV Dramas/TV Crime & Legal Shows',
                    label: 'TV Crime & Legal Shows',
                    category: 'interest_other',
                  },
                  {
                    value: 'Arts & Entertainment/TV & Video/TV Shows & Programs/TV Dramas/TV Medical Shows',
                    label: 'TV Medical Shows',
                    category: 'interest_other',
                  },
                  {
                    value: 'Arts & Entertainment/TV & Video/TV Shows & Programs/TV Dramas/TV Soap Operas',
                    label: 'TV Soap Operas',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Arts & Entertainment/TV & Video/TV Shows & Programs/TV Family-Oriented Shows',
                label: 'TV Family-Oriented Shows',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/TV & Video/TV Shows & Programs/TV Game Shows',
                label: 'TV Game Shows',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/TV & Video/TV Shows & Programs/TV Reality Shows',
                label: 'TV Reality Shows',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/TV & Video/TV Shows & Programs/TV Sci-Fi & Fantasy Shows',
                label: 'TV Sci-Fi & Fantasy Shows',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/TV & Video/TV Shows & Programs/TV Talent & Variety Shows',
                label: 'TV Talent & Variety Shows',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/TV & Video/TV Shows & Programs/TV Talk Shows',
                label: 'TV Talk Shows',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Arts & Entertainment/Visual Art & Design',
        label: 'Visual Art & Design',
        category: 'interest_other',
        children: [
          {
            value: 'Arts & Entertainment/Visual Art & Design/Architecture',
            label: 'Architecture',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Visual Art & Design/Art Museums & Galleries',
            label: 'Art Museums & Galleries',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Visual Art & Design/Design',
            label: 'Design',
            category: 'interest_other',
            children: [
              {
                value: 'Arts & Entertainment/Visual Art & Design/Design/Graphic Design',
                label: 'Graphic Design',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Visual Art & Design/Design/Industrial & Product Design',
                label: 'Industrial & Product Design',
                category: 'interest_other',
              },
              {
                value: 'Arts & Entertainment/Visual Art & Design/Design/Interior Design',
                label: 'Interior Design',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Arts & Entertainment/Visual Art & Design/Painting',
            label: 'Painting',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Visual Art & Design/Photographic & Digital Arts',
            label: 'Photographic & Digital Arts',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Visual Art & Design/Sculpture',
            label: 'Sculpture',
            category: 'interest_other',
          },
          {
            value: 'Arts & Entertainment/Visual Art & Design/Visual Arts & Design Education',
            label: 'Visual Arts & Design Education',
            category: 'interest_other',
          },
        ],
      },
    ],
  },
  {
    value: 'Autos & Vehicles',
    label: 'Autos & Vehicles',
    category: 'interest_other',
    children: [
      {
        value: 'Autos & Vehicles/Auto Parts & Accessories',
        label: 'Auto Parts & Accessories',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Autos & Vehicles/Auto Parts & Accessories/Auto Exterior Parts & Accessories',
            label: 'Auto Exterior Parts & Accessories',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Auto Parts & Accessories/Auto Interior Parts & Accessories',
            label: 'Auto Interior Parts & Accessories',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Auto Parts & Accessories/Automotive Electronic Components',
            label: 'Automotive Electronic Components',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Auto Parts & Accessories/Car Batteries',
            label: 'Car Batteries',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Auto Parts & Accessories/Car Brakes',
            label: 'Car Brakes',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Auto Parts & Accessories/Engine & Transmission',
            label: 'Engine & Transmission',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Auto Parts & Accessories/High Performance & Aftermarket Auto Parts',
            label: 'High Performance & Aftermarket Auto Parts',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Auto Parts & Accessories/Wheels & Tires',
            label: 'Wheels & Tires',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Autos & Vehicles/Auto Repair & Maintenance',
        label: 'Auto Repair & Maintenance',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Autos & Vehicles/Auto Repair & Maintenance/Brake Service & Repair',
            label: 'Brake Service & Repair',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Auto Repair & Maintenance/Collision & Auto Body Repair',
            label: 'Collision & Auto Body Repair',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Auto Repair & Maintenance/Glass Repair & Replacement',
            label: 'Glass Repair & Replacement',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Auto Repair & Maintenance/Oil Changes',
            label: 'Oil Changes',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Auto Repair & Maintenance/Transmission Repair',
            label: 'Transmission Repair',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Autos & Vehicles/Bicycles & Accessories',
        label: 'Bicycles & Accessories',
        category: 'interest_other',
        children: [
          {
            value: 'Autos & Vehicles/Bicycles & Accessories/BMX Bikes',
            label: 'BMX Bikes',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Bicycles & Accessories/Bike Accessories',
            label: 'Bike Accessories',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Bicycles & Accessories/Bike Frames',
            label: 'Bike Frames',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Bicycles & Accessories/Bike Helmets & Protective Gear',
            label: 'Bike Helmets & Protective Gear',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Bicycles & Accessories/Bike Parts & Repair',
            label: 'Bike Parts & Repair',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Bicycles & Accessories/Cruiser Bicycles',
            label: 'Cruiser Bicycles',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Bicycles & Accessories/Electric Bicycles',
            label: 'Electric Bicycles',
            category: 'interest_other',
          },
          {
            value: "Autos & Vehicles/Bicycles & Accessories/Kids' Bikes",
            label: "Kids' Bikes",
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Bicycles & Accessories/Mountain Bikes',
            label: 'Mountain Bikes',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Bicycles & Accessories/Road Bikes',
            label: 'Road Bikes',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Autos & Vehicles/Boats & Watercraft',
        label: 'Boats & Watercraft',
        category: 'interest_other',
      },
      {
        value: 'Autos & Vehicles/Campers & RVs',
        label: 'Campers & RVs',
        category: 'interest_other',
      },
      {
        value: 'Autos & Vehicles/Classic Vehicles',
        label: 'Classic Vehicles',
        category: 'interest_other',
      },
      {
        value: 'Autos & Vehicles/Commercial Vehicles',
        label: 'Commercial Vehicles',
        category: 'interest_other',
        children: [
          {
            value: 'Autos & Vehicles/Commercial Vehicles/Cargo Trucks & Trailers',
            label: 'Cargo Trucks & Trailers',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Autos & Vehicles/Custom & Performance Vehicles',
        label: 'Custom & Performance Vehicles',
        category: 'interest_other',
      },
      {
        value: 'Autos & Vehicles/Motor Vehicles',
        label: 'Motor Vehicles',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles (New)',
            label: 'Motor Vehicles (New)',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles (Used)',
            label: 'Motor Vehicles (Used)',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand',
            label: 'Motor Vehicles by Brand',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Acura',
                label: 'Acura',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Alfa Romeo',
                label: 'Alfa Romeo',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Audi',
                label: 'Audi',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/BMW',
                label: 'BMW',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Buick',
                label: 'Buick',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Cadillac',
                label: 'Cadillac',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Chevrolet',
                label: 'Chevrolet',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Chrysler',
                label: 'Chrysler',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Citroën',
                label: 'Citroën',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Dodge',
                label: 'Dodge',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Fiat',
                label: 'Fiat',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Ford',
                label: 'Ford',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/GMC',
                label: 'GMC',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Honda',
                label: 'Honda',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Hyundai',
                label: 'Hyundai',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Infiniti',
                label: 'Infiniti',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Isuzu',
                label: 'Isuzu',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Jaguar',
                label: 'Jaguar',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Jeep',
                label: 'Jeep',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Kia',
                label: 'Kia',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Land Rover',
                label: 'Land Rover',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Lexus',
                label: 'Lexus',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Lincoln',
                label: 'Lincoln',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Maserati',
                label: 'Maserati',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Mazda',
                label: 'Mazda',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Mercedes-Benz',
                label: 'Mercedes-Benz',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Mini',
                label: 'Mini',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Mitsubishi',
                label: 'Mitsubishi',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Nissan',
                label: 'Nissan',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Peugeot',
                label: 'Peugeot',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Porsche',
                label: 'Porsche',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Ram Trucks',
                label: 'Ram Trucks',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Renault',
                label: 'Renault',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/SEAT',
                label: 'SEAT',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Scion',
                label: 'Scion',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Subaru',
                label: 'Subaru',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Suzuki',
                label: 'Suzuki',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Tesla Motors',
                label: 'Tesla Motors',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Toyota',
                label: 'Toyota',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Vauxhall-Opel',
                label: 'Vauxhall-Opel',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Volkswagen',
                label: 'Volkswagen',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Brand/Volvo',
                label: 'Volvo',
                category: 'interest_inmarket',
              },
            ],
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type',
            label: 'Motor Vehicles by Type',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Classic Vehicles',
                label: 'Classic Vehicles',
                category: 'interest_inmarket',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Compact Cars',
                label: 'Compact Cars',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Compact Cars/Compact Cars (New)',
                    label: 'Compact Cars (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Compact Cars/Compact Cars (Used)',
                    label: 'Compact Cars (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Convertibles',
                label: 'Convertibles',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Convertibles/Convertibles (New)',
                    label: 'Convertibles (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Convertibles/Convertibles (Used)',
                    label: 'Convertibles (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Coupes',
                label: 'Coupes',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Coupes/Coupes (New)',
                    label: 'Coupes (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Coupes/Coupes (Used)',
                    label: 'Coupes (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Crossovers',
                label: 'Crossovers',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Crossovers/Crossovers (New)',
                    label: 'Crossovers (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Crossovers/Crossovers (Used)',
                    label: 'Crossovers (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Diesel Vehicles',
                label: 'Diesel Vehicles',
                category: 'interest_inmarket',
                children: [
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Diesel Vehicles/Diesel Vehicles (New)',
                    label: 'Diesel Vehicles (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Diesel Vehicles/Diesel Vehicles (Used)',
                    label: 'Diesel Vehicles (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Hatchbacks',
                label: 'Hatchbacks',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Hatchbacks/Hatchbacks (New)',
                    label: 'Hatchbacks (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Hatchbacks/Hatchbacks (Used)',
                    label: 'Hatchbacks (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Hybrid & Alternative Vehicles',
                label: 'Hybrid & Alternative Vehicles',
                category: 'interest_inmarket',
                children: [
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Hybrid & Alternative Vehicles/Hybrid & Alternative Vehicles (New)',
                    label: 'Hybrid & Alternative Vehicles (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Hybrid & Alternative Vehicles/Hybrid & Alternative Vehicles (Used)',
                    label: 'Hybrid & Alternative Vehicles (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Luxury Vehicles',
                label: 'Luxury Vehicles',
                category: 'interest_inmarket',
                children: [
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Luxury Vehicles/Luxury Vehicles (New)',
                    label: 'Luxury Vehicles (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Luxury Vehicles/Luxury Vehicles (Used)',
                    label: 'Luxury Vehicles (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Microcars & Subcompacts',
                label: 'Microcars & Subcompacts',
                category: 'interest_inmarket',
                children: [
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Microcars & Subcompacts/Microcars & Subcompacts (New)',
                    label: 'Microcars & Subcompacts (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Microcars & Subcompacts/Microcars & Subcompacts (Used)',
                    label: 'Microcars & Subcompacts (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Motorcycles',
                label: 'Motorcycles',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Motorcycles/Motorcycles (New)',
                    label: 'Motorcycles (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Motorcycles/Motorcycles (Used)',
                    label: 'Motorcycles (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Off-Road Vehicles',
                label: 'Off-Road Vehicles',
                category: 'interest_inmarket',
                children: [
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Off-Road Vehicles/Off-Road Vehicles (New)',
                    label: 'Off-Road Vehicles (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Off-Road Vehicles/Off-Road Vehicles (Used)',
                    label: 'Off-Road Vehicles (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Pickup Trucks',
                label: 'Pickup Trucks',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Pickup Trucks/Pickup Trucks (New)',
                    label: 'Pickup Trucks (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Pickup Trucks/Pickup Trucks (Used)',
                    label: 'Pickup Trucks (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/SUVs',
                label: 'SUVs',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/SUVs/SUVs (New)',
                    label: 'SUVs (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/SUVs/SUVs (Used)',
                    label: 'SUVs (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Scooters & Mopeds',
                label: 'Scooters & Mopeds',
                category: 'interest_inmarket',
                children: [
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Scooters & Mopeds/Scooters & Mopeds (New)',
                    label: 'Scooters & Mopeds (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Scooters & Mopeds/Scooters & Mopeds (Used)',
                    label: 'Scooters & Mopeds (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Sedans',
                label: 'Sedans',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Sedans/Sedans (New)',
                    label: 'Sedans (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Sedans/Sedans (Used)',
                    label: 'Sedans (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Sports Cars',
                label: 'Sports Cars',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Sports Cars/Sports Cars (New)',
                    label: 'Sports Cars (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Sports Cars/Sports Cars (Used)',
                    label: 'Sports Cars (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Station Wagons',
                label: 'Station Wagons',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Station Wagons/Station Wagons (New)',
                    label: 'Station Wagons (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Station Wagons/Station Wagons (Used)',
                    label: 'Station Wagons (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Vans & Minivans',
                label: 'Vans & Minivans',
                category: 'interest_inmarket',
                children: [
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Vans & Minivans/Vans & Minivans (New)',
                    label: 'Vans & Minivans (New)',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Autos & Vehicles/Motor Vehicles/Motor Vehicles by Type/Vans & Minivans/Vans & Minivans (Used)',
                    label: 'Vans & Minivans (Used)',
                    category: 'interest_inmarket',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        value: 'Autos & Vehicles/Motor Vehicles (By Brand)',
        label: 'Motor Vehicles (By Brand)',
        category: 'interest_other',
        children: [
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Audi',
            label: 'Audi',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/BMW',
            label: 'BMW',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Bentley',
            label: 'Bentley',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Buick',
            label: 'Buick',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Cadillac',
            label: 'Cadillac',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Chevrolet',
            label: 'Chevrolet',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Chrysler',
            label: 'Chrysler',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Citroën',
            label: 'Citroën',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Daewoo Motors',
            label: 'Daewoo Motors',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Dodge',
            label: 'Dodge',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Ferrari',
            label: 'Ferrari',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Fiat',
            label: 'Fiat',
            category: 'interest_other',
            children: [
              {
                value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Fiat/Alfa Romeo',
                label: 'Alfa Romeo',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Ford',
            label: 'Ford',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/GMC',
            label: 'GMC',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Honda',
            label: 'Honda',
            category: 'interest_other',
            children: [
              {
                value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Honda/Acura',
                label: 'Acura',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Hummer',
            label: 'Hummer',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Hyundai',
            label: 'Hyundai',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Isuzu',
            label: 'Isuzu',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Jaguar',
            label: 'Jaguar',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Jeep',
            label: 'Jeep',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Kia',
            label: 'Kia',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Lamborghini',
            label: 'Lamborghini',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Land Rover',
            label: 'Land Rover',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Lincoln',
            label: 'Lincoln',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Maserati',
            label: 'Maserati',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Mazda',
            label: 'Mazda',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Mercedes-Benz',
            label: 'Mercedes-Benz',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Mercury',
            label: 'Mercury',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Mini',
            label: 'Mini',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Mitsubishi',
            label: 'Mitsubishi',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Nissan',
            label: 'Nissan',
            category: 'interest_other',
            children: [
              {
                value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Nissan/Infiniti',
                label: 'Infiniti',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Peugeot',
            label: 'Peugeot',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Pontiac',
            label: 'Pontiac',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Porsche',
            label: 'Porsche',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Ram Trucks',
            label: 'Ram Trucks',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Renault',
            label: 'Renault',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Rolls-Royce',
            label: 'Rolls-Royce',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/SEAT',
            label: 'SEAT',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Saab',
            label: 'Saab',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Saturn',
            label: 'Saturn',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Skoda',
            label: 'Skoda',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Subaru',
            label: 'Subaru',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Suzuki',
            label: 'Suzuki',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Tesla Motors',
            label: 'Tesla Motors',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Toyota',
            label: 'Toyota',
            category: 'interest_other',
            children: [
              {
                value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Toyota/Lexus',
                label: 'Lexus',
                category: 'interest_other',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Toyota/Scion',
                label: 'Scion',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Vauxhall-Opel',
            label: 'Vauxhall-Opel',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Volkswagen',
            label: 'Volkswagen',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Brand)/Volvo',
            label: 'Volvo',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Autos & Vehicles/Motor Vehicles (By Type)',
        label: 'Motor Vehicles (By Type)',
        category: 'interest_other',
        children: [
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Autonomous Vehicles',
            label: 'Autonomous Vehicles',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Convertibles',
            label: 'Convertibles',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Coupes',
            label: 'Coupes',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Hatchbacks',
            label: 'Hatchbacks',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Hybrid & Alternative Vehicles',
            label: 'Hybrid & Alternative Vehicles',
            category: 'interest_other',
            children: [
              {
                value:
                  'Autos & Vehicles/Motor Vehicles (By Type)/Hybrid & Alternative Vehicles/Electric & Plug-In Vehicles',
                label: 'Electric & Plug-In Vehicles',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Luxury Vehicles',
            label: 'Luxury Vehicles',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Microcars & Subcompacts',
            label: 'Microcars & Subcompacts',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Motorcycles',
            label: 'Motorcycles',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Off-Road Vehicles',
            label: 'Off-Road Vehicles',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Scooters & Mopeds',
            label: 'Scooters & Mopeds',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Sedans',
            label: 'Sedans',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Sports Cars',
            label: 'Sports Cars',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Station Wagons',
            label: 'Station Wagons',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Motor Vehicles (By Type)/Trucks, Vans & SUVs',
            label: 'Trucks, Vans & SUVs',
            category: 'interest_other',
            children: [
              {
                value: 'Autos & Vehicles/Motor Vehicles (By Type)/Trucks, Vans & SUVs/Pickup Trucks',
                label: 'Pickup Trucks',
                category: 'interest_other',
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles (By Type)/Trucks, Vans & SUVs/SUVs & Crossovers',
                label: 'SUVs & Crossovers',
                category: 'interest_other',
                children: [
                  {
                    value: 'Autos & Vehicles/Motor Vehicles (By Type)/Trucks, Vans & SUVs/SUVs & Crossovers/Crossovers',
                    label: 'Crossovers',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Autos & Vehicles/Motor Vehicles (By Type)/Trucks, Vans & SUVs/Vans & Minivans',
                label: 'Vans & Minivans',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Autos & Vehicles/Personal Aircraft',
        label: 'Personal Aircraft',
        category: 'interest_other',
      },
      {
        value: 'Autos & Vehicles/Vehicle Codes & Driving Laws',
        label: 'Vehicle Codes & Driving Laws',
        category: 'interest_other',
        children: [
          {
            value: 'Autos & Vehicles/Vehicle Codes & Driving Laws/Vehicle & Traffic Safety',
            label: 'Vehicle & Traffic Safety',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Vehicle Codes & Driving Laws/Vehicle Licensing & Registration',
            label: 'Vehicle Licensing & Registration',
            category: 'interest_other',
            children: [
              {
                value:
                  'Autos & Vehicles/Vehicle Codes & Driving Laws/Vehicle Licensing & Registration/Driving Instruction & Driver Education',
                label: 'Driving Instruction & Driver Education',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Autos & Vehicles/Vehicle Parts & Services',
        label: 'Vehicle Parts & Services',
        category: 'interest_other',
        children: [
          {
            value: 'Autos & Vehicles/Vehicle Parts & Services/Gas Prices & Vehicle Fueling',
            label: 'Gas Prices & Vehicle Fueling',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Vehicle Parts & Services/Towing & Roadside Assistance',
            label: 'Towing & Roadside Assistance',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Vehicle Parts & Services/Vehicle Modification & Tuning',
            label: 'Vehicle Modification & Tuning',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Vehicle Parts & Services/Vehicle Parts & Accessories',
            label: 'Vehicle Parts & Accessories',
            category: 'interest_other',
            children: [
              {
                value: 'Autos & Vehicles/Vehicle Parts & Services/Vehicle Parts & Accessories/Auto Batteries',
                label: 'Auto Batteries',
                category: 'interest_other',
              },
              {
                value: 'Autos & Vehicles/Vehicle Parts & Services/Vehicle Parts & Accessories/Auto Brakes',
                label: 'Auto Brakes',
                category: 'interest_other',
              },
              {
                value: 'Autos & Vehicles/Vehicle Parts & Services/Vehicle Parts & Accessories/Auto Exterior',
                label: 'Auto Exterior',
                category: 'interest_other',
              },
              {
                value: 'Autos & Vehicles/Vehicle Parts & Services/Vehicle Parts & Accessories/Auto Interior',
                label: 'Auto Interior',
                category: 'interest_other',
              },
              {
                value:
                  'Autos & Vehicles/Vehicle Parts & Services/Vehicle Parts & Accessories/Automotive Electronic Components',
                label: 'Automotive Electronic Components',
                category: 'interest_other',
              },
              {
                value: 'Autos & Vehicles/Vehicle Parts & Services/Vehicle Parts & Accessories/Engine & Transmission',
                label: 'Engine & Transmission',
                category: 'interest_other',
              },
              {
                value:
                  'Autos & Vehicles/Vehicle Parts & Services/Vehicle Parts & Accessories/High Performance & Aftermarket Auto Parts',
                label: 'High Performance & Aftermarket Auto Parts',
                category: 'interest_other',
              },
              {
                value:
                  'Autos & Vehicles/Vehicle Parts & Services/Vehicle Parts & Accessories/Vehicle Fuels & Lubricants',
                label: 'Vehicle Fuels & Lubricants',
                category: 'interest_other',
              },
              {
                value: 'Autos & Vehicles/Vehicle Parts & Services/Vehicle Parts & Accessories/Vehicle Wheels & Tires',
                label: 'Vehicle Wheels & Tires',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Autos & Vehicles/Vehicle Parts & Services/Vehicle Repair & Maintenance',
            label: 'Vehicle Repair & Maintenance',
            category: 'interest_other',
            children: [
              {
                value:
                  'Autos & Vehicles/Vehicle Parts & Services/Vehicle Repair & Maintenance/Auto Glass Repair & Replacement',
                label: 'Auto Glass Repair & Replacement',
                category: 'interest_other',
              },
              {
                value: 'Autos & Vehicles/Vehicle Parts & Services/Vehicle Repair & Maintenance/Brake Service & Repair',
                label: 'Brake Service & Repair',
                category: 'interest_other',
              },
              {
                value:
                  'Autos & Vehicles/Vehicle Parts & Services/Vehicle Repair & Maintenance/Collision & Auto Body Repair',
                label: 'Collision & Auto Body Repair',
                category: 'interest_other',
              },
              {
                value: 'Autos & Vehicles/Vehicle Parts & Services/Vehicle Repair & Maintenance/Oil Changes',
                label: 'Oil Changes',
                category: 'interest_other',
              },
              {
                value:
                  'Autos & Vehicles/Vehicle Parts & Services/Vehicle Repair & Maintenance/Transmission Repair & Maintenance',
                label: 'Transmission Repair & Maintenance',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Autos & Vehicles/Vehicle Shopping',
        label: 'Vehicle Shopping',
        category: 'interest_other',
        children: [
          {
            value: 'Autos & Vehicles/Vehicle Shopping/Used Vehicles',
            label: 'Used Vehicles',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Vehicle Shopping/Vehicle Dealers & Retailers',
            label: 'Vehicle Dealers & Retailers',
            category: 'interest_other',
          },
          {
            value: 'Autos & Vehicles/Vehicle Shopping/Vehicle Specs, Reviews & Comparisons',
            label: 'Vehicle Specs, Reviews & Comparisons',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Autos & Vehicles/Vehicle Shows',
        label: 'Vehicle Shows',
        category: 'interest_other',
      },
      {
        value: 'Autos & Vehicles/Vehicles (Other)',
        label: 'Vehicles (Other)',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Autos & Vehicles/Vehicles (Other)/Bicycles & Accessories',
            label: 'Bicycles & Accessories',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Vehicles (Other)/Boats & Watercraft',
            label: 'Boats & Watercraft',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Vehicles (Other)/Campers & RVs',
            label: 'Campers & RVs',
            category: 'interest_inmarket',
          },
          {
            value: 'Autos & Vehicles/Vehicles (Other)/Commercial Vehicles',
            label: 'Commercial Vehicles',
            category: 'interest_inmarket',
          },
        ],
      },
    ],
  },
  {
    value: "Baby & Children's Products",
    label: "Baby & Children's Products",
    category: 'interest_inmarket',
    children: [
      {
        value: "Baby & Children's Products/Baby & Children's Apparel",
        label: "Baby & Children's Apparel",
        category: 'interest_inmarket',
        children: [
          {
            value: "Baby & Children's Products/Baby & Children's Apparel/Baby & Toddler Apparel",
            label: 'Baby & Toddler Apparel',
            category: 'interest_inmarket',
          },
          {
            value: "Baby & Children's Products/Baby & Children's Apparel/Children's Apparel",
            label: "Children's Apparel",
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: "Baby & Children's Products/Child Car Seats",
        label: 'Child Car Seats',
        category: 'interest_inmarket',
      },
      {
        value: "Baby & Children's Products/Childcare & Education",
        label: 'Childcare & Education',
        category: 'interest_inmarket',
        children: [
          {
            value: "Baby & Children's Products/Childcare & Education/Childcare",
            label: 'Childcare',
            category: 'interest_inmarket',
          },
          {
            value: "Baby & Children's Products/Childcare & Education/Early Childhood Education",
            label: 'Early Childhood Education',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: "Baby & Children's Products/Diapers & Baby Hygiene Products",
        label: 'Diapers & Baby Hygiene Products',
        category: 'interest_inmarket',
      },
      {
        value: "Baby & Children's Products/Infant & Toddler Feeding",
        label: 'Infant & Toddler Feeding',
        category: 'interest_inmarket',
        children: [
          {
            value: "Baby & Children's Products/Infant & Toddler Feeding/Infant Feeding Supplies",
            label: 'Infant Feeding Supplies',
            category: 'interest_inmarket',
          },
          {
            value: "Baby & Children's Products/Infant & Toddler Feeding/Toddler Meals",
            label: 'Toddler Meals',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: "Baby & Children's Products/Strollers & Baby Carriages",
        label: 'Strollers & Baby Carriages',
        category: 'interest_inmarket',
      },
      {
        value: "Baby & Children's Products/Toys",
        label: 'Toys',
        category: 'interest_inmarket',
      },
      {
        value: "Baby & Children's Products/Toys & Games",
        label: 'Toys & Games',
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Banking & Finance',
    label: 'Banking & Finance',
    category: 'interest_affinity',
    children: [
      {
        value: 'Banking & Finance/Avid Investors',
        label: 'Avid Investors',
        category: 'interest_affinity',
      },
    ],
  },
  {
    value: 'Beauty & Fitness',
    label: 'Beauty & Fitness',
    category: 'interest_other',
    children: [
      {
        value: 'Beauty & Fitness/Beauty Pageants',
        label: 'Beauty Pageants',
        category: 'interest_other',
      },
      {
        value: 'Beauty & Fitness/Beauty Services & Spas',
        label: 'Beauty Services & Spas',
        category: 'interest_other',
        children: [
          {
            value: 'Beauty & Fitness/Beauty Services & Spas/Cosmetic Procedures',
            label: 'Cosmetic Procedures',
            category: 'interest_other',
            children: [
              {
                value: 'Beauty & Fitness/Beauty Services & Spas/Cosmetic Procedures/Cosmetic Dentistry',
                label: 'Cosmetic Dentistry',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Beauty & Fitness/Beauty Services & Spas/Manicures & Pedicures',
            label: 'Manicures & Pedicures',
            category: 'interest_other',
          },
          {
            value: 'Beauty & Fitness/Beauty Services & Spas/Massage Therapy',
            label: 'Massage Therapy',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Beauty & Fitness/Body Art',
        label: 'Body Art',
        category: 'interest_other',
      },
      {
        value: 'Beauty & Fitness/Cosmetology & Beauty Professionals',
        label: 'Cosmetology & Beauty Professionals',
        category: 'interest_other',
      },
      {
        value: 'Beauty & Fitness/Face & Body Care',
        label: 'Face & Body Care',
        category: 'interest_other',
        children: [
          {
            value: 'Beauty & Fitness/Face & Body Care/Clean Beauty',
            label: 'Clean Beauty',
            category: 'interest_other',
          },
          {
            value: 'Beauty & Fitness/Face & Body Care/Hygiene & Toiletries',
            label: 'Hygiene & Toiletries',
            category: 'interest_other',
            children: [
              {
                value:
                  'Beauty & Fitness/Face & Body Care/Hygiene & Toiletries/Antiperspirants, Deodorants & Body Sprays',
                label: 'Antiperspirants, Deodorants & Body Sprays',
                category: 'interest_other',
              },
              {
                value: 'Beauty & Fitness/Face & Body Care/Hygiene & Toiletries/Feminine Hygiene Products',
                label: 'Feminine Hygiene Products',
                category: 'interest_other',
              },
              {
                value: 'Beauty & Fitness/Face & Body Care/Hygiene & Toiletries/Hand & Body Soaps',
                label: 'Hand & Body Soaps',
                category: 'interest_other',
              },
              {
                value: 'Beauty & Fitness/Face & Body Care/Hygiene & Toiletries/Toothbrushes',
                label: 'Toothbrushes',
                category: 'interest_other',
              },
              {
                value: 'Beauty & Fitness/Face & Body Care/Hygiene & Toiletries/Toothpaste',
                label: 'Toothpaste',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Beauty & Fitness/Face & Body Care/Make-Up & Cosmetics',
            label: 'Make-Up & Cosmetics',
            category: 'interest_other',
            children: [
              {
                value: 'Beauty & Fitness/Face & Body Care/Make-Up & Cosmetics/Eye Makeup',
                label: 'Eye Makeup',
                category: 'interest_other',
              },
              {
                value: 'Beauty & Fitness/Face & Body Care/Make-Up & Cosmetics/Face Makeup',
                label: 'Face Makeup',
                category: 'interest_other',
              },
              {
                value: 'Beauty & Fitness/Face & Body Care/Make-Up & Cosmetics/Lip Makeup',
                label: 'Lip Makeup',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Beauty & Fitness/Face & Body Care/Perfumes & Fragrances',
            label: 'Perfumes & Fragrances',
            category: 'interest_other',
          },
          {
            value: 'Beauty & Fitness/Face & Body Care/Skin & Nail Care',
            label: 'Skin & Nail Care',
            category: 'interest_other',
            children: [
              {
                value: 'Beauty & Fitness/Face & Body Care/Skin & Nail Care/Bath & Body Products',
                label: 'Bath & Body Products',
                category: 'interest_other',
              },
              {
                value: 'Beauty & Fitness/Face & Body Care/Skin & Nail Care/Face Care Products',
                label: 'Face Care Products',
                category: 'interest_other',
                children: [
                  {
                    value:
                      'Beauty & Fitness/Face & Body Care/Skin & Nail Care/Face Care Products/Anti-Aging Skin Care Products',
                    label: 'Anti-Aging Skin Care Products',
                    category: 'interest_other',
                  },
                  {
                    value:
                      'Beauty & Fitness/Face & Body Care/Skin & Nail Care/Face Care Products/Face Cleansers & Makeup Removers',
                    label: 'Face Cleansers & Makeup Removers',
                    category: 'interest_other',
                  },
                  {
                    value:
                      'Beauty & Fitness/Face & Body Care/Skin & Nail Care/Face Care Products/Face Lotions & Moisturizers',
                    label: 'Face Lotions & Moisturizers',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Beauty & Fitness/Face & Body Care/Skin & Nail Care/Nail Care Products',
                label: 'Nail Care Products',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Beauty & Fitness/Face & Body Care/Sun Care & Tanning Products',
            label: 'Sun Care & Tanning Products',
            category: 'interest_other',
          },
          {
            value: 'Beauty & Fitness/Face & Body Care/Unwanted Body & Facial Hair Removal',
            label: 'Unwanted Body & Facial Hair Removal',
            category: 'interest_other',
            children: [
              {
                value: 'Beauty & Fitness/Face & Body Care/Unwanted Body & Facial Hair Removal/Razors & Shavers',
                label: 'Razors & Shavers',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Beauty & Fitness/Fashion & Style',
        label: 'Fashion & Style',
        category: 'interest_other',
        children: [
          {
            value: 'Beauty & Fitness/Fashion & Style/Fashion Designers & Collections',
            label: 'Fashion Designers & Collections',
            category: 'interest_other',
          },
          {
            value: 'Beauty & Fitness/Fashion & Style/Fashion Modeling',
            label: 'Fashion Modeling',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Beauty & Fitness/Fitness',
        label: 'Fitness',
        category: 'interest_other',
        children: [
          {
            value: 'Beauty & Fitness/Fitness/Bodybuilding',
            label: 'Bodybuilding',
            category: 'interest_other',
          },
          {
            value: 'Beauty & Fitness/Fitness/Fitness Equipment & Accessories',
            label: 'Fitness Equipment & Accessories',
            category: 'interest_other',
            children: [
              {
                value: 'Beauty & Fitness/Fitness/Fitness Equipment & Accessories/Cardio Training Equipment',
                label: 'Cardio Training Equipment',
                category: 'interest_other',
              },
              {
                value: 'Beauty & Fitness/Fitness/Fitness Equipment & Accessories/Fitness Technology Products',
                label: 'Fitness Technology Products',
                category: 'interest_other',
              },
              {
                value: 'Beauty & Fitness/Fitness/Fitness Equipment & Accessories/Weights & Strength Training Equipment',
                label: 'Weights & Strength Training Equipment',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Beauty & Fitness/Fitness/Fitness Instruction & Personal Training',
            label: 'Fitness Instruction & Personal Training',
            category: 'interest_other',
          },
          {
            value: 'Beauty & Fitness/Fitness/Gyms & Health Clubs',
            label: 'Gyms & Health Clubs',
            category: 'interest_other',
          },
          {
            value: 'Beauty & Fitness/Fitness/High Intensity Interval Training',
            label: 'High Intensity Interval Training',
            category: 'interest_other',
          },
          {
            value: 'Beauty & Fitness/Fitness/Yoga & Pilates',
            label: 'Yoga & Pilates',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Beauty & Fitness/Hair Care',
        label: 'Hair Care',
        category: 'interest_other',
        children: [
          {
            value: 'Beauty & Fitness/Hair Care/Shampoos & Conditioners',
            label: 'Shampoos & Conditioners',
            category: 'interest_other',
          },
        ],
      },
    ],
  },
  {
    value: 'Beauty & Personal Care',
    label: 'Beauty & Personal Care',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Beauty & Personal Care/Bath & Body Products',
        label: 'Bath & Body Products',
        category: 'interest_inmarket',
      },
      {
        value: 'Beauty & Personal Care/Hair Care Products',
        label: 'Hair Care Products',
        category: 'interest_inmarket',
      },
      {
        value: 'Beauty & Personal Care/Makeup & Cosmetics',
        label: 'Makeup & Cosmetics',
        category: 'interest_inmarket',
      },
      {
        value: 'Beauty & Personal Care/Perfumes & Fragrances',
        label: 'Perfumes & Fragrances',
        category: 'interest_inmarket',
      },
      {
        value: 'Beauty & Personal Care/Skin Care Products',
        label: 'Skin Care Products',
        category: 'interest_inmarket',
      },
      {
        value: 'Beauty & Personal Care/Spas & Beauty Services',
        label: 'Spas & Beauty Services',
        category: 'interest_inmarket',
      },
      {
        value: 'Beauty & Personal Care/Tanning & Sun Care Products',
        label: 'Tanning & Sun Care Products',
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Beauty & Wellness',
    label: 'Beauty & Wellness',
    category: 'interest_affinity',
    children: [
      {
        value: 'Beauty & Wellness/Beauty Mavens',
        label: 'Beauty Mavens',
        category: 'interest_affinity',
      },
      {
        value: 'Beauty & Wellness/Frequently Visits Salons',
        label: 'Frequently Visits Salons',
        category: 'interest_affinity',
      },
    ],
  },
  {
    value: 'Beauty Products & Services',
    label: 'Beauty Products & Services',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Beauty Products & Services/Bath & Body Products',
        label: 'Bath & Body Products',
        category: 'interest_inmarket',
      },
      {
        value: 'Beauty Products & Services/Hair Care Products',
        label: 'Hair Care Products',
        category: 'interest_inmarket',
      },
      {
        value: 'Beauty Products & Services/Makeup & Cosmetics',
        label: 'Makeup & Cosmetics',
        category: 'interest_inmarket',
      },
      {
        value: 'Beauty Products & Services/Perfumes & Fragrances',
        label: 'Perfumes & Fragrances',
        category: 'interest_inmarket',
      },
      {
        value: 'Beauty Products & Services/Skin Care Products',
        label: 'Skin Care Products',
        category: 'interest_inmarket',
      },
      {
        value: 'Beauty Products & Services/Spas & Beauty Services',
        label: 'Spas & Beauty Services',
        category: 'interest_inmarket',
      },
      {
        value: 'Beauty Products & Services/Tanning & Sun Care Products',
        label: 'Tanning & Sun Care Products',
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Books & Literature',
    label: 'Books & Literature',
    category: 'interest_other',
    children: [
      {
        value: 'Books & Literature/Audiobooks',
        label: 'Audiobooks',
        category: 'interest_other',
      },
      {
        value: 'Books & Literature/Book Retailers',
        label: 'Book Retailers',
        category: 'interest_other',
      },
      {
        value: "Books & Literature/Children's Literature",
        label: "Children's Literature",
        category: 'interest_other',
      },
      {
        value: 'Books & Literature/E-Books',
        label: 'E-Books',
        category: 'interest_other',
      },
      {
        value: 'Books & Literature/Fan Fiction',
        label: 'Fan Fiction',
        category: 'interest_other',
      },
      {
        value: 'Books & Literature/Literary Classics',
        label: 'Literary Classics',
        category: 'interest_other',
      },
      {
        value: 'Books & Literature/Magazines',
        label: 'Magazines',
        category: 'interest_other',
      },
      {
        value: 'Books & Literature/Poetry',
        label: 'Poetry',
        category: 'interest_other',
      },
      {
        value: 'Books & Literature/Writers Resources',
        label: 'Writers Resources',
        category: 'interest_other',
      },
    ],
  },
  {
    value: 'Business & Industrial',
    label: 'Business & Industrial',
    category: 'interest_other',
    children: [
      {
        value: 'Business & Industrial/Advertising & Marketing',
        label: 'Advertising & Marketing',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Advertising & Marketing/Brand Management',
            label: 'Brand Management',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Advertising & Marketing/Marketing',
            label: 'Marketing',
            category: 'interest_other',
            children: [
              {
                value: 'Business & Industrial/Advertising & Marketing/Marketing/Direct Mail Marketing',
                label: 'Direct Mail Marketing',
                category: 'interest_other',
              },
              {
                value: 'Business & Industrial/Advertising & Marketing/Marketing/Email Marketing',
                label: 'Email Marketing',
                category: 'interest_other',
              },
              {
                value: 'Business & Industrial/Advertising & Marketing/Marketing/Social Media Marketing',
                label: 'Social Media Marketing',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Business & Industrial/Advertising & Marketing/Promotional Products & Corporate Gifts',
            label: 'Promotional Products & Corporate Gifts',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Advertising & Marketing/Public Relations',
            label: 'Public Relations',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Advertising & Marketing/Sales',
            label: 'Sales',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Advertising & Marketing/Telemarketing',
            label: 'Telemarketing',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Aerospace & Defense',
        label: 'Aerospace & Defense',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Aerospace & Defense/Aviation Industry',
            label: 'Aviation Industry',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Aerospace & Defense/Defense Industry',
            label: 'Defense Industry',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Aerospace & Defense/Space Technology',
            label: 'Space Technology',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Agriculture & Forestry',
        label: 'Agriculture & Forestry',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Agriculture & Forestry/Agricultural Equipment',
            label: 'Agricultural Equipment',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Agriculture & Forestry/Aquaculture',
            label: 'Aquaculture',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Agriculture & Forestry/Crops & Seed',
            label: 'Crops & Seed',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Agriculture & Forestry/Farms & Ranches',
            label: 'Farms & Ranches',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Agriculture & Forestry/Food Production',
            label: 'Food Production',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Agriculture & Forestry/Forestry',
            label: 'Forestry',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Agriculture & Forestry/Horticulture',
            label: 'Horticulture',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Agriculture & Forestry/Livestock',
            label: 'Livestock',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Automotive Industry',
        label: 'Automotive Industry',
        category: 'interest_other',
      },
      {
        value: 'Business & Industrial/Business Education',
        label: 'Business Education',
        category: 'interest_other',
      },
      {
        value: 'Business & Industrial/Business Finance',
        label: 'Business Finance',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Business Finance/Commercial Lending',
            label: 'Commercial Lending',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Finance/Investment Banking',
            label: 'Investment Banking',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Finance/Risk Management',
            label: 'Risk Management',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Finance/Venture Capital',
            label: 'Venture Capital',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Business Operations',
        label: 'Business Operations',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Business Operations/Business Plans & Presentations',
            label: 'Business Plans & Presentations',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Operations/Flexible Work Arrangements',
            label: 'Flexible Work Arrangements',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Operations/Human Resources',
            label: 'Human Resources',
            category: 'interest_other',
            children: [
              {
                value: 'Business & Industrial/Business Operations/Human Resources/Compensation & Benefits',
                label: 'Compensation & Benefits',
                category: 'interest_other',
              },
              {
                value: 'Business & Industrial/Business Operations/Human Resources/Corporate Training',
                label: 'Corporate Training',
                category: 'interest_other',
              },
              {
                value: 'Business & Industrial/Business Operations/Human Resources/Payroll Services',
                label: 'Payroll Services',
                category: 'interest_other',
              },
              {
                value: 'Business & Industrial/Business Operations/Human Resources/Recruitment & Staffing',
                label: 'Recruitment & Staffing',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Business & Industrial/Business Operations/Management',
            label: 'Management',
            category: 'interest_other',
            children: [
              {
                value: 'Business & Industrial/Business Operations/Management/Business Process',
                label: 'Business Process',
                category: 'interest_other',
              },
              {
                value: 'Business & Industrial/Business Operations/Management/Project Management',
                label: 'Project Management',
                category: 'interest_other',
              },
              {
                value: 'Business & Industrial/Business Operations/Management/Strategic Planning',
                label: 'Strategic Planning',
                category: 'interest_other',
              },
              {
                value: 'Business & Industrial/Business Operations/Management/Supply Chain Management',
                label: 'Supply Chain Management',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Business & Industrial/Business Services',
        label: 'Business Services',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Business Services/Consulting',
            label: 'Consulting',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Services/Corporate Events',
            label: 'Corporate Events',
            category: 'interest_other',
            children: [
              {
                value: 'Business & Industrial/Business Services/Corporate Events/Trade Fairs & Industry Shows',
                label: 'Trade Fairs & Industry Shows',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Business & Industrial/Business Services/E-Commerce Services',
            label: 'E-Commerce Services',
            category: 'interest_other',
            children: [
              {
                value:
                  'Business & Industrial/Business Services/E-Commerce Services/Merchant Services & Payment Systems',
                label: 'Merchant Services & Payment Systems',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Business & Industrial/Business Services/Fire & Security Services',
            label: 'Fire & Security Services',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Services/Knowledge Management',
            label: 'Knowledge Management',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Services/Office Services',
            label: 'Office Services',
            category: 'interest_other',
            children: [
              {
                value: 'Business & Industrial/Business Services/Office Services/Office & Facilities Management',
                label: 'Office & Facilities Management',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Business & Industrial/Business Services/Office Supplies',
            label: 'Office Supplies',
            category: 'interest_other',
            children: [
              {
                value: 'Business & Industrial/Business Services/Office Supplies/Office Furniture',
                label: 'Office Furniture',
                category: 'interest_other',
                children: [
                  {
                    value:
                      'Business & Industrial/Business Services/Office Supplies/Office Furniture/Office & Computer Desks',
                    label: 'Office & Computer Desks',
                    category: 'interest_other',
                  },
                  {
                    value: 'Business & Industrial/Business Services/Office Supplies/Office Furniture/Office Chairs',
                    label: 'Office Chairs',
                    category: 'interest_other',
                  },
                ],
              },
            ],
          },
          {
            value: 'Business & Industrial/Business Services/Outsourcing',
            label: 'Outsourcing',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Services/Physical Asset Management',
            label: 'Physical Asset Management',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Services/Quality Control & Tracking',
            label: 'Quality Control & Tracking',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Services/Shared Workspaces',
            label: 'Shared Workspaces',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Services/Signage',
            label: 'Signage',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Services/Warehousing',
            label: 'Warehousing',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Business Services/Writing & Editing Services',
            label: 'Writing & Editing Services',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Chemicals Industry',
        label: 'Chemicals Industry',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Chemicals Industry/Agrochemicals',
            label: 'Agrochemicals',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Chemicals Industry/Cleaning Agents',
            label: 'Cleaning Agents',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Chemicals Industry/Coatings & Adhesives',
            label: 'Coatings & Adhesives',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Chemicals Industry/Dyes & Pigments',
            label: 'Dyes & Pigments',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Chemicals Industry/Plastics & Polymers',
            label: 'Plastics & Polymers',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Construction & Maintenance',
        label: 'Construction & Maintenance',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Construction & Maintenance/Building Materials & Supplies',
            label: 'Building Materials & Supplies',
            category: 'interest_other',
            children: [
              {
                value:
                  'Business & Industrial/Construction & Maintenance/Building Materials & Supplies/Nails, Screws & Fasteners',
                label: 'Nails, Screws & Fasteners',
                category: 'interest_other',
              },
              {
                value:
                  'Business & Industrial/Construction & Maintenance/Building Materials & Supplies/Plumbing Fixtures & Equipment',
                label: 'Plumbing Fixtures & Equipment',
                category: 'interest_other',
              },
              {
                value: 'Business & Industrial/Construction & Maintenance/Building Materials & Supplies/Wood & Plastics',
                label: 'Wood & Plastics',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Business & Industrial/Construction & Maintenance/Civil Engineering',
            label: 'Civil Engineering',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Construction & Maintenance/Construction Consulting & Contracting',
            label: 'Construction Consulting & Contracting',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Construction & Maintenance/Urban & Regional Planning',
            label: 'Urban & Regional Planning',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Energy & Utilities',
        label: 'Energy & Utilities',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Energy & Utilities/Electricity',
            label: 'Electricity',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Energy & Utilities/Nuclear Energy',
            label: 'Nuclear Energy',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Energy & Utilities/Oil & Gas',
            label: 'Oil & Gas',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Energy & Utilities/Renewable & Alternative Energy',
            label: 'Renewable & Alternative Energy',
            category: 'interest_other',
            children: [
              {
                value: 'Business & Industrial/Energy & Utilities/Renewable & Alternative Energy/Hydropower',
                label: 'Hydropower',
                category: 'interest_other',
              },
              {
                value: 'Business & Industrial/Energy & Utilities/Renewable & Alternative Energy/Solar Power',
                label: 'Solar Power',
                category: 'interest_other',
              },
              {
                value: 'Business & Industrial/Energy & Utilities/Renewable & Alternative Energy/Wind Power',
                label: 'Wind Power',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Business & Industrial/Energy & Utilities/Waste Management',
            label: 'Waste Management',
            category: 'interest_other',
            children: [
              {
                value: 'Business & Industrial/Energy & Utilities/Waste Management/Recycling',
                label: 'Recycling',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Business & Industrial/Energy & Utilities/Water Supply & Treatment',
            label: 'Water Supply & Treatment',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Hospitality Industry',
        label: 'Hospitality Industry',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Hospitality Industry/Event Planning',
            label: 'Event Planning',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Hospitality Industry/Event Venue Rentals',
            label: 'Event Venue Rentals',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Hospitality Industry/Food Service',
            label: 'Food Service',
            category: 'interest_other',
            children: [
              {
                value: 'Business & Industrial/Hospitality Industry/Food Service/Restaurant Supply',
                label: 'Restaurant Supply',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Business & Industrial/Industrial Materials & Equipment',
        label: 'Industrial Materials & Equipment',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Industrial Materials & Equipment/Fluid Handling',
            label: 'Fluid Handling',
            category: 'interest_other',
            children: [
              {
                value: 'Business & Industrial/Industrial Materials & Equipment/Fluid Handling/Valves Hoses & Fittings',
                label: 'Valves Hoses & Fittings',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Business & Industrial/Industrial Materials & Equipment/Generators',
            label: 'Generators',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Industrial Materials & Equipment/Heavy Machinery',
            label: 'Heavy Machinery',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Industrial Materials & Equipment/Work Safety Protective Gear',
            label: 'Work Safety Protective Gear',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Manufacturing',
        label: 'Manufacturing',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Manufacturing/Factory Automation',
            label: 'Factory Automation',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Metals & Mining',
        label: 'Metals & Mining',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Metals & Mining/Precious Metals',
            label: 'Precious Metals',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Pharmaceuticals & Biotech',
        label: 'Pharmaceuticals & Biotech',
        category: 'interest_other',
      },
      {
        value: 'Business & Industrial/Printing & Publishing',
        label: 'Printing & Publishing',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Printing & Publishing/Document & Printing Services',
            label: 'Document & Printing Services',
            category: 'interest_other',
            children: [
              {
                value:
                  'Business & Industrial/Printing & Publishing/Document & Printing Services/Business Cards & Stationery',
                label: 'Business Cards & Stationery',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Business & Industrial/Professional & Trade Associations',
        label: 'Professional & Trade Associations',
        category: 'interest_other',
      },
      {
        value: 'Business & Industrial/Retail Trade',
        label: 'Retail Trade',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Retail Trade/Retail Equipment & Technology',
            label: 'Retail Equipment & Technology',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Shipping & Logistics',
        label: 'Shipping & Logistics',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Shipping & Logistics/Freight Transport',
            label: 'Freight Transport',
            category: 'interest_other',
            children: [
              {
                value: 'Business & Industrial/Shipping & Logistics/Freight Transport/Maritime Transport',
                label: 'Maritime Transport',
                category: 'interest_other',
              },
              {
                value: 'Business & Industrial/Shipping & Logistics/Freight Transport/Rail Freight',
                label: 'Rail Freight',
                category: 'interest_other',
              },
              {
                value: 'Business & Industrial/Shipping & Logistics/Freight Transport/Trucking',
                label: 'Trucking',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Business & Industrial/Shipping & Logistics/Import & Export',
            label: 'Import & Export',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Shipping & Logistics/Mail & Package Delivery',
            label: 'Mail & Package Delivery',
            category: 'interest_other',
            children: [
              {
                value:
                  'Business & Industrial/Shipping & Logistics/Mail & Package Delivery/Couriers, Messengers & Home Delivery Services',
                label: 'Couriers, Messengers & Home Delivery Services',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Business & Industrial/Shipping & Logistics/Moving & Relocation',
            label: 'Moving & Relocation',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Shipping & Logistics/Packaging',
            label: 'Packaging',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Shipping & Logistics/Self Storage',
            label: 'Self Storage',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Small Business',
        label: 'Small Business',
        category: 'interest_other',
        children: [
          {
            value: 'Business & Industrial/Small Business/Business Formation',
            label: 'Business Formation',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Small Business/Home Office',
            label: 'Home Office',
            category: 'interest_other',
          },
          {
            value: 'Business & Industrial/Small Business/MLM & Business Opportunities',
            label: 'MLM & Business Opportunities',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Business & Industrial/Textiles & Nonwovens',
        label: 'Textiles & Nonwovens',
        category: 'interest_other',
      },
    ],
  },
  {
    value: 'Business Services',
    label: 'Business Services',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Business Services/Advertising & Marketing Services',
        label: 'Advertising & Marketing Services',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Business Services/Advertising & Marketing Services/SEO & SEM Services',
            label: 'SEO & SEM Services',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Business Services/Business Financial Services',
        label: 'Business Financial Services',
        category: 'interest_inmarket',
      },
      {
        value: 'Business Services/Business Printing & Document Services',
        label: 'Business Printing & Document Services',
        category: 'interest_inmarket',
      },
      {
        value: 'Business Services/Business Technology',
        label: 'Business Technology',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Business Services/Business Technology/Enterprise Software',
            label: 'Enterprise Software',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Business Services/Business Technology/Enterprise Software/CRM Solutions',
                label: 'CRM Solutions',
                category: 'interest_inmarket',
              },
              {
                value: 'Business Services/Business Technology/Enterprise Software/Collaboration & Conferencing Tools',
                label: 'Collaboration & Conferencing Tools',
                category: 'interest_inmarket',
              },
              {
                value: 'Business Services/Business Technology/Enterprise Software/ERP Solutions',
                label: 'ERP Solutions',
                category: 'interest_inmarket',
              },
              {
                value:
                  'Business Services/Business Technology/Enterprise Software/Helpdesk & Customer Support Solutions',
                label: 'Helpdesk & Customer Support Solutions',
                category: 'interest_inmarket',
              },
            ],
          },
          {
            value: 'Business Services/Business Technology/Network Systems & Services',
            label: 'Network Systems & Services',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Business Services/Business Technology/Network Systems & Services/Hosted Data & Cloud Storage',
                label: 'Hosted Data & Cloud Storage',
                category: 'interest_inmarket',
              },
              {
                value: 'Business Services/Business Technology/Network Systems & Services/Network & Enterprise Security',
                label: 'Network & Enterprise Security',
                category: 'interest_inmarket',
              },
              {
                value:
                  'Business Services/Business Technology/Network Systems & Services/Network Equipment & Virtualization',
                label: 'Network Equipment & Virtualization',
                category: 'interest_inmarket',
              },
              {
                value: 'Business Services/Business Technology/Network Systems & Services/Network Management',
                label: 'Network Management',
                category: 'interest_inmarket',
              },
            ],
          },
          {
            value: 'Business Services/Business Technology/Web Services',
            label: 'Web Services',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Business Services/Business Technology/Web Services/Domain Registration',
                label: 'Domain Registration',
                category: 'interest_inmarket',
              },
              {
                value: 'Business Services/Business Technology/Web Services/Web Design & Development',
                label: 'Web Design & Development',
                category: 'interest_inmarket',
              },
              {
                value: 'Business Services/Business Technology/Web Services/Web Hosting',
                label: 'Web Hosting',
                category: 'interest_inmarket',
              },
            ],
          },
        ],
      },
      {
        value: 'Business Services/Corporate Event Planning',
        label: 'Corporate Event Planning',
        category: 'interest_inmarket',
      },
      {
        value: 'Business Services/Office Supplies',
        label: 'Office Supplies',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Business Services/Office Supplies/Office Furniture',
            label: 'Office Furniture',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Business Services/Payment Processing & Merchant Services',
        label: 'Payment Processing & Merchant Services',
        category: 'interest_inmarket',
      },
      {
        value: 'Business Services/Payroll Services',
        label: 'Payroll Services',
        category: 'interest_inmarket',
      },
      {
        value: 'Business Services/Staffing & Recruitment Services',
        label: 'Staffing & Recruitment Services',
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Cold & Flu',
    label: 'Cold & Flu',
    category: 'interest_other',
  },
  {
    value: 'Computers & Electronics',
    label: 'Computers & Electronics',
    category: 'interest_other',
    children: [
      {
        value: 'Computers & Electronics/CAD & CAM',
        label: 'CAD & CAM',
        category: 'interest_other',
      },
      {
        value: 'Computers & Electronics/Computer Hardware',
        label: 'Computer Hardware',
        category: 'interest_other',
        children: [
          {
            value: 'Computers & Electronics/Computer Hardware/Computer Components',
            label: 'Computer Components',
            category: 'interest_other',
            children: [
              {
                value: 'Computers & Electronics/Computer Hardware/Computer Components/Chips & Processors',
                label: 'Chips & Processors',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Computer Hardware/Computer Components/Computer Memory',
                label: 'Computer Memory',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Computer Hardware/Computer Components/Sound & Video Cards',
                label: 'Sound & Video Cards',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Computers & Electronics/Computer Hardware/Computer Drives & Storage',
            label: 'Computer Drives & Storage',
            category: 'interest_other',
            children: [
              {
                value: 'Computers & Electronics/Computer Hardware/Computer Drives & Storage/CD & DVD Drives & Burners',
                label: 'CD & DVD Drives & Burners',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Computer Hardware/Computer Drives & Storage/CD & DVD Storage Media',
                label: 'CD & DVD Storage Media',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Computer Hardware/Computer Drives & Storage/Data Backup & Recovery',
                label: 'Data Backup & Recovery',
                category: 'interest_other',
              },
              {
                value:
                  'Computers & Electronics/Computer Hardware/Computer Drives & Storage/Flash Drives & Memory Cards',
                label: 'Flash Drives & Memory Cards',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Computer Hardware/Computer Drives & Storage/Hard Drives',
                label: 'Hard Drives',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Computer Hardware/Computer Drives & Storage/Memory Card Readers',
                label: 'Memory Card Readers',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Computer Hardware/Computer Drives & Storage/Network Storage',
                label: 'Network Storage',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Computers & Electronics/Computer Hardware/Computer Peripherals',
            label: 'Computer Peripherals',
            category: 'interest_other',
            children: [
              {
                value: 'Computers & Electronics/Computer Hardware/Computer Peripherals/Computer Monitors & Displays',
                label: 'Computer Monitors & Displays',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Computer Hardware/Computer Peripherals/Input Devices',
                label: 'Input Devices',
                category: 'interest_other',
              },
              {
                value:
                  'Computers & Electronics/Computer Hardware/Computer Peripherals/Printers, Copiers & Fax Machines',
                label: 'Printers, Copiers & Fax Machines',
                category: 'interest_other',
                children: [
                  {
                    value:
                      'Computers & Electronics/Computer Hardware/Computer Peripherals/Printers, Copiers & Fax Machines/3D Printers & Scanners',
                    label: '3D Printers & Scanners',
                    category: 'interest_other',
                  },
                  {
                    value:
                      'Computers & Electronics/Computer Hardware/Computer Peripherals/Printers, Copiers & Fax Machines/Copiers',
                    label: 'Copiers',
                    category: 'interest_other',
                  },
                  {
                    value:
                      'Computers & Electronics/Computer Hardware/Computer Peripherals/Printers, Copiers & Fax Machines/Fax Machines',
                    label: 'Fax Machines',
                    category: 'interest_other',
                  },
                  {
                    value:
                      'Computers & Electronics/Computer Hardware/Computer Peripherals/Printers, Copiers & Fax Machines/Ink & Toner',
                    label: 'Ink & Toner',
                    category: 'interest_other',
                  },
                  {
                    value:
                      'Computers & Electronics/Computer Hardware/Computer Peripherals/Printers, Copiers & Fax Machines/Printers',
                    label: 'Printers',
                    category: 'interest_other',
                  },
                  {
                    value:
                      'Computers & Electronics/Computer Hardware/Computer Peripherals/Printers, Copiers & Fax Machines/Scanners',
                    label: 'Scanners',
                    category: 'interest_other',
                  },
                ],
              },
            ],
          },
          {
            value: 'Computers & Electronics/Computer Hardware/Computer Servers',
            label: 'Computer Servers',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Computer Hardware/Desktop Computers',
            label: 'Desktop Computers',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Computer Hardware/Hardware Modding & Tuning',
            label: 'Hardware Modding & Tuning',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Computer Hardware/Laptops & Notebooks',
            label: 'Laptops & Notebooks',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Computers & Electronics/Computer Security',
        label: 'Computer Security',
        category: 'interest_other',
        children: [
          {
            value: 'Computers & Electronics/Computer Security/Antivirus & Malware',
            label: 'Antivirus & Malware',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Computer Security/Network Security',
            label: 'Network Security',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Computers & Electronics/Consumer Electronics',
        label: 'Consumer Electronics',
        category: 'interest_other',
        children: [
          {
            value: 'Computers & Electronics/Consumer Electronics/Audio Equipment',
            label: 'Audio Equipment',
            category: 'interest_other',
            children: [
              {
                value: 'Computers & Electronics/Consumer Electronics/Audio Equipment/Headphones',
                label: 'Headphones',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Consumer Electronics/Audio Equipment/Speakers',
                label: 'Speakers',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Consumer Electronics/Audio Equipment/Stereo Systems & Components',
                label: 'Stereo Systems & Components',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Computers & Electronics/Consumer Electronics/Camera & Photo Equipment',
            label: 'Camera & Photo Equipment',
            category: 'interest_other',
            children: [
              {
                value:
                  'Computers & Electronics/Consumer Electronics/Camera & Photo Equipment/Binoculars, Telescopes & Optical Devices',
                label: 'Binoculars, Telescopes & Optical Devices',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Consumer Electronics/Camera & Photo Equipment/Cameras & Camcorders',
                label: 'Cameras & Camcorders',
                category: 'interest_other',
                children: [
                  {
                    value:
                      'Computers & Electronics/Consumer Electronics/Camera & Photo Equipment/Cameras & Camcorders/Camcorders',
                    label: 'Camcorders',
                    category: 'interest_other',
                    children: [
                      {
                        value:
                          'Computers & Electronics/Consumer Electronics/Camera & Photo Equipment/Cameras & Camcorders/Camcorders/Body Mounted & Action Cameras',
                        label: 'Body Mounted & Action Cameras',
                        category: 'interest_other',
                      },
                    ],
                  },
                  {
                    value:
                      'Computers & Electronics/Consumer Electronics/Camera & Photo Equipment/Cameras & Camcorders/Camera Lenses',
                    label: 'Camera Lenses',
                    category: 'interest_other',
                  },
                  {
                    value:
                      'Computers & Electronics/Consumer Electronics/Camera & Photo Equipment/Cameras & Camcorders/Cameras',
                    label: 'Cameras',
                    category: 'interest_other',
                    children: [
                      {
                        value:
                          'Computers & Electronics/Consumer Electronics/Camera & Photo Equipment/Cameras & Camcorders/Cameras/Digital SLR Cameras',
                        label: 'Digital SLR Cameras',
                        category: 'interest_other',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Computers & Electronics/Consumer Electronics/Car Electronics',
            label: 'Car Electronics',
            category: 'interest_other',
            children: [
              {
                value: 'Computers & Electronics/Consumer Electronics/Car Electronics/Car Audio',
                label: 'Car Audio',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Consumer Electronics/Car Electronics/Car Video',
                label: 'Car Video',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Computers & Electronics/Consumer Electronics/Drones & RC Aircraft',
            label: 'Drones & RC Aircraft',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Consumer Electronics/Electronic Accessories',
            label: 'Electronic Accessories',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Consumer Electronics/GPS & Navigation',
            label: 'GPS & Navigation',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Consumer Electronics/Gadgets & Portable Electronics',
            label: 'Gadgets & Portable Electronics',
            category: 'interest_other',
            children: [
              {
                value: 'Computers & Electronics/Consumer Electronics/Gadgets & Portable Electronics/E-Book Readers',
                label: 'E-Book Readers',
                category: 'interest_other',
              },
              {
                value:
                  'Computers & Electronics/Consumer Electronics/Gadgets & Portable Electronics/MP3 & Portable Media Players',
                label: 'MP3 & Portable Media Players',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Consumer Electronics/Gadgets & Portable Electronics/PDAs & Handhelds',
                label: 'PDAs & Handhelds',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Consumer Electronics/Gadgets & Portable Electronics/Tablet PCs',
                label: 'Tablet PCs',
                category: 'interest_other',
              },
              {
                value:
                  'Computers & Electronics/Consumer Electronics/Gadgets & Portable Electronics/Wearable Technology',
                label: 'Wearable Technology',
                category: 'interest_other',
                children: [
                  {
                    value:
                      'Computers & Electronics/Consumer Electronics/Gadgets & Portable Electronics/Wearable Technology/Smart Watches',
                    label: 'Smart Watches',
                    category: 'interest_other',
                  },
                ],
              },
            ],
          },
          {
            value: 'Computers & Electronics/Consumer Electronics/Game Systems & Consoles',
            label: 'Game Systems & Consoles',
            category: 'interest_other',
            children: [
              {
                value: 'Computers & Electronics/Consumer Electronics/Game Systems & Consoles/Handheld Game Consoles',
                label: 'Handheld Game Consoles',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Consumer Electronics/Game Systems & Consoles/Nintendo',
                label: 'Nintendo',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Consumer Electronics/Game Systems & Consoles/Sony PlayStation',
                label: 'Sony PlayStation',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Consumer Electronics/Game Systems & Consoles/Xbox',
                label: 'Xbox',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Computers & Electronics/Consumer Electronics/Home Automation',
            label: 'Home Automation',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Consumer Electronics/Media Streaming Devices',
            label: 'Media Streaming Devices',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Consumer Electronics/TV & Video Equipment',
            label: 'TV & Video Equipment',
            category: 'interest_other',
            children: [
              {
                value: 'Computers & Electronics/Consumer Electronics/TV & Video Equipment/DVRs & Set-Top Boxes',
                label: 'DVRs & Set-Top Boxes',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Consumer Electronics/TV & Video Equipment/Home Theater Systems',
                label: 'Home Theater Systems',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Consumer Electronics/TV & Video Equipment/Projectors & Screens',
                label: 'Projectors & Screens',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Consumer Electronics/TV & Video Equipment/Televisions',
                label: 'Televisions',
                category: 'interest_other',
                children: [
                  {
                    value: 'Computers & Electronics/Consumer Electronics/TV & Video Equipment/Televisions/HDTVs',
                    label: 'HDTVs',
                    category: 'interest_other',
                  },
                  {
                    value: 'Computers & Electronics/Consumer Electronics/TV & Video Equipment/Televisions/LCD TVs',
                    label: 'LCD TVs',
                    category: 'interest_other',
                  },
                  {
                    value: 'Computers & Electronics/Consumer Electronics/TV & Video Equipment/Televisions/Plasma TVs',
                    label: 'Plasma TVs',
                    category: 'interest_other',
                  },
                  {
                    value:
                      'Computers & Electronics/Consumer Electronics/TV & Video Equipment/Televisions/Projection TVs',
                    label: 'Projection TVs',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Computers & Electronics/Consumer Electronics/TV & Video Equipment/Video Players & Recorders',
                label: 'Video Players & Recorders',
                category: 'interest_other',
                children: [
                  {
                    value:
                      'Computers & Electronics/Consumer Electronics/TV & Video Equipment/Video Players & Recorders/Blu-Ray Players & Recorders',
                    label: 'Blu-Ray Players & Recorders',
                    category: 'interest_other',
                  },
                  {
                    value:
                      'Computers & Electronics/Consumer Electronics/TV & Video Equipment/Video Players & Recorders/DVD Players & Recorders',
                    label: 'DVD Players & Recorders',
                    category: 'interest_other',
                  },
                ],
              },
            ],
          },
          {
            value: 'Computers & Electronics/Consumer Electronics/Virtual Reality Devices',
            label: 'Virtual Reality Devices',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Computers & Electronics/Electronics & Electrical',
        label: 'Electronics & Electrical',
        category: 'interest_other',
        children: [
          {
            value: 'Computers & Electronics/Electronics & Electrical/Data Sheets & Electronics Reference',
            label: 'Data Sheets & Electronics Reference',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Electronics & Electrical/Electrical Test & Measurement',
            label: 'Electrical Test & Measurement',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Electronics & Electrical/Electromechanical Devices',
            label: 'Electromechanical Devices',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Electronics & Electrical/Electronic Components',
            label: 'Electronic Components',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Electronics & Electrical/Optoelectronics & Fiber',
            label: 'Optoelectronics & Fiber',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Electronics & Electrical/Power Supplies',
            label: 'Power Supplies',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Computers & Electronics/Enterprise Technology',
        label: 'Enterprise Technology',
        category: 'interest_other',
        children: [
          {
            value: 'Computers & Electronics/Enterprise Technology/Customer Relationship Management (CRM)',
            label: 'Customer Relationship Management (CRM)',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Enterprise Technology/Data Management',
            label: 'Data Management',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Enterprise Technology/Enterprise Resource Planning (ERP)',
            label: 'Enterprise Resource Planning (ERP)',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Enterprise Technology/Helpdesk & Customer Support Systems',
            label: 'Helpdesk & Customer Support Systems',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Computers & Electronics/Networking',
        label: 'Networking',
        category: 'interest_other',
        children: [
          {
            value: 'Computers & Electronics/Networking/Data Formats & Protocols',
            label: 'Data Formats & Protocols',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Networking/Distributed & Cloud Computing',
            label: 'Distributed & Cloud Computing',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Networking/Network Monitoring & Management',
            label: 'Network Monitoring & Management',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Networking/Networking Equipment',
            label: 'Networking Equipment',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Networking/VPN & Remote Access',
            label: 'VPN & Remote Access',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Computers & Electronics/Programming',
        label: 'Programming',
        category: 'interest_other',
        children: [
          {
            value: 'Computers & Electronics/Programming/C & C++',
            label: 'C & C++',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Programming/Development Tools',
            label: 'Development Tools',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Programming/Java (Programming Language)',
            label: 'Java (Programming Language)',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Programming/Scripting Languages',
            label: 'Scripting Languages',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Programming/Windows & .NET',
            label: 'Windows & .NET',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Computers & Electronics/Software',
        label: 'Software',
        category: 'interest_other',
        children: [
          {
            value: 'Computers & Electronics/Software/Business & Productivity Software',
            label: 'Business & Productivity Software',
            category: 'interest_other',
            children: [
              {
                value:
                  'Computers & Electronics/Software/Business & Productivity Software/Accounting & Financial Software',
                label: 'Accounting & Financial Software',
                category: 'interest_other',
              },
              {
                value:
                  'Computers & Electronics/Software/Business & Productivity Software/Calendar & Scheduling Software',
                label: 'Calendar & Scheduling Software',
                category: 'interest_other',
              },
              {
                value:
                  'Computers & Electronics/Software/Business & Productivity Software/Collaboration & Conferencing Software',
                label: 'Collaboration & Conferencing Software',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Software/Business & Productivity Software/Presentation Software',
                label: 'Presentation Software',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Software/Business & Productivity Software/Project Management Software',
                label: 'Project Management Software',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Software/Business & Productivity Software/Spreadsheet Software',
                label: 'Spreadsheet Software',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Software/Business & Productivity Software/Word Processing Software',
                label: 'Word Processing Software',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Computers & Electronics/Software/Device Drivers',
            label: 'Device Drivers',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Software/Educational Software',
            label: 'Educational Software',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Software/Freeware & Shareware',
            label: 'Freeware & Shareware',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Software/Intelligent Personal Assistants',
            label: 'Intelligent Personal Assistants',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Software/Internet Software',
            label: 'Internet Software',
            category: 'interest_other',
            children: [
              {
                value: 'Computers & Electronics/Software/Internet Software/Content Management',
                label: 'Content Management',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Software/Internet Software/Download Managers',
                label: 'Download Managers',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Software/Internet Software/Proxying & Filtering',
                label: 'Proxying & Filtering',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Software/Internet Software/Web Browsers',
                label: 'Web Browsers',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Computers & Electronics/Software/Monitoring Software',
            label: 'Monitoring Software',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Software/Multimedia Software',
            label: 'Multimedia Software',
            category: 'interest_other',
            children: [
              {
                value: 'Computers & Electronics/Software/Multimedia Software/Audio & Music Software',
                label: 'Audio & Music Software',
                category: 'interest_other',
                children: [
                  {
                    value:
                      'Computers & Electronics/Software/Multimedia Software/Audio & Music Software/Audio File Formats & Codecs',
                    label: 'Audio File Formats & Codecs',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Computers & Electronics/Software/Multimedia Software/Desktop Publishing',
                label: 'Desktop Publishing',
                category: 'interest_other',
                children: [
                  {
                    value: 'Computers & Electronics/Software/Multimedia Software/Desktop Publishing/Fonts',
                    label: 'Fonts',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Computers & Electronics/Software/Multimedia Software/Graphics & Animation Software',
                label: 'Graphics & Animation Software',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Software/Multimedia Software/Media Players',
                label: 'Media Players',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Software/Multimedia Software/Photo & Video Software',
                label: 'Photo & Video Software',
                category: 'interest_other',
                children: [
                  {
                    value: 'Computers & Electronics/Software/Multimedia Software/Photo & Video Software/Photo Software',
                    label: 'Photo Software',
                    category: 'interest_other',
                  },
                  {
                    value: 'Computers & Electronics/Software/Multimedia Software/Photo & Video Software/Video Software',
                    label: 'Video Software',
                    category: 'interest_other',
                    children: [
                      {
                        value:
                          'Computers & Electronics/Software/Multimedia Software/Photo & Video Software/Video Software/Video File Formats & Codecs',
                        label: 'Video File Formats & Codecs',
                        category: 'interest_other',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Computers & Electronics/Software/Open Source',
            label: 'Open Source',
            category: 'interest_other',
          },
          {
            value: 'Computers & Electronics/Software/Operating Systems',
            label: 'Operating Systems',
            category: 'interest_other',
            children: [
              {
                value: 'Computers & Electronics/Software/Operating Systems/Linux & Unix',
                label: 'Linux & Unix',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Software/Operating Systems/Mac OS',
                label: 'Mac OS',
                category: 'interest_other',
              },
              {
                value: 'Computers & Electronics/Software/Operating Systems/Mobile OS',
                label: 'Mobile OS',
                category: 'interest_other',
                children: [
                  {
                    value: 'Computers & Electronics/Software/Operating Systems/Mobile OS/Android OS',
                    label: 'Android OS',
                    category: 'interest_other',
                  },
                  {
                    value: 'Computers & Electronics/Software/Operating Systems/Mobile OS/Apple iOS',
                    label: 'Apple iOS',
                    category: 'interest_other',
                  },
                  {
                    value: 'Computers & Electronics/Software/Operating Systems/Mobile OS/BlackBerry OS',
                    label: 'BlackBerry OS',
                    category: 'interest_other',
                  },
                  {
                    value: 'Computers & Electronics/Software/Operating Systems/Mobile OS/Symbian OS',
                    label: 'Symbian OS',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Computers & Electronics/Software/Operating Systems/Windows OS',
                label: 'Windows OS',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Computers & Electronics/Software/Software Utilities',
            label: 'Software Utilities',
            category: 'interest_other',
          },
        ],
      },
    ],
  },
  {
    value: 'Computers & Peripherals',
    label: 'Computers & Peripherals',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Computers & Peripherals/Computer Accessories & Components',
        label: 'Computer Accessories & Components',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Computers & Peripherals/Computer Accessories & Components/Computer Monitors',
            label: 'Computer Monitors',
            category: 'interest_inmarket',
          },
          {
            value: 'Computers & Peripherals/Computer Accessories & Components/Memory & Storage',
            label: 'Memory & Storage',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Computers & Peripherals/Computers',
        label: 'Computers',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Computers & Peripherals/Computers/Desktop Computers',
            label: 'Desktop Computers',
            category: 'interest_inmarket',
          },
          {
            value: 'Computers & Peripherals/Computers/Laptops & Notebooks',
            label: 'Laptops & Notebooks',
            category: 'interest_inmarket',
          },
          {
            value: 'Computers & Peripherals/Computers/Tablets & Ultraportable Devices',
            label: 'Tablets & Ultraportable Devices',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Computers & Peripherals/Printers, Scanners & Faxes',
        label: 'Printers, Scanners & Faxes',
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Consumer Electronics',
    label: 'Consumer Electronics',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Consumer Electronics/Audio',
        label: 'Audio',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Consumer Electronics/Audio/Headphones & Headsets',
            label: 'Headphones & Headsets',
            category: 'interest_inmarket',
          },
          {
            value: 'Consumer Electronics/Audio/Pro Musician & DJ Equipment',
            label: 'Pro Musician & DJ Equipment',
            category: 'interest_inmarket',
          },
          {
            value: 'Consumer Electronics/Audio/Speakers',
            label: 'Speakers',
            category: 'interest_inmarket',
          },
          {
            value: 'Consumer Electronics/Audio/Stereo Systems',
            label: 'Stereo Systems',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Consumer Electronics/Camcorders',
        label: 'Camcorders',
        category: 'interest_inmarket',
      },
      {
        value: 'Consumer Electronics/Cameras',
        label: 'Cameras',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Consumer Electronics/Cameras/Camera Lenses',
            label: 'Camera Lenses',
            category: 'interest_inmarket',
          },
          {
            value: 'Consumer Electronics/Cameras/Digital SLRs',
            label: 'Digital SLRs',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Consumer Electronics/Game Consoles',
        label: 'Game Consoles',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Consumer Electronics/Game Consoles/Nintendo Consoles',
            label: 'Nintendo Consoles',
            category: 'interest_inmarket',
          },
          {
            value: 'Consumer Electronics/Game Consoles/Sony PlayStation',
            label: 'Sony PlayStation',
            category: 'interest_inmarket',
          },
          {
            value: 'Consumer Electronics/Game Consoles/Xbox',
            label: 'Xbox',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Consumer Electronics/Home Theater Systems',
        label: 'Home Theater Systems',
        category: 'interest_inmarket',
      },
      {
        value: 'Consumer Electronics/Mobile Phone Accessories',
        label: 'Mobile Phone Accessories',
        category: 'interest_inmarket',
      },
      {
        value: 'Consumer Electronics/Mobile Phones',
        label: 'Mobile Phones',
        category: 'interest_inmarket',
      },
      {
        value: 'Consumer Electronics/Televisions',
        label: 'Televisions',
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Dating Services',
    label: 'Dating Services',
    category: 'interest_inmarket',
  },
  {
    value: 'Education',
    label: 'Education',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Education/Foreign Language Study',
        label: 'Foreign Language Study',
        category: 'interest_inmarket',
      },
      {
        value: 'Education/Post-Secondary Education',
        label: 'Post-Secondary Education',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Education/Post-Secondary Education/Arts & Design Education',
            label: 'Arts & Design Education',
            category: 'interest_inmarket',
          },
          {
            value: 'Education/Post-Secondary Education/Business Education',
            label: 'Business Education',
            category: 'interest_inmarket',
          },
          {
            value: 'Education/Post-Secondary Education/Cosmetology Education & Training',
            label: 'Cosmetology Education & Training',
            category: 'interest_inmarket',
          },
          {
            value: 'Education/Post-Secondary Education/Technology Education',
            label: 'Technology Education',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Education/Primary & Secondary Schools (K-12)',
        label: 'Primary & Secondary Schools (K-12)',
        category: 'interest_inmarket',
      },
      {
        value: 'Education/Study Abroad Programs',
        label: 'Study Abroad Programs',
        category: 'interest_inmarket',
      },
      {
        value: 'Education/Test Preparation & Tutoring',
        label: 'Test Preparation & Tutoring',
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Employment',
    label: 'Employment',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Employment/Accounting & Finance Jobs',
        label: 'Accounting & Finance Jobs',
        category: 'interest_inmarket',
      },
      {
        value: 'Employment/Career Consulting Services',
        label: 'Career Consulting Services',
        category: 'interest_inmarket',
      },
      {
        value: 'Employment/Clerical & Administrative Jobs',
        label: 'Clerical & Administrative Jobs',
        category: 'interest_inmarket',
      },
      {
        value: 'Employment/Education Jobs',
        label: 'Education Jobs',
        category: 'interest_inmarket',
      },
      {
        value: 'Employment/Executive & Management Jobs',
        label: 'Executive & Management Jobs',
        category: 'interest_inmarket',
      },
      {
        value: 'Employment/Government & Public Sector Jobs',
        label: 'Government & Public Sector Jobs',
        category: 'interest_inmarket',
      },
      {
        value: 'Employment/Health & Medical Jobs',
        label: 'Health & Medical Jobs',
        category: 'interest_inmarket',
      },
      {
        value: 'Employment/IT & Technical Jobs',
        label: 'IT & Technical Jobs',
        category: 'interest_inmarket',
      },
      {
        value: 'Employment/Internships',
        label: 'Internships',
        category: 'interest_inmarket',
      },
      {
        value: 'Employment/Legal Jobs',
        label: 'Legal Jobs',
        category: 'interest_inmarket',
      },
      {
        value: 'Employment/Resumes & Portfolios',
        label: 'Resumes & Portfolios',
        category: 'interest_inmarket',
      },
      {
        value: 'Employment/Retail Jobs',
        label: 'Retail Jobs',
        category: 'interest_inmarket',
      },
      {
        value: 'Employment/Sales & Marketing Jobs',
        label: 'Sales & Marketing Jobs',
        category: 'interest_inmarket',
      },
      {
        value: 'Employment/Temporary & Seasonal Jobs',
        label: 'Temporary & Seasonal Jobs',
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Event Tickets',
    label: 'Event Tickets',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Event Tickets/Concert & Music Festival Tickets',
        label: 'Concert & Music Festival Tickets',
        category: 'interest_inmarket',
      },
      {
        value: 'Event Tickets/Performing Arts Tickets',
        label: 'Performing Arts Tickets',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Event Tickets/Performing Arts Tickets/Broadway & Theater Tickets',
            label: 'Broadway & Theater Tickets',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Event Tickets/Sports Tickets',
        label: 'Sports Tickets',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Event Tickets/Sports Tickets/American Football Tickets',
            label: 'American Football Tickets',
            category: 'interest_inmarket',
          },
          {
            value: 'Event Tickets/Sports Tickets/Baseball Tickets',
            label: 'Baseball Tickets',
            category: 'interest_inmarket',
          },
          {
            value: 'Event Tickets/Sports Tickets/Basketball Tickets',
            label: 'Basketball Tickets',
            category: 'interest_inmarket',
          },
          {
            value: 'Event Tickets/Sports Tickets/Hockey Tickets',
            label: 'Hockey Tickets',
            category: 'interest_inmarket',
          },
          {
            value: 'Event Tickets/Sports Tickets/Soccer Tickets',
            label: 'Soccer Tickets',
            category: 'interest_inmarket',
          },
        ],
      },
    ],
  },
  {
    value: 'Finance',
    label: 'Finance',
    category: 'interest_other',
    children: [
      {
        value: 'Finance/Accounting & Auditing',
        label: 'Accounting & Auditing',
        category: 'interest_other',
        children: [
          {
            value: 'Finance/Accounting & Auditing/Billing & Invoicing',
            label: 'Billing & Invoicing',
            category: 'interest_other',
          },
          {
            value: 'Finance/Accounting & Auditing/Bookkeeping',
            label: 'Bookkeeping',
            category: 'interest_other',
          },
          {
            value: 'Finance/Accounting & Auditing/Tax Preparation & Planning',
            label: 'Tax Preparation & Planning',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Finance/Banking',
        label: 'Banking',
        category: 'interest_other',
        children: [
          {
            value: 'Finance/Banking/ATMs & Branch Locations',
            label: 'ATMs & Branch Locations',
            category: 'interest_other',
          },
          {
            value: 'Finance/Banking/Debit & Checking Services',
            label: 'Debit & Checking Services',
            category: 'interest_other',
          },
          {
            value: 'Finance/Banking/Mobile Payments & Digital Wallets',
            label: 'Mobile Payments & Digital Wallets',
            category: 'interest_other',
          },
          {
            value: 'Finance/Banking/Money Transfer & Wire Services',
            label: 'Money Transfer & Wire Services',
            category: 'interest_other',
          },
          {
            value: 'Finance/Banking/Savings Accounts',
            label: 'Savings Accounts',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Finance/Credit & Lending',
        label: 'Credit & Lending',
        category: 'interest_other',
        children: [
          {
            value: 'Finance/Credit & Lending/Credit Cards',
            label: 'Credit Cards',
            category: 'interest_other',
          },
          {
            value: 'Finance/Credit & Lending/Credit Reporting & Monitoring',
            label: 'Credit Reporting & Monitoring',
            category: 'interest_other',
          },
          {
            value: 'Finance/Credit & Lending/Loans',
            label: 'Loans',
            category: 'interest_other',
            children: [
              {
                value: 'Finance/Credit & Lending/Loans/Home Financing',
                label: 'Home Financing',
                category: 'interest_other',
                children: [
                  {
                    value: 'Finance/Credit & Lending/Loans/Home Financing/Home Equity Loans & Lines of Credit',
                    label: 'Home Equity Loans & Lines of Credit',
                    category: 'interest_other',
                  },
                  {
                    value: 'Finance/Credit & Lending/Loans/Home Financing/Home Refinancing',
                    label: 'Home Refinancing',
                    category: 'interest_other',
                  },
                  {
                    value: 'Finance/Credit & Lending/Loans/Home Financing/Mortgages',
                    label: 'Mortgages',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Finance/Credit & Lending/Loans/Personal Loans',
                label: 'Personal Loans',
                category: 'interest_other',
              },
              {
                value: 'Finance/Credit & Lending/Loans/Student Loans & College Financing',
                label: 'Student Loans & College Financing',
                category: 'interest_other',
              },
              {
                value: 'Finance/Credit & Lending/Loans/Vehicle Financing',
                label: 'Vehicle Financing',
                category: 'interest_other',
                children: [
                  {
                    value: 'Finance/Credit & Lending/Loans/Vehicle Financing/Auto Financing',
                    label: 'Auto Financing',
                    category: 'interest_other',
                    children: [
                      {
                        value: 'Finance/Credit & Lending/Loans/Vehicle Financing/Auto Financing/Auto Leasing',
                        label: 'Auto Leasing',
                        category: 'interest_other',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        value: 'Finance/Crowdfunding',
        label: 'Crowdfunding',
        category: 'interest_other',
      },
      {
        value: 'Finance/Financial Planning & Management',
        label: 'Financial Planning & Management',
        category: 'interest_other',
        children: [
          {
            value: 'Finance/Financial Planning & Management/Asset & Portfolio Management',
            label: 'Asset & Portfolio Management',
            category: 'interest_other',
          },
          {
            value: 'Finance/Financial Planning & Management/Inheritance & Estate Planning',
            label: 'Inheritance & Estate Planning',
            category: 'interest_other',
          },
          {
            value: 'Finance/Financial Planning & Management/Retirement & Pension',
            label: 'Retirement & Pension',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Finance/Grants, Scholarships & Financial Aid',
        label: 'Grants, Scholarships & Financial Aid',
        category: 'interest_other',
        children: [
          {
            value: 'Finance/Grants, Scholarships & Financial Aid/Government Grants',
            label: 'Government Grants',
            category: 'interest_other',
          },
          {
            value: 'Finance/Grants, Scholarships & Financial Aid/Study Grants & Scholarships',
            label: 'Study Grants & Scholarships',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Finance/Insurance',
        label: 'Insurance',
        category: 'interest_other',
        children: [
          {
            value: 'Finance/Insurance/Health Insurance',
            label: 'Health Insurance',
            category: 'interest_other',
          },
          {
            value: 'Finance/Insurance/Home Insurance',
            label: 'Home Insurance',
            category: 'interest_other',
          },
          {
            value: 'Finance/Insurance/Life Insurance',
            label: 'Life Insurance',
            category: 'interest_other',
          },
          {
            value: 'Finance/Insurance/Travel Insurance',
            label: 'Travel Insurance',
            category: 'interest_other',
          },
          {
            value: 'Finance/Insurance/Vehicle Insurance',
            label: 'Vehicle Insurance',
            category: 'interest_other',
            children: [
              {
                value: 'Finance/Insurance/Vehicle Insurance/Auto Insurance',
                label: 'Auto Insurance',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Finance/Investing',
        label: 'Investing',
        category: 'interest_other',
        children: [
          {
            value: 'Finance/Investing/Brokerages & Day Trading',
            label: 'Brokerages & Day Trading',
            category: 'interest_other',
          },
          {
            value: 'Finance/Investing/Commodities & Futures Trading',
            label: 'Commodities & Futures Trading',
            category: 'interest_other',
          },
          {
            value: 'Finance/Investing/Currencies & Foreign Exchange',
            label: 'Currencies & Foreign Exchange',
            category: 'interest_other',
            children: [
              {
                value: 'Finance/Investing/Currencies & Foreign Exchange/Digital Currencies',
                label: 'Digital Currencies',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Finance/Investing/Derivatives',
            label: 'Derivatives',
            category: 'interest_other',
          },
          {
            value: 'Finance/Investing/Funds',
            label: 'Funds',
            category: 'interest_other',
            children: [
              {
                value: 'Finance/Investing/Funds/Exchange Traded Funds',
                label: 'Exchange Traded Funds',
                category: 'interest_other',
              },
              {
                value: 'Finance/Investing/Funds/Hedge Funds',
                label: 'Hedge Funds',
                category: 'interest_other',
              },
              {
                value: 'Finance/Investing/Funds/Mutual Funds',
                label: 'Mutual Funds',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Finance/Investing/Real Estate Investment Trusts',
            label: 'Real Estate Investment Trusts',
            category: 'interest_other',
          },
          {
            value: 'Finance/Investing/Socially Responsible Investing',
            label: 'Socially Responsible Investing',
            category: 'interest_other',
          },
          {
            value: 'Finance/Investing/Stocks & Bonds',
            label: 'Stocks & Bonds',
            category: 'interest_other',
            children: [
              {
                value: 'Finance/Investing/Stocks & Bonds/Bonds',
                label: 'Bonds',
                category: 'interest_other',
              },
              {
                value: 'Finance/Investing/Stocks & Bonds/Exchanges',
                label: 'Exchanges',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: 'Financial Services',
    label: 'Financial Services',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Financial Services/Banking Services',
        label: 'Banking Services',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Financial Services/Banking Services/Debit & Checking Services',
            label: 'Debit & Checking Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Financial Services/Banking Services/Savings Accounts',
            label: 'Savings Accounts',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Financial Services/Credit & Lending',
        label: 'Credit & Lending',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Financial Services/Credit & Lending/Auto Loans',
            label: 'Auto Loans',
            category: 'interest_inmarket',
          },
          {
            value: 'Financial Services/Credit & Lending/Business Loans',
            label: 'Business Loans',
            category: 'interest_inmarket',
          },
          {
            value: 'Financial Services/Credit & Lending/Credit Cards',
            label: 'Credit Cards',
            category: 'interest_inmarket',
          },
          {
            value: 'Financial Services/Credit & Lending/Credit Reports & Monitoring Services',
            label: 'Credit Reports & Monitoring Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Financial Services/Credit & Lending/Mortgage',
            label: 'Mortgage',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Financial Services/Credit & Lending/Mortgage/Home Equity Loans',
                label: 'Home Equity Loans',
                category: 'interest_inmarket',
              },
              {
                value: 'Financial Services/Credit & Lending/Mortgage/Home Purchase Loans',
                label: 'Home Purchase Loans',
                category: 'interest_inmarket',
              },
              {
                value: 'Financial Services/Credit & Lending/Mortgage/Mortgage Refinancing',
                label: 'Mortgage Refinancing',
                category: 'interest_inmarket',
              },
            ],
          },
          {
            value: 'Financial Services/Credit & Lending/Personal Loans',
            label: 'Personal Loans',
            category: 'interest_inmarket',
          },
          {
            value: 'Financial Services/Credit & Lending/Student Loans',
            label: 'Student Loans',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Financial Services/Financial Planning',
        label: 'Financial Planning',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Financial Services/Financial Planning/Estate Planning',
            label: 'Estate Planning',
            category: 'interest_inmarket',
          },
          {
            value: 'Financial Services/Financial Planning/Retirement Planning',
            label: 'Retirement Planning',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Financial Services/Insurance',
        label: 'Insurance',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Financial Services/Insurance/Auto Insurance',
            label: 'Auto Insurance',
            category: 'interest_inmarket',
          },
          {
            value: 'Financial Services/Insurance/Health Insurance',
            label: 'Health Insurance',
            category: 'interest_inmarket',
          },
          {
            value: 'Financial Services/Insurance/Home Insurance',
            label: 'Home Insurance',
            category: 'interest_inmarket',
          },
          {
            value: 'Financial Services/Insurance/Life Insurance',
            label: 'Life Insurance',
            category: 'interest_inmarket',
          },
          {
            value: 'Financial Services/Insurance/Travel Insurance',
            label: 'Travel Insurance',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Financial Services/Investment Services',
        label: 'Investment Services',
        category: 'interest_inmarket',
      },
      {
        value: 'Financial Services/Tax Preparation Services & Software',
        label: 'Tax Preparation Services & Software',
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Food & Dining',
    label: 'Food & Dining',
    category: 'interest_affinity',
    children: [
      {
        value: 'Food & Dining/Coffee Shop Regulars',
        label: 'Coffee Shop Regulars',
        category: 'interest_affinity',
      },
      {
        value: 'Food & Dining/Cooking Enthusiasts',
        label: 'Cooking Enthusiasts',
        category: 'interest_affinity',
        children: [
          {
            value: 'Food & Dining/Cooking Enthusiasts/30 Minute Chefs',
            label: '30 Minute Chefs',
            category: 'interest_affinity',
          },
          {
            value: 'Food & Dining/Cooking Enthusiasts/Aspiring Chefs',
            label: 'Aspiring Chefs',
            category: 'interest_affinity',
          },
        ],
      },
      {
        value: 'Food & Dining/Fast Food Cravers',
        label: 'Fast Food Cravers',
        category: 'interest_affinity',
      },
      {
        value: 'Food & Dining/Foodies',
        label: 'Foodies',
        category: 'interest_affinity',
      },
      {
        value: 'Food & Dining/Frequently Dines Out',
        label: 'Frequently Dines Out',
        category: 'interest_affinity',
        children: [
          {
            value: 'Food & Dining/Frequently Dines Out/Diners by Meal',
            label: 'Diners by Meal',
            category: 'interest_affinity',
            children: [
              {
                value: 'Food & Dining/Frequently Dines Out/Diners by Meal/Frequently Eats Breakfast Out',
                label: 'Frequently Eats Breakfast Out',
                category: 'interest_affinity',
              },
              {
                value: 'Food & Dining/Frequently Dines Out/Diners by Meal/Frequently Eats Dinner Out',
                label: 'Frequently Eats Dinner Out',
                category: 'interest_affinity',
              },
              {
                value: 'Food & Dining/Frequently Dines Out/Diners by Meal/Frequently Eats Lunch Out',
                label: 'Frequently Eats Lunch Out',
                category: 'interest_affinity',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: 'Food & Drink',
    label: 'Food & Drink',
    category: 'interest_other',
    children: [
      {
        value: 'Food & Drink/Beverages',
        label: 'Beverages',
        category: 'interest_other',
        children: [
          {
            value: 'Food & Drink/Beverages/Alcohol-Free Beverages',
            label: 'Alcohol-Free Beverages',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Beverages/Bottled Water',
            label: 'Bottled Water',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Beverages/Coffee & Tea',
            label: 'Coffee & Tea',
            category: 'interest_other',
            children: [
              {
                value: 'Food & Drink/Beverages/Coffee & Tea/Coffee',
                label: 'Coffee',
                category: 'interest_other',
              },
              {
                value: 'Food & Drink/Beverages/Coffee & Tea/Tea',
                label: 'Tea',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Food & Drink/Beverages/Energy Drinks',
            label: 'Energy Drinks',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Beverages/Juice',
            label: 'Juice',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Beverages/Nutrition Drinks & Shakes',
            label: 'Nutrition Drinks & Shakes',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Beverages/Soft Drinks',
            label: 'Soft Drinks',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Beverages/Sports Drinks',
            label: 'Sports Drinks',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Food & Drink/Cooking & Recipes',
        label: 'Cooking & Recipes',
        category: 'interest_other',
        children: [
          {
            value: 'Food & Drink/Cooking & Recipes/BBQ & Grilling',
            label: 'BBQ & Grilling',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Cooking & Recipes/Cuisines',
            label: 'Cuisines',
            category: 'interest_other',
            children: [
              {
                value: 'Food & Drink/Cooking & Recipes/Cuisines/East Asian Cuisine',
                label: 'East Asian Cuisine',
                category: 'interest_other',
                children: [
                  {
                    value: 'Food & Drink/Cooking & Recipes/Cuisines/East Asian Cuisine/Chinese Cuisine',
                    label: 'Chinese Cuisine',
                    category: 'interest_other',
                  },
                  {
                    value: 'Food & Drink/Cooking & Recipes/Cuisines/East Asian Cuisine/Japanese Cuisine',
                    label: 'Japanese Cuisine',
                    category: 'interest_other',
                  },
                  {
                    value: 'Food & Drink/Cooking & Recipes/Cuisines/East Asian Cuisine/Korean Cuisine',
                    label: 'Korean Cuisine',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Food & Drink/Cooking & Recipes/Cuisines/Eastern European Cuisine',
                label: 'Eastern European Cuisine',
                category: 'interest_other',
              },
              {
                value: 'Food & Drink/Cooking & Recipes/Cuisines/French Cuisine',
                label: 'French Cuisine',
                category: 'interest_other',
              },
              {
                value: 'Food & Drink/Cooking & Recipes/Cuisines/German Cuisine',
                label: 'German Cuisine',
                category: 'interest_other',
              },
              {
                value: 'Food & Drink/Cooking & Recipes/Cuisines/Latin American Cuisine',
                label: 'Latin American Cuisine',
                category: 'interest_other',
                children: [
                  {
                    value: 'Food & Drink/Cooking & Recipes/Cuisines/Latin American Cuisine/Caribbean Cuisine',
                    label: 'Caribbean Cuisine',
                    category: 'interest_other',
                  },
                  {
                    value: 'Food & Drink/Cooking & Recipes/Cuisines/Latin American Cuisine/Mexican Cuisine',
                    label: 'Mexican Cuisine',
                    category: 'interest_other',
                  },
                  {
                    value: 'Food & Drink/Cooking & Recipes/Cuisines/Latin American Cuisine/South American Cuisine',
                    label: 'South American Cuisine',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Food & Drink/Cooking & Recipes/Cuisines/Mediterranean Cuisine',
                label: 'Mediterranean Cuisine',
                category: 'interest_other',
                children: [
                  {
                    value: 'Food & Drink/Cooking & Recipes/Cuisines/Mediterranean Cuisine/Greek Cuisine',
                    label: 'Greek Cuisine',
                    category: 'interest_other',
                  },
                  {
                    value: 'Food & Drink/Cooking & Recipes/Cuisines/Mediterranean Cuisine/Italian Cuisine',
                    label: 'Italian Cuisine',
                    category: 'interest_other',
                  },
                  {
                    value: 'Food & Drink/Cooking & Recipes/Cuisines/Mediterranean Cuisine/Spanish Cuisine',
                    label: 'Spanish Cuisine',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Food & Drink/Cooking & Recipes/Cuisines/Middle Eastern Cuisine',
                label: 'Middle Eastern Cuisine',
                category: 'interest_other',
              },
              {
                value: 'Food & Drink/Cooking & Recipes/Cuisines/North American Cuisine',
                label: 'North American Cuisine',
                category: 'interest_other',
              },
              {
                value: 'Food & Drink/Cooking & Recipes/Cuisines/South Asian Cuisine',
                label: 'South Asian Cuisine',
                category: 'interest_other',
                children: [
                  {
                    value: 'Food & Drink/Cooking & Recipes/Cuisines/South Asian Cuisine/Indian Cuisine',
                    label: 'Indian Cuisine',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Food & Drink/Cooking & Recipes/Cuisines/Southeast Asian Cuisine',
                label: 'Southeast Asian Cuisine',
                category: 'interest_other',
                children: [
                  {
                    value: 'Food & Drink/Cooking & Recipes/Cuisines/Southeast Asian Cuisine/Thai Cuisine',
                    label: 'Thai Cuisine',
                    category: 'interest_other',
                  },
                  {
                    value: 'Food & Drink/Cooking & Recipes/Cuisines/Southeast Asian Cuisine/Vietnamese Cuisine',
                    label: 'Vietnamese Cuisine',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Food & Drink/Cooking & Recipes/Cuisines/Vegetarian Cuisine',
                label: 'Vegetarian Cuisine',
                category: 'interest_other',
                children: [
                  {
                    value: 'Food & Drink/Cooking & Recipes/Cuisines/Vegetarian Cuisine/Vegan Cuisine',
                    label: 'Vegan Cuisine',
                    category: 'interest_other',
                  },
                ],
              },
            ],
          },
          {
            value: 'Food & Drink/Cooking & Recipes/Culinary Training',
            label: 'Culinary Training',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Cooking & Recipes/Desserts',
            label: 'Desserts',
            category: 'interest_other',
            children: [
              {
                value: 'Food & Drink/Cooking & Recipes/Desserts/Ice Cream & Frozen Desserts',
                label: 'Ice Cream & Frozen Desserts',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Food & Drink/Cooking & Recipes/Healthy Eating',
            label: 'Healthy Eating',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Cooking & Recipes/Salads',
            label: 'Salads',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Cooking & Recipes/Soups & Stews',
            label: 'Soups & Stews',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Food & Drink/Food',
        label: 'Food',
        category: 'interest_other',
        children: [
          {
            value: 'Food & Drink/Food/Baked Goods',
            label: 'Baked Goods',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food/Breakfast Foods',
            label: 'Breakfast Foods',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food/Candy & Sweets',
            label: 'Candy & Sweets',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food/Condiments & Dressings',
            label: 'Condiments & Dressings',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food/Cooking Fats & Oils',
            label: 'Cooking Fats & Oils',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food/Dairy & Egg Substitutes',
            label: 'Dairy & Egg Substitutes',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food/Dairy & Eggs',
            label: 'Dairy & Eggs',
            category: 'interest_other',
            children: [
              {
                value: 'Food & Drink/Food/Dairy & Eggs/Cheese',
                label: 'Cheese',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Food & Drink/Food/Fruits & Vegetables',
            label: 'Fruits & Vegetables',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food/Gourmet & Specialty Foods',
            label: 'Gourmet & Specialty Foods',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food/Grains & Pasta',
            label: 'Grains & Pasta',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food/Herbs & Spices',
            label: 'Herbs & Spices',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food/Jams, Jellies & Preserves',
            label: 'Jams, Jellies & Preserves',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food/Meat & Seafood',
            label: 'Meat & Seafood',
            category: 'interest_other',
            children: [
              {
                value: 'Food & Drink/Food/Meat & Seafood/Beef',
                label: 'Beef',
                category: 'interest_other',
              },
              {
                value: 'Food & Drink/Food/Meat & Seafood/Fish & Seafood',
                label: 'Fish & Seafood',
                category: 'interest_other',
              },
              {
                value: 'Food & Drink/Food/Meat & Seafood/Pork',
                label: 'Pork',
                category: 'interest_other',
              },
              {
                value: 'Food & Drink/Food/Meat & Seafood/Poultry',
                label: 'Poultry',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Food & Drink/Food/Meat & Seafood Substitutes',
            label: 'Meat & Seafood Substitutes',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food/Organic & Natural Foods',
            label: 'Organic & Natural Foods',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food/Snack Foods',
            label: 'Snack Foods',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Food & Drink/Food & Grocery Delivery',
        label: 'Food & Grocery Delivery',
        category: 'interest_other',
        children: [
          {
            value: 'Food & Drink/Food & Grocery Delivery/Grocery Delivery Services',
            label: 'Grocery Delivery Services',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food & Grocery Delivery/Meal Kits',
            label: 'Meal Kits',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food & Grocery Delivery/Restaurant Delivery Services',
            label: 'Restaurant Delivery Services',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Food & Drink/Food & Grocery Retailers',
        label: 'Food & Grocery Retailers',
        category: 'interest_other',
        children: [
          {
            value: 'Food & Drink/Food & Grocery Retailers/Bakeries',
            label: 'Bakeries',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food & Grocery Retailers/Butchers',
            label: 'Butchers',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food & Grocery Retailers/Convenience Stores',
            label: 'Convenience Stores',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Food & Grocery Retailers/Delicatessens',
            label: 'Delicatessens',
            category: 'interest_other',
          },
          {
            value: "Food & Drink/Food & Grocery Retailers/Farmers' Markets",
            label: "Farmers' Markets",
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Food & Drink/Restaurants',
        label: 'Restaurants',
        category: 'interest_other',
        children: [
          {
            value: 'Food & Drink/Restaurants/Catering',
            label: 'Catering',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Restaurants/Fast Food',
            label: 'Fast Food',
            category: 'interest_other',
            children: [
              {
                value: 'Food & Drink/Restaurants/Fast Food/Burgers',
                label: 'Burgers',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Food & Drink/Restaurants/Fine Dining',
            label: 'Fine Dining',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Restaurants/Pizzerias',
            label: 'Pizzerias',
            category: 'interest_other',
          },
          {
            value: 'Food & Drink/Restaurants/Restaurant Reviews & Reservations',
            label: 'Restaurant Reviews & Reservations',
            category: 'interest_other',
          },
        ],
      },
    ],
  },
  {
    value: 'Games',
    label: 'Games',
    category: 'interest_other',
    children: [
      {
        value: 'Games/Arcade & Coin-Op Games',
        label: 'Arcade & Coin-Op Games',
        category: 'interest_other',
      },
      {
        value: 'Games/Board Games',
        label: 'Board Games',
        category: 'interest_other',
        children: [
          {
            value: 'Games/Board Games/Chess & Abstract Strategy Games',
            label: 'Chess & Abstract Strategy Games',
            category: 'interest_other',
          },
          {
            value: 'Games/Board Games/Miniatures & Wargaming',
            label: 'Miniatures & Wargaming',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Games/Card Games',
        label: 'Card Games',
        category: 'interest_other',
        children: [
          {
            value: 'Games/Card Games/Collectible Card Games',
            label: 'Collectible Card Games',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Games/Computer & Video Games',
        label: 'Computer & Video Games',
        category: 'interest_other',
        children: [
          {
            value: 'Games/Computer & Video Games/Action & Platform Games',
            label: 'Action & Platform Games',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Adventure Games',
            label: 'Adventure Games',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Browser Games',
            label: 'Browser Games',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Casual Games',
            label: 'Casual Games',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Competitive Video Gaming',
            label: 'Competitive Video Gaming',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Driving & Racing Games',
            label: 'Driving & Racing Games',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Fighting Games',
            label: 'Fighting Games',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Gaming Reference & Reviews',
            label: 'Gaming Reference & Reviews',
            category: 'interest_other',
            children: [
              {
                value: 'Games/Computer & Video Games/Gaming Reference & Reviews/Video Game Cheats & Hints',
                label: 'Video Game Cheats & Hints',
                category: 'interest_other',
              },
              {
                value: 'Games/Computer & Video Games/Gaming Reference & Reviews/Video Game Playthroughs',
                label: 'Video Game Playthroughs',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Games/Computer & Video Games/Massively Multiplayer Games',
            label: 'Massively Multiplayer Games',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Music & Dance Games',
            label: 'Music & Dance Games',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Sandbox Games',
            label: 'Sandbox Games',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Shooter Games',
            label: 'Shooter Games',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Simulation Games',
            label: 'Simulation Games',
            category: 'interest_other',
            children: [
              {
                value: 'Games/Computer & Video Games/Simulation Games/Business & Tycoon Games',
                label: 'Business & Tycoon Games',
                category: 'interest_other',
              },
              {
                value: 'Games/Computer & Video Games/Simulation Games/City Building Games',
                label: 'City Building Games',
                category: 'interest_other',
              },
              {
                value: 'Games/Computer & Video Games/Simulation Games/Life Simulation Games',
                label: 'Life Simulation Games',
                category: 'interest_other',
              },
              {
                value: 'Games/Computer & Video Games/Simulation Games/Vehicle Simulators',
                label: 'Vehicle Simulators',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Games/Computer & Video Games/Sports Games',
            label: 'Sports Games',
            category: 'interest_other',
            children: [
              {
                value: 'Games/Computer & Video Games/Sports Games/Sports Management Games',
                label: 'Sports Management Games',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Games/Computer & Video Games/Strategy Games',
            label: 'Strategy Games',
            category: 'interest_other',
            children: [
              {
                value: 'Games/Computer & Video Games/Strategy Games/MOBA Games',
                label: 'MOBA Games',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Games/Computer & Video Games/Video Game Development',
            label: 'Video Game Development',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Video Game Emulation',
            label: 'Video Game Emulation',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Video Game Mods & Add-Ons',
            label: 'Video Game Mods & Add-Ons',
            category: 'interest_other',
          },
          {
            value: 'Games/Computer & Video Games/Video Game Retailers',
            label: 'Video Game Retailers',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Games/Dice Games',
        label: 'Dice Games',
        category: 'interest_other',
      },
      {
        value: 'Games/Educational Games',
        label: 'Educational Games',
        category: 'interest_other',
      },
      {
        value: 'Games/Family-Oriented Games & Activities',
        label: 'Family-Oriented Games & Activities',
        category: 'interest_other',
        children: [
          {
            value: 'Games/Family-Oriented Games & Activities/Drawing & Coloring',
            label: 'Drawing & Coloring',
            category: 'interest_other',
          },
          {
            value: 'Games/Family-Oriented Games & Activities/Dress-Up & Fashion Games',
            label: 'Dress-Up & Fashion Games',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Games/Party Games',
        label: 'Party Games',
        category: 'interest_other',
      },
      {
        value: 'Games/Puzzles & Brainteasers',
        label: 'Puzzles & Brainteasers',
        category: 'interest_other',
      },
      {
        value: 'Games/Roleplaying Games',
        label: 'Roleplaying Games',
        category: 'interest_other',
      },
      {
        value: 'Games/Table Games',
        label: 'Table Games',
        category: 'interest_other',
        children: [
          {
            value: 'Games/Table Games/Billiards',
            label: 'Billiards',
            category: 'interest_other',
          },
          {
            value: 'Games/Table Games/Table Tennis',
            label: 'Table Tennis',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Games/Tile Games',
        label: 'Tile Games',
        category: 'interest_other',
      },
      {
        value: 'Games/Word Games',
        label: 'Word Games',
        category: 'interest_other',
      },
    ],
  },
  {
    value: 'Gifts & Occasions',
    label: 'Gifts & Occasions',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Gifts & Occasions/Flowers',
        label: 'Flowers',
        category: 'interest_inmarket',
      },
      {
        value: 'Gifts & Occasions/Gift Baskets',
        label: 'Gift Baskets',
        category: 'interest_inmarket',
      },
      {
        value: 'Gifts & Occasions/Holiday Items & Decorations',
        label: 'Holiday Items & Decorations',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Gifts & Occasions/Holiday Items & Decorations/Christmas Items & Decor',
            label: 'Christmas Items & Decor',
            category: 'interest_inmarket',
          },
          {
            value: 'Gifts & Occasions/Holiday Items & Decorations/Halloween Items & Decor',
            label: 'Halloween Items & Decor',
            category: 'interest_inmarket',
          },
          {
            value: "Gifts & Occasions/Holiday Items & Decorations/Valentine's Day Items & Decor",
            label: "Valentine's Day Items & Decor",
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Gifts & Occasions/Party Supplies & Planning',
        label: 'Party Supplies & Planning',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Gifts & Occasions/Party Supplies & Planning/Event Planning Services',
            label: 'Event Planning Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Gifts & Occasions/Party Supplies & Planning/Party Supplies',
            label: 'Party Supplies',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Gifts & Occasions/Personalized Gifts',
        label: 'Personalized Gifts',
        category: 'interest_inmarket',
      },
      {
        value: 'Gifts & Occasions/Photo & Video Services',
        label: 'Photo & Video Services',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Gifts & Occasions/Photo & Video Services/Event Photographers & Studios',
            label: 'Event Photographers & Studios',
            category: 'interest_inmarket',
          },
          {
            value: 'Gifts & Occasions/Photo & Video Services/Photo Printing Services',
            label: 'Photo Printing Services',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Gifts & Occasions/Wedding Planning',
        label: 'Wedding Planning',
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Hobbies & Leisure',
    label: 'Hobbies & Leisure',
    category: 'interest_other',
    children: [
      {
        value: 'Hobbies & Leisure/Clubs & Organizations',
        label: 'Clubs & Organizations',
        category: 'interest_other',
        children: [
          {
            value: 'Hobbies & Leisure/Clubs & Organizations/Fraternal Orders & Service Clubs',
            label: 'Fraternal Orders & Service Clubs',
            category: 'interest_other',
          },
          {
            value: 'Hobbies & Leisure/Clubs & Organizations/Youth Organizations & Resources',
            label: 'Youth Organizations & Resources',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Hobbies & Leisure/Crafts',
        label: 'Crafts',
        category: 'interest_other',
        children: [
          {
            value: 'Hobbies & Leisure/Crafts/Art & Craft Supplies',
            label: 'Art & Craft Supplies',
            category: 'interest_other',
          },
          {
            value: 'Hobbies & Leisure/Crafts/Ceramics & Pottery',
            label: 'Ceramics & Pottery',
            category: 'interest_other',
          },
          {
            value: 'Hobbies & Leisure/Crafts/Fiber & Textile Arts',
            label: 'Fiber & Textile Arts',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Hobbies & Leisure/Merit Prizes & Contests',
        label: 'Merit Prizes & Contests',
        category: 'interest_other',
      },
      {
        value: 'Hobbies & Leisure/Outdoors',
        label: 'Outdoors',
        category: 'interest_other',
        children: [
          {
            value: 'Hobbies & Leisure/Outdoors/Fishing',
            label: 'Fishing',
            category: 'interest_other',
          },
          {
            value: 'Hobbies & Leisure/Outdoors/Hiking & Camping',
            label: 'Hiking & Camping',
            category: 'interest_other',
          },
          {
            value: 'Hobbies & Leisure/Outdoors/Hunting & Shooting',
            label: 'Hunting & Shooting',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Hobbies & Leisure/Paintball',
        label: 'Paintball',
        category: 'interest_other',
      },
      {
        value: 'Hobbies & Leisure/Radio Control & Modeling',
        label: 'Radio Control & Modeling',
        category: 'interest_other',
        children: [
          {
            value: 'Hobbies & Leisure/Radio Control & Modeling/Model Trains & Railroads',
            label: 'Model Trains & Railroads',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Hobbies & Leisure/Recreational Aviation',
        label: 'Recreational Aviation',
        category: 'interest_other',
      },
      {
        value: 'Hobbies & Leisure/Special Occasions',
        label: 'Special Occasions',
        category: 'interest_other',
        children: [
          {
            value: 'Hobbies & Leisure/Special Occasions/Anniversaries',
            label: 'Anniversaries',
            category: 'interest_other',
          },
          {
            value: 'Hobbies & Leisure/Special Occasions/Holidays & Seasonal Events',
            label: 'Holidays & Seasonal Events',
            category: 'interest_other',
            children: [
              {
                value: 'Hobbies & Leisure/Special Occasions/Holidays & Seasonal Events/Birthdays & Name Days',
                label: 'Birthdays & Name Days',
                category: 'interest_other',
              },
              {
                value: 'Hobbies & Leisure/Special Occasions/Holidays & Seasonal Events/Carnival & Mardi Gras',
                label: 'Carnival & Mardi Gras',
                category: 'interest_other',
              },
              {
                value: 'Hobbies & Leisure/Special Occasions/Holidays & Seasonal Events/Halloween & October 31st',
                label: 'Halloween & October 31st',
                category: 'interest_other',
              },
              {
                value: 'Hobbies & Leisure/Special Occasions/Holidays & Seasonal Events/New Year',
                label: 'New Year',
                category: 'interest_other',
              },
              {
                value: 'Hobbies & Leisure/Special Occasions/Holidays & Seasonal Events/Thanksgiving',
                label: 'Thanksgiving',
                category: 'interest_other',
              },
              {
                value: "Hobbies & Leisure/Special Occasions/Holidays & Seasonal Events/Valentine's Day",
                label: "Valentine's Day",
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Hobbies & Leisure/Special Occasions/Weddings',
            label: 'Weddings',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Hobbies & Leisure/Sweepstakes & Promotional Giveaways',
        label: 'Sweepstakes & Promotional Giveaways',
        category: 'interest_other',
      },
      {
        value: 'Hobbies & Leisure/Water Activities',
        label: 'Water Activities',
        category: 'interest_other',
        children: [
          {
            value: 'Hobbies & Leisure/Water Activities/Boating',
            label: 'Boating',
            category: 'interest_other',
          },
          {
            value: 'Hobbies & Leisure/Water Activities/Diving & Underwater Activities',
            label: 'Diving & Underwater Activities',
            category: 'interest_other',
          },
          {
            value: 'Hobbies & Leisure/Water Activities/Surf & Swim',
            label: 'Surf & Swim',
            category: 'interest_other',
          },
        ],
      },
    ],
  },
  {
    value: 'Home & Garden',
    label: 'Home & Garden',
    category: 'interest_other',
    children: [
      {
        value: 'Home & Garden/Bed & Bath',
        label: 'Bed & Bath',
        category: 'interest_other',
        children: [
          {
            value: 'Home & Garden/Bed & Bath/Bathroom',
            label: 'Bathroom',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Bed & Bath/Bedroom',
            label: 'Bedroom',
            category: 'interest_other',
            children: [
              {
                value: 'Home & Garden/Bed & Bath/Bedroom/Bedding & Bed Linens',
                label: 'Bedding & Bed Linens',
                category: 'interest_other',
              },
              {
                value: 'Home & Garden/Bed & Bath/Bedroom/Beds & Headboards',
                label: 'Beds & Headboards',
                category: 'interest_other',
              },
              {
                value: 'Home & Garden/Bed & Bath/Bedroom/Mattresses',
                label: 'Mattresses',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Home & Garden/Do-It-Yourselfers',
        label: 'Do-It-Yourselfers',
        category: 'interest_affinity',
      },
      {
        value: 'Home & Garden/Domestic Services',
        label: 'Domestic Services',
        category: 'interest_other',
        children: [
          {
            value: 'Home & Garden/Domestic Services/Cleaning Services',
            label: 'Cleaning Services',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Home & Garden/HVAC & Climate Control',
        label: 'HVAC & Climate Control',
        category: 'interest_other',
        children: [
          {
            value: 'Home & Garden/HVAC & Climate Control/Air Conditioners',
            label: 'Air Conditioners',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/HVAC & Climate Control/Air Filters & Purifiers',
            label: 'Air Filters & Purifiers',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/HVAC & Climate Control/Fireplaces & Stoves',
            label: 'Fireplaces & Stoves',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/HVAC & Climate Control/Heaters',
            label: 'Heaters',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/HVAC & Climate Control/Household Fans',
            label: 'Household Fans',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Home & Garden/Home & Garden Services',
        label: 'Home & Garden Services',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Home & Garden/Home & Garden Services/Architectural Services',
            label: 'Architectural Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/Carpet Installation',
            label: 'Carpet Installation',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/Door & Window Installation',
            label: 'Door & Window Installation',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/Electrician Services',
            label: 'Electrician Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/Flooring Services',
            label: 'Flooring Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/General Contracting & Remodeling Services',
            label: 'General Contracting & Remodeling Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/Home Cleaning Services',
            label: 'Home Cleaning Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/Home Inspection Services',
            label: 'Home Inspection Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/Interior Design & Decorating Services',
            label: 'Interior Design & Decorating Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/Landscape Design',
            label: 'Landscape Design',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/Locksmith Services',
            label: 'Locksmith Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/Painting Services',
            label: 'Painting Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/Pest Control Services',
            label: 'Pest Control Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/Plumbing Services',
            label: 'Plumbing Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home & Garden Services/Roofing Services',
            label: 'Roofing Services',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Home & Garden/Home & Interior Decor',
        label: 'Home & Interior Decor',
        category: 'interest_other',
      },
      {
        value: 'Home & Garden/Home Appliances',
        label: 'Home Appliances',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Home & Garden/Home Appliances/Cooking Ranges & Stoves',
            label: 'Cooking Ranges & Stoves',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Appliances/Dishwashers',
            label: 'Dishwashers',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Appliances/Microwaves',
            label: 'Microwaves',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Appliances/Refrigerators',
            label: 'Refrigerators',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Appliances/Small Appliances',
            label: 'Small Appliances',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Home & Garden/Home Appliances/Small Appliances/Coffee & Espresso Makers',
                label: 'Coffee & Espresso Makers',
                category: 'interest_inmarket',
              },
              {
                value: 'Home & Garden/Home Appliances/Small Appliances/Juicers & Blenders',
                label: 'Juicers & Blenders',
                category: 'interest_inmarket',
              },
              {
                value: 'Home & Garden/Home Appliances/Small Appliances/Mixers',
                label: 'Mixers',
                category: 'interest_inmarket',
              },
            ],
          },
          {
            value: 'Home & Garden/Home Appliances/Vacuums',
            label: 'Vacuums',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Appliances/Vacuums & Floor Care',
            label: 'Vacuums & Floor Care',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Appliances/Washers & Dryers',
            label: 'Washers & Dryers',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Appliances/Water Filters & Purifiers',
            label: 'Water Filters & Purifiers',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Home & Garden/Home Appliances',
        label: 'Home Appliances',
        category: 'interest_other',
      },
      {
        value: 'Home & Garden/Home Decor',
        label: 'Home Decor',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Home & Garden/Home Decor/Bedding',
            label: 'Bedding',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Decor/Curtains & Window Treatments',
            label: 'Curtains & Window Treatments',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Decor/Fireplaces',
            label: 'Fireplaces',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Decor/Lights & Fixtures',
            label: 'Lights & Fixtures',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Decor/Rugs & Carpets',
            label: 'Rugs & Carpets',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Home & Garden/Home Decor Enthusiasts',
        label: 'Home Decor Enthusiasts',
        category: 'interest_affinity',
      },
      {
        value: 'Home & Garden/Home Furnishings',
        label: 'Home Furnishings',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Home & Garden/Home Furnishings/Bedroom',
            label: 'Bedroom',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Home & Garden/Home Furnishings/Bedroom/Beds & Bed Frames',
                label: 'Beds & Bed Frames',
                category: 'interest_inmarket',
              },
              {
                value: 'Home & Garden/Home Furnishings/Bedroom/Mattresses',
                label: 'Mattresses',
                category: 'interest_inmarket',
              },
            ],
          },
          {
            value: 'Home & Garden/Home Furnishings/Clocks',
            label: 'Clocks',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Furnishings/Countertops',
            label: 'Countertops',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Furnishings/Curtains & Window Treatments',
            label: 'Curtains & Window Treatments',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Furnishings/Home Office',
            label: 'Home Office',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Furnishings/Home Storage & Shelving',
            label: 'Home Storage & Shelving',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Furnishings/Kitchen & Dining Furniture',
            label: 'Kitchen & Dining Furniture',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Furnishings/Kitchen & Dining Room',
            label: 'Kitchen & Dining Room',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Furnishings/Lamps & Lighting',
            label: 'Lamps & Lighting',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Furnishings/Living Room',
            label: 'Living Room',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Furnishings/Living Room Furniture',
            label: 'Living Room Furniture',
            category: 'interest_other',
            children: [
              {
                value: 'Home & Garden/Home Furnishings/Living Room Furniture/Coffee Tables & End Tables',
                label: 'Coffee Tables & End Tables',
                category: 'interest_other',
              },
              {
                value: 'Home & Garden/Home Furnishings/Living Room Furniture/Sofas & Armchairs',
                label: 'Sofas & Armchairs',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Home & Garden/Home Furnishings/Nursery',
            label: 'Nursery',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Furnishings/Outdoor Furniture',
            label: 'Outdoor Furniture',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Furnishings/Rugs & Carpets',
            label: 'Rugs & Carpets',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Home & Garden/Home Furnishings',
        label: 'Home Furnishings',
        category: 'interest_other',
      },
      {
        value: 'Home & Garden/Home Improvement',
        label: 'Home Improvement',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Home & Garden/Home Improvement/Construction & Power Tools',
            label: 'Construction & Power Tools',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Improvement/Doors & Windows',
            label: 'Doors & Windows',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Improvement/Flooring',
            label: 'Flooring',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Improvement/Flooring',
            label: 'Flooring',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Improvement/House Painting & Finishing',
            label: 'House Painting & Finishing',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Improvement/Kitchen & Bathroom Cabinets',
            label: 'Kitchen & Bathroom Cabinets',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Improvement/Kitchen & Bathroom Counters',
            label: 'Kitchen & Bathroom Counters',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Improvement/Locks & Locksmiths',
            label: 'Locks & Locksmiths',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Improvement/Paint',
            label: 'Paint',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Improvement/Plumbing',
            label: 'Plumbing',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Improvement/Plumbing Fixtures',
            label: 'Plumbing Fixtures',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Home Improvement/Roofing',
            label: 'Roofing',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Home Improvement/Tools',
            label: 'Tools',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Home & Garden/Home Improvement',
        label: 'Home Improvement',
        category: 'interest_other',
      },
      {
        value: 'Home & Garden/Home Safety & Security',
        label: 'Home Safety & Security',
        category: 'interest_other',
        children: [
          {
            value: 'Home & Garden/Home Safety & Security/Home Alarm & Security Systems',
            label: 'Home Alarm & Security Systems',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Home & Garden/Home Security',
        label: 'Home Security',
        category: 'interest_inmarket',
      },
      {
        value: 'Home & Garden/Home Storage & Shelving',
        label: 'Home Storage & Shelving',
        category: 'interest_other',
        children: [
          {
            value: 'Home & Garden/Home Storage & Shelving/Cabinetry',
            label: 'Cabinetry',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Home & Garden/Home Swimming Pools, Saunas & Spas',
        label: 'Home Swimming Pools, Saunas & Spas',
        category: 'interest_other',
      },
      {
        value: 'Home & Garden/Household Supplies',
        label: 'Household Supplies',
        category: 'interest_other',
        children: [
          {
            value: 'Home & Garden/Household Supplies/Household Batteries',
            label: 'Household Batteries',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Household Supplies/Household Cleaning Supplies',
            label: 'Household Cleaning Supplies',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Home & Garden/Kitchen & Dining',
        label: 'Kitchen & Dining',
        category: 'interest_other',
        children: [
          {
            value: 'Home & Garden/Kitchen & Dining/Cookware & Diningware',
            label: 'Cookware & Diningware',
            category: 'interest_other',
            children: [
              {
                value: 'Home & Garden/Kitchen & Dining/Cookware & Diningware/Cookware',
                label: 'Cookware',
                category: 'interest_other',
                children: [
                  {
                    value:
                      'Home & Garden/Kitchen & Dining/Cookware & Diningware/Cookware/Cutlery & Cutting Accessories',
                    label: 'Cutlery & Cutting Accessories',
                    category: 'interest_other',
                  },
                  {
                    value: 'Home & Garden/Kitchen & Dining/Cookware & Diningware/Cookware/Kitchen Utensils & Gadgets',
                    label: 'Kitchen Utensils & Gadgets',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Home & Garden/Kitchen & Dining/Cookware & Diningware/Diningware',
                label: 'Diningware',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Home & Garden/Kitchen & Dining/Major Kitchen Appliances',
            label: 'Major Kitchen Appliances',
            category: 'interest_other',
            children: [
              {
                value: 'Home & Garden/Kitchen & Dining/Major Kitchen Appliances/Dishwashers',
                label: 'Dishwashers',
                category: 'interest_other',
              },
              {
                value: 'Home & Garden/Kitchen & Dining/Major Kitchen Appliances/Kitchen Hoods & Vents',
                label: 'Kitchen Hoods & Vents',
                category: 'interest_other',
              },
              {
                value: 'Home & Garden/Kitchen & Dining/Major Kitchen Appliances/Microwaves',
                label: 'Microwaves',
                category: 'interest_other',
              },
              {
                value: 'Home & Garden/Kitchen & Dining/Major Kitchen Appliances/Ranges, Cooktops & Ovens',
                label: 'Ranges, Cooktops & Ovens',
                category: 'interest_other',
              },
              {
                value: 'Home & Garden/Kitchen & Dining/Major Kitchen Appliances/Refrigerators & Freezers',
                label: 'Refrigerators & Freezers',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Home & Garden/Kitchen & Dining/Small Kitchen Appliances',
            label: 'Small Kitchen Appliances',
            category: 'interest_other',
            children: [
              {
                value: 'Home & Garden/Kitchen & Dining/Small Kitchen Appliances/Blenders & Juicers',
                label: 'Blenders & Juicers',
                category: 'interest_other',
              },
              {
                value: 'Home & Garden/Kitchen & Dining/Small Kitchen Appliances/Bread Makers',
                label: 'Bread Makers',
                category: 'interest_other',
              },
              {
                value: 'Home & Garden/Kitchen & Dining/Small Kitchen Appliances/Coffee & Espresso Makers',
                label: 'Coffee & Espresso Makers',
                category: 'interest_other',
              },
              {
                value: 'Home & Garden/Kitchen & Dining/Small Kitchen Appliances/Food Mixers',
                label: 'Food Mixers',
                category: 'interest_other',
              },
              {
                value: 'Home & Garden/Kitchen & Dining/Small Kitchen Appliances/Food Processors',
                label: 'Food Processors',
                category: 'interest_other',
              },
              {
                value: 'Home & Garden/Kitchen & Dining/Small Kitchen Appliances/Toasters & Toaster Ovens',
                label: 'Toasters & Toaster Ovens',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Home & Garden/Laundry',
        label: 'Laundry',
        category: 'interest_other',
        children: [
          {
            value: 'Home & Garden/Laundry/Washers & Dryers',
            label: 'Washers & Dryers',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Home & Garden/Nursery & Playroom',
        label: 'Nursery & Playroom',
        category: 'interest_other',
      },
      {
        value: 'Home & Garden/Outdoor Items',
        label: 'Outdoor Items',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Home & Garden/Outdoor Items/BBQs & Grills',
            label: 'BBQs & Grills',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Outdoor Items/Garden & Outdoor Furniture',
            label: 'Garden & Outdoor Furniture',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Outdoor Items/Lawn Mowers',
            label: 'Lawn Mowers',
            category: 'interest_inmarket',
          },
          {
            value: 'Home & Garden/Outdoor Items/Pools & Spas',
            label: 'Pools & Spas',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Home & Garden/Patio, Lawn & Garden',
        label: 'Patio, Lawn & Garden',
        category: 'interest_other',
        children: [
          {
            value: 'Home & Garden/Patio, Lawn & Garden/Barbecues & Grills',
            label: 'Barbecues & Grills',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Patio, Lawn & Garden/Garden Structures',
            label: 'Garden Structures',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Patio, Lawn & Garden/Gardening',
            label: 'Gardening',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Patio, Lawn & Garden/Landscape Design',
            label: 'Landscape Design',
            category: 'interest_other',
          },
          {
            value: 'Home & Garden/Patio, Lawn & Garden/Yard Maintenance',
            label: 'Yard Maintenance',
            category: 'interest_other',
            children: [
              {
                value: 'Home & Garden/Patio, Lawn & Garden/Yard Maintenance/Lawn Mowers',
                label: 'Lawn Mowers',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Home & Garden/Pest Control',
        label: 'Pest Control',
        category: 'interest_other',
      },
    ],
  },
  {
    value: 'Internet & Telecom',
    label: 'Internet & Telecom',
    category: 'interest_other',
    children: [
      {
        value: 'Internet & Telecom/Communications Equipment',
        label: 'Communications Equipment',
        category: 'interest_other',
        children: [
          {
            value: 'Internet & Telecom/Communications Equipment/Radio Equipment',
            label: 'Radio Equipment',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Internet & Telecom/Email & Messaging',
        label: 'Email & Messaging',
        category: 'interest_other',
        children: [
          {
            value: 'Internet & Telecom/Email & Messaging/Electronic Spam',
            label: 'Electronic Spam',
            category: 'interest_other',
          },
          {
            value: 'Internet & Telecom/Email & Messaging/Email',
            label: 'Email',
            category: 'interest_other',
          },
          {
            value: 'Internet & Telecom/Email & Messaging/Text & Instant Messaging',
            label: 'Text & Instant Messaging',
            category: 'interest_other',
          },
          {
            value: 'Internet & Telecom/Email & Messaging/Voice & Video Chat',
            label: 'Voice & Video Chat',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Internet & Telecom/Mobile & Wireless',
        label: 'Mobile & Wireless',
        category: 'interest_other',
        children: [
          {
            value: 'Internet & Telecom/Mobile & Wireless/Mobile & Wireless Accessories',
            label: 'Mobile & Wireless Accessories',
            category: 'interest_other',
            children: [
              {
                value: 'Internet & Telecom/Mobile & Wireless/Mobile & Wireless Accessories/Bluetooth Accessories',
                label: 'Bluetooth Accessories',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
            label: 'Mobile Apps & Add-Ons',
            category: 'interest_other',
            children: [
              {
                value: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons/Android Apps',
                label: 'Android Apps',
                category: 'interest_other',
              },
              {
                value: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons/Ringtones & Mobile Themes',
                label: 'Ringtones & Mobile Themes',
                category: 'interest_other',
              },
              {
                value: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons/iOS Apps',
                label: 'iOS Apps',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
            label: 'Mobile Phones',
            category: 'interest_other',
            children: [
              {
                value: 'Internet & Telecom/Mobile & Wireless/Mobile Phones/Mobile Phone Repair & Services',
                label: 'Mobile Phone Repair & Services',
                category: 'interest_other',
              },
              {
                value: 'Internet & Telecom/Mobile & Wireless/Mobile Phones/Smart Phones',
                label: 'Smart Phones',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Internet & Telecom/Search Engines',
        label: 'Search Engines',
        category: 'interest_other',
        children: [
          {
            value: 'Internet & Telecom/Search Engines/People Search',
            label: 'People Search',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Internet & Telecom/Service Providers',
        label: 'Service Providers',
        category: 'interest_other',
        children: [
          {
            value: 'Internet & Telecom/Service Providers/Cable & Satellite Providers',
            label: 'Cable & Satellite Providers',
            category: 'interest_other',
          },
          {
            value: 'Internet & Telecom/Service Providers/ISPs',
            label: 'ISPs',
            category: 'interest_other',
          },
          {
            value: 'Internet & Telecom/Service Providers/Internet Cafes',
            label: 'Internet Cafes',
            category: 'interest_other',
          },
          {
            value: 'Internet & Telecom/Service Providers/Phone Service Providers',
            label: 'Phone Service Providers',
            category: 'interest_other',
            children: [
              {
                value: 'Internet & Telecom/Service Providers/Phone Service Providers/Calling Cards',
                label: 'Calling Cards',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Internet & Telecom/Teleconferencing',
        label: 'Teleconferencing',
        category: 'interest_other',
      },
      {
        value: 'Internet & Telecom/Web Apps & Online Tools',
        label: 'Web Apps & Online Tools',
        category: 'interest_other',
      },
      {
        value: 'Internet & Telecom/Web Portals',
        label: 'Web Portals',
        category: 'interest_other',
      },
      {
        value: 'Internet & Telecom/Web Services',
        label: 'Web Services',
        category: 'interest_other',
        children: [
          {
            value: 'Internet & Telecom/Web Services/Affiliate Programs',
            label: 'Affiliate Programs',
            category: 'interest_other',
          },
          {
            value: 'Internet & Telecom/Web Services/Cloud Storage',
            label: 'Cloud Storage',
            category: 'interest_other',
          },
          {
            value: 'Internet & Telecom/Web Services/Search Engine Optimization & Marketing',
            label: 'Search Engine Optimization & Marketing',
            category: 'interest_other',
          },
          {
            value: 'Internet & Telecom/Web Services/Web Design & Development',
            label: 'Web Design & Development',
            category: 'interest_other',
          },
          {
            value: 'Internet & Telecom/Web Services/Web Hosting & Domain Registration',
            label: 'Web Hosting & Domain Registration',
            category: 'interest_other',
            children: [
              {
                value: 'Internet & Telecom/Web Services/Web Hosting & Domain Registration/Domain Registration',
                label: 'Domain Registration',
                category: 'interest_other',
              },
              {
                value: 'Internet & Telecom/Web Services/Web Hosting & Domain Registration/Web Hosting',
                label: 'Web Hosting',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Internet & Telecom/Web Services/Web Stats & Analytics',
            label: 'Web Stats & Analytics',
            category: 'interest_other',
          },
        ],
      },
    ],
  },
  {
    value: 'Jobs & Education',
    label: 'Jobs & Education',
    category: 'interest_other',
    children: [
      {
        value: 'Jobs & Education/Education',
        label: 'Education',
        category: 'interest_other',
        children: [
          {
            value: 'Jobs & Education/Education/Academic Conferences & Publications',
            label: 'Academic Conferences & Publications',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Education/Alumni & Reunions',
            label: 'Alumni & Reunions',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Education/Colleges & Universities',
            label: 'Colleges & Universities',
            category: 'interest_other',
            children: [
              {
                value:
                  'Jobs & Education/Education/Colleges & Universities/Fraternities, Sororities & Student Societies',
                label: 'Fraternities, Sororities & Student Societies',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Jobs & Education/Education/Computer Education',
            label: 'Computer Education',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Education/Distance Learning',
            label: 'Distance Learning',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Education/Early Childhood Education',
            label: 'Early Childhood Education',
            category: 'interest_other',
            children: [
              {
                value: 'Jobs & Education/Education/Early Childhood Education/Preschool',
                label: 'Preschool',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Jobs & Education/Education/Health Education & Medical Training',
            label: 'Health Education & Medical Training',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Education/Homeschooling',
            label: 'Homeschooling',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Education/Open Online Courses',
            label: 'Open Online Courses',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Education/Primary & Secondary Schooling (K-12)',
            label: 'Primary & Secondary Schooling (K-12)',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Education/Private Tutoring Services',
            label: 'Private Tutoring Services',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Education/Special Education',
            label: 'Special Education',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Education/Standardized & Admissions Tests',
            label: 'Standardized & Admissions Tests',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Education/Study Abroad',
            label: 'Study Abroad',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Education/Teaching & Classroom Resources',
            label: 'Teaching & Classroom Resources',
            category: 'interest_other',
            children: [
              {
                value: 'Jobs & Education/Education/Teaching & Classroom Resources/Lesson Plans',
                label: 'Lesson Plans',
                category: 'interest_other',
              },
              {
                value:
                  'Jobs & Education/Education/Teaching & Classroom Resources/School Supplies & Classroom Equipment',
                label: 'School Supplies & Classroom Equipment',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Jobs & Education/Education/Training & Certification',
            label: 'Training & Certification',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Education/Vocational & Continuing Education',
            label: 'Vocational & Continuing Education',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Jobs & Education/Internships',
        label: 'Internships',
        category: 'interest_other',
      },
      {
        value: 'Jobs & Education/Jobs',
        label: 'Jobs',
        category: 'interest_other',
        children: [
          {
            value: 'Jobs & Education/Jobs/Career Resources & Planning',
            label: 'Career Resources & Planning',
            category: 'interest_other',
          },
          {
            value: 'Jobs & Education/Jobs/Job Listings',
            label: 'Job Listings',
            category: 'interest_other',
            children: [
              {
                value: 'Jobs & Education/Jobs/Job Listings/Accounting & Finance Jobs',
                label: 'Accounting & Finance Jobs',
                category: 'interest_other',
              },
              {
                value: 'Jobs & Education/Jobs/Job Listings/Clerical & Administrative Jobs',
                label: 'Clerical & Administrative Jobs',
                category: 'interest_other',
              },
              {
                value: 'Jobs & Education/Jobs/Job Listings/Education Jobs',
                label: 'Education Jobs',
                category: 'interest_other',
              },
              {
                value: 'Jobs & Education/Jobs/Job Listings/Executive & Management Jobs',
                label: 'Executive & Management Jobs',
                category: 'interest_other',
              },
              {
                value: 'Jobs & Education/Jobs/Job Listings/Government & Public Sector Jobs',
                label: 'Government & Public Sector Jobs',
                category: 'interest_other',
              },
              {
                value: 'Jobs & Education/Jobs/Job Listings/Health & Medical Jobs',
                label: 'Health & Medical Jobs',
                category: 'interest_other',
              },
              {
                value: 'Jobs & Education/Jobs/Job Listings/IT & Technical Jobs',
                label: 'IT & Technical Jobs',
                category: 'interest_other',
              },
              {
                value: 'Jobs & Education/Jobs/Job Listings/Retail Jobs',
                label: 'Retail Jobs',
                category: 'interest_other',
              },
              {
                value: 'Jobs & Education/Jobs/Job Listings/Sales & Marketing Jobs',
                label: 'Sales & Marketing Jobs',
                category: 'interest_other',
              },
              {
                value: 'Jobs & Education/Jobs/Job Listings/Temporary & Seasonal Jobs',
                label: 'Temporary & Seasonal Jobs',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Jobs & Education/Jobs/Resumes & Portfolios',
            label: 'Resumes & Portfolios',
            category: 'interest_other',
          },
        ],
      },
    ],
  },
  {
    value: 'Law & Government',
    label: 'Law & Government',
    category: 'interest_other',
    children: [
      {
        value: 'Law & Government/Government',
        label: 'Government',
        category: 'interest_other',
        children: [
          {
            value: 'Law & Government/Government/Courts & Judiciary',
            label: 'Courts & Judiciary',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Government/Embassies & Consulates',
            label: 'Embassies & Consulates',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Government/Executive Branch',
            label: 'Executive Branch',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Government/Government Agencies',
            label: 'Government Agencies',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Government/Government Contracting & Procurement',
            label: 'Government Contracting & Procurement',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Government/Intelligence & Counterterrorism',
            label: 'Intelligence & Counterterrorism',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Government/Legislative Branch',
            label: 'Legislative Branch',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Government/Lobbying',
            label: 'Lobbying',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Government/Multilateral Organizations',
            label: 'Multilateral Organizations',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Government/Public Finance',
            label: 'Public Finance',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Government/Royalty',
            label: 'Royalty',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Government/State & Local Government',
            label: 'State & Local Government',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Law & Government/Legal',
        label: 'Legal',
        category: 'interest_other',
        children: [
          {
            value: 'Law & Government/Legal/Business & Corporate Law',
            label: 'Business & Corporate Law',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Legal/Intellectual Property',
            label: 'Intellectual Property',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Legal/Labor & Employment Law',
            label: 'Labor & Employment Law',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Legal/Legal Education',
            label: 'Legal Education',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Legal/Legal Services',
            label: 'Legal Services',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Legal/Product Liability',
            label: 'Product Liability',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Legal/Real Estate Law',
            label: 'Real Estate Law',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Law & Government/Military',
        label: 'Military',
        category: 'interest_other',
        children: [
          {
            value: 'Law & Government/Military/Air Force',
            label: 'Air Force',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Military/Army',
            label: 'Army',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Military/Marines',
            label: 'Marines',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Military/Navy',
            label: 'Navy',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Military/Veterans',
            label: 'Veterans',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Law & Government/Public Safety',
        label: 'Public Safety',
        category: 'interest_other',
        children: [
          {
            value: 'Law & Government/Public Safety/Crime & Justice',
            label: 'Crime & Justice',
            category: 'interest_other',
            children: [
              {
                value: 'Law & Government/Public Safety/Crime & Justice/Corporate & Financial Crime',
                label: 'Corporate & Financial Crime',
                category: 'interest_other',
              },
              {
                value: 'Law & Government/Public Safety/Crime & Justice/Gangs & Organized Crime',
                label: 'Gangs & Organized Crime',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Law & Government/Public Safety/Emergency Services',
            label: 'Emergency Services',
            category: 'interest_other',
            children: [
              {
                value: 'Law & Government/Public Safety/Emergency Services/Emergency & Disaster Preparedness',
                label: 'Emergency & Disaster Preparedness',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Law & Government/Public Safety/Law Enforcement',
            label: 'Law Enforcement',
            category: 'interest_other',
          },
          {
            value: 'Law & Government/Public Safety/Public Health',
            label: 'Public Health',
            category: 'interest_other',
            children: [
              {
                value: 'Law & Government/Public Safety/Public Health/Health Policy',
                label: 'Health Policy',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Law & Government/Public Safety/Security Products & Services',
            label: 'Security Products & Services',
            category: 'interest_other',
          },
        ],
      },
    ],
  },
  {
    value: 'Lifestyles & Hobbies',
    label: 'Lifestyles & Hobbies',
    category: 'interest_affinity',
    children: [
      {
        value: 'Lifestyles & Hobbies/Art & Theater Aficionados',
        label: 'Art & Theater Aficionados',
        category: 'interest_affinity',
      },
      {
        value: 'Lifestyles & Hobbies/Business Professionals',
        label: 'Business Professionals',
        category: 'interest_affinity',
      },
      {
        value: 'Lifestyles & Hobbies/Family-Focused',
        label: 'Family-Focused',
        category: 'interest_affinity',
      },
      {
        value: 'Lifestyles & Hobbies/Fashionistas',
        label: 'Fashionistas',
        category: 'interest_affinity',
      },
      {
        value: 'Lifestyles & Hobbies/Frequently Attends Live Events',
        label: 'Frequently Attends Live Events',
        category: 'interest_affinity',
      },
      {
        value: 'Lifestyles & Hobbies/Green Living Enthusiasts',
        label: 'Green Living Enthusiasts',
        category: 'interest_affinity',
      },
      {
        value: 'Lifestyles & Hobbies/Nightlife Enthusiasts',
        label: 'Nightlife Enthusiasts',
        category: 'interest_affinity',
      },
      {
        value: 'Lifestyles & Hobbies/Outdoor Enthusiasts',
        label: 'Outdoor Enthusiasts',
        category: 'interest_affinity',
      },
      {
        value: 'Lifestyles & Hobbies/Pet Lovers',
        label: 'Pet Lovers',
        category: 'interest_affinity',
        children: [
          {
            value: 'Lifestyles & Hobbies/Pet Lovers/Cat Lovers',
            label: 'Cat Lovers',
            category: 'interest_affinity',
          },
          {
            value: 'Lifestyles & Hobbies/Pet Lovers/Dog Lovers',
            label: 'Dog Lovers',
            category: 'interest_affinity',
          },
        ],
      },
      {
        value: 'Lifestyles & Hobbies/Shutterbugs',
        label: 'Shutterbugs',
        category: 'interest_affinity',
      },
      {
        value: 'Lifestyles & Hobbies/Thrill Seekers',
        label: 'Thrill Seekers',
        category: 'interest_affinity',
      },
    ],
  },
  {
    value: 'Media & Entertainment',
    label: 'Media & Entertainment',
    category: 'interest_affinity',
    children: [
      {
        value: 'Media & Entertainment/Book Lovers',
        label: 'Book Lovers',
        category: 'interest_affinity',
      },
      {
        value: 'Media & Entertainment/Comics & Animation Fans',
        label: 'Comics & Animation Fans',
        category: 'interest_affinity',
      },
      {
        value: 'Media & Entertainment/Gamers',
        label: 'Gamers',
        category: 'interest_affinity',
        children: [
          {
            value: 'Media & Entertainment/Gamers/Action Game Fans',
            label: 'Action Game Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Gamers/Adventure & Strategy Game Fans',
            label: 'Adventure & Strategy Game Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Gamers/Casual & Social Gamers',
            label: 'Casual & Social Gamers',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Gamers/Driving & Racing Game Fans',
            label: 'Driving & Racing Game Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Gamers/Hardcore Gamers',
            label: 'Hardcore Gamers',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Gamers/Roleplaying Game Fans',
            label: 'Roleplaying Game Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Gamers/Shooter Game Fans',
            label: 'Shooter Game Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Gamers/Sports Game Fans',
            label: 'Sports Game Fans',
            category: 'interest_affinity',
          },
        ],
      },
      {
        value: 'Media & Entertainment/Light TV Viewers',
        label: 'Light TV Viewers',
        category: 'interest_affinity',
      },
      {
        value: 'Media & Entertainment/Movie Lovers',
        label: 'Movie Lovers',
        category: 'interest_affinity',
        children: [
          {
            value: 'Media & Entertainment/Movie Lovers/Action & Adventure Movie Fans',
            label: 'Action & Adventure Movie Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Movie Lovers/Comedy Movie Fans',
            label: 'Comedy Movie Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Movie Lovers/Family Movie Fans',
            label: 'Family Movie Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Movie Lovers/Fans of South Asian Film',
            label: 'Fans of South Asian Film',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Movie Lovers/Horror Movie Fans',
            label: 'Horror Movie Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Movie Lovers/Romance & Drama Movie Fans',
            label: 'Romance & Drama Movie Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Movie Lovers/Sci-Fi & Fantasy Movie Fans',
            label: 'Sci-Fi & Fantasy Movie Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Movie Lovers/South Asian Film Fans',
            label: 'South Asian Film Fans',
            category: 'interest_affinity',
          },
        ],
      },
      {
        value: 'Media & Entertainment/Music Lovers',
        label: 'Music Lovers',
        category: 'interest_affinity',
        children: [
          {
            value: 'Media & Entertainment/Music Lovers/Blues Fans',
            label: 'Blues Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Music Lovers/Classical Music Enthusiasts',
            label: 'Classical Music Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Music Lovers/Country Music Fans',
            label: 'Country Music Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Music Lovers/Electronic Dance Music Fans',
            label: 'Electronic Dance Music Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Music Lovers/Fans of Latin Music',
            label: 'Fans of Latin Music',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Music Lovers/Folk & Traditional Music Enthusiasts',
            label: 'Folk & Traditional Music Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Music Lovers/Indie & Alternative Rock Fans',
            label: 'Indie & Alternative Rock Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Music Lovers/Jazz Enthusiasts',
            label: 'Jazz Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Music Lovers/Metalheads',
            label: 'Metalheads',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Music Lovers/Pop Music Fans',
            label: 'Pop Music Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Music Lovers/Rap & Hip Hop Fans',
            label: 'Rap & Hip Hop Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Music Lovers/Rock Music Fans',
            label: 'Rock Music Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Music Lovers/Spanish-Language Music Fans',
            label: 'Spanish-Language Music Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/Music Lovers/World Music Fans',
            label: 'World Music Fans',
            category: 'interest_affinity',
          },
        ],
      },
      {
        value: 'Media & Entertainment/TV Lovers',
        label: 'TV Lovers',
        category: 'interest_affinity',
        children: [
          {
            value: 'Media & Entertainment/TV Lovers/Documentary & Nonfiction TV Fans',
            label: 'Documentary & Nonfiction TV Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/TV Lovers/Family Television Fans',
            label: 'Family Television Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/TV Lovers/Game, Reality & Talk Show Fans',
            label: 'Game, Reality & Talk Show Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/TV Lovers/Sci-Fi & Fantasy TV Fans',
            label: 'Sci-Fi & Fantasy TV Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/TV Lovers/TV Comedy Fans',
            label: 'TV Comedy Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Media & Entertainment/TV Lovers/TV Drama Fans',
            label: 'TV Drama Fans',
            category: 'interest_affinity',
          },
        ],
      },
    ],
  },
  {
    value: 'News',
    label: 'News',
    category: 'interest_other',
    children: [
      {
        value: 'News/Broadcast & Network News',
        label: 'Broadcast & Network News',
        category: 'interest_other',
      },
      {
        value: 'News/Business News',
        label: 'Business News',
        category: 'interest_other',
        children: [
          {
            value: 'News/Business News/Company News',
            label: 'Company News',
            category: 'interest_other',
            children: [
              {
                value: 'News/Business News/Company News/Company Earnings',
                label: 'Company Earnings',
                category: 'interest_other',
              },
              {
                value: 'News/Business News/Company News/Mergers & Acquisitions',
                label: 'Mergers & Acquisitions',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'News/Business News/Economy News',
            label: 'Economy News',
            category: 'interest_other',
          },
          {
            value: 'News/Business News/Financial Markets News',
            label: 'Financial Markets News',
            category: 'interest_other',
          },
          {
            value: 'News/Business News/Fiscal Policy News',
            label: 'Fiscal Policy News',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'News/Gossip & Tabloid News',
        label: 'Gossip & Tabloid News',
        category: 'interest_other',
        children: [
          {
            value: 'News/Gossip & Tabloid News/Scandals & Investigations',
            label: 'Scandals & Investigations',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'News/Health News',
        label: 'Health News',
        category: 'interest_other',
      },
      {
        value: 'News/Journalism & News Industry',
        label: 'Journalism & News Industry',
        category: 'interest_other',
      },
      {
        value: 'News/Local News',
        label: 'Local News',
        category: 'interest_other',
      },
      {
        value: 'News/Newspapers',
        label: 'Newspapers',
        category: 'interest_other',
      },
      {
        value: 'News/Politics',
        label: 'Politics',
        category: 'interest_other',
        children: [
          {
            value: 'News/Politics/Campaigns & Elections',
            label: 'Campaigns & Elections',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'News/Sports News',
        label: 'Sports News',
        category: 'interest_other',
      },
      {
        value: 'News/Technology News',
        label: 'Technology News',
        category: 'interest_other',
      },
      {
        value: 'News/Weather',
        label: 'Weather',
        category: 'interest_other',
      },
      {
        value: 'News/World News',
        label: 'World News',
        category: 'interest_other',
      },
    ],
  },
  {
    value: 'News & Politics',
    label: 'News & Politics',
    category: 'interest_affinity',
    children: [
      {
        value: 'News & Politics/Avid News Readers',
        label: 'Avid News Readers',
        category: 'interest_affinity',
        children: [
          {
            value: 'News & Politics/Avid News Readers/Avid Business News Readers',
            label: 'Avid Business News Readers',
            category: 'interest_affinity',
          },
          {
            value: 'News & Politics/Avid News Readers/Avid Local News Readers',
            label: 'Avid Local News Readers',
            category: 'interest_affinity',
          },
          {
            value: 'News & Politics/Avid News Readers/Avid Political News Readers',
            label: 'Avid Political News Readers',
            category: 'interest_affinity',
          },
          {
            value: 'News & Politics/Avid News Readers/Avid World News Readers',
            label: 'Avid World News Readers',
            category: 'interest_affinity',
          },
          {
            value: 'News & Politics/Avid News Readers/Entertainment News Enthusiasts',
            label: 'Entertainment News Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: "News & Politics/Avid News Readers/Men's Media Fans",
            label: "Men's Media Fans",
            category: 'interest_affinity',
          },
          {
            value: "News & Politics/Avid News Readers/Women's Media Fans",
            label: "Women's Media Fans",
            category: 'interest_affinity',
          },
        ],
      },
    ],
  },
  {
    value: 'Online Communities',
    label: 'Online Communities',
    category: 'interest_other',
    children: [
      {
        value: 'Online Communities/Blogging Resources & Services',
        label: 'Blogging Resources & Services',
        category: 'interest_other',
        children: [
          {
            value: 'Online Communities/Blogging Resources & Services/Microblogging',
            label: 'Microblogging',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Online Communities/Dating & Personals',
        label: 'Dating & Personals',
        category: 'interest_other',
        children: [
          {
            value: 'Online Communities/Dating & Personals/Personals',
            label: 'Personals',
            category: 'interest_other',
          },
          {
            value: 'Online Communities/Dating & Personals/Photo Rating Sites',
            label: 'Photo Rating Sites',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Online Communities/Feed Aggregation & Social Bookmarking',
        label: 'Feed Aggregation & Social Bookmarking',
        category: 'interest_other',
      },
      {
        value: 'Online Communities/File Sharing & Hosting',
        label: 'File Sharing & Hosting',
        category: 'interest_other',
      },
      {
        value: 'Online Communities/Forum & Chat Providers',
        label: 'Forum & Chat Providers',
        category: 'interest_other',
      },
      {
        value: 'Online Communities/Online Goodies',
        label: 'Online Goodies',
        category: 'interest_other',
        children: [
          {
            value: 'Online Communities/Online Goodies/Clip Art & Animated GIFs',
            label: 'Clip Art & Animated GIFs',
            category: 'interest_other',
          },
          {
            value: 'Online Communities/Online Goodies/Skins, Themes & Wallpapers',
            label: 'Skins, Themes & Wallpapers',
            category: 'interest_other',
          },
          {
            value: 'Online Communities/Online Goodies/Social Network Apps & Add-Ons',
            label: 'Social Network Apps & Add-Ons',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Online Communities/Online Journals & Personal Sites',
        label: 'Online Journals & Personal Sites',
        category: 'interest_other',
      },
      {
        value: 'Online Communities/Photo & Video Sharing',
        label: 'Photo & Video Sharing',
        category: 'interest_other',
        children: [
          {
            value: 'Online Communities/Photo & Video Sharing/Photo & Image Sharing',
            label: 'Photo & Image Sharing',
            category: 'interest_other',
          },
          {
            value: 'Online Communities/Photo & Video Sharing/Video Sharing',
            label: 'Video Sharing',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Online Communities/Social Networks',
        label: 'Social Networks',
        category: 'interest_other',
      },
      {
        value: 'Online Communities/Virtual Worlds',
        label: 'Virtual Worlds',
        category: 'interest_other',
      },
    ],
  },
  {
    value: 'People & Society',
    label: 'People & Society',
    category: 'interest_other',
    children: [
      {
        value: 'People & Society/Family & Relationships',
        label: 'Family & Relationships',
        category: 'interest_other',
        children: [
          {
            value: 'People & Society/Family & Relationships/Etiquette',
            label: 'Etiquette',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Family & Relationships/Family',
            label: 'Family',
            category: 'interest_other',
            children: [
              {
                value: 'People & Society/Family & Relationships/Family/Ancestry & Genealogy',
                label: 'Ancestry & Genealogy',
                category: 'interest_other',
              },
              {
                value: 'People & Society/Family & Relationships/Family/Baby & Pet Names',
                label: 'Baby & Pet Names',
                category: 'interest_other',
              },
              {
                value: 'People & Society/Family & Relationships/Family/Parenting',
                label: 'Parenting',
                category: 'interest_other',
                children: [
                  {
                    value: 'People & Society/Family & Relationships/Family/Parenting/Adoption',
                    label: 'Adoption',
                    category: 'interest_other',
                  },
                  {
                    value: 'People & Society/Family & Relationships/Family/Parenting/Babies & Toddlers',
                    label: 'Babies & Toddlers',
                    category: 'interest_other',
                    children: [
                      {
                        value:
                          'People & Society/Family & Relationships/Family/Parenting/Babies & Toddlers/Baby & Toddler Toys',
                        label: 'Baby & Toddler Toys',
                        category: 'interest_other',
                      },
                      {
                        value:
                          'People & Society/Family & Relationships/Family/Parenting/Babies & Toddlers/Baby Care & Hygiene',
                        label: 'Baby Care & Hygiene',
                        category: 'interest_other',
                      },
                      {
                        value:
                          'People & Society/Family & Relationships/Family/Parenting/Babies & Toddlers/Baby Feeding',
                        label: 'Baby Feeding',
                        category: 'interest_other',
                        children: [
                          {
                            value:
                              'People & Society/Family & Relationships/Family/Parenting/Babies & Toddlers/Baby Feeding/Baby Food',
                            label: 'Baby Food',
                            category: 'interest_other',
                          },
                          {
                            value:
                              'People & Society/Family & Relationships/Family/Parenting/Babies & Toddlers/Baby Feeding/Baby Formula',
                            label: 'Baby Formula',
                            category: 'interest_other',
                          },
                        ],
                      },
                      {
                        value: 'People & Society/Family & Relationships/Family/Parenting/Babies & Toddlers/Baby Safety',
                        label: 'Baby Safety',
                        category: 'interest_other',
                      },
                      {
                        value:
                          'People & Society/Family & Relationships/Family/Parenting/Babies & Toddlers/Baby Strollers & Transport',
                        label: 'Baby Strollers & Transport',
                        category: 'interest_other',
                      },
                      {
                        value:
                          'People & Society/Family & Relationships/Family/Parenting/Babies & Toddlers/Child Car Seats',
                        label: 'Child Car Seats',
                        category: 'interest_other',
                      },
                      {
                        value:
                          'People & Society/Family & Relationships/Family/Parenting/Babies & Toddlers/Diapering & Potty Training',
                        label: 'Diapering & Potty Training',
                        category: 'interest_other',
                      },
                      {
                        value:
                          'People & Society/Family & Relationships/Family/Parenting/Babies & Toddlers/Nursery & Playroom',
                        label: 'Nursery & Playroom',
                        category: 'interest_other',
                      },
                    ],
                  },
                  {
                    value: 'People & Society/Family & Relationships/Family/Parenting/Child Care',
                    label: 'Child Care',
                    category: 'interest_other',
                  },
                  {
                    value: 'People & Society/Family & Relationships/Family/Parenting/Child Internet Safety',
                    label: 'Child Internet Safety',
                    category: 'interest_other',
                  },
                  {
                    value: 'People & Society/Family & Relationships/Family/Parenting/Youth Camps',
                    label: 'Youth Camps',
                    category: 'interest_other',
                  },
                ],
              },
            ],
          },
          {
            value: 'People & Society/Family & Relationships/Friendship',
            label: 'Friendship',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Family & Relationships/Marriage',
            label: 'Marriage',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Family & Relationships/Romance',
            label: 'Romance',
            category: 'interest_other',
          },
        ],
      },
      {
        value: "People & Society/Men's Interests",
        label: "Men's Interests",
        category: 'interest_other',
      },
      {
        value: 'People & Society/Mindfulness & Meditation',
        label: 'Mindfulness & Meditation',
        category: 'interest_other',
      },
      {
        value: 'People & Society/Social Issues & Advocacy',
        label: 'Social Issues & Advocacy',
        category: 'interest_other',
        children: [
          {
            value: 'People & Society/Social Issues & Advocacy/Charity & Philanthropy',
            label: 'Charity & Philanthropy',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Social Issues & Advocacy/Drug Laws & Policy',
            label: 'Drug Laws & Policy',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Social Issues & Advocacy/Ethics',
            label: 'Ethics',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Social Issues & Advocacy/Green Living & Environmental Issues',
            label: 'Green Living & Environmental Issues',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Social Issues & Advocacy/Immigration Policy & Border Issues',
            label: 'Immigration Policy & Border Issues',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Social Issues & Advocacy/Privacy Issues',
            label: 'Privacy Issues',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Social Issues & Advocacy/Work & Labor Issues',
            label: 'Work & Labor Issues',
            category: 'interest_other',
            children: [
              {
                value: 'People & Society/Social Issues & Advocacy/Work & Labor Issues/Unions & Labor Movement',
                label: 'Unions & Labor Movement',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'People & Society/Social Sciences',
        label: 'Social Sciences',
        category: 'interest_other',
        children: [
          {
            value: 'People & Society/Social Sciences/Anthropology',
            label: 'Anthropology',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Social Sciences/Archaeology',
            label: 'Archaeology',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Social Sciences/Communications & Media Studies',
            label: 'Communications & Media Studies',
            category: 'interest_other',
            children: [
              {
                value: 'People & Society/Social Sciences/Communications & Media Studies/Public Speaking',
                label: 'Public Speaking',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'People & Society/Social Sciences/Demographics',
            label: 'Demographics',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Social Sciences/Economics',
            label: 'Economics',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Social Sciences/Political Science',
            label: 'Political Science',
            category: 'interest_other',
            children: [
              {
                value: 'People & Society/Social Sciences/Political Science/International Relations',
                label: 'International Relations',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'People & Society/Social Sciences/Psychology',
            label: 'Psychology',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'People & Society/Subcultures & Niche Interests',
        label: 'Subcultures & Niche Interests',
        category: 'interest_other',
        children: [
          {
            value: 'People & Society/Subcultures & Niche Interests/Goth Subculture',
            label: 'Goth Subculture',
            category: 'interest_other',
          },
          {
            value: 'People & Society/Subcultures & Niche Interests/Science Fiction & Fantasy',
            label: 'Science Fiction & Fantasy',
            category: 'interest_other',
          },
        ],
      },
      {
        value: "People & Society/Women's Interests",
        label: "Women's Interests",
        category: 'interest_other',
      },
    ],
  },
  {
    value: 'Pets & Animals',
    label: 'Pets & Animals',
    category: 'interest_other',
    children: [
      {
        value: 'Pets & Animals/Animal Products & Services',
        label: 'Animal Products & Services',
        category: 'interest_other',
        children: [
          {
            value: 'Pets & Animals/Animal Products & Services/Animal Welfare',
            label: 'Animal Welfare',
            category: 'interest_other',
          },
          {
            value: 'Pets & Animals/Animal Products & Services/Pet Food & Pet Care Supplies',
            label: 'Pet Food & Pet Care Supplies',
            category: 'interest_other',
          },
          {
            value: 'Pets & Animals/Animal Products & Services/Veterinarians',
            label: 'Veterinarians',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Pets & Animals/Pets',
        label: 'Pets',
        category: 'interest_other',
        children: [
          {
            value: 'Pets & Animals/Pets/Birds',
            label: 'Birds',
            category: 'interest_other',
          },
          {
            value: 'Pets & Animals/Pets/Cats',
            label: 'Cats',
            category: 'interest_other',
          },
          {
            value: 'Pets & Animals/Pets/Dogs',
            label: 'Dogs',
            category: 'interest_other',
          },
          {
            value: 'Pets & Animals/Pets/Exotic Pets',
            label: 'Exotic Pets',
            category: 'interest_other',
          },
          {
            value: 'Pets & Animals/Pets/Fish & Aquaria',
            label: 'Fish & Aquaria',
            category: 'interest_other',
          },
          {
            value: 'Pets & Animals/Pets/Horses',
            label: 'Horses',
            category: 'interest_other',
          },
          {
            value: 'Pets & Animals/Pets/Rabbits & Rodents',
            label: 'Rabbits & Rodents',
            category: 'interest_other',
          },
          {
            value: 'Pets & Animals/Pets/Reptiles & Amphibians',
            label: 'Reptiles & Amphibians',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Pets & Animals/Wildlife',
        label: 'Wildlife',
        category: 'interest_other',
      },
    ],
  },
  {
    value: 'Real Estate',
    label: 'Real Estate',
    category: 'interest_other',
    children: [
      {
        value: 'Real Estate/Commercial Properties',
        label: 'Commercial Properties',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Real Estate/Commercial Properties/Commercial Properties (For Rent)',
            label: 'Commercial Properties (For Rent)',
            category: 'interest_inmarket',
          },
          {
            value: 'Real Estate/Commercial Properties/Commercial Properties (For Sale)',
            label: 'Commercial Properties (For Sale)',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Real Estate/Moving & Relocation',
        label: 'Moving & Relocation',
        category: 'interest_inmarket',
      },
      {
        value: 'Real Estate/Property Development',
        label: 'Property Development',
        category: 'interest_other',
      },
      {
        value: 'Real Estate/Real Estate Listings',
        label: 'Real Estate Listings',
        category: 'interest_other',
        children: [
          {
            value: 'Real Estate/Real Estate Listings/Commercial Properties',
            label: 'Commercial Properties',
            category: 'interest_other',
          },
          {
            value: 'Real Estate/Real Estate Listings/Lots & Land',
            label: 'Lots & Land',
            category: 'interest_other',
          },
          {
            value: 'Real Estate/Real Estate Listings/Residential Rentals',
            label: 'Residential Rentals',
            category: 'interest_other',
            children: [
              {
                value: 'Real Estate/Real Estate Listings/Residential Rentals/Furnished Rentals',
                label: 'Furnished Rentals',
                category: 'interest_other',
              },
              {
                value: 'Real Estate/Real Estate Listings/Residential Rentals/Roommates & Shares',
                label: 'Roommates & Shares',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Real Estate/Real Estate Listings/Residential Sales',
            label: 'Residential Sales',
            category: 'interest_other',
            children: [
              {
                value: 'Real Estate/Real Estate Listings/Residential Sales/Condos & Townhomes',
                label: 'Condos & Townhomes',
                category: 'interest_other',
              },
              {
                value: 'Real Estate/Real Estate Listings/Residential Sales/Mobile & Manufactured Homes',
                label: 'Mobile & Manufactured Homes',
                category: 'interest_other',
              },
              {
                value: 'Real Estate/Real Estate Listings/Residential Sales/New Homes & Custom Homes',
                label: 'New Homes & Custom Homes',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Real Estate/Real Estate Listings/Timeshares & Vacation Properties',
            label: 'Timeshares & Vacation Properties',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Real Estate/Real Estate Services',
        label: 'Real Estate Services',
        category: 'interest_other',
        children: [
          {
            value: 'Real Estate/Real Estate Services/Property Inspections & Appraisals',
            label: 'Property Inspections & Appraisals',
            category: 'interest_other',
          },
          {
            value: 'Real Estate/Real Estate Services/Property Management',
            label: 'Property Management',
            category: 'interest_other',
          },
          {
            value: 'Real Estate/Real Estate Services/Real Estate Agencies',
            label: 'Real Estate Agencies',
            category: 'interest_other',
          },
          {
            value: 'Real Estate/Real Estate Services/Real Estate Title & Escrow',
            label: 'Real Estate Title & Escrow',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Real Estate/Residential Properties',
        label: 'Residential Properties',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Real Estate/Residential Properties/Residential Properties (For Rent)',
            label: 'Residential Properties (For Rent)',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Real Estate/Residential Properties/Residential Properties (For Rent)/Apartments (For Rent)',
                label: 'Apartments (For Rent)',
                category: 'interest_inmarket',
              },
              {
                value: 'Real Estate/Residential Properties/Residential Properties (For Rent)/Houses (For Rent)',
                label: 'Houses (For Rent)',
                category: 'interest_inmarket',
              },
            ],
          },
          {
            value: 'Real Estate/Residential Properties/Residential Properties (For Sale)',
            label: 'Residential Properties (For Sale)',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Real Estate/Residential Properties/Residential Properties (For Sale)/Apartments (For Sale)',
                label: 'Apartments (For Sale)',
                category: 'interest_inmarket',
                children: [
                  {
                    value:
                      'Real Estate/Residential Properties/Residential Properties (For Sale)/Apartments (For Sale)/New Apartments (For Sale)',
                    label: 'New Apartments (For Sale)',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Real Estate/Residential Properties/Residential Properties (For Sale)/Apartments (For Sale)/Preowned Apartments (For Sale)',
                    label: 'Preowned Apartments (For Sale)',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Real Estate/Residential Properties/Residential Properties (For Sale)/Houses (For Sale)',
                label: 'Houses (For Sale)',
                category: 'interest_inmarket',
                children: [
                  {
                    value:
                      'Real Estate/Residential Properties/Residential Properties (For Sale)/Houses (For Sale)/New Houses (For Sale)',
                    label: 'New Houses (For Sale)',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Real Estate/Residential Properties/Residential Properties (For Sale)/Houses (For Sale)/Preowned Houses (For Sale)',
                    label: 'Preowned Houses (For Sale)',
                    category: 'interest_inmarket',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: 'Reference',
    label: 'Reference',
    category: 'interest_other',
    children: [
      {
        value: 'Reference/Directories & Listings',
        label: 'Directories & Listings',
        category: 'interest_other',
        children: [
          {
            value: 'Reference/Directories & Listings/Business & Personal Listings',
            label: 'Business & Personal Listings',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Reference/General Reference',
        label: 'General Reference',
        category: 'interest_other',
        children: [
          {
            value: 'Reference/General Reference/Biographies & Quotations',
            label: 'Biographies & Quotations',
            category: 'interest_other',
          },
          {
            value: 'Reference/General Reference/Calculators & Reference Tools',
            label: 'Calculators & Reference Tools',
            category: 'interest_other',
          },
          {
            value: 'Reference/General Reference/Dictionaries & Encyclopedias',
            label: 'Dictionaries & Encyclopedias',
            category: 'interest_other',
          },
          {
            value: 'Reference/General Reference/Educational Resources',
            label: 'Educational Resources',
            category: 'interest_other',
          },
          {
            value: 'Reference/General Reference/Forms Guides & Templates',
            label: 'Forms Guides & Templates',
            category: 'interest_other',
            children: [
              {
                value: 'Reference/General Reference/Forms Guides & Templates/Legal Forms',
                label: 'Legal Forms',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Reference/General Reference/How-To, DIY & Expert Content',
            label: 'How-To, DIY & Expert Content',
            category: 'interest_other',
          },
          {
            value: 'Reference/General Reference/Public Records',
            label: 'Public Records',
            category: 'interest_other',
          },
          {
            value: 'Reference/General Reference/Time & Calendars',
            label: 'Time & Calendars',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Reference/Geographic Reference',
        label: 'Geographic Reference',
        category: 'interest_other',
        children: [
          {
            value: 'Reference/Geographic Reference/City & Local Guides',
            label: 'City & Local Guides',
            category: 'interest_other',
          },
          {
            value: 'Reference/Geographic Reference/Maps',
            label: 'Maps',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Reference/Humanities',
        label: 'Humanities',
        category: 'interest_other',
        children: [
          {
            value: 'Reference/Humanities/History',
            label: 'History',
            category: 'interest_other',
            children: [
              {
                value: 'Reference/Humanities/History/Military History',
                label: 'Military History',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Reference/Humanities/Myth & Folklore',
            label: 'Myth & Folklore',
            category: 'interest_other',
          },
          {
            value: 'Reference/Humanities/Philosophy',
            label: 'Philosophy',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Reference/Language Resources',
        label: 'Language Resources',
        category: 'interest_other',
        children: [
          {
            value: 'Reference/Language Resources/Foreign Language Resources',
            label: 'Foreign Language Resources',
            category: 'interest_other',
            children: [
              {
                value: 'Reference/Language Resources/Foreign Language Resources/Foreign Language Study',
                label: 'Foreign Language Study',
                category: 'interest_other',
              },
              {
                value: 'Reference/Language Resources/Foreign Language Resources/Translation Tools & Resources',
                label: 'Translation Tools & Resources',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Reference/Libraries & Museums',
        label: 'Libraries & Museums',
        category: 'interest_other',
        children: [
          {
            value: 'Reference/Libraries & Museums/Libraries',
            label: 'Libraries',
            category: 'interest_other',
          },
          {
            value: 'Reference/Libraries & Museums/Museums',
            label: 'Museums',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Reference/Technical Reference',
        label: 'Technical Reference',
        category: 'interest_other',
      },
    ],
  },
  {
    value: 'Science',
    label: 'Science',
    category: 'interest_other',
    children: [
      {
        value: 'Science/Astronomy',
        label: 'Astronomy',
        category: 'interest_other',
      },
      {
        value: 'Science/Biological Sciences',
        label: 'Biological Sciences',
        category: 'interest_other',
        children: [
          {
            value: 'Science/Biological Sciences/Anatomy',
            label: 'Anatomy',
            category: 'interest_other',
          },
          {
            value: 'Science/Biological Sciences/Flora & Fauna',
            label: 'Flora & Fauna',
            category: 'interest_other',
            children: [
              {
                value: 'Science/Biological Sciences/Flora & Fauna/Insects & Entomology',
                label: 'Insects & Entomology',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Science/Biological Sciences/Genetics',
            label: 'Genetics',
            category: 'interest_other',
          },
          {
            value: 'Science/Biological Sciences/Neuroscience',
            label: 'Neuroscience',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Science/Chemistry',
        label: 'Chemistry',
        category: 'interest_other',
      },
      {
        value: 'Science/Computer Science',
        label: 'Computer Science',
        category: 'interest_other',
        children: [
          {
            value: 'Science/Computer Science/Machine Learning & Artificial Intelligence',
            label: 'Machine Learning & Artificial Intelligence',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Science/Earth Sciences',
        label: 'Earth Sciences',
        category: 'interest_other',
        children: [
          {
            value: 'Science/Earth Sciences/Atmospheric Science',
            label: 'Atmospheric Science',
            category: 'interest_other',
          },
          {
            value: 'Science/Earth Sciences/Geology',
            label: 'Geology',
            category: 'interest_other',
          },
          {
            value: 'Science/Earth Sciences/Paleontology',
            label: 'Paleontology',
            category: 'interest_other',
          },
          {
            value: 'Science/Earth Sciences/Water & Marine Sciences',
            label: 'Water & Marine Sciences',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Science/Ecology & Environment',
        label: 'Ecology & Environment',
        category: 'interest_other',
        children: [
          {
            value: 'Science/Ecology & Environment/Climate Change & Global Warming',
            label: 'Climate Change & Global Warming',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Science/Engineering & Technology',
        label: 'Engineering & Technology',
        category: 'interest_other',
        children: [
          {
            value: 'Science/Engineering & Technology/Augmented & Virtual Reality',
            label: 'Augmented & Virtual Reality',
            category: 'interest_other',
          },
          {
            value: 'Science/Engineering & Technology/Robotics',
            label: 'Robotics',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Science/Mathematics',
        label: 'Mathematics',
        category: 'interest_other',
        children: [
          {
            value: 'Science/Mathematics/Statistics',
            label: 'Statistics',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Science/Physics',
        label: 'Physics',
        category: 'interest_other',
      },
      {
        value: 'Science/Scientific Equipment',
        label: 'Scientific Equipment',
        category: 'interest_other',
      },
      {
        value: 'Science/Scientific Institutions',
        label: 'Scientific Institutions',
        category: 'interest_other',
      },
    ],
  },
  {
    value: 'Shoppers',
    label: 'Shoppers',
    category: 'interest_affinity',
    children: [
      {
        value: 'Shoppers/Bargain Hunters',
        label: 'Bargain Hunters',
        category: 'interest_affinity',
      },
      {
        value: 'Shoppers/Luxury Shoppers',
        label: 'Luxury Shoppers',
        category: 'interest_affinity',
      },
      {
        value: 'Shoppers/Shopaholics',
        label: 'Shopaholics',
        category: 'interest_affinity',
      },
      {
        value: 'Shoppers/Shoppers by Store Type',
        label: 'Shoppers by Store Type',
        category: 'interest_affinity',
        children: [
          {
            value: 'Shoppers/Shoppers by Store Type/Convenience Store Shoppers',
            label: 'Convenience Store Shoppers',
            category: 'interest_affinity',
          },
          {
            value: 'Shoppers/Shoppers by Store Type/Department Store Shoppers',
            label: 'Department Store Shoppers',
            category: 'interest_affinity',
          },
        ],
      },
      {
        value: 'Shoppers/Shopping Enthusiasts',
        label: 'Shopping Enthusiasts',
        category: 'interest_affinity',
      },
      {
        value: 'Shoppers/Value Shoppers',
        label: 'Value Shoppers',
        category: 'interest_affinity',
      },
    ],
  },
  {
    value: 'Shopping',
    label: 'Shopping',
    category: 'interest_other',
    children: [
      {
        value: 'Shopping/Antiques & Collectibles',
        label: 'Antiques & Collectibles',
        category: 'interest_other',
      },
      {
        value: 'Shopping/Apparel',
        label: 'Apparel',
        category: 'interest_other',
        children: [
          {
            value: 'Shopping/Apparel/Apparel Services',
            label: 'Apparel Services',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Apparel/Athletic Apparel',
            label: 'Athletic Apparel',
            category: 'interest_other',
            children: [
              {
                value: 'Shopping/Apparel/Athletic Apparel/Cycling Apparel',
                label: 'Cycling Apparel',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Shopping/Apparel/Casual Apparel',
            label: 'Casual Apparel',
            category: 'interest_other',
            children: [
              {
                value: 'Shopping/Apparel/Casual Apparel/Athleisure & Lounge Wear',
                label: 'Athleisure & Lounge Wear',
                category: 'interest_other',
              },
              {
                value: 'Shopping/Apparel/Casual Apparel/Denim Wear',
                label: 'Denim Wear',
                category: 'interest_other',
              },
              {
                value: 'Shopping/Apparel/Casual Apparel/T-Shirts',
                label: 'T-Shirts',
                category: 'interest_other',
              },
            ],
          },
          {
            value: "Shopping/Apparel/Children's Clothing",
            label: "Children's Clothing",
            category: 'interest_other',
          },
          {
            value: 'Shopping/Apparel/Clothing Accessories',
            label: 'Clothing Accessories',
            category: 'interest_other',
            children: [
              {
                value: 'Shopping/Apparel/Clothing Accessories/Face Masks',
                label: 'Face Masks',
                category: 'interest_other',
              },
              {
                value: 'Shopping/Apparel/Clothing Accessories/Gems & Jewelry',
                label: 'Gems & Jewelry',
                category: 'interest_other',
                children: [
                  {
                    value: 'Shopping/Apparel/Clothing Accessories/Gems & Jewelry/Necklaces',
                    label: 'Necklaces',
                    category: 'interest_other',
                  },
                  {
                    value: 'Shopping/Apparel/Clothing Accessories/Gems & Jewelry/Rings',
                    label: 'Rings',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Shopping/Apparel/Clothing Accessories/Handbags & Purses',
                label: 'Handbags & Purses',
                category: 'interest_other',
              },
              {
                value: 'Shopping/Apparel/Clothing Accessories/Socks & Hosiery',
                label: 'Socks & Hosiery',
                category: 'interest_other',
              },
              {
                value: 'Shopping/Apparel/Clothing Accessories/Watches',
                label: 'Watches',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Shopping/Apparel/Costumes',
            label: 'Costumes',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Apparel/Eyewear',
            label: 'Eyewear',
            category: 'interest_other',
            children: [
              {
                value: 'Shopping/Apparel/Eyewear/Eyeglasses & Contacts',
                label: 'Eyeglasses & Contacts',
                category: 'interest_other',
              },
              {
                value: 'Shopping/Apparel/Eyewear/Sunglasses',
                label: 'Sunglasses',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Shopping/Apparel/Footwear',
            label: 'Footwear',
            category: 'interest_other',
            children: [
              {
                value: 'Shopping/Apparel/Footwear/Athletic Shoes',
                label: 'Athletic Shoes',
                category: 'interest_other',
              },
              {
                value: 'Shopping/Apparel/Footwear/Boots',
                label: 'Boots',
                category: 'interest_other',
              },
              {
                value: 'Shopping/Apparel/Footwear/Casual Shoes',
                label: 'Casual Shoes',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Shopping/Apparel/Formal Wear',
            label: 'Formal Wear',
            category: 'interest_other',
            children: [
              {
                value: 'Shopping/Apparel/Formal Wear/Bridal Wear',
                label: 'Bridal Wear',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Shopping/Apparel/Headwear',
            label: 'Headwear',
            category: 'interest_other',
          },
          {
            value: "Shopping/Apparel/Men's Clothing",
            label: "Men's Clothing",
            category: 'interest_other',
          },
          {
            value: 'Shopping/Apparel/Outerwear',
            label: 'Outerwear',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Apparel/Pants & Shorts',
            label: 'Pants & Shorts',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Apparel/Shirts & Tops',
            label: 'Shirts & Tops',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Apparel/Sleepwear',
            label: 'Sleepwear',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Apparel/Suits & Business Attire',
            label: 'Suits & Business Attire',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Apparel/Swimwear',
            label: 'Swimwear',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Apparel/Undergarments',
            label: 'Undergarments',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Apparel/Uniforms & Workwear',
            label: 'Uniforms & Workwear',
            category: 'interest_other',
          },
          {
            value: "Shopping/Apparel/Women's Clothing",
            label: "Women's Clothing",
            category: 'interest_other',
            children: [
              {
                value: "Shopping/Apparel/Women's Clothing/Dresses",
                label: 'Dresses',
                category: 'interest_other',
              },
              {
                value: "Shopping/Apparel/Women's Clothing/Skirts",
                label: 'Skirts',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Shopping/Auctions',
        label: 'Auctions',
        category: 'interest_other',
      },
      {
        value: 'Shopping/Classifieds',
        label: 'Classifieds',
        category: 'interest_other',
      },
      {
        value: 'Shopping/Consumer Resources',
        label: 'Consumer Resources',
        category: 'interest_other',
        children: [
          {
            value: 'Shopping/Consumer Resources/Consumer Advocacy & Protection',
            label: 'Consumer Advocacy & Protection',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Consumer Resources/Coupons & Discount Offers',
            label: 'Coupons & Discount Offers',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Consumer Resources/Customer Services',
            label: 'Customer Services',
            category: 'interest_other',
            children: [
              {
                value: 'Shopping/Consumer Resources/Customer Services/Loyalty Cards & Programs',
                label: 'Loyalty Cards & Programs',
                category: 'interest_other',
              },
              {
                value: 'Shopping/Consumer Resources/Customer Services/Technical Support & Repair',
                label: 'Technical Support & Repair',
                category: 'interest_other',
              },
              {
                value: 'Shopping/Consumer Resources/Customer Services/Warranties & Service Contracts',
                label: 'Warranties & Service Contracts',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Shopping/Consumer Resources/Identity Theft Protection',
            label: 'Identity Theft Protection',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
            label: 'Product Reviews & Price Comparisons',
            category: 'interest_other',
            children: [
              {
                value: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons/Price Comparisons',
                label: 'Price Comparisons',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Shopping/Discount & Outlet Stores',
        label: 'Discount & Outlet Stores',
        category: 'interest_other',
      },
      {
        value: 'Shopping/Gifts & Special Event Items',
        label: 'Gifts & Special Event Items',
        category: 'interest_other',
        children: [
          {
            value: 'Shopping/Gifts & Special Event Items/Custom & Personalized Items',
            label: 'Custom & Personalized Items',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Gifts & Special Event Items/Flowers',
            label: 'Flowers',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Gifts & Special Event Items/Gifts',
            label: 'Gifts',
            category: 'interest_other',
            children: [
              {
                value: 'Shopping/Gifts & Special Event Items/Gifts/Gift Baskets',
                label: 'Gift Baskets',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Shopping/Gifts & Special Event Items/Greeting Cards',
            label: 'Greeting Cards',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Gifts & Special Event Items/Party & Holiday Supplies',
            label: 'Party & Holiday Supplies',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Shopping/Green & Eco-Friendly Shopping',
        label: 'Green & Eco-Friendly Shopping',
        category: 'interest_other',
      },
      {
        value: 'Shopping/Luxury Goods',
        label: 'Luxury Goods',
        category: 'interest_other',
      },
      {
        value: 'Shopping/Mass Merchants & Department Stores',
        label: 'Mass Merchants & Department Stores',
        category: 'interest_other',
      },
      {
        value: 'Shopping/Photo & Video Services',
        label: 'Photo & Video Services',
        category: 'interest_other',
        children: [
          {
            value: 'Shopping/Photo & Video Services/Event & Studio Photography',
            label: 'Event & Studio Photography',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Photo & Video Services/Photo Printing Services',
            label: 'Photo Printing Services',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Photo & Video Services/Stock Photography',
            label: 'Stock Photography',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Shopping/Shopping Portals',
        label: 'Shopping Portals',
        category: 'interest_other',
      },
      {
        value: 'Shopping/Swap Meets & Outdoor Markets',
        label: 'Swap Meets & Outdoor Markets',
        category: 'interest_other',
      },
      {
        value: 'Shopping/Toys',
        label: 'Toys',
        category: 'interest_other',
        children: [
          {
            value: 'Shopping/Toys/Action Figures',
            label: 'Action Figures',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Toys/Building Toys',
            label: 'Building Toys',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Toys/Die-cast & Toy Vehicles',
            label: 'Die-cast & Toy Vehicles',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Toys/Dolls & Accessories',
            label: 'Dolls & Accessories',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Toys/Educational Toys',
            label: 'Educational Toys',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Toys/Outdoor Toys & Play Equipment',
            label: 'Outdoor Toys & Play Equipment',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Toys/Puppets',
            label: 'Puppets',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Toys/Ride-On Toys & Wagons',
            label: 'Ride-On Toys & Wagons',
            category: 'interest_other',
          },
          {
            value: 'Shopping/Toys/Stuffed Toys',
            label: 'Stuffed Toys',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Shopping/Wholesalers & Liquidators',
        label: 'Wholesalers & Liquidators',
        category: 'interest_other',
      },
    ],
  },
  {
    value: 'Software',
    label: 'Software',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Software/Accounting Software',
        label: 'Accounting Software',
        category: 'interest_inmarket',
      },
      {
        value: 'Software/Antivirus & Security Software',
        label: 'Antivirus & Security Software',
        category: 'interest_inmarket',
      },
      {
        value: 'Software/Audio & Music Software',
        label: 'Audio & Music Software',
        category: 'interest_inmarket',
      },
      {
        value: 'Software/Business & Productivity Software',
        label: 'Business & Productivity Software',
        category: 'interest_inmarket',
      },
      {
        value: 'Software/Design Software',
        label: 'Design Software',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Software/Design Software/Drawing & Animation Software',
            label: 'Drawing & Animation Software',
            category: 'interest_inmarket',
          },
          {
            value: 'Software/Design Software/Photo Software',
            label: 'Photo Software',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Software/Video Editing & Production Software',
        label: 'Video Editing & Production Software',
        category: 'interest_inmarket',
      },
      {
        value: 'Software/Video Software',
        label: 'Video Software',
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Sports',
    label: 'Sports',
    category: 'interest_other',
    children: [
      {
        value: 'Sports/Animal Sports',
        label: 'Animal Sports',
        category: 'interest_other',
        children: [
          {
            value: 'Sports/Animal Sports/Equestrian',
            label: 'Equestrian',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Sports/College Sports',
        label: 'College Sports',
        category: 'interest_other',
      },
      {
        value: 'Sports/Combat Sports',
        label: 'Combat Sports',
        category: 'interest_other',
        children: [
          {
            value: 'Sports/Combat Sports/Boxing',
            label: 'Boxing',
            category: 'interest_other',
          },
          {
            value: 'Sports/Combat Sports/Fencing',
            label: 'Fencing',
            category: 'interest_other',
          },
          {
            value: 'Sports/Combat Sports/Martial Arts',
            label: 'Martial Arts',
            category: 'interest_other',
            children: [
              {
                value: 'Sports/Combat Sports/Martial Arts/Chinese Martial Arts',
                label: 'Chinese Martial Arts',
                category: 'interest_other',
                children: [
                  {
                    value: 'Sports/Combat Sports/Martial Arts/Chinese Martial Arts/Tai Chi & Internal Martial Arts',
                    label: 'Tai Chi & Internal Martial Arts',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Sports/Combat Sports/Martial Arts/Japanese Martial Arts',
                label: 'Japanese Martial Arts',
                category: 'interest_other',
                children: [
                  {
                    value: 'Sports/Combat Sports/Martial Arts/Japanese Martial Arts/Jujutsu',
                    label: 'Jujutsu',
                    category: 'interest_other',
                  },
                  {
                    value: 'Sports/Combat Sports/Martial Arts/Japanese Martial Arts/Karate',
                    label: 'Karate',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Sports/Combat Sports/Martial Arts/Kickboxing',
                label: 'Kickboxing',
                category: 'interest_other',
              },
              {
                value: 'Sports/Combat Sports/Martial Arts/Mixed Martial Arts',
                label: 'Mixed Martial Arts',
                category: 'interest_other',
                children: [
                  {
                    value: 'Sports/Combat Sports/Martial Arts/Mixed Martial Arts/Ultimate Fighting Championship (UFC)',
                    label: 'Ultimate Fighting Championship (UFC)',
                    category: 'interest_other',
                  },
                ],
              },
              {
                value: 'Sports/Combat Sports/Martial Arts/Taekwondo',
                label: 'Taekwondo',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Sports/Combat Sports/Wrestling',
            label: 'Wrestling',
            category: 'interest_other',
            children: [
              {
                value: 'Sports/Combat Sports/Wrestling/Amateur & Sport Wrestling',
                label: 'Amateur & Sport Wrestling',
                category: 'interest_other',
              },
              {
                value: 'Sports/Combat Sports/Wrestling/Professional Wrestling',
                label: 'Professional Wrestling',
                category: 'interest_other',
                children: [
                  {
                    value: 'Sports/Combat Sports/Wrestling/Professional Wrestling/World Wrestling Entertainment (WWE)',
                    label: 'World Wrestling Entertainment (WWE)',
                    category: 'interest_other',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        value: 'Sports/Extreme Sports',
        label: 'Extreme Sports',
        category: 'interest_other',
        children: [
          {
            value: 'Sports/Extreme Sports/Climbing & Mountaineering',
            label: 'Climbing & Mountaineering',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Sports/Fantasy Sports',
        label: 'Fantasy Sports',
        category: 'interest_other',
      },
      {
        value: 'Sports/Individual Sports',
        label: 'Individual Sports',
        category: 'interest_other',
        children: [
          {
            value: 'Sports/Individual Sports/Bowling',
            label: 'Bowling',
            category: 'interest_other',
          },
          {
            value: 'Sports/Individual Sports/Cycling',
            label: 'Cycling',
            category: 'interest_other',
          },
          {
            value: 'Sports/Individual Sports/Golf',
            label: 'Golf',
            category: 'interest_other',
          },
          {
            value: 'Sports/Individual Sports/Gymnastics',
            label: 'Gymnastics',
            category: 'interest_other',
          },
          {
            value: 'Sports/Individual Sports/Racquet Sports',
            label: 'Racquet Sports',
            category: 'interest_other',
            children: [
              {
                value: 'Sports/Individual Sports/Racquet Sports/Tennis',
                label: 'Tennis',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Sports/Individual Sports/Running & Walking',
            label: 'Running & Walking',
            category: 'interest_other',
            children: [
              {
                value: 'Sports/Individual Sports/Running & Walking/Distance Running',
                label: 'Distance Running',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Sports/Individual Sports/Skate Sports',
            label: 'Skate Sports',
            category: 'interest_other',
          },
          {
            value: 'Sports/Individual Sports/Track & Field',
            label: 'Track & Field',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Sports/International Sports Competitions',
        label: 'International Sports Competitions',
        category: 'interest_other',
        children: [
          {
            value: 'Sports/International Sports Competitions/Olympics',
            label: 'Olympics',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Sports/Motor Sports',
        label: 'Motor Sports',
        category: 'interest_other',
        children: [
          {
            value: 'Sports/Motor Sports/Auto Racing',
            label: 'Auto Racing',
            category: 'interest_other',
          },
          {
            value: 'Sports/Motor Sports/Motorcycle Racing',
            label: 'Motorcycle Racing',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Sports/Sport Scores & Statistics',
        label: 'Sport Scores & Statistics',
        category: 'interest_other',
      },
      {
        value: 'Sports/Sporting Goods',
        label: 'Sporting Goods',
        category: 'interest_other',
        children: [
          {
            value: 'Sports/Sporting Goods/American Football Equipment',
            label: 'American Football Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Baseball Equipment',
            label: 'Baseball Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Basketball Equipment',
            label: 'Basketball Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Bowling Equipment',
            label: 'Bowling Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Combat Sports Equipment',
            label: 'Combat Sports Equipment',
            category: 'interest_other',
            children: [
              {
                value: 'Sports/Sporting Goods/Combat Sports Equipment/Boxing Gloves & Gear',
                label: 'Boxing Gloves & Gear',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Sports/Sporting Goods/Cricket Equipment',
            label: 'Cricket Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Electric Skateboards & Scooters',
            label: 'Electric Skateboards & Scooters',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Equestrian Equipment & Tack',
            label: 'Equestrian Equipment & Tack',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Golf Equipment',
            label: 'Golf Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Gymnastics Equipment',
            label: 'Gymnastics Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Hockey Equipment',
            label: 'Hockey Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Ice Skating Equipment',
            label: 'Ice Skating Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Roller Skating & Rollerblading Equipment',
            label: 'Roller Skating & Rollerblading Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Skateboarding Equipment',
            label: 'Skateboarding Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Soccer Equipment',
            label: 'Soccer Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Sports Memorabilia',
            label: 'Sports Memorabilia',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Squash & Racquetball Equipment',
            label: 'Squash & Racquetball Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Table Tennis Equipment',
            label: 'Table Tennis Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Tennis Equipment',
            label: 'Tennis Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Volleyball Equipment',
            label: 'Volleyball Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Water Sports Equipment',
            label: 'Water Sports Equipment',
            category: 'interest_other',
          },
          {
            value: 'Sports/Sporting Goods/Winter Sports Equipment',
            label: 'Winter Sports Equipment',
            category: 'interest_other',
            children: [
              {
                value: 'Sports/Sporting Goods/Winter Sports Equipment/Skiing Equipment',
                label: 'Skiing Equipment',
                category: 'interest_other',
              },
              {
                value: 'Sports/Sporting Goods/Winter Sports Equipment/Snowboarding Gear',
                label: 'Snowboarding Gear',
                category: 'interest_other',
              },
            ],
          },
        ],
      },
      {
        value: 'Sports/Sports Coaching & Training',
        label: 'Sports Coaching & Training',
        category: 'interest_other',
      },
      {
        value: 'Sports/Sports Fan Gear & Apparel',
        label: 'Sports Fan Gear & Apparel',
        category: 'interest_other',
      },
      {
        value: 'Sports/Team Sports',
        label: 'Team Sports',
        category: 'interest_other',
        children: [
          {
            value: 'Sports/Team Sports/American Football',
            label: 'American Football',
            category: 'interest_other',
          },
          {
            value: 'Sports/Team Sports/Australian Football',
            label: 'Australian Football',
            category: 'interest_other',
          },
          {
            value: 'Sports/Team Sports/Baseball',
            label: 'Baseball',
            category: 'interest_other',
          },
          {
            value: 'Sports/Team Sports/Basketball',
            label: 'Basketball',
            category: 'interest_other',
          },
          {
            value: 'Sports/Team Sports/Cheerleading',
            label: 'Cheerleading',
            category: 'interest_other',
          },
          {
            value: 'Sports/Team Sports/Cricket',
            label: 'Cricket',
            category: 'interest_other',
          },
          {
            value: 'Sports/Team Sports/Handball',
            label: 'Handball',
            category: 'interest_other',
          },
          {
            value: 'Sports/Team Sports/Hockey',
            label: 'Hockey',
            category: 'interest_other',
          },
          {
            value: 'Sports/Team Sports/Rugby',
            label: 'Rugby',
            category: 'interest_other',
          },
          {
            value: 'Sports/Team Sports/Soccer',
            label: 'Soccer',
            category: 'interest_other',
          },
          {
            value: 'Sports/Team Sports/Volleyball',
            label: 'Volleyball',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Sports/Water Sports',
        label: 'Water Sports',
        category: 'interest_other',
        children: [
          {
            value: 'Sports/Water Sports/Surfing',
            label: 'Surfing',
            category: 'interest_other',
          },
          {
            value: 'Sports/Water Sports/Swimming',
            label: 'Swimming',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Sports/Winter Sports',
        label: 'Winter Sports',
        category: 'interest_other',
        children: [
          {
            value: 'Sports/Winter Sports/Ice Skating',
            label: 'Ice Skating',
            category: 'interest_other',
          },
          {
            value: 'Sports/Winter Sports/Skiing & Snowboarding',
            label: 'Skiing & Snowboarding',
            category: 'interest_other',
          },
        ],
      },
    ],
  },
  {
    value: 'Sports & Fitness',
    label: 'Sports & Fitness',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Sports & Fitness/Fitness Products & Services',
        label: 'Fitness Products & Services',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Sports & Fitness/Fitness Products & Services/Exercise Equipment',
            label: 'Exercise Equipment',
            category: 'interest_inmarket',
          },
          {
            value: 'Sports & Fitness/Fitness Products & Services/Fitness Classes & Personal Training Services',
            label: 'Fitness Classes & Personal Training Services',
            category: 'interest_inmarket',
          },
          {
            value: 'Sports & Fitness/Fitness Products & Services/Gyms & Athletic Clubs',
            label: 'Gyms & Athletic Clubs',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Sports & Fitness/Health & Fitness Buffs',
        label: 'Health & Fitness Buffs',
        category: 'interest_affinity',
      },
      {
        value: 'Sports & Fitness/Outdoor Recreational Equipment',
        label: 'Outdoor Recreational Equipment',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Sports & Fitness/Outdoor Recreational Equipment/Camping & Hiking Equipment',
            label: 'Camping & Hiking Equipment',
            category: 'interest_inmarket',
          },
          {
            value: 'Sports & Fitness/Outdoor Recreational Equipment/Fishing Equipment',
            label: 'Fishing Equipment',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Sports & Fitness/Sporting Goods',
        label: 'Sporting Goods',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Sports & Fitness/Sporting Goods/Golf Equipment',
            label: 'Golf Equipment',
            category: 'interest_inmarket',
          },
          {
            value: 'Sports & Fitness/Sporting Goods/Winter Sports Equipment & Accessories',
            label: 'Winter Sports Equipment & Accessories',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Sports & Fitness/Sports Fans',
        label: 'Sports Fans',
        category: 'interest_affinity',
        children: [
          {
            value: 'Sports & Fitness/Sports Fans/American Football Fans',
            label: 'American Football Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Australian Football Fans',
            label: 'Australian Football Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Baseball Fans',
            label: 'Baseball Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Basketball Fans',
            label: 'Basketball Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Boating & Sailing Enthusiasts',
            label: 'Boating & Sailing Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Cricket Enthusiasts',
            label: 'Cricket Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Cycling Enthusiasts',
            label: 'Cycling Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Fans of American Football',
            label: 'Fans of American Football',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Fans of Australian Football',
            label: 'Fans of Australian Football',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Fight & Wrestling Fans',
            label: 'Fight & Wrestling Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Golf Enthusiasts',
            label: 'Golf Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Hockey Fans',
            label: 'Hockey Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Motor Sports Enthusiasts',
            label: 'Motor Sports Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Racquetball Enthusiasts',
            label: 'Racquetball Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Rugby Enthusiasts',
            label: 'Rugby Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Running Enthusiasts',
            label: 'Running Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Skiing Enthusiasts',
            label: 'Skiing Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Soccer Fans',
            label: 'Soccer Fans',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Swimming Enthusiasts',
            label: 'Swimming Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Tennis Enthusiasts',
            label: 'Tennis Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Water Sports Enthusiasts',
            label: 'Water Sports Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Sports & Fitness/Sports Fans/Winter Sports Enthusiasts',
            label: 'Winter Sports Enthusiasts',
            category: 'interest_affinity',
          },
        ],
      },
    ],
  },
  {
    value: 'Technology',
    label: 'Technology',
    category: 'interest_affinity',
    children: [
      {
        value: 'Technology/Mobile Enthusiasts',
        label: 'Mobile Enthusiasts',
        category: 'interest_affinity',
      },
      {
        value: 'Technology/Social Media Enthusiasts',
        label: 'Social Media Enthusiasts',
        category: 'interest_affinity',
      },
      {
        value: 'Technology/Technophiles',
        label: 'Technophiles',
        category: 'interest_affinity',
      },
    ],
  },
  {
    value: 'Telecom',
    label: 'Telecom',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Telecom/Cable & Satellite TV Providers',
        label: 'Cable & Satellite TV Providers',
        category: 'interest_inmarket',
      },
      {
        value: 'Telecom/Internet Service Providers',
        label: 'Internet Service Providers',
        category: 'interest_inmarket',
      },
      {
        value: 'Telecom/Mobile Phone Service Providers',
        label: 'Mobile Phone Service Providers',
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Travel & Transportation',
    label: 'Travel & Transportation',
    category: 'interest_other',
    children: [
      {
        value: 'Travel & Transportation/Hotels & Accommodations',
        label: 'Hotels & Accommodations',
        category: 'interest_other',
        children: [
          {
            value: 'Travel & Transportation/Hotels & Accommodations/Vacation Rentals & Short-Term Stays',
            label: 'Vacation Rentals & Short-Term Stays',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Travel & Transportation/Luggage & Travel Accessories',
        label: 'Luggage & Travel Accessories',
        category: 'interest_other',
        children: [
          {
            value: 'Travel & Transportation/Luggage & Travel Accessories/Backpacks & Utility Bags',
            label: 'Backpacks & Utility Bags',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Travel & Transportation/Specialty Travel',
        label: 'Specialty Travel',
        category: 'interest_other',
        children: [
          {
            value: 'Travel & Transportation/Specialty Travel/Adventure Travel',
            label: 'Adventure Travel',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Specialty Travel/Business Travel',
            label: 'Business Travel',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Specialty Travel/Ecotourism',
            label: 'Ecotourism',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Specialty Travel/Honeymoons & Romantic Getaways',
            label: 'Honeymoons & Romantic Getaways',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Specialty Travel/Low Cost & Last Minute Travel',
            label: 'Low Cost & Last Minute Travel',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Travel & Transportation/Tourist Destinations',
        label: 'Tourist Destinations',
        category: 'interest_other',
        children: [
          {
            value: 'Travel & Transportation/Tourist Destinations/Beaches & Islands',
            label: 'Beaches & Islands',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Tourist Destinations/Historical Sites & Buildings',
            label: 'Historical Sites & Buildings',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Tourist Destinations/Lakes & Rivers',
            label: 'Lakes & Rivers',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Tourist Destinations/Mountain & Ski Resorts',
            label: 'Mountain & Ski Resorts',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Tourist Destinations/Regional Parks & Gardens',
            label: 'Regional Parks & Gardens',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Tourist Destinations/Theme Parks',
            label: 'Theme Parks',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Tourist Destinations/Vineyards & Wine Tourism',
            label: 'Vineyards & Wine Tourism',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Tourist Destinations/Zoos, Aquariums & Preserves',
            label: 'Zoos, Aquariums & Preserves',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Travel & Transportation/Transportation',
        label: 'Transportation',
        category: 'interest_other',
        children: [
          {
            value: 'Travel & Transportation/Transportation/Air Travel',
            label: 'Air Travel',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Transportation/Car Rentals',
            label: 'Car Rentals',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Transportation/Carpooling',
            label: 'Carpooling',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Transportation/Chartered Transportation Rentals',
            label: 'Chartered Transportation Rentals',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Transportation/Cruises & Charters',
            label: 'Cruises & Charters',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Transportation/Long Distance Bus & Rail',
            label: 'Long Distance Bus & Rail',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Transportation/Parking',
            label: 'Parking',
            category: 'interest_other',
            children: [
              {
                value: 'Travel & Transportation/Transportation/Parking/Airport Parking & Transportation',
                label: 'Airport Parking & Transportation',
                category: 'interest_other',
              },
            ],
          },
          {
            value: 'Travel & Transportation/Transportation/Scooter & Bike Rentals',
            label: 'Scooter & Bike Rentals',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Transportation/Taxi & Ride Hail Services',
            label: 'Taxi & Ride Hail Services',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Transportation/Traffic & Route Planners',
            label: 'Traffic & Route Planners',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Transportation/Urban Transit',
            label: 'Urban Transit',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Travel & Transportation/Travel Agencies & Services',
        label: 'Travel Agencies & Services',
        category: 'interest_other',
        children: [
          {
            value: 'Travel & Transportation/Travel Agencies & Services/Sightseeing Tours',
            label: 'Sightseeing Tours',
            category: 'interest_other',
          },
          {
            value: 'Travel & Transportation/Travel Agencies & Services/Vacation Offers',
            label: 'Vacation Offers',
            category: 'interest_other',
          },
        ],
      },
      {
        value: 'Travel & Transportation/Travel Guides & Travelogues',
        label: 'Travel Guides & Travelogues',
        category: 'interest_other',
      },
    ],
  },
  {
    value: 'Travel',
    label: 'Travel',
    category: 'interest_inmarket',
    children: [
      {
        value: 'Travel/Air Travel',
        label: 'Air Travel',
        category: 'interest_inmarket',
      },
      {
        value: 'Travel/Bus & Rail Travel',
        label: 'Bus & Rail Travel',
        category: 'interest_inmarket',
      },
      {
        value: 'Travel/Business Travelers',
        label: 'Business Travelers',
        category: 'interest_affinity',
      },
      {
        value: 'Travel/Car Rental',
        label: 'Car Rental',
        category: 'interest_inmarket',
      },
      {
        value: 'Travel/Cruises',
        label: 'Cruises',
        category: 'interest_inmarket',
      },
      {
        value: 'Travel/Hotels & Accommodations',
        label: 'Hotels & Accommodations',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Travel/Hotels & Accommodations/Vacation Rentals',
            label: 'Vacation Rentals',
            category: 'interest_inmarket',
          },
        ],
      },
      {
        value: 'Travel/Sightseeing Tours & Tourist Attractions',
        label: 'Sightseeing Tours & Tourist Attractions',
        category: 'interest_inmarket',
      },
      {
        value: 'Travel/Travel Buffs',
        label: 'Travel Buffs',
        category: 'interest_affinity',
        children: [
          {
            value: 'Travel/Travel Buffs/Beachbound Travelers',
            label: 'Beachbound Travelers',
            category: 'interest_affinity',
          },
          {
            value: 'Travel/Travel Buffs/Family Vacationers',
            label: 'Family Vacationers',
            category: 'interest_affinity',
          },
          {
            value: 'Travel/Travel Buffs/Luxury Travelers',
            label: 'Luxury Travelers',
            category: 'interest_affinity',
          },
          {
            value: 'Travel/Travel Buffs/Snowbound Travelers',
            label: 'Snowbound Travelers',
            category: 'interest_affinity',
          },
        ],
      },
      {
        value: 'Travel/Trips by Destination',
        label: 'Trips by Destination',
        category: 'interest_inmarket',
        children: [
          {
            value: 'Travel/Trips by Destination/Trips to Asia-Pacific',
            label: 'Trips to Asia-Pacific',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Australia',
                label: 'Trips to Australia',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Australia/Trips to Brisbane',
                    label: 'Trips to Brisbane',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Australia/Trips to Melbourne',
                    label: 'Trips to Melbourne',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Australia/Trips to Perth',
                    label: 'Trips to Perth',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Australia/Trips to Sydney',
                    label: 'Trips to Sydney',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to China',
                label: 'Trips to China',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to China/Trips to Beijing',
                    label: 'Trips to Beijing',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to China/Trips to Shanghai',
                    label: 'Trips to Shanghai',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Hong Kong',
                label: 'Trips to Hong Kong',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to India',
                label: 'Trips to India',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to India/Trips to Bangalore',
                    label: 'Trips to Bangalore',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to India/Trips to Chennai',
                    label: 'Trips to Chennai',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to India/Trips to Delhi',
                    label: 'Trips to Delhi',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to India/Trips to Goa',
                    label: 'Trips to Goa',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to India/Trips to Jaipur',
                    label: 'Trips to Jaipur',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to India/Trips to Kolkata',
                    label: 'Trips to Kolkata',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to India/Trips to Mumbai',
                    label: 'Trips to Mumbai',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Indonesia',
                label: 'Trips to Indonesia',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Indonesia/Trips to Bali',
                    label: 'Trips to Bali',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Indonesia/Trips to Jakarta',
                    label: 'Trips to Jakarta',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Japan',
                label: 'Trips to Japan',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Japan/Trips to Fukuoka',
                    label: 'Trips to Fukuoka',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Japan/Trips to Kyoto-Osaka-Kobe',
                    label: 'Trips to Kyoto-Osaka-Kobe',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Japan/Trips to Nagoya',
                    label: 'Trips to Nagoya',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Japan/Trips to Okinawa',
                    label: 'Trips to Okinawa',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Japan/Trips to Sapporo',
                    label: 'Trips to Sapporo',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Japan/Trips to Tokyo',
                    label: 'Trips to Tokyo',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Malaysia',
                label: 'Trips to Malaysia',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Malaysia/Trips to Kuala Lumpur',
                    label: 'Trips to Kuala Lumpur',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Nepal',
                label: 'Trips to Nepal',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to New Zealand',
                label: 'Trips to New Zealand',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Seoul',
                label: 'Trips to Seoul',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Singapore',
                label: 'Trips to Singapore',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Sri Lanka',
                label: 'Trips to Sri Lanka',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Taipei',
                label: 'Trips to Taipei',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Thailand',
                label: 'Trips to Thailand',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Thailand/Trips to Bangkok',
                    label: 'Trips to Bangkok',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Thailand/Trips to Chiang Mai',
                    label: 'Trips to Chiang Mai',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Thailand/Trips to Phuket',
                    label: 'Trips to Phuket',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Vietnam',
                label: 'Trips to Vietnam',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Vietnam/Trips to Hanoi',
                    label: 'Trips to Hanoi',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to Vietnam/Trips to Ho Chi Minh City',
                    label: 'Trips to Ho Chi Minh City',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Asia-Pacific/Trips to the Philippines',
                label: 'Trips to the Philippines',
                category: 'interest_inmarket',
              },
            ],
          },
          {
            value: 'Travel/Trips by Destination/Trips to Europe',
            label: 'Trips to Europe',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Amsterdam',
                label: 'Trips to Amsterdam',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Brussels',
                label: 'Trips to Brussels',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Croatia',
                label: 'Trips to Croatia',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Denmark',
                label: 'Trips to Denmark',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Denmark/Trips to Copenhagen',
                    label: 'Trips to Copenhagen',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to France',
                label: 'Trips to France',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to France/Trips to Bordeaux',
                    label: 'Trips to Bordeaux',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to France/Trips to Lyon',
                    label: 'Trips to Lyon',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to France/Trips to Marseille',
                    label: 'Trips to Marseille',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to France/Trips to Nice',
                    label: 'Trips to Nice',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to France/Trips to Paris',
                    label: 'Trips to Paris',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to France/Trips to Toulouse',
                    label: 'Trips to Toulouse',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Germany',
                label: 'Trips to Germany',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Germany/Trips to Berlin',
                    label: 'Trips to Berlin',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Germany/Trips to Cologne',
                    label: 'Trips to Cologne',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Germany/Trips to Dusseldorf',
                    label: 'Trips to Dusseldorf',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Germany/Trips to Frankfurt',
                    label: 'Trips to Frankfurt',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Germany/Trips to Hamburg',
                    label: 'Trips to Hamburg',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Germany/Trips to Munich',
                    label: 'Trips to Munich',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Greece',
                label: 'Trips to Greece',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Helsinki',
                label: 'Trips to Helsinki',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Hungary',
                label: 'Trips to Hungary',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Hungary/Trips to Budapest',
                    label: 'Trips to Budapest',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Iceland',
                label: 'Trips to Iceland',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Ireland',
                label: 'Trips to Ireland',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Ireland/Trips to Dublin',
                    label: 'Trips to Dublin',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Italy',
                label: 'Trips to Italy',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Italy/Trips to Florence',
                    label: 'Trips to Florence',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Italy/Trips to Milan',
                    label: 'Trips to Milan',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Italy/Trips to Naples',
                    label: 'Trips to Naples',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Italy/Trips to Rome',
                    label: 'Trips to Rome',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Italy/Trips to Sicily',
                    label: 'Trips to Sicily',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Italy/Trips to Venice',
                    label: 'Trips to Venice',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Kiev',
                label: 'Trips to Kiev',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Malta',
                label: 'Trips to Malta',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Moscow',
                label: 'Trips to Moscow',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Oslo',
                label: 'Trips to Oslo',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Poland',
                label: 'Trips to Poland',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Poland/Trips to Krakow',
                    label: 'Trips to Krakow',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Poland/Trips to Warsaw',
                    label: 'Trips to Warsaw',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Portugal',
                label: 'Trips to Portugal',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Portugal/Trips to Lisbon',
                    label: 'Trips to Lisbon',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Portugal/Trips to Porto',
                    label: 'Trips to Porto',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Prague',
                label: 'Trips to Prague',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Romania',
                label: 'Trips to Romania',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Spain',
                label: 'Trips to Spain',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Spain/Trips to Barcelona',
                    label: 'Trips to Barcelona',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Spain/Trips to Madrid',
                    label: 'Trips to Madrid',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Spain/Trips to Malaga',
                    label: 'Trips to Malaga',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Travel/Trips by Destination/Trips to Europe/Trips to Spain/Trips to Mallorca, Ibiza & Balearic Islands',
                    label: 'Trips to Mallorca, Ibiza & Balearic Islands',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Spain/Trips to Seville',
                    label: 'Trips to Seville',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Spain/Trips to Valencia',
                    label: 'Trips to Valencia',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Spain/Trips to the Canary Islands',
                    label: 'Trips to the Canary Islands',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to St. Petersburg',
                label: 'Trips to St. Petersburg',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Sweden',
                label: 'Trips to Sweden',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Sweden/Trips to Stockholm',
                    label: 'Trips to Stockholm',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Switzerland',
                label: 'Trips to Switzerland',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Switzerland/Trips to Geneva',
                    label: 'Trips to Geneva',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Switzerland/Trips to Zurich',
                    label: 'Trips to Zurich',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Turkey',
                label: 'Trips to Turkey',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Turkey/Trips to Antalya',
                    label: 'Trips to Antalya',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to Turkey/Trips to Istanbul',
                    label: 'Trips to Istanbul',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to Vienna',
                label: 'Trips to Vienna',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Europe/Trips to the UK',
                label: 'Trips to the UK',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to the UK/Trips to Birmingham, UK',
                    label: 'Trips to Birmingham, UK',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to the UK/Trips to Edinburgh',
                    label: 'Trips to Edinburgh',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to the UK/Trips to Glasgow',
                    label: 'Trips to Glasgow',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to the UK/Trips to London, UK',
                    label: 'Trips to London, UK',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Europe/Trips to the UK/Trips to Manchester, UK',
                    label: 'Trips to Manchester, UK',
                    category: 'interest_inmarket',
                  },
                ],
              },
            ],
          },
          {
            value: 'Travel/Trips by Destination/Trips to Latin America',
            label: 'Trips to Latin America',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Bogota',
                label: 'Trips to Bogota',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Brazil',
                label: 'Trips to Brazil',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Brazil/Trips to Rio de Janeiro',
                    label: 'Trips to Rio de Janeiro',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Brazil/Trips to Salvador',
                    label: 'Trips to Salvador',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Brazil/Trips to São Paulo',
                    label: 'Trips to São Paulo',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Buenos Aires',
                label: 'Trips to Buenos Aires',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Costa Rica',
                label: 'Trips to Costa Rica',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Guatemala',
                label: 'Trips to Guatemala',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Lima',
                label: 'Trips to Lima',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Mexico',
                label: 'Trips to Mexico',
                category: 'interest_inmarket',
                children: [
                  {
                    value:
                      'Travel/Trips by Destination/Trips to Latin America/Trips to Mexico/Trips to Cancun & Playa del Carmen',
                    label: 'Trips to Cancun & Playa del Carmen',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Mexico/Trips to Guadalajara',
                    label: 'Trips to Guadalajara',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Mexico/Trips to Los Cabos',
                    label: 'Trips to Los Cabos',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Mexico/Trips to Mexico City',
                    label: 'Trips to Mexico City',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Mexico/Trips to Monterrey',
                    label: 'Trips to Monterrey',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Travel/Trips by Destination/Trips to Latin America/Trips to Mexico/Trips to Puerto Vallarta',
                    label: 'Trips to Puerto Vallarta',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Panama',
                label: 'Trips to Panama',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Latin America/Trips to Santiago',
                label: 'Trips to Santiago',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to Latin America/Trips to the Caribbean',
                label: 'Trips to the Caribbean',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to Latin America/Trips to the Caribbean/Trips to Cuba',
                    label: 'Trips to Cuba',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to Latin America/Trips to the Caribbean/Trips to Jamaica',
                    label: 'Trips to Jamaica',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Travel/Trips by Destination/Trips to Latin America/Trips to the Caribbean/Trips to Puerto Rico',
                    label: 'Trips to Puerto Rico',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Travel/Trips by Destination/Trips to Latin America/Trips to the Caribbean/Trips to the Dominican Republic',
                    label: 'Trips to the Dominican Republic',
                    category: 'interest_inmarket',
                  },
                ],
              },
            ],
          },
          {
            value: 'Travel/Trips by Destination/Trips to North America',
            label: 'Trips to North America',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Travel/Trips by Destination/Trips to North America/Trips to Canada',
                label: 'Trips to Canada',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to Canada/Trips to Calgary',
                    label: 'Trips to Calgary',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to Canada/Trips to Montreal',
                    label: 'Trips to Montreal',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to Canada/Trips to Ottawa',
                    label: 'Trips to Ottawa',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to Canada/Trips to Toronto',
                    label: 'Trips to Toronto',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to Canada/Trips to Vancouver',
                    label: 'Trips to Vancouver',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value: 'Travel/Trips by Destination/Trips to North America/Trips to the US',
                label: 'Trips to the US',
                category: 'interest_inmarket',
                children: [
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Alaska',
                    label: 'Trips to Alaska',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Albuquerque',
                    label: 'Trips to Albuquerque',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Atlanta',
                    label: 'Trips to Atlanta',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Austin',
                    label: 'Trips to Austin',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Baltimore',
                    label: 'Trips to Baltimore',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Boston',
                    label: 'Trips to Boston',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Buffalo-Rochester Area',
                    label: 'Trips to Buffalo-Rochester Area',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to California',
                    label: 'Trips to California',
                    category: 'interest_inmarket',
                    children: [
                      {
                        value:
                          'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to California/Trips to Los Angeles',
                        label: 'Trips to Los Angeles',
                        category: 'interest_inmarket',
                      },
                      {
                        value:
                          'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to California/Trips to Orange County, CA',
                        label: 'Trips to Orange County, CA',
                        category: 'interest_inmarket',
                      },
                      {
                        value:
                          'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to California/Trips to San Diego',
                        label: 'Trips to San Diego',
                        category: 'interest_inmarket',
                      },
                      {
                        value:
                          'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to California/Trips to San Francisco Bay Area',
                        label: 'Trips to San Francisco Bay Area',
                        category: 'interest_inmarket',
                      },
                    ],
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Charleston, SC',
                    label: 'Trips to Charleston, SC',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Charlotte',
                    label: 'Trips to Charlotte',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Chicago',
                    label: 'Trips to Chicago',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Cincinnati',
                    label: 'Trips to Cincinnati',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Cleveland',
                    label: 'Trips to Cleveland',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Columbus',
                    label: 'Trips to Columbus',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Dallas-Fort Worth',
                    label: 'Trips to Dallas-Fort Worth',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Denver',
                    label: 'Trips to Denver',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Detroit',
                    label: 'Trips to Detroit',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Florida',
                    label: 'Trips to Florida',
                    category: 'interest_inmarket',
                    children: [
                      {
                        value:
                          'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Florida/Trips to Fort Lauderdale',
                        label: 'Trips to Fort Lauderdale',
                        category: 'interest_inmarket',
                      },
                      {
                        value:
                          'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Florida/Trips to Fort Myers',
                        label: 'Trips to Fort Myers',
                        category: 'interest_inmarket',
                      },
                      {
                        value:
                          'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Florida/Trips to Jacksonville',
                        label: 'Trips to Jacksonville',
                        category: 'interest_inmarket',
                      },
                      {
                        value:
                          'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Florida/Trips to Miami',
                        label: 'Trips to Miami',
                        category: 'interest_inmarket',
                      },
                      {
                        value:
                          'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Florida/Trips to Orlando',
                        label: 'Trips to Orlando',
                        category: 'interest_inmarket',
                      },
                      {
                        value:
                          'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Florida/Trips to Tampa Bay Area',
                        label: 'Trips to Tampa Bay Area',
                        category: 'interest_inmarket',
                      },
                    ],
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Hawaii',
                    label: 'Trips to Hawaii',
                    category: 'interest_inmarket',
                    children: [
                      {
                        value:
                          'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Hawaii/Trips to Maui',
                        label: 'Trips to Maui',
                        category: 'interest_inmarket',
                      },
                      {
                        value:
                          'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Hawaii/Trips to Oahu',
                        label: 'Trips to Oahu',
                        category: 'interest_inmarket',
                      },
                      {
                        value:
                          'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Hawaii/Trips to the Big Island',
                        label: 'Trips to the Big Island',
                        category: 'interest_inmarket',
                      },
                    ],
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Houston',
                    label: 'Trips to Houston',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Indianapolis',
                    label: 'Trips to Indianapolis',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Kansas City',
                    label: 'Trips to Kansas City',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Las Vegas',
                    label: 'Trips to Las Vegas',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Louisville',
                    label: 'Trips to Louisville',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Memphis',
                    label: 'Trips to Memphis',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Milwaukee',
                    label: 'Trips to Milwaukee',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Minneapolis-Saint Paul',
                    label: 'Trips to Minneapolis-Saint Paul',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Myrtle Beach & Grand Strand',
                    label: 'Trips to Myrtle Beach & Grand Strand',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Nashville',
                    label: 'Trips to Nashville',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to New Orleans',
                    label: 'Trips to New Orleans',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to New York City',
                    label: 'Trips to New York City',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Norfolk-Virginia Beach Region',
                    label: 'Trips to Norfolk-Virginia Beach Region',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Oklahoma City',
                    label: 'Trips to Oklahoma City',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Philadelphia',
                    label: 'Trips to Philadelphia',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Phoenix',
                    label: 'Trips to Phoenix',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Pittsburgh',
                    label: 'Trips to Pittsburgh',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Portland, OR',
                    label: 'Trips to Portland, OR',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Raleigh-Durham Area',
                    label: 'Trips to Raleigh-Durham Area',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Reno',
                    label: 'Trips to Reno',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Richmond, VA',
                    label: 'Trips to Richmond, VA',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Salt Lake City',
                    label: 'Trips to Salt Lake City',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to San Antonio',
                    label: 'Trips to San Antonio',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Seattle',
                    label: 'Trips to Seattle',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to St. Louis',
                    label: 'Trips to St. Louis',
                    category: 'interest_inmarket',
                  },
                  {
                    value: 'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Tucson',
                    label: 'Trips to Tucson',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Travel/Trips by Destination/Trips to North America/Trips to the US/Trips to Washington, D.C.',
                    label: 'Trips to Washington, D.C.',
                    category: 'interest_inmarket',
                  },
                ],
              },
            ],
          },
          {
            value: 'Travel/Trips by Destination/Trips to the Middle East & Africa',
            label: 'Trips to the Middle East & Africa',
            category: 'interest_inmarket',
            children: [
              {
                value: 'Travel/Trips by Destination/Trips to the Middle East & Africa/Trips to Egypt',
                label: 'Trips to Egypt',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to the Middle East & Africa/Trips to Israel',
                label: 'Trips to Israel',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to the Middle East & Africa/Trips to Jordan',
                label: 'Trips to Jordan',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to the Middle East & Africa/Trips to Kenya',
                label: 'Trips to Kenya',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to the Middle East & Africa/Trips to Morocco',
                label: 'Trips to Morocco',
                category: 'interest_inmarket',
              },
              {
                value: 'Travel/Trips by Destination/Trips to the Middle East & Africa/Trips to South Africa',
                label: 'Trips to South Africa',
                category: 'interest_inmarket',
                children: [
                  {
                    value:
                      'Travel/Trips by Destination/Trips to the Middle East & Africa/Trips to South Africa/Trips to Cape Town',
                    label: 'Trips to Cape Town',
                    category: 'interest_inmarket',
                  },
                  {
                    value:
                      'Travel/Trips by Destination/Trips to the Middle East & Africa/Trips to South Africa/Trips to Johannesburg',
                    label: 'Trips to Johannesburg',
                    category: 'interest_inmarket',
                  },
                ],
              },
              {
                value:
                  'Travel/Trips by Destination/Trips to the Middle East & Africa/Trips to the United Arab Emirates',
                label: 'Trips to the United Arab Emirates',
                category: 'interest_inmarket',
              },
            ],
          },
        ],
      },
      {
        value: 'Travel/Vacation Packages',
        label: 'Vacation Packages',
        category: 'interest_inmarket',
      },
    ],
  },
  {
    value: 'Vehicles & Transportation',
    label: 'Vehicles & Transportation',
    category: 'interest_affinity',
    children: [
      {
        value: 'Vehicles & Transportation/Auto Enthusiasts',
        label: 'Auto Enthusiasts',
        category: 'interest_affinity',
        children: [
          {
            value: 'Vehicles & Transportation/Auto Enthusiasts/Motorcycle Enthusiasts',
            label: 'Motorcycle Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Vehicles & Transportation/Auto Enthusiasts/Performance & Luxury Vehicle Enthusiasts',
            label: 'Performance & Luxury Vehicle Enthusiasts',
            category: 'interest_affinity',
          },
          {
            value: 'Vehicles & Transportation/Auto Enthusiasts/Truck & SUV Enthusiasts',
            label: 'Truck & SUV Enthusiasts',
            category: 'interest_affinity',
          },
        ],
      },
    ],
  },
  {
    value: '[Life Events] Business Creation',
    label: '[Life Events] Business Creation',
    category: 'interest_other',
    children: [
      {
        value: '[Life Events] Business Creation/Recently Started a Business',
        label: 'Recently Started a Business',
        category: 'interest_other',
      },
      {
        value: '[Life Events] Business Creation/Starting a Business Soon',
        label: 'Starting a Business Soon',
        category: 'interest_other',
      },
    ],
  },
  {
    value: '[Life Events] College Graduation',
    label: '[Life Events] College Graduation',
    category: 'interest_other',
    children: [
      {
        value: '[Life Events] College Graduation/Graduating Soon',
        label: 'Graduating Soon',
        category: 'interest_other',
      },
      {
        value: '[Life Events] College Graduation/Recently Graduated',
        label: 'Recently Graduated',
        category: 'interest_other',
      },
    ],
  },
  {
    value: '[Life Events] Job Change',
    label: '[Life Events] Job Change',
    category: 'interest_other',
    children: [
      {
        value: '[Life Events] Job Change/Recently Started New Job',
        label: 'Recently Started New Job',
        category: 'interest_other',
      },
      {
        value: '[Life Events] Job Change/Starting New Job Soon',
        label: 'Starting New Job Soon',
        category: 'interest_other',
      },
    ],
  },
  {
    value: '[Life Events] Marriage',
    label: '[Life Events] Marriage',
    category: 'interest_other',
    children: [
      {
        value: '[Life Events] Marriage/Getting Married Soon',
        label: 'Getting Married Soon',
        category: 'interest_other',
      },
      {
        value: '[Life Events] Marriage/Recently Married',
        label: 'Recently Married',
        category: 'interest_other',
      },
    ],
  },
  {
    value: '[Life Events] Moving',
    label: '[Life Events] Moving',
    category: 'interest_other',
    children: [
      {
        value: '[Life Events] Moving/Moving Soon',
        label: 'Moving Soon',
        category: 'interest_other',
      },
      {
        value: '[Life Events] Moving/Recently Moved',
        label: 'Recently Moved',
        category: 'interest_other',
      },
    ],
  },
  {
    value: '[Life Events] Purchasing a Home',
    label: '[Life Events] Purchasing a Home',
    category: 'interest_other',
    children: [
      {
        value: '[Life Events] Purchasing a Home/Purchasing a Home Soon',
        label: 'Purchasing a Home Soon',
        category: 'interest_other',
      },
      {
        value: '[Life Events] Purchasing a Home/Recently Purchased a Home',
        label: 'Recently Purchased a Home',
        category: 'interest_other',
      },
    ],
  },
  {
    value: '[Life Events] Retirement',
    label: '[Life Events] Retirement',
    category: 'interest_other',
    children: [
      {
        value: '[Life Events] Retirement/Recently Retired',
        label: 'Recently Retired',
        category: 'interest_other',
      },
      {
        value: '[Life Events] Retirement/Retiring Soon',
        label: 'Retiring Soon',
        category: 'interest_other',
      },
    ],
  },
];
