export const getCollectionSizeHelper = (audienceIds) => {
  return {
    definition: {
      base: {
        operator: 'or',
        rules: [
          {
            operator: 'and',
            field: 'Audience',
            values: audienceIds,
          },
        ],
      },
    },
    demo: {
      operator: 'and',
      rules: [],
    },
    rules: [],
  };
};
