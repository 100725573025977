import React from 'react';

enum ActionTypes {
  SET_COUNTRY,
  REMOVE_COUNTRY,
  RESET_COUNTRIES,
}

interface ContextProps {
  state: {
    countries: string[];
  };
  setCountry: (name: string) => Promise<void>;
}

const Context = React.createContext<ContextProps | undefined>(undefined);

const initialState = {
  countries: [],
};

function reducer(state, action) {
  switch (action.type) {
    case ActionTypes.SET_COUNTRY: {
      return { ...state, countries: [action.payload, ...state.countries] };
    }
    case ActionTypes.REMOVE_COUNTRY: {
      return {
        ...state,
        countries: state.countries.filter((name) => name !== action.payload),
      };
    }
    case ActionTypes.RESET_COUNTRIES: {
      return {
        ...state,
        countries: [],
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function FilterByCountryProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const setCountry = React.useCallback(
    async (countryName) => {
      if (countryName) {
        dispatch({
          type: state.countries.includes(countryName) ? ActionTypes.REMOVE_COUNTRY : ActionTypes.SET_COUNTRY,
          payload: countryName,
        });
      } else {
        dispatch({ type: ActionTypes.RESET_COUNTRIES });
      }
    },
    [state.countries],
  );

  return (
    <Context.Provider
      value={{
        state,
        setCountry,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useFilterByCountry() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error('useFilterByCountry must be used within a FilterByCountryProvider');
  }
  return context;
}
