import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { IconType } from 'components/Icon';
import { IconButton } from 'components/IconButton';
import { Modal } from 'components/Modal';
import PopOver, { PopOverItem } from 'components/PopOver';
import { TextInput } from 'components/TextInput';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import AdditionalRecipients from '../AdditionalRecipients';
import { createAudienceExtension } from './api';

type AudienceExtensionType = {
  brand: string | undefined;
  flightDates: { from: string | undefined; to: string | undefined };
  budget: string | undefined;
  adType: string[];
  inventoryType: string[];
  geo: string | undefined;
  dsp: string | undefined;
  dspSeatId: string | undefined;
  additionalParticipants: string[];
  additionalParticipantsQuery: string | undefined;
};

export const AudienceExtensionModal = ({ onClose, isVisible }) => {
  const [isSelecting, setIsSelecting] = React.useState<string | boolean>(false);
  const [isAddingAdditionalParticipants, setIsAddingAdditionalParticipants] = React.useState<string | boolean>(false);
  const [isCreated, setIsCreated] = React.useState<boolean>(false);
  const [formData, setFormDate] = useState<AudienceExtensionType>({
    brand: undefined,
    flightDates: { from: undefined, to: undefined },
    budget: undefined,
    adType: [],
    inventoryType: [],
    geo: undefined,
    dsp: undefined,
    dspSeatId: undefined,
    additionalParticipants: [],
    additionalParticipantsQuery: undefined,
  });

  const { mutateAsync: createAsync, isLoading } = useMutation(createAudienceExtension);

  const handleClose = () => {
    setIsCreated(false);
    onClose();
  };

  const onInputChange = (field, e) => {
    e.preventDefault();

    let { value } = e.target;
    if (field.includes('.')) {
      value = {
        ...formData[field.split('.')[0]],
        ...{ [field.split('.')[1]]: value },
      };
    }
    setFormDate({
      ...formData,
      [field.split('.')[0]]: value,
    });
  };

  const onSelectFocus = (field) => {
    setIsSelecting(field);
  };
  const onSelectChange = (field, option) => {
    const exists = formData[field].includes(option);

    const additionalParticipantsQuery =
      field === 'additionalParticipants' ? undefined : formData.additionalParticipantsQuery;

    setFormDate({
      ...formData,
      additionalParticipantsQuery,
      [field]: exists ? formData[field].filter((o) => o !== option) : [...formData[field], option],
    });
  };

  const onSubmit = async () => {
    await createAsync(formData);
    setIsCreated(true);
  };

  if (!isVisible) return null;

  return (
    <Modal visible={!!isVisible}>
      {!isCreated && (
        <Card>
          <div className="column gap-m">
            <div className="row">
              <div className="column flex">
                <h3 className="flex">wmXtension</h3>
              </div>
              <IconButton icon={IconType.cross} onClick={handleClose} />
            </div>

            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="column gap scrollbar" style={{ maxHeight: '50vh', minWidth: 400 }}>
                  <TextInput
                    inlineLabel
                    value={formData.brand}
                    placeholder="Brand"
                    onChange={onInputChange.bind(null, 'brand')}
                  />
                  <div style={{ position: 'relative' }}>
                    <TextInput
                      inlineLabel
                      type="date"
                      value={formData.flightDates.from}
                      placeholder="From"
                      onChange={onInputChange.bind(null, 'flightDates.from')}
                    />
                    <TextInput
                      inlineLabel
                      type="date"
                      value={formData.flightDates.to}
                      placeholder="To"
                      onChange={onInputChange.bind(null, 'flightDates.to')}
                    />
                  </div>
                  <div style={{ position: 'relative' }}>
                    <TextInput
                      prefix={formData.budget && '$'}
                      value={formData.budget}
                      placeholder="Budget"
                      inlineLabel
                      onChange={onInputChange.bind(null, 'budget')}
                    />
                  </div>
                  <div style={{ position: 'relative' }}>
                    <TextInput
                      inlineLabel
                      onClick={onSelectFocus.bind(null, 'adType')}
                      onFocus={onSelectFocus.bind(null, 'adType')}
                      value={formData.adType && formData.adType.toString()}
                      placeholder="Ad type"
                    />
                    <PopOver
                      fullWidth
                      bottom
                      isVisible={isSelecting === 'adType'}
                      onCancel={onSelectFocus.bind(null, false)}
                    >
                      {['Display', 'Video', 'CTV', 'Native'].map((d) => (
                        <PopOverItem
                          selected={formData.adType.includes(d)}
                          onClick={onSelectChange.bind(null, 'adType', d)}
                        >
                          {d}
                        </PopOverItem>
                      ))}
                    </PopOver>
                  </div>
                  <div style={{ position: 'relative' }}>
                    <TextInput
                      disabled={formData.adType.includes('CTV')}
                      inlineLabel
                      onClick={formData.adType.includes('CTV') ? () => null : onSelectFocus.bind(null, 'inventoryType')}
                      onFocus={onSelectFocus.bind(null, 'inventoryType')}
                      value={formData.inventoryType && formData.inventoryType.toString()}
                      placeholder="Inventory type"
                    />
                    <PopOver
                      fullWidth
                      bottom
                      isVisible={isSelecting === 'inventoryType'}
                      onCancel={onSelectFocus.bind(null, false)}
                    >
                      {['Web', 'In-App'].map((d) => (
                        <PopOverItem
                          selected={formData.inventoryType.includes(d)}
                          onClick={onSelectChange.bind(null, 'inventoryType', d)}
                        >
                          {d}
                        </PopOverItem>
                      ))}
                    </PopOver>
                  </div>
                  <TextInput
                    optional
                    inlineLabel
                    value={formData.geo}
                    placeholder="Country"
                    onChange={onInputChange.bind(null, 'geo')}
                  />
                  <TextInput
                    optional
                    inlineLabel
                    value={formData.dsp}
                    placeholder="DSP"
                    onChange={onInputChange.bind(null, 'dsp')}
                  />
                  <TextInput
                    optional
                    inlineLabel
                    value={formData.dspSeatId}
                    placeholder="DSP Seat ID"
                    onChange={onInputChange.bind(null, 'dspSeatId')}
                  />
                  {!isAddingAdditionalParticipants && (
                    <div className="row end">
                      <Button onClick={setIsAddingAdditionalParticipants.bind(null, true)} sml>
                        Add Additional Participants
                      </Button>
                    </div>
                  )}
                </div>
                {isAddingAdditionalParticipants && (
                  <AdditionalRecipients
                    title="Additional Participants"
                    placeholder={'eg. joe@wmx.com'}
                    value={formData.additionalParticipantsQuery}
                    onChange={onInputChange.bind(null, 'additionalParticipantsQuery')}
                    onEnter={onSelectChange.bind(null, 'additionalParticipants', formData.additionalParticipantsQuery)}
                    onRemove={(email) => onSelectChange('additionalParticipants', email)}
                    options={formData.additionalParticipants}
                  />
                )}
              </div>
            </form>
            <Button primary submit onClick={onSubmit} loading={isLoading} disabled={isLoading}>
              Create wmXtension
            </Button>
          </div>
        </Card>
      )}
      {isCreated && (
        <Card>
          <div className="column ">
            <div className="row center">
              <h3 className="flex">{`wmXtension Created`}</h3>
              <IconButton icon={IconType.cross} onClick={handleClose} />
            </div>
            <p className="secondary column">{`The wmXtension was successfully created`}</p>
          </div>
        </Card>
      )}
    </Modal>
  );
};
