import React from 'react';
import RTLite from 'react-tooltip-lite';
import './style.css';

interface TooltipProps {
  description: React.ReactNode;
  children: React.ReactNode;
  direction?: 'right' | 'left' | 'up' | 'down';
}

export const SimpleTooltip = ({ description, children, direction }: TooltipProps) =>
  children ? (
    <RTLite
      className="simpleTip"
      content={<span style={{ maxWidth: '250px', display: 'block', textAlign: 'center' }}>{description}</span>}
      direction={direction}
    >
      {children}
    </RTLite>
  ) : null;
