import api from 'utils/api';
import locations from './locations';
import interests_raw from './interests';

const devicesList = [
  'IOS',
  'ANDROID',
  'APPLE',
  'GOOGLE',
  'SAMSUNG',
  'IPHONE',
  'LG',
  'SONY',
  'VERIZON',
  'AT&T',
  'T-MOBILE',
  'VODAFONE',
  'O2',
];

export const getFilterGroup = async (group) => {
  if (group === 'interests') return interests_raw;

  let list = await api(`reporting/filters-list?group=${group}`);
  if (group === 'location') {
    list = list
      .filter((f) => locations.some((d) => d.value === f.value && !d.remove))
      .map((f) => {
        const location = locations.find((d) => d.value === f.value);
        return {
          ...location,
          category: f.category,
          categoryLabel: location?.category,
        };
      });
    const worldRegion = list.filter((item) => item.category.toLowerCase() === 'world_region');
    const country = list.filter((item) => item.category.toLowerCase() === 'country');
    const usRegion = list.filter((item) => item.category.toLowerCase() === 'us_region');
    const usState = list.filter((item) => item.category.toLowerCase() === 'us_state');
    const usMetro = list.filter((item) => item.category.toLowerCase() === 'us_metro');
    const metro = list.filter((item) => item.category.toLowerCase() === 'metro');
    const metroId = list.filter((item) => item.category.toLowerCase() === 'metro_id');
    const other = list.filter((item) => item.category.toLowerCase() === 'other');
    return [...worldRegion, ...country, ...usRegion, ...usState, ...usMetro, ...metro, ...metroId, ...other];
  }
  return [
    ...list.filter(
      (f) => f.category.startsWith('device') && devicesList.some((d) => f.value.toUpperCase() === d.toUpperCase()),
    ),
    ...list.filter((f) => !f.category.startsWith('device')),
  ];
};

export const getFilterSuggestions = async (group) => api(`reporting/filters-list/suggestions?group=${group}`);

export const setFilterSuggestion = async (suggestions) => {
  await api('reporting/filters-list/suggestions', {
    method: 'POST',
    body: JSON.stringify(suggestions),
  });
};

export const getFilterList = async () => {
  const location = getFilterGroup('location');
  const engagement = getFilterGroup('engagement');

  const filters = await Promise.all([location, engagement, interests_raw]);
  return filters.flat();
};

export const getFilterLabel = (value, filters) =>
  filters.some((f) => f.value === value) ? filters.find((f) => f.value === value).label : value;
