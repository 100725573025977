import React from 'react';

const calculateStrokeDash = (radius, percent) => {
  const roundCircum = 2 * radius * Math.PI;
  const roundDraw = (percent * roundCircum) / 100;
  return `${roundDraw}px, 999px`;
};

const modifiers = [0, 6, 11];
const colors = ['var(--wmg-color-secondary)', 'var(--wmg-color-primary2)', 'var(--wmg-color-primary)'];
const strokeWidth = [8, 20, 30];

const EngagementTypeDonutRing = ({ percent, index }) => {
  const viewW = '70%';
  const r = 40;

  return (
    <>
      <svg className="round" viewBox="0 0 100 100" width={viewW} data-percent={percent}>
        <circle
          cx="50%"
          cy="50%"
          strokeWidth={strokeWidth[index]}
          r={String(r - modifiers[index])}
          stroke={colors[index]}
          fill="transparent"
          strokeDasharray={calculateStrokeDash(r - modifiers[index], percent)}
        />
      </svg>
    </>
  );
};
export default EngagementTypeDonutRing;
