import React from 'react';
import './style.css';

interface CustomTooltipProps {
  value: string | undefined;
  total: string | undefined;
  label?: string;
  chartHeight?: number;
}

export const CustomTooltip = ({ value, total, label, chartHeight }: CustomTooltipProps) => {
  const chartHeightStyle = {
    '--chart-height': `${chartHeight}px`,
  } as React.CSSProperties;

  return (
    <>
      <span className="tooltip">
        <div className=" gap-s">
          <span className="label">Total: </span>
          {total}
        </div>
        <div className=" gap-s ">
          <span className="label">Added: </span> {value}
        </div>
      </span>
      <span className="date-tooltip" style={chartHeightStyle}>
        {label}
      </span>
    </>
  );
};
