import React from 'react';

import PieChartLabel from './PieChartLabel';
import { ResponsiveContainer, Pie, PieChart, Cell, Tooltip } from 'recharts';
import PieCustomTooltip from './PieCustomTooltip';

const AgeGenderPieChart = ({ data }) => {
  const colors = {
    male: 'var(--wmg-color-secondary)',
    female: 'var(--wmg-color-primary)',
  };

  const totalCount = data.male + data.female;

  const orderedData = [
    {
      name: 'male',
      count: data.male,
    },
    {
      name: 'female',
      count: data.female,
    },
  ];
  return (
    <div className="column">
      <ResponsiveContainer width={'100%'} height={150}>
        <PieChart>
          <Pie
            dataKey="count"
            startAngle={180}
            endAngle={0}
            data={orderedData}
            cx="50%"
            cy="100%"
            outerRadius={'125%'}
            fill="#8884d8"
            stroke="transparent"
          >
            {orderedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[entry.name]} />
            ))}
          </Pie>
          <Tooltip content={<PieCustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>

      <div className="row">
        <PieChartLabel numeratorValue={data.male} totalValue={totalCount} name="Male" />
        <div></div>
        <div></div>
        <PieChartLabel numeratorValue={data.female} totalValue={totalCount} name="Female" />
      </div>
    </div>
  );
};

export default AgeGenderPieChart;
