import api from 'utils/api';
import { engagementContextHelper } from './helpers';
const renameMappings = {
  youtube: 'YT Subscribers',
  twitter: 'X (Twitter)',
};

const socialValuesOrder = (name) => {
  switch (name.toLowerCase()) {
    case 'facebook':
      return 1;
    case 'instagram':
      return 2;
    case 'twitter':
      return 3;
    case 'tiktok':
      return 4;
    default:
      return 0;
  }
};

const socialDataHelper = (data) =>
  data.map((d) => ({
    name: renameMappings[d.platform_name.toLowerCase()] || d.platform_name,
    platform: d.platform_name,
    count: d.rtd,
    order: socialValuesOrder(d.platform_name),
  }));

const getSocialData = async (name) => {
  const highlightedSocial = ['spotify', 'youtube'];
  const newName = name.replaceAll(' ', '_').toLowerCase();
  const data = await api(`artist-social-data/${newName}`);

  const formattedData = socialDataHelper(data);
  const highlightedData = highlightedSocial.map((d) => formattedData.find((s) => s.platform.toLowerCase() === d));
  const socialData = formattedData
    .filter((d) => !highlightedSocial.includes(d.platform.toLowerCase()))

    .reduce(
      (acc, curr) => {
        return {
          ...acc,
          count: acc.count + curr.count,
          values: [...acc.values, curr],
        };
      },
      { name: 'Social', count: 0, values: [] },
    );
  return formattedData && formattedData.length ? [...highlightedData, socialData] : [];
};

export const getEngagementContext = async (segmentId, name, countries) => {
  const socialData = await getSocialData(name);

  const data = await api(
    `reporting/segment/${segmentId}/engagement_context${countries ? `?countries=${countries.join('|')}` : ''}`,
  );

  return [...engagementContextHelper(data), ...socialData].sort((a, b) => b.count - a.count);
};
