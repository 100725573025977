import { refreshAuthToken } from 'contexts/AuthContext/api';
import { retrieveToken } from './authToken';

export default async function api(endpoint: string, params: RequestInit = {}) {
  const token = retrieveToken();
  const { REACT_APP_WMG_API_URL: API_URL } = process.env;
  // const API_URL = 'http://localhost:8000';
  params.headers = {
    'Content-Type': 'application/json',
  };
  params.credentials = 'include';

  if (token && endpoint !== 'token/refresh/') {
    params.headers.Authorization = `Bearer ${token}`;
  }

  try {
    const response = await fetch(`${API_URL}/api/v1/${endpoint}`, params);
    let data = await response.json();
    if (data.code === 'token_not_valid' && endpoint !== 'token/logout/') {
      await refreshAuthToken();
      data = await api(endpoint, params);
    }
    return data;
  } catch (error) {
    return { error };
  }
}
