import React from 'react';
import { ChartCard } from 'components/ChartCard';
import AgeGenderPieChart from './components/AgeGenderPieChart';
import AgeGenderBarChart from './components/AgeGenderBarChart';

import './style.css';

interface BarChartEntry {
  age: string;
  femaleCount: number;
  maleCount: number;
  totalPercent: string;
}

const ages: string[] = ['18-20', '21-24', '25-34', '35-44', '45-54', '55-64', '65+'];

const barChartDataHelper = (data) => {
  const females = data.filter((i) => i.name.startsWith('female'));
  const males = data.filter((i) => i.name.startsWith('male'));
  return ages.map((age, index) => {
    const multiplier = age === '18-20' ? 3 / 7 : age === '21-24' ? 4 / 7 : 1;
    const ageGroup = index > 1 ? age : '18-24';
    const femaleGroup = females.find((g) => g.name.includes(ageGroup));
    const maleGroup = males.find((g) => g.name.includes(ageGroup));
    return {
      age,
      femaleCount: Math.round((femaleGroup?.count || 0) * multiplier),
      maleCount: Math.round((maleGroup?.count || 0) * multiplier),
      totalPercent: Math.round(((femaleGroup?.percent || 0) + (maleGroup?.percent || 0)) * multiplier * 1000) / 10,
    };
  });
};

export const AgeGenderChart = ({ isLoading, metadata, ...props }) => {
  const gender = React.useMemo(
    () =>
      !isLoading
        ? props.data
            .map(({ name, count }) => ({
              name: name.split('_')[0],
              count,
            }))
            .reduce(
              (accumulator, currentValue) => ({
                ...accumulator,
                [currentValue.name]: accumulator[currentValue.name] + currentValue.count,
              }),
              {
                male: 0,
                female: 0,
              },
            )
        : props.data,
    [isLoading, props.data],
  );

  const data = React.useMemo(() => props.data, [props.data]);

  return (
    <ChartCard
      header={<div className="row center">Gender & Age distribution</div>}
      isLoading={isLoading}
      noData={!isLoading && props.data.length == 0}
    >
      {!isLoading && props.data.length > 0 && (
        <div className="row age-gender-charts flex">
          <AgeGenderPieChart data={gender} />
          <AgeGenderBarChart ageGenderData={barChartDataHelper(data)} />
        </div>
      )}
    </ChartCard>
  );
};
