import React from 'react';
import { combineClasses } from 'utils/classNames';
import './style.css';
interface CardProps {
  children?: React.ReactNode;
  placeholder?: boolean;
  collection?: boolean;
  flex?: boolean;
  sml?: boolean;
  noscroll?: boolean;
}
export const Card = ({ children, placeholder, collection, flex, sml, noscroll }: CardProps) => {
  const containerStyles = combineClasses([
    'card',
    'column',
    flex && 'flex',
    sml && 'sml',
    collection && 'collection',
    placeholder && 'placeholder',
    noscroll && 'noscroll',
  ]);
  return (
    <div className={containerStyles}>
      <div className="card-content flex column">{children}</div>
    </div>
  );
};
