import Icon, { IconType } from 'components/Icon';
import React from 'react';
import { PaginationItem } from './PaginationItem';
import { PaginationItemSpacer } from './PaginationItemSpacer';

export const Pagination = ({ currentPage, totalPages, onChange }) => {
  if (totalPages < 2) return null;

  return (
    <div className="pagination row center v-spacing" data-html2canvas-ignore="true">
      {/* the first page and the < symbol */}
      <PaginationItem disabled={currentPage === 1} onClick={() => onChange(currentPage > 1 ? currentPage - 1 : null)}>
        <Icon type={IconType.arrowLeft} />
      </PaginationItem>
      <PaginationItem page={1} currentPage={currentPage} onClick={() => onChange(1)} />

      {/* when totalPages <= 7, show page numbers from the 2nd to the last second */}
      {totalPages <= 7 &&
        [...Array(totalPages - 2)].map((d, i) => (
          <PaginationItem key={i + 2} page={i + 2} currentPage={currentPage} onClick={() => onChange(i + 2)} />
        ))}

      {/* when totalPages > 7, which page numbers will be shown depends on the current page location */}
      {totalPages > 7 && (
        <>
          {currentPage > 4 && <PaginationItemSpacer />}
          {currentPage < 5 &&
            [...Array(4)].map((d, i) => (
              <PaginationItem key={i + 2} page={i + 2} currentPage={currentPage} onClick={() => onChange(i + 2)} />
            ))}
          {currentPage > 4 && currentPage < totalPages - 3 && (
            <>
              <PaginationItem page={currentPage - 1} onClick={() => onChange(currentPage - 1)} />
              <div className="page-item current">{currentPage}</div>
              <PaginationItem page={currentPage + 1} onClick={() => onChange(currentPage + 1)} />
            </>
          )}
          {currentPage > totalPages - 4 &&
            [...Array(5)].map((d, i) => (
              <PaginationItem
                key={totalPages - 5 + i}
                page={totalPages - 5 + i}
                currentPage={currentPage}
                onClick={() => onChange(totalPages - 5 + i)}
              />
            ))}
          {currentPage < totalPages - 3 && <PaginationItemSpacer />}
        </>
      )}

      {/* the last page and the > symbol */}
      <PaginationItem page={totalPages} currentPage={currentPage} onClick={() => onChange(totalPages)} />
      <PaginationItem disabled={currentPage === totalPages} onClick={() => onChange(currentPage + 1)}>
        <Icon type={IconType.arrowRight} />
      </PaginationItem>
    </div>
  );
};
