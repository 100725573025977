import React from 'react';
import AppContainer from 'containers/AppContainer';
import { useHistory } from 'react-router-dom';
import { Page } from 'components/Page';
import { Button } from 'components/Button';

export const ErrorFallback = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.push(`/`);
    location.reload();
    localStorage.removeItem('artistlist_search_filter_state');
  };
  return (
    <AppContainer>
      <Page>
        <div className="flex row center">
          <div className="column center flex gap-l">
            <h2>Something went wrong</h2>
            <Button onClick={handleGoBack}>Return to the homepage</Button>
          </div>
        </div>
      </Page>
    </AppContainer>
  );
};
