import api from 'utils/api';

export const passwordResetRequest = async ({ email }) =>
  api(`users/password-reset`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      confirmPath: 'reset-password',
    }),
  });

export const resetPasswordConfirmation = async ({ token, uid, newPassword, newPasswordConfirm }) =>
  api(`users/password-reset-confirm`, {
    method: 'POST',
    body: JSON.stringify({
      token,
      uid,
      newPassword,
      newPasswordConfirm,
    }),
  });

export const updatePassword = async ({ oldPassword: op, newPassword: np }) =>
  api(`users/update_password`, {
    method: 'PUT',
    body: JSON.stringify({
      op,
      np,
    }),
  });
