type EngagementContextData = {
  name: string;
  count: number;
};

const renameMappings = {
  subscribers: 'YT Subscribers',
  youtube: 'WMX Video',
};

const renameEntry = (entry) => renameMappings[entry.toLowerCase()] || entry;
export const engagementContextHelper = (data) => {
  const entries: EngagementContextData[] = [];

  Object.keys(data).forEach((key) => {
    data[key]
      .filter(
        (d) =>
          d.name.toLowerCase() !== 'other' &&
          d.name.toLowerCase() !== 'video' &&
          d.name.toLowerCase() !== 'music' &&
          d.name.toLowerCase() !== 'subscribers' &&
          !d.name.toLowerCase().includes('photos'),
      )
      .forEach((i) => {
        entries.push({
          count: i.count,
          name: renameEntry(i.name),
        });
      });
  });

  return entries;
};
