import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuth } from 'contexts/AuthContext';
import AppContainer from 'containers/AppContainer';
import { CollectionProvider } from 'pages/CollectionList/context';
import { ErrorFallback } from 'components/ErrorFallback';

export const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { state } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        state.token && state.user ? (
          <AppContainer>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <CollectionProvider>
                <Component {...props} />
              </CollectionProvider>
            </ErrorBoundary>
          </AppContainer>
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  );
};
