import React from 'react';

import { ArtistDetailHeader } from './components/ArtistDetailHeader';
import { FilterByCountryProvider } from './components/FilterByCountry/context';
import ArtistDetailContent from './components/ArtistDetailContent';

const ArtistDetail = () => (
  <FilterByCountryProvider>
    <ArtistDetailHeader />
    <ArtistDetailContent />
  </FilterByCountryProvider>
);

export default ArtistDetail;
