import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from './routes';
import { PublicRoute } from './components/PublicRoute';
import { AuthenticatedRoute } from './components/AuthenticatedRoute';

const NavigationContainer = () => {
  return (
    <Router>
      <Switch>
        {routes.map((d) =>
          d.public ? (
            <PublicRoute key={d.slug} path={d.path} component={d.component} />
          ) : (
            <AuthenticatedRoute key={d.slug} exact={d.exact} path={d.path} component={d.component} />
          ),
        )}
      </Switch>
    </Router>
  );
};

export default NavigationContainer;
