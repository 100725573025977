import React from 'react';
import { combineClasses } from 'utils/classNames';
import './style.css';

const FilterItem = ({ active, label, onClick }) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    onClick();
  };
  const styles = combineClasses(['filterItem', active && 'active']);
  return (
    <a href="" className={styles} onClick={handleOnClick}>
      {label}
    </a>
  );
};

export const DateRangeFilter = ({ options, activeFilter, onChange }) => {
  return (
    <div className="row dateRangeFilter">
      {options.map((d) => (
        <FilterItem key={d.value} onClick={() => onChange(d)} label={d.label} active={activeFilter.value === d.value} />
      ))}
    </div>
  );
};
