import React from 'react';
import { Page } from 'components/Page';
import { CollectionGrid } from './components/CollectionGrid';
import { useCollection } from './context';
import { Button } from 'components/Button';
import Icon, { IconType } from 'components/Icon';
import { Colors } from 'utils/colors';
import { useAuth } from 'contexts/AuthContext';
import { getCustomAudiences } from 'pages/CustomAudience/api';
import { useQuery } from 'react-query';

const CollectionList = () => {
  const {
    state: { items },
    showAddCollectionModal,
  } = useCollection();

  const { data } = useQuery('customAudiences', () => getCustomAudiences(), {
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const {
    state: { user },
  } = useAuth();

  const username = React.useMemo(() => user.email.split('@')[0], [user]);
  const activatedCollections = React.useMemo(
    () => (data ? data.filter((c) => c.audience && c.name.includes(username)) : false),
    [data, username],
  );

  return (
    <Page>
      <div className="row">
        <Button secondary onClick={() => showAddCollectionModal()} icon={IconType.plus}>
          Create Collection
        </Button>
      </div>
      {items && !items.length && (
        <div className="column center flex">
          <div className="row center flex">
            <Icon type={IconType.heartStroke} color={Colors.grey2} size={200} />
          </div>
        </div>
      )}
      {items && !!items.length && (
        <CollectionGrid
          data={items.map((d) => ({
            ...d,
            customAudience: activatedCollections && activatedCollections.find((a) => a.name.endsWith(d.name)),
          }))}
        />
      )}
    </Page>
  );
};

export default CollectionList;
