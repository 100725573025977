import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';

interface ModalProps {
  children: React.ReactNode;
  visible?: boolean;
  style?: React.CSSProperties;
}

export const Modal = ({ children, visible, style = {} }: ModalProps) => {
  if (!visible) return null;
  return ReactDOM.createPortal(
    <div className="modal" style={{ ...style }}>
      {children}
    </div>,
    document.body,
  );
};
