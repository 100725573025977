import React from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useAuth } from 'contexts/AuthContext';
import { TextInput } from 'components/TextInput';
import { Button } from 'components/Button';
import { ErrorMessage } from 'components/ErrorMessage';
import { Logo } from 'components/Logo';

export const SignInForm = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const {
    signIn,
    state: { error },
  } = useAuth();

  const onSubmit = async (data) => {
    setLoading(true);
    await signIn(data);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    history.push(`/forgot-password`);
  };

  React.useEffect(() => {
    if (error) setLoading(false);
  }, [error]);

  return (
    <div className="column card center gap">
      <div className="v-spacing row center">
        <Logo />
      </div>
      {error && <ErrorMessage message={error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="column">
          <div className="column gap">
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextInput placeholder="E-mail" error={errors.clientName} {...field} />
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextInput secure placeholder="Password" error={errors.clientName} {...field} />
              )}
            />
            <Button primary submit onClick={handleSubmit(onSubmit)} loading={loading}>
              Login
            </Button>
            <div className="row">
              <span className="flex" />
              <a href="/" className="secondary underline" onClick={handleForgotPassword}>
                Forgot password
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
