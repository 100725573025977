import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { Checkbox } from 'components/Checkbox';
import Icon, { IconType } from 'components/Icon';
import { IconButton } from 'components/IconButton';
import { Modal } from 'components/Modal';
import { useAuth } from 'contexts/AuthContext';
import { deleteDestination, updateDestination } from 'pages/CustomAudience/api';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AudienceExtensionModal } from '../AudienceExtensionModal';
import GamStaholderModal from '../GamStakeholderModal';

const isGAM = (destinationId: string) => destinationId === '29011727';

export const EditDestinationsModal = ({ onClose, isVisible, audiences }) => {
  const queryClient = useQueryClient();
  const {
    state: {
      user: { destinations },
    },
  } = useAuth();

  const [showAudiences, setShowAudiences] = React.useState(false);
  const [showAudienceExtension, setShowAudienceExtension] = React.useState(false);
  const [selectedDestinations, setSelectedDestinations] = React.useState<number[]>([]);
  const [isAddingAdditionalParticipants, setIsAddingAdditionalParticipants] = React.useState(false);
  const [additionalParticipants, setAdditionalParticipants] = React.useState<string[]>([]);
  const [additionalParticipantsQuery, setAdditionalParticipantsQuery] = React.useState<string>('');

  const preselectedDestinations = React.useMemo(
    () => audiences.map((a) => a.destinations.map((d) => d)).flat(),
    [audiences],
  );

  React.useEffect(() => {
    setSelectedDestinations(preselectedDestinations.map((d) => d.id));
  }, [preselectedDestinations]);

  const { mutateAsync: updateDestinationAsync, isLoading: isUpdating } = useMutation(updateDestination);

  const { mutateAsync: deleteDestinationAsync, isLoading: isDeleting } = useMutation(deleteDestination);

  const isLoading = React.useMemo(() => isUpdating || isDeleting, [isDeleting, isUpdating]);

  const handleClose = () => {
    setShowAudienceExtension(false);
    setSelectedDestinations([]);
    onClose();
  };

  const handleUpdate = async () => {
    const deletes = preselectedDestinations
      .filter((d) => !selectedDestinations.some((s) => d.id === s))
      .map((d) => d.display_segment_id);

    const updates = audiences
      .map((a) =>
        selectedDestinations
          .filter((destinationId) =>
            preselectedDestinations.length ? !preselectedDestinations.some((p) => p.id === destinationId) : true,
          )
          .map((id) => {
            const destination = destinations.find((d) => d.id === id);
            const displaySegment = {
              segmentId: a.segment_id,
              destinationId: id,
              additionalParticipants: destination && isGAM(destination?.account_id) ? additionalParticipants : [],
            };
            return displaySegment;
          }),
      )
      .flat();

    await Promise.all([...deletes.map(deleteDestinationAsync), ...updates.map(updateDestinationAsync)]);
    await queryClient.invalidateQueries('customAudiences');

    onClose();
  };

  const handleSelection = (id) => {
    setSelectedDestinations((_destinations) =>
      _destinations.some((i) => i === id) ? _destinations.filter((i) => i !== id) : [id, ..._destinations],
    );
  };

  const handleAdditionalParticipantSelection = (id) => {
    setAdditionalParticipants((participant) =>
      participant.some((i) => i === id) ? participant.filter((i) => i !== id) : [id, ...participant],
    );
    setAdditionalParticipantsQuery('');
  };

  const onParticipantsQuery = (e) => {
    e.preventDefault();

    const { value } = e.target;
    setAdditionalParticipantsQuery(value);
  };

  if (!isVisible) return null;

  return (
    <>
      <Modal visible={!!isVisible}>
        <Card>
          <div className="column gap-m">
            <div className="row">
              <div className="column flex">
                <h3 className="flex">Edit destinations</h3>
                {audiences.length === 1 && <div className="secondary column">{audiences[0].name}</div>}
              </div>
              <IconButton icon={IconType.cross} onClick={handleClose} />
            </div>
            {audiences.length > 1 && (
              <div
                className="column gap"
                style={{
                  padding: 10,
                  minWidth: 300,
                  backgroundColor: 'var(--wmg-color-background)',
                  borderRadius: 'var(--wmg-border-radius-small)',
                }}
              >
                <div className="row" onClick={() => setShowAudiences(!showAudiences)}>
                  <Icon type={IconType.info} />
                  <div className={`${!showAudiences && 'secondary'} column gap flex`}>
                    {audiences.length} Audiences are selected
                  </div>
                  <span className="secondary">{showAudiences ? 'Hide' : 'Show'}</span>
                </div>
                {showAudiences && (
                  <div
                    className="column gap scrollbar"
                    style={{
                      maxHeight: 150,
                      paddingLeft: 32,
                    }}
                  >
                    {audiences.map((a, i) => (
                      <div key={i} className="secondary column">
                        {a.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            <div className="row gap">
              <div className="column gap scrollbar" style={{ maxHeight: 200 }}>
                {destinations &&
                  !showAudiences &&
                  destinations.map((d, i) => (
                    <div className="column gap">
                      <div key={i} className="row center" onClick={handleSelection.bind(null, d.id)}>
                        <Checkbox
                          onClick={handleSelection.bind(null, d.id)}
                          checked={selectedDestinations.some((e) => d.id === e)}
                        />
                        <span key={d.name}>{d.name}</span>
                        <span className="secondary">{` Acc:(${d.account_id})`}</span>
                        {isGAM(d.account_id) && !isAddingAdditionalParticipants && (
                          <div className="row end">
                            <Button
                              sml
                              disabled={!selectedDestinations.some((e) => d.id === e)}
                              onClick={setIsAddingAdditionalParticipants.bind(null, true)}
                              // secondary
                            >
                              {`${!!additionalParticipants.length ? 'Update' : 'Add'} Stakeholder`}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                <div className="row center" onClick={() => setShowAudienceExtension(true)}>
                  <Checkbox onClick={() => setShowAudienceExtension(true)} checked={false} />
                  <span>{'wmXtension'}</span>
                </div>
              </div>
            </div>
            <Button primary onClick={handleUpdate} loading={isLoading} disabled={isLoading}>
              Save
            </Button>
          </div>
        </Card>
      </Modal>
      <GamStaholderModal
        options={additionalParticipants}
        query={additionalParticipantsQuery}
        onChange={onParticipantsQuery}
        onEnter={handleAdditionalParticipantSelection.bind(null, additionalParticipantsQuery)}
        onRemove={handleAdditionalParticipantSelection}
        isVisible={isAddingAdditionalParticipants}
        onClose={setIsAddingAdditionalParticipants.bind(null, false)}
      />
      <AudienceExtensionModal onClose={handleClose} isVisible={showAudienceExtension} />
    </>
  );
};
