import React, { useState } from 'react';
import { Button } from '../../../../components/Button';
import Icon, { IconType } from '../../../../components/Icon';
import html2canvas from 'html2canvas';
import PopOver, { PopOverItem } from 'components/PopOver';
import './style.css';

interface DownloadButtonProps {
  artistName: string;
  disabled: boolean;
}

const DownloadButton = ({ artistName, disabled }: DownloadButtonProps) => {
  const [isPopOverVisible, setIsPopOverVisible] = useState(false);

  const onToggleDownloadList = (e) => {
    e.preventDefault();
    setIsPopOverVisible(true);
  };

  const downloadPng = () => {
    {
      const tableElems = document.querySelectorAll('.table-content');
      tableElems.forEach((t) => t.setAttribute('style', 'overflow: hidden;'));
      const tallTableCards = document.querySelectorAll('.table-card:not(.static-height) .card');
      const countryTable = document.querySelector('.country-table');
      if (countryTable) {
        countryTable.setAttribute('style', 'height: 110%;');
      }
      tallTableCards.forEach((a) => a.setAttribute('style', 'height: 800px; max-height: 800px;'));
      tallTableCards.forEach((e) => e.classList.add('export-png'));
      const headerEl = document.querySelector('.artist-detail-header');
      let isAlreadyScrolled = false;
      if (headerEl) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        headerEl.classList.contains('scrolled') ? (isAlreadyScrolled = true) : headerEl.classList.add('scrolled');
      }

      setIsPopOverVisible(false);
      html2canvas(document.body, {
        scrollX: 0,
        scrollY: 0,
        foreignObjectRendering: true,
        windowWidth: 1426,
        backgroundColor: 'rgba(18, 18, 18, 1)',
      }).then(function (canvas) {
        const MIME_TYPE = 'image/png';

        const date = new Date().toLocaleDateString();

        const imgURL = canvas.toDataURL(MIME_TYPE);

        const dlLink = document.createElement('a');
        dlLink.download = artistName + date;
        dlLink.href = imgURL;
        dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':');

        document.body.appendChild(dlLink);
        dlLink.click();
        document.body.removeChild(dlLink);
      });
      countryTable?.removeAttribute('style');
      if (!isAlreadyScrolled) {
        headerEl?.classList.remove('scrolled');
      }
      tallTableCards.forEach((t) => t.removeAttribute('style'));
      tableElems.forEach((t) => t.removeAttribute('style'));
    }
  };

  return (
    <div className="export-option-container" data-html2canvas-ignore="true">
      <Button disabled={disabled} dark icon={IconType.download} onClick={onToggleDownloadList}>
        {' '}
      </Button>
      <div className="export">
        <PopOver isVisible={isPopOverVisible} onCancel={() => setIsPopOverVisible(false)} bottom>
          <PopOverItem onClick={downloadPng}>
            <Icon type={IconType.image} /> <span>{'Export as PNG'}</span>
          </PopOverItem>
        </PopOver>
      </div>
    </div>
  );
};

export default DownloadButton;
