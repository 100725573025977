export const abbreviateNumber = (value, place = 0) => {
  let newValue = value;
  const suffixes = ['', 'k', 'm', 'b', 't'];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  newValue = newValue.toFixed(place).toLocaleString();
  newValue += suffixes[suffixNum];
  return newValue;
};

export const abbreviateWholeNumber = (number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + 'b';
  } else if (number >= 1000000) {
    return (number / 1000000).toString() + 'm';
  } else if (number >= 1000) {
    return (number / 1000).toString() + 'k';
  } else {
    return number.toString();
  }
};
