import React from 'react';
import { ReactComponent as AppleIcon } from 'assets/icons/apple.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_down.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EyeCrossIcon } from 'assets/icons/eye_cross.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/Image.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow_right.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter_fill.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search_stroke.svg';
import { ReactComponent as NoArtworkIcon } from 'assets/icons/no_artwork.svg';
import { ReactComponent as HeartIcon } from 'assets/icons/heart_fill.svg';
import { ReactComponent as HeartStrokeIcon } from 'assets/icons/heart_stroke.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';
import { ReactComponent as SortDescIcon } from 'assets/icons/DownArrow.svg';
import { ReactComponent as SortAscIcon } from 'assets/icons/UpArrow.svg';
import { ReactComponent as VertDotsIcon } from 'assets/icons/vertical-dots.svg';
import { Colors } from 'utils/colors';

export enum IconType {
  apple = 'apple',
  arrowDown = 'arrowDown',
  arrowLeft = 'arrowLeft',
  arrowRight = 'arrowRight',
  ascSort = 'ascSort',
  descSort = 'descSort',
  download = 'download',
  eye = 'eye',
  eyeCross = 'eyeCross',
  image = 'image',
  info = 'info',
  filter = 'filter',
  search = 'search',
  noArtwork = 'noArtwork',
  heart = 'heart',
  heartStroke = 'heartStroke',
  user = 'user',
  plus = 'plus',
  check = 'check',
  cross = 'cross',
  trash = 'trash',
  vertDots = 'vertDots',
}

interface IconProps {
  type: IconType;
  color?: string;
  size?: number | string;
}

const Icon = ({ type, size = 24, color = Colors.white }: IconProps) => {
  const defaultIconStyle = { fill: color, height: size, width: size };

  return (
    (
      <div className="icon">
        {(type === IconType.apple && <AppleIcon style={defaultIconStyle} />) ||
          (type === IconType.download && <DownloadIcon style={defaultIconStyle} />) ||
          (type === IconType.ascSort && <SortAscIcon style={defaultIconStyle} />) ||
          (type === IconType.descSort && <SortDescIcon style={defaultIconStyle} />) ||
          (type === IconType.eye && <EyeIcon style={defaultIconStyle} />) ||
          (type === IconType.image && <ImageIcon style={defaultIconStyle} />) ||
          (type === IconType.info && <InfoIcon style={defaultIconStyle} />) ||
          (type === IconType.heart && <HeartIcon style={defaultIconStyle} />) ||
          (type === IconType.user && <UserIcon style={defaultIconStyle} />) ||
          (type === IconType.plus && <PlusIcon style={defaultIconStyle} />) ||
          (type === IconType.check && <CheckIcon style={defaultIconStyle} />) ||
          (type === IconType.cross && <CrossIcon style={defaultIconStyle} />) ||
          (type === IconType.vertDots && <VertDotsIcon style={defaultIconStyle} />) ||
          (type === IconType.trash && <TrashIcon style={defaultIconStyle} />) ||
          (type === IconType.heartStroke && <HeartStrokeIcon style={defaultIconStyle} />) ||
          (type === IconType.noArtwork && <NoArtworkIcon style={defaultIconStyle} />) ||
          (type === IconType.search && <SearchIcon style={defaultIconStyle} />) ||
          (type === IconType.filter && <FilterIcon style={defaultIconStyle} />) ||
          (type === IconType.eyeCross && <EyeCrossIcon style={defaultIconStyle} />) ||
          (type === IconType.arrowDown && <ArrowDownIcon style={defaultIconStyle} />) ||
          (type === IconType.arrowRight && <ArrowRightIcon style={defaultIconStyle} />) ||
          (type === IconType.arrowLeft && <ArrowLeftIcon style={defaultIconStyle} />)}
      </div>
    ) || null
  );
};

export default Icon;
