import React from 'react';
import { Card } from 'components/Card';
import { useQuery } from 'react-query';
import { getCollectionDemographicSummary } from '../../api';
import { useParams } from 'react-router-dom';

const ageList: string[] = ['18-20', '21-24', '25-34', '35-44', '45-54', '55-64', '65+'];

type LabelValueType = {
  label: string;
  value: number;
};

const DemographicSummary = ({ segments }) => {
  const { id } = useParams();
  const { data, isFetching: isLoading } = useQuery(
    `collection_demo_${id}`,
    () => getCollectionDemographicSummary(segments),
    { staleTime: 0, refetchOnWindowFocus: false },
  );

  const agePercentage = React.useMemo(
    () =>
      data
        ? ageList
            .reduce((acc: LabelValueType[], curr, i) => {
              const multiplier = curr === '18-20' ? 3 / 7 : curr === '21-24' ? 4 / 7 : 1;
              const ageGroup = i > 1 ? curr : '18-24';
              const totalByAge = data.reduce((sum, el) => (el.name.includes(ageGroup) ? (sum += el.percent) : sum), 0);

              return [
                ...acc,
                {
                  label: curr,
                  value: totalByAge * multiplier,
                },
              ];
            }, [])
            .map((d, i, l) => ({
              ...d,
              value: d.value / l.reduce((sum, el) => sum + el.value, 0),
            }))
        : [],
    [data],
  );

  const genderPercentage = React.useCallback(() => {
    if (!data) return [];
    const male: number = data.filter((d) => d.name.startsWith('male')).reduce((p, c) => p + c.percent, 0) * 100;
    const female = data.filter((d) => d.name.startsWith('female')).reduce((p, c) => p + c.percent, 0) * 100;
    return {
      male: ((male / (male + female)) * 100).toFixed(1),
      female: ((female / 100 / (male / 100 + female / 100)) * 100).toFixed(1),
    };
  }, [data]);

  if (isLoading)
    return (
      <Card sml placeholder flex>
        <div style={{ minHeight: 40 }} />
      </Card>
    );

  return (
    <Card sml>
      <div className="flex row gap-xl">
        <div className="gap-l">
          {['Male', 'Female'].map((label) => (
            <h5 key={label} className="row center gap">
              <span className="secondary">{label}</span>
              <span>{genderPercentage()[label.toLowerCase()]}%</span>
            </h5>
          ))}
        </div>
        <div className="caption row center gap-m">
          <div className="secondary column caption center">
            <div>Age</div>
            <div>%</div>
          </div>

          {agePercentage.map((d) => (
            <div key={d.label} className="column center">
              <div className="secondary">{d.label}</div>
              <div>{(d.value * 100).toFixed(1)}</div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default DemographicSummary;
