import React, { useEffect, useState } from 'react';
import './style.css';
import 'App.css';
import { ChartCard } from 'components/ChartCard';
import EngagementTypeDonutRing from './EngagementTypeDonutRing';
import EngagementTypeLegendItem from './EngagementTypeLegendItem';
import { abbreviateNumber } from '../../../../utils/numbers';

export const EngagementTypeChart = ({ data, isLoading }) => {
  const [accumulatedPercentages, setAccumulatedPercentages] = useState<number[]>([]);

  useEffect(() => {
    if (data) {
      const accPercentages = data.map((d, i) => {
        let tempPercent = 0;
        let x = i;
        while (x >= 0) {
          tempPercent += data[x].percent;
          x--;
        }
        return tempPercent;
      });
      setAccumulatedPercentages(accPercentages.reverse());
    }
  }, [data]);
  return (
    <ChartCard
      header={<div className="row center">Audience Segments</div>}
      isLoading={isLoading}
      noData={!isLoading && data.length === 0}
    >
      {!isLoading && !!data.length && !!accumulatedPercentages.length && (
        <div className="row center" style={{ width: '100%' }}>
          <div className="column" style={{ width: '35%', marginTop: '20px' }}>
            {data.map((d, i) => <EngagementTypeLegendItem data={d} index={i} key={i} />).reverse()}
          </div>
          <div className="svg-item">
            {accumulatedPercentages.map((p, i) => (
              <EngagementTypeDonutRing percent={p * 100} index={i} key={i} />
            ))}
            <div className="center column" style={{ position: 'absolute' }}>
              <h2>
                {abbreviateNumber(
                  data.reduce((s, d) => s + d.count, 0),
                  1,
                )}
              </h2>
              <span className="secondary caption" style={{ marginTop: -10 }}>
                total audience
              </span>
            </div>
          </div>
        </div>
      )}
    </ChartCard>
  );
};
