import React from 'react';
import { combineClasses } from 'utils/classNames';
import './style.css';

interface PaginationItemProps {
  key?: number;
  page?: number;
  currentPage?: number;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick: () => void;
}

export const PaginationItem = ({ page, currentPage, disabled, children, onClick }: PaginationItemProps) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    if (!disabled) onClick();
  };
  return (
    <a
      data-html2canvas-ignore="true"
      href=""
      className={combineClasses([
        'page-item',
        disabled && 'disabled',
        currentPage && currentPage === page && 'current',
      ])}
      onClick={handleOnClick}
    >
      {page && page}
      {children && children}
    </a>
  );
};
