import { getFilterLabel, getFilterList } from 'pages/ArtistList/components/Filters/api';
import { toTitleCase } from '../../../../utils/strings';

export const segmentDefinitionHelper = (segment__definition, filters) => {
  const genders: string[] = ['male', 'female'];
  const demoDef = segment__definition.demo
    ? segment__definition.demo.rules
        .map(({ values, operator, field: category }) => {
          const ageGroups = ['_18-24', '_25-34', '_35-44', '_45-54', '_55-64', '_65+'];
          const isOnlyGender = ageGroups.every((val) => values.some((d) => d.includes(val)));

          if (isOnlyGender) {
            const value = values[0].split('_')[0];
            const label = value.charAt(0).toUpperCase() + value.slice(1);

            return {
              value,
              label,
              category,
              operator,
            };
          }

          const gendersInDemo = Array.from(
            new Set(
              values.reduce((acc, curr) => {
                const parts = curr.split('_');
                if (genders.includes(parts[0])) {
                  acc.push(parts[0]);
                }
                return acc;
              }, []),
            ),
          );

          const isBothSexesGenderAge = gendersInDemo.length === 2;

          if (isBothSexesGenderAge) {
            const ages: string[] = Array.from(new Set(values.map((value) => value.split('_')[1]).sort()));
            return [...genders, ...ages].map((value: string) => ({
              value,
              label: value.charAt(0).toUpperCase() + value.slice(1),
              category,
              operator,
            }));
          }

          const isSingleSexGenderAge = gendersInDemo.length === 1;

          if (isSingleSexGenderAge) {
            const gender = gendersInDemo[0];
            const ages = Array.from(
              new Set(
                values.map((value) => {
                  const [, age] = value.split('_');
                  return age || null;
                }),
              ),
            )
              .filter((el) => el)
              .map((value) => ({
                value,
                label: value,
                category,
                operator,
              }));
            ages.push({
              value: gender,
              label: toTitleCase(gender),
              category,
              operator,
            });
            return ages;
          }

          const isOnlyAge = !gendersInDemo.length && ageGroups.some((val) => values.some((d) => d.includes(val)));

          if (isOnlyAge) {
            const ages = Array.from(
              new Set(
                values.map((value) => {
                  const [, age] = value.split('_');
                  return age || null;
                }),
              ),
            );

            return ages.map((value) => ({
              value,
              label: value,
              category,
              operator,
            }));
          }

          return values.map((value) => ({
            value,
            label: getFilterLabel(value, filters),
            category,
            operator,
          }));
        })
        .flat()
    : [];

  const rulesDef = segment__definition.rules
    .map(({ rules }) =>
      rules
        .map(({ values, operator, field: category }) =>
          values.map((value) => ({
            value,
            label: getFilterLabel(value, filters),
            category,
            operator,
          })),
        )
        .flat(),
    )
    .flat();

  return [...demoDef, ...rulesDef];
};

export const audienceHelper = async (audiences) => {
  const filterList = await getFilterList();

  return audiences.map(
    ({
      display_segment_id,
      display_segment__display_name,
      display_segment__size,
      segment__definition,
      ...audience
    }) => {
      const audienceValues = segment__definition.base.rules.some((d) => d.field === 'Audience')
        ? segment__definition.base.rules.filter((d) => d.field === 'Audience').map((d) => d.values[0])
        : false;
      return {
        ...audience,
        id: display_segment_id,
        name: display_segment__display_name,
        size: display_segment__size,
        audience: audienceValues,
        filters: segmentDefinitionHelper(segment__definition, filterList),
      };
    },
  );
};
