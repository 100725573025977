import React from 'react';

import { TooltipProps } from 'recharts';

const BarCustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip">
        <h2>{data.age}</h2>
        <div>
          <h3>Male</h3>
          <p>Count: {data.maleCount ? data.maleCount.toLocaleString() : 'n/a'}</p>
        </div>
        <div>
          <h3>Female</h3>
          <p>Count: {data.femaleCount ? data.femaleCount.toLocaleString() : 'n/a'}</p>
        </div>
      </div>
    );
  }
  return null;
};

export default BarCustomTooltip;
