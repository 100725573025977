export const Colors = {
  background: `var(--wmg-color-background)`,
  background2: `var(--wmg-color-background2)`,
  background3: `var(--wmg-color-background3)`,
  background4: `var(--wmg-color-background4)`,
  primary: `var(--wmg-color-primary)`,
  primary2: `var(--wmg-color-primary2)`,
  secondary: `var(--wmg-color-secondary)`,
  error: `var(--wmg-color-error)`,
  white: `var(--wmg-color-white)`,
  grey: `var(--wmg-color-grey)`,
  grey2: `var(--wmg-color-grey2)`,
};
