import React from 'react';

const PieChartLabel = ({ numeratorValue, totalValue, name }) => {
  return (
    <div className="column center">
      <h3>{((numeratorValue / totalValue) * 100).toFixed(1) + '%'}</h3>
      <div className="secondary" style={{ fontSize: 'var(--wmg-text-caption-size)' }}>
        {name}
      </div>
    </div>
  );
};

export default PieChartLabel;
