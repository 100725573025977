import React from 'react';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { useMutation, useQueryClient } from 'react-query';
import { Modal } from 'components/Modal';
import { deleteCollection } from '../CollectionCard/api';

export const DeleteCollectionModal = ({ isVisible, onClose, collectionId }) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(deleteCollection, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('collections');
    },
  });

  const handleDelete = async () => {
    await mutateAsync(collectionId);
    onClose();
  };

  return (
    <Modal visible={isVisible}>
      <Card>
        <div className="column center gap-l">
          <div className="bold flex">Are you sure you want to delete this collection?</div>
          <div className="row">
            <Button primary onClick={handleDelete} loading={isLoading} disabled={isLoading}>
              Yes
            </Button>
            <Button secondary onClick={onClose} disabled={isLoading}>
              No
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
