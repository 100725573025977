import { getFilterList } from 'pages/ArtistList/components/Filters/api';
import { segmentDefinitionHelper } from 'pages/CustomAudience/api/helpers';

export const metadataHelper = async (medatadata) => {
  const filterList = await getFilterList();

  const { definition, ...data } = await medatadata;
  if (!definition) return data;

  const filters = segmentDefinitionHelper(definition, filterList);
  return { ...data, filters };
};
