import { queryClient } from 'App';
import { Button } from 'components/Button';
import Icon, { IconType } from 'components/Icon';
import { Table } from 'components/Table';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import Spinner from 'react-svg-spinner';
import { SearchBar } from '../../SearchBar';
import { getFilterGroup, getFilterSuggestions, setFilterSuggestion } from '../api';
import ChildTableRow from './ChildTableRow';
import ParentTableRow from './ParentTableRow';
import './style.css';

const demographics = [
  {
    value: 'male',
    label: 'Male',
    category: 'age_gender_membership',
    operator: 'and',
  },
  {
    value: 'female',
    label: 'Female',
    operator: 'and',
    category: 'age_gender_membership',
  },
  { value: '18-24', label: '18-24', category: 'age_gender_membership' },
  { value: '25-34', label: '25-34', category: 'age_gender_membership' },
  { value: '35-44', label: '35-44', category: 'age_gender_membership' },
  { value: '45-54', label: '45-54', category: 'age_gender_membership' },
  { value: '55-64', label: '55-64', category: 'age_gender_membership' },
  { value: '65_+', label: '65+', category: 'age_gender_membership' },
];

export type GroupedFilterDataType = {
  operator: string;
  items: [];
};

export type FilterDataType = {
  value: string;
  label: string;
  category: string;
  categoryLabel?: string;
  operator?: string;
  children?: FilterDataType[];
};

const matchingChildren = (data, searchQuery, key) =>
  data.some(
    (c) => c[key].toLowerCase().includes(searchQuery) || (c.children && matchingChildren(c.children, searchQuery, key)),
  );

const searchTree = (data, searchQuery, key) =>
  searchQuery
    ? data
        .filter(
          (d) =>
            d[key].toLowerCase().startsWith(searchQuery) ||
            (d.children && matchingChildren(d.children, searchQuery, key)),
        )
        .map((d) => (d.children ? { ...d, children: searchTree(d.children, searchQuery, key) } : d))
    : data;

function searchTreeForMatches(data, query) {
  const matches: any = [];

  data.forEach((item: any) => {
    if (!item.children && item.label.toLowerCase().includes(query)) {
      matches.push(item);
    }
    if (item.children && searchTreeForMatches(item.children, query).length) {
      matches.push({
        ...item,
        children: searchTreeForMatches(item.children, query),
      });
    }

    // if (item.children) {
    //   matches = matches.concat(searchTreeForMatches(item.children, query));
    // }
  });

  return matches;
}

export const FilterDrawer = ({ filterGroup, onSelection, selectedFilters }) => {
  const { data, isLoading } = useQuery([`artist_filters_${filterGroup.value}`, filterGroup.value], () =>
    getFilterGroup(filterGroup.value),
  );

  const { data: suggestions, isLoading: isLoadingSuggestions } = useQuery<FilterDataType[]>(
    [`artist_filter_suggestions`, filterGroup.value],
    () => getFilterSuggestions(filterGroup.value),
  );

  const { mutate: addFilterSuggestion } = useMutation(setFilterSuggestion, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('artist_filter_suggestions');
    },
  });

  const isFemaleClicked = selectedFilters ? selectedFilters.findIndex((d) => d.value === 'female') !== -1 : false;

  const isMaleClicked = selectedFilters ? selectedFilters.findIndex((d) => d.value === 'male') !== -1 : false;

  const [searchQuery, setSearchQuery] = React.useState<string>('');

  const [filteredData, setFilteredData] = React.useState<FilterDataType[]>([]);

  const [selected, setSelected] = React.useState<FilterDataType>();

  const [buttonDisabled, setButtonDisabled] = React.useState<boolean[]>([isFemaleClicked, isMaleClicked]);

  React.useEffect(() => {
    if (!isLoading && data) {
      const matches = searchTreeForMatches(data, searchQuery);
      setFilteredData(matches);
      setSelected(filterGroup.value === 'interests' ? matches[0] : undefined);
    }
  }, [isLoading, searchQuery, data, filterGroup.value]);

  React.useEffect(() => {
    setSearchQuery('');
  }, [filterGroup.value]);

  const onQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onFilterSelection = async (filter) => {
    const clicked = selectedFilters ? selectedFilters.findIndex((d) => d.value === filter.value) : -1;
    // when the feamale button is clicked, the male button is supposed to be disabled; while the male button is clicked, the female button should be disabled.
    if (filter.value === 'female' || filter.value === 'male') {
      if (clicked !== -1) {
        setButtonDisabled([false, false]);
      } else {
        setButtonDisabled([filter.value === 'female', filter.value === 'male']);
      }
    }
    if (filterGroup.value !== 'interests') {
      setSearchQuery('');
    }

    await addFilterSuggestion([filter]);
    onSelection({ ...filter, operator: 'or' });
  };

  const isSelected = (filter) => {
    return selectedFilters.some((d) => d.value === filter.value && d.category === filter.category);
  };

  return (
    filterGroup && (
      <div className="drawer column gap">
        {filterGroup.value === 'demographic' && (
          <>
            <div className="caption secondary">GENDER</div>
            <div className="row">
              {demographics
                .filter((d) => ['male', 'female'].some((g) => g === d.value))
                .map((d, i) => (
                  <Button secondary key={d.value} onClick={() => onFilterSelection(d)} disabled={buttonDisabled[i]}>
                    <span style={{ paddingRight: isSelected(d) ? 10 : 0 }}>{d.label}</span>
                    {isSelected(d) ? (
                      <span style={{ position: 'absolute', right: 5 }}>
                        <Icon type={IconType.check} color="white" />{' '}
                      </span>
                    ) : (
                      ''
                    )}
                  </Button>
                ))}
            </div>
            <div className="caption secondary">AGE GROUP</div>
            <div className="row">
              {demographics
                .filter((d) => !['male', 'female'].some((g) => g === d.value))
                .map((d) => (
                  <Button secondary key={d.value} onClick={() => onFilterSelection(d)}>
                    <span style={{ paddingRight: isSelected(d) ? 10 : 0 }}>{d.label}</span>

                    {isSelected(d) ? (
                      <span style={{ position: 'absolute', right: 5 }}>
                        <Icon type={IconType.check} color="white" />{' '}
                      </span>
                    ) : (
                      ''
                    )}
                  </Button>
                ))}
            </div>
          </>
        )}
        {filterGroup.value !== 'demographic' && (
          <>
            <div className="row center" onClick={() => null}>
              <div className="caption secondary flex">QUICK PICKS</div>
            </div>
            <div className="row">
              {!isLoadingSuggestions &&
                suggestions?.slice(0, 5).map((suggestion) => (
                  <Button sml secondary key={suggestion.value} onClick={() => onFilterSelection(suggestion)}>
                    {suggestion.label.split('/').pop()}
                  </Button>
                ))}
            </div>
            <SearchBar compact query={searchQuery} onChange={onQueryChange} disabled={isLoading} />
            {isLoading && (
              <div className="column center flex">
                <div className="row center flex">
                  <Spinner size="32px" color="rgba(255,255,255,0.15)" />
                </div>
              </div>
            )}
            <div className="row gap-l" style={{ height: 250 }}>
              <div className="column flex">
                <Table isLoading={isLoading}>
                  {!isLoading &&
                    filteredData.map((d) => (
                      <>
                        <ParentTableRow
                          data={d}
                          selected={selected?.value === d.value}
                          onSelection={d.children ? setSelected : onFilterSelection}
                          isSelectedFilter={filterGroup.value !== 'interest' ? isSelected(d) : false}
                        />
                      </>
                    ))}
                </Table>
              </div>
              {selected && selected.children && (
                <div className="column flex">
                  <Table isLoading={isLoading}>
                    <ChildTableRow
                      isSelected={isSelected}
                      data={selected.children ? filteredData.find((d) => d.value === selected.value) : selected}
                      onSelection={onFilterSelection}
                    />
                  </Table>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    )
  );
};
