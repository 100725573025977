import React from 'react';
import { Bar, ComposedChart, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Rectangle } from 'recharts';

import { abbreviateWholeNumber } from 'utils/numbers';

import BarCustomTooltip from './BarCustomTooltip';

const ageList: string[] = ['18-20', '21-24', '25-34', '35-44', '45-54', '55-64', '65+'];

const AgeGenderBarChart = ({ ageGenderData }) => {
  let totalLDAAud = 0;
  const label = ageList.map((d, i) => {
    if (i != 0) {
      totalLDAAud += ageGenderData[i].maleCount + ageGenderData[i].femaleCount;
    }
    return (
      <div key={d} className="column center caption">
        <div>{d}</div>
        <div>{ageGenderData[i] && ageGenderData[i].totalPercent ? <>{ageGenderData[i].totalPercent}</> : 'n/a'}</div>
      </div>
    );
  });

  return (
    <div className="column center flex">
      <span className="caption secondary" style={{ alignSelf: 'flex-end', marginBottom: -10 }}>
        21+ Total: {totalLDAAud.toLocaleString()}
      </span>
      <ResponsiveContainer width={'100%'} height={200}>
        <ComposedChart
          barCategoryGap="20%"
          data={ageGenderData}
          margin={{
            top: 30,
            bottom: 10,
            left: -5,
            right: -10,
          }}
        >
          <CartesianGrid strokeDasharray="5 5" vertical={false} stroke="var(--wmg-color-grey2)" />
          <XAxis
            dy={18}
            tick={{
              fontSize: 'var(--wmg-text-caption-size)',
              fill: 'var(--wmg-color-grey)',
            }}
            dataKey="label"
            tickLine={false}
            axisLine={false}
          />
          <YAxis
            tickCount={3}
            tick={{
              fontSize: 'var(--wmg-text-caption-size)',
              fill: 'var(--wmg-color-grey)',
            }}
            axisLine={false}
            tickFormatter={abbreviateWholeNumber}
            tickLine={false}
          />
          <Tooltip content={<BarCustomTooltip />} />
          <Bar
            barSize={12}
            dataKey="femaleCount"
            stackId="a"
            fill="var(--wmg-color-primary)"
            shape={(props) => {
              if (props.maleCount === 0) {
                props.radius = [20, 20, 0, 0];
              }
              return <Rectangle {...props} />;
            }}
          />
          <Bar barSize={12} dataKey="maleCount" stackId="a" fill="var(--wmg-color-secondary)" radius={[20, 20, 0, 0]} />
        </ComposedChart>
      </ResponsiveContainer>
      <div
        className="caption row center secondary flex"
        style={{
          justifyContent: 'space-between',
          alignSelf: 'normal',
          marginLeft: '6%',
          marginTop: '-6%',
          marginRight: '0%',
        }}
      >
        <div className="column">
          <div>Age</div>
          <div>%</div>
        </div>
        {label}
      </div>
    </div>
  );
};

export default AgeGenderBarChart;
